import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/AxiosInstance";
import { revertAll } from "../ResetStateAction";

const initialState = {
  UserRolesData: null,
  RolesPopulateData: null,
  AllRolesData: null,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

export const fetchUserRolesData = createAsyncThunk(
  "/Role/GetAllRoles",
  async (id) => {
    const response = await axiosInstance.get(`/User/getUserRole/${id}`);
    return response?.data;
  }
);

export const fetchRolePopulateData = createAsyncThunk(
  "/Role/Populate",
  async () => {
    const response = await axiosInstance.get(`/Role/Populate`);
    return response?.data;
  }
);

export const fetchAllRolesData = createAsyncThunk("/Role/GetAll", async () => {
  const response = await axiosInstance.get(`/Role/GetAll`);
  return response?.data;
});

const RolesSlice = createSlice({
  name: "RolesSlice",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchUserRolesData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserRolesData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.UserRolesData = action.payload.data;
      })
      .addCase(fetchUserRolesData.rejected, (state, action) => {
        state.UserRolesData = null;
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchRolePopulateData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchRolePopulateData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.RolesPopulateData = action.payload.data;
      })
      .addCase(fetchRolePopulateData.rejected, (state, action) => {
        state.RolesPopulateData = null;
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchAllRolesData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllRolesData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.AllRolesData = action.payload.data;
      })
      .addCase(fetchAllRolesData.rejected, (state, action) => {
        state.AllRolesData = null;
        state.error = action.error.message;
        state.status = "failed";
      });
  },
});
export const selectRolesState = (state) => state.RolesSlice;

export default RolesSlice.reducer;
