import { React, useState, useContext, useEffect } from "react";
import { useFormik } from "formik";
import { ThreeDots } from "react-loader-spinner";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { BreadcrumbsContext } from "../../context/BcrumbsContextProvider";
import { City, Country, State } from "country-state-city";
import * as Yup from "yup";
import { SnackBarContext } from "../../context/SnackBarContext";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCompanyData,
  fetchCompanyPopulate,
} from "../../redux/slice/CompanySlice/CompanySlice";
import PropTypes from "prop-types";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { CreateSiteApi, UpdateSiteApi } from "../../Apis/Sites/SitesApis";
function SitesForm({ parent, onClose, setFieldValue }) {
  const { CompanyPopulate, statusPopulate } = useSelector(
    (state) => state?.Companies
  );

  const siteData = useLocation();

  const { setBreadcrumbsItems } = useContext(BreadcrumbsContext);
  const handleBreadcrumbs = () => {
    setBreadcrumbsItems([
      {
        id: 1,
        url: "/sites",
        name: "Sites",
      },
      {
        id: 2,
        url: "/sites/form",
        name: "Form",
      },
    ]);
  };

  useEffect(() => {
    handleBreadcrumbs();
    return () => {
      setBreadcrumbsItems([]);
    };
  }, [setBreadcrumbsItems]);
  const [loading, setLoading] = useState(false);
  const { setsnackBarData } = useContext(SnackBarContext);
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);

  const [cities, setCities] = useState([]);
  const [update] = useState(siteData?.state?.id ? true : false);
  console.log("this is update dtaa", siteData?.state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchCompanyPopulate());
  }, [dispatch]);
  const validationSchema = Yup.object({
    phoneNumber: Yup.string().required("Field required!"),
    email: Yup.string()
      .email("Enter a valid Email")
      .required("Field required!"),

    address: Yup.string().required("Field required!"),
    city: cities?.length
      ? Yup.string().required("Field required!")
      : Yup.string(),
    state: states.length
      ? Yup.string().required("Field required!")
      : Yup.string(),
    country: Yup.string().required("Field required!"),

    name: Yup.string().required("Field required!"),
    company: Yup.string().required("Field required!"),
    jobsiteNo: Yup.string().required("Field required!"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      address: "",
      email: "",
      state: "",
      city: "",
      country: "",
      phoneNumber: "",
      company: "",
      jobsiteNo: "",
    },
    validationSchema: validationSchema,

    onSubmit: async (values, { resetForm }) => {
      setLoading(true);

      if (siteData?.state?.id) {
        const response = await UpdateSiteApi(siteData?.state?._id, values);
        setLoading(false);
        response?.snackBarData?.message &&
          setsnackBarData(response?.snackBarData);
        if (response?.success) {
          dispatch(fetchCompanyData());
          resetForm();
          navigate(-1);
        }
      } else {
        const response = await CreateSiteApi(values);
        setLoading(false);

        response?.snackBarData?.message &&
          setsnackBarData(response?.snackBarData);
        if (response?.success) {
          // dispatch(fetchAllReceivings());
          navigate("/sites");
          resetForm();
          if (parent == "dialog") {
            onClose(true);
            setFieldValue("companyId", response?.data?.companyId);
          } else {
            navigate("/sites");
          }
        }
      }
    },
  });
  useEffect(() => {
    let value = Country.getAllCountries();
    value = value?.map((data) => {
      let obj = {
        label: data?.name,
        value: data?.isoCode,
      };
      return obj;
    });
    setCountries(value);
  }, []);
  useEffect(() => {
    if (formik.values.country) {
      let selectedCountry = countries.find(
        (country) => country.label === formik.values.country
      );
      let value = State.getStatesOfCountry(selectedCountry?.value);
      console.log("selectedCountry", value);

      value = value?.map((data) => {
        let obj = {
          label: data?.name,
          value: data?.isoCode,
        };
        return obj;
      });
      setStates(value);
    }
  }, [formik.values.country, countries]);
  console.log("formik.values", formik.values);
  useEffect(() => {
    if (formik.values.state && states) {
      let selectedCountry = countries.find(
        (country) => country.label === formik.values.country
      );
      let selectedState = states.find(
        (state) => state.label === formik.values.state
      );
      let value = City.getCitiesOfState(
        selectedCountry?.value,
        selectedState?.value
      );
      console.log("value", selectedCountry?.value, selectedState?.value);
      value = value?.map((data) => {
        let obj = {
          label: data?.name,
          value: data?.name,
        };
        return obj;
      });
      setCities(value);
    } else {
      setCities([]);
    }
  }, [formik.values.state, formik.values.country, states]);
  useEffect(() => {
    if (update) {
      console.log("siteData", siteData?.state);
      formik.setFieldValue("name", siteData.state?.name);
      formik.setFieldValue("email", siteData.state?.email);
      formik.setFieldValue("phoneNumber", siteData.state?.phoneNumber);
      formik.setFieldValue("state", siteData.state?.state);
      formik.setFieldValue("country", siteData.state?.country);
      formik.setFieldValue("city", siteData.state?.city);
      formik.setFieldValue("address", siteData.state?.address);
      formik.setFieldValue("jobsiteNo", siteData.state?.jobsiteNo);
      formik.setFieldValue("company", siteData.state?.companyId);
    }
  }, [siteData, CompanyPopulate]);
  console.log(formik.values);
  return (
    <div style={{ overflowY: "scroll", overflowX: "hidden", height: "100vh" }}>
      <form
        noValidate
        autoComplete="off"
        onSubmit={formik.handleSubmit}
        className="form-container"
      >
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div
                    className="col-12 col-md-6"
                    style={{ marginTop: "10px" }}
                  >
                    <div className="form-group">
                      <label htmlFor="name">Site Name</label>
                      <input
                        id="name"
                        name="name"
                        type="text"
                        className={`form-control ${
                          formik.touched.name && formik.errors.name
                            ? "is-invalid"
                            : ""
                        }`}
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <div className="invalid-feedback">
                        {formik.touched.name && formik.errors.name}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group" style={{ marginTop: "10px" }}>
                      <label htmlFor="jobsiteNo">Site #</label>
                      <input
                        id="jobsiteNo"
                        name="jobsiteNo"
                        type="text"
                        className={`form-control ${
                          formik.touched.jobsiteNo && formik.errors.jobsiteNo
                            ? "is-invalid"
                            : ""
                        }`}
                        value={formik.values.jobsiteNo}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <div className="invalid-feedback">
                        {formik.touched.jobsiteNo && formik.errors.jobsiteNo}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div
                      className="form-group"
                      style={{ marginTop: "13px", marginBottom: "13px" }}
                    >
                      <label htmlFor="company">Company</label>
                      <Select
                        required
                        id="company"
                        name="company"
                        options={CompanyPopulate?.map((item) => ({
                          value: item.id,
                          label: item.companyName,
                        }))}
                        value={CompanyPopulate?.map((item) => ({
                          value: item.id,
                          label: item.companyName,
                        })).find(
                          (option) => option.value === formik.values?.company
                        )}
                        onBlur={formik.handleBlur}
                        onChange={(selectedOption) => {
                          formik.setFieldValue(
                            "company",
                            selectedOption?.value
                          );
                        }}
                        className={`react-select-container ${
                          formik.touched.company && formik.errors.company
                            ? "border border-danger rounded"
                            : ""
                        }`}
                        classNamePrefix="react-select"
                      />
                      <div className="text-sm text-danger">
                        {formik.touched.company && formik.errors.company}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <></>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label htmlFor="phoneNumber">Phone</label>
                      <PhoneInput
                        country={"us"}
                        disableAreaCodes={true}
                        onlyCountries={["us"]}
                        value={formik.values?.phoneNumber}
                        onChange={(value) => {
                          formik.setFieldValue("phoneNumber", value);
                        }}
                        onBlur={formik.handleBlur}
                        inputProps={{
                          name: "phoneNumber",
                          id: "phoneNumber",
                        }}
                        containerClass="react-tel-input w-100"
                        inputClass={`form-control w-100 ${
                          formik.touched.phoneNumber &&
                          formik.errors.phoneNumber
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                      <div className="invalid-feedback">
                        {formik.touched.phoneNumber &&
                          formik.errors.phoneNumber}
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <input
                        id="email"
                        type="email"
                        name="email"
                        className={`form-control ${
                          formik.touched.email && formik.errors.email
                            ? "is-invalid"
                            : ""
                        }`}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <div className="invalid-feedback">
                        {formik.touched.email && formik.errors.email}
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    {/* <h4 className="mb-3">Company Address</h4> */}
                    {/* <hr /> */}
                    <div
                      className="form-group"
                      style={{ marginTop: "13px", marginBottom: "13px" }}
                    >
                      <label htmlFor="address">Address</label>
                      <textarea
                        id="address"
                        name="address"
                        className={`form-control ${
                          formik.touched.address && formik.errors.address
                            ? "is-invalid"
                            : ""
                        }`}
                        rows="3"
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <div className="invalid-feedback">
                        {formik.touched.address && formik.errors.address}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group">
                      <label htmlFor="country">Country</label>
                      <Select
                        required
                        id="country"
                        name="country"
                        options={countries}
                        value={countries.find(
                          (option) => option.label === formik.values?.country
                        )}
                        onBlur={formik.handleBlur}
                        onChange={(selectedOption) => {
                          formik.setFieldValue(
                            "country",
                            selectedOption?.label
                          );
                          formik.setFieldValue("city", "");
                          formik.setFieldValue("state", "");
                        }}
                        className={`react-select-container ${
                          formik.touched.country && formik.errors.country
                            ? "border border-danger rounded"
                            : ""
                        }`}
                        classNamePrefix="react-select"
                      />
                      <div className="text-sm text-danger">
                        {formik.touched.country && formik.errors.country}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group">
                      <label htmlFor="state">State</label>
                      <Select
                        id="state"
                        name="state"
                        options={states}
                        value={states.find(
                          (option) => option.label === formik.values?.state
                        )}
                        onBlur={formik.handleBlur}
                        onChange={(selectedOption) => {
                          formik.setFieldValue("state", selectedOption?.label);
                          formik.setFieldValue("city", "");
                        }}
                        className={`react-select-container ${
                          formik.touched.state && formik.errors.state
                            ? "border border-danger rounded"
                            : ""
                        }`}
                        classNamePrefix="react-select"
                      />
                      <div className="text-sm text-danger">
                        {formik.touched.state && formik.errors.state}
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-4">
                    <div className="form-group">
                      <label htmlFor="city">City</label>
                      <Select
                        id="city"
                        name="city"
                        options={cities}
                        value={cities.find(
                          (option) => option.label === formik.values?.city
                        )}
                        onBlur={formik.handleBlur}
                        onChange={(selectedOption) => {
                          formik.setFieldValue("city", selectedOption?.label);
                        }}
                        className={`react-select-container ${
                          formik.touched.city && formik.errors.city
                            ? "border border-danger rounded"
                            : ""
                        }`}
                        classNamePrefix="react-select"
                      />
                      <div className="text-sm text-danger">
                        {formik.touched.city && formik.errors.city}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-end align-items-center mt-4 gap-3">
          <ThreeDots
            height="40"
            width="40"
            radius="9"
            color="#1c4e80 "
            visible={loading}
          />
          {parent == "screen" && (
            <button
              type="button"
              className=" btn-secondary"
              onClick={() => navigate(-1)}
            >
              Back
            </button>
          )}
          <button
            type="submit"
            className="btn btn-primary"
            disabled={loading || statusPopulate === "loading"}
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
}

export default SitesForm;
SitesForm.propTypes = {
  parent: PropTypes.string,
  onClose: PropTypes.func,
  screen: PropTypes.string,
  setFieldValue: PropTypes.func,
};
