import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Spinner } from "react-bootstrap";
import { UpdateUserStatusApi } from "../../Apis/Users/CreateUserApi";
import { SnackBarContext } from "../../context/SnackBarContext";
import { fetchUsersData } from "../../redux/slice/UsersSlice/UsersSlice";
import { useDispatch } from "react-redux";

const StatusDialog = ({ hideClose, open, onClose, state }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { setsnackBarData } = useContext(SnackBarContext);
  const styles = {
    mainContainer: {
      padding: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      gap: "30px",
    },
    iconContainer: {
      marginBottom: "5px",
      // backgroundColor: iconContainerColor,
      padding: "15px",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    title: {
      fontSize: "17px",
      fontWeight: 600,
      lineHeight: 1.5,
      textAlign: "center",
    },
    subtitle: {
      fontSize: "12px",
      fontWeight: 600,
      color: "GrayText",
      lineHeight: 1.5,
      textAlign: "center",
    },
    button: {
      fontWeight: 600,
    },
  };
  const handleUpdateStatus = async () => {
    setLoading(true);
    try {
      const payload = {
        userId: state.id,
        status: state?.status === "Active" ? "Inactive" : "Active",
      };

      const response = await UpdateUserStatusApi(payload);
      setsnackBarData(response?.snackBarData);
      console.log("response", response);
      if (response?.success) {
        dispatch(fetchUsersData());
        onClose();
        setLoading(false);
      } else {
        console.log("failed");
        onClose();
        setLoading(false);
      }
    } catch (error) {
      console.error("Error updating user status:", error);
      onClose();
      setLoading(false);
    }
  };

  return (
    <Modal show={open} onHide={onClose} centered>
      <div style={styles.container}>
        <Modal.Header closeButton={!hideClose}>
          <Modal.Title
            style={styles.title}
            className="d-flex align-items-center justify-content-center gap-2"
          >
            {" "}
            Update Status
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={styles.subtitle}>
            {" "}
            Do you want to change user status to &quot;
            {state?.status === "Active" ? "InActive" : "Active"}
          </p>
        </Modal.Body>
        <Modal.Footer>
          {loading ? (
            <Spinner animation="border" />
          ) : (
            <>
              <Button
                variant="secondary"
                onClick={onClose}
                style={{ flex: 1, ...styles.button }}
              >
                No
              </Button>
              <Button
                variant="primary"
                onClick={handleUpdateStatus}
                style={{ flex: 1, ...styles.button }}
              >
                {state?.status === "Active" ? "InActive" : "Active"}
              </Button>
            </>
          )}
        </Modal.Footer>
      </div>
    </Modal>
  );
};

StatusDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  hideClose: PropTypes.bool,
  state: PropTypes.any,
};

export default StatusDialog;
