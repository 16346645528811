import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SnackBarContext } from "../../context/SnackBarContext";
import { fetchCompanyData } from "../../redux/slice/CompanySlice/CompanySlice";
import { DeleteCompanyApi } from "../../Apis/Companies/CompaniesApis";
import AppConfirmationDialog from "../../components/ConfirmationDialog/ConfirmationDialog";
import Loader from "../../components/Loader/Loader";
import CompaniesDetailDialog from "../../components/Companies/CompaniesDetailDialog/CompaniesDetailDialog";
import { MdDelete } from "react-icons/md";
import { Dropdown } from "react-bootstrap";

function Companies() {
  const [alldata, setalldata] = useState([]);
  const [rows, setrows] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setsnackBarData } = useContext(SnackBarContext);
  const [filterText, setFilterText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  // const inputRef = useRef(null);
  const [showCompanyDetails, setShowCompanyDetail] = useState(false);

  const { CompanyData, status } = useSelector((state) => state?.Companies);
  console.log("allData", CompanyData);
  // function formatDigits(value) {
  //   // Check for null, undefined, or empty values
  //   if (!value || typeof value !== "string") {
  //     return "";
  //   }

  //   // Remove non-digit characters
  //   const digits = value.replace(/\D/g, "");

  //   // Return an empty string if there are no digits
  //   if (digits.length === 0) {
  //     return "";
  //   }

  //   // Format the string into groups separated by spaces
  //   return `+1 ${digits.slice(0, 3)} ${digits.slice(3, 6)} ${digits.slice(
  //     6
  //   )}`.trim();
  // }
  useEffect(() => {
    dispatch(fetchCompanyData());
  }, [dispatch]);

  useEffect(() => {
    if (CompanyData) {
      setalldata(CompanyData);
    } else {
      setalldata([]);
    }
  }, [CompanyData]);

  const deleteCompany = async () => {
    setLoading(true);
    setShowConfirmation(false);

    const response = await DeleteCompanyApi(selectedRow?.id);

    response?.snackBarData?.message && setsnackBarData(response?.snackBarData);
    if (response?.success) {
      dispatch(fetchCompanyData());
      setSelectedRow(null);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    let arr = [];
    if (alldata) {
      alldata?.forEach((item, idx) => {
        return arr.push({
          id: idx + 1,
          ...item,
        });
      });
    }
    setrows(arr);
  }, [alldata]);

  useEffect(() => {
    if (rows) {
      setFilteredData(rows);
    } else {
      setFilteredData([]);
    }
  }, [rows]);

  const handleFilterChange = (event) => {
    const value = event.target.value.toLowerCase();
    setFilterText(value);

    const filtered = alldata.filter(
      (item) =>
        item.companyName.toLowerCase().includes(value) ||
        item.email.toLowerCase().includes(value) ||
        item.phoneNumber.toLowerCase().includes(value)
    );
    setFilteredData(filtered);
  };

  return (
    <>
      <div
        style={{ overflowY: "scroll", overflowX: "hidden", height: "100vh" }}
      >
        <div className="mb-4 d-flex justify-content-between ">
          <input
            type="text"
            className="form-control "
            placeholder="Search By Name, Email or Phone"
            value={filterText}
            onChange={handleFilterChange}
            style={{ width: "370px" }}
          />
          <button
            className="btn btn-primary"
            onClick={() => navigate("/companies/form")}
          >
            New
          </button>
        </div>

        <div className="row">
          {filteredData?.map((company, idx) => (
            <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-3" key={idx}>
              <div className="card bg-light text-center rounded-3 p-3">
                <div className="d-flex justify-content-end">
                  <Dropdown>
                    <Dropdown.Toggle
                      as="div"
                      className=" p-0 custom-dropdown-toggle "
                      style={{ cursor: "pointer" }}
                    >
                      <i
                        className="bi bi-three-dots-vertical"
                        style={{ fontSize: "25px" }}
                      ></i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dropdown-menu-end">
                      <Dropdown.Item
                        onClick={() =>
                          navigate("/companies/form", { state: company })
                        }
                      >
                        <i className="bi bi-pencil-fill text-info me-2"></i>{" "}
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setShowCompanyDetail(true);
                          setSelectedRow(company);
                        }}
                      >
                        <i className="bi bi-eye-fill text-warning me-2"></i>{" "}
                        View Details
                      </Dropdown.Item>
                      {/* <Dropdown.Item
                      className="text-danger"
                      onClick={() => setShowConfirmation(true)}
                    >
                      <i className="bi bi-trash-fill text-danger me-2"></i>{" "}
                      Delete
                    </Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                {/* <div className="d-flex justify-content-center align-items-center my-3"> */}
                {company?.profileImage ? (
                  <img
                    src={company?.profileImage}
                    alt="Company Logo"
                    className="img-fluid "
                    style={{
                      width: "100%",
                      height: "150px",
                      objectFit: "contain",
                      padding: "2px",
                    }}
                  />
                ) : (
                  <div
                    className="bg-secondary text-white rounded-circle d-flex align-items-center justify-content-center"
                    style={{
                      width: "148px",
                      height: "148px",
                      cursor: "pointer",
                    }}
                  >
                    Logo
                  </div>
                )}
                {/* </div> */}

                {/* <input type="file" ref={inputRef} style={{ display: "none" }} /> */}

                <h5 style={{ marginTop: "20px" }}>{company.companyName}</h5>
                {/* <div className="d-flex justify-content-between">
                  <p>{company.email}</p>
                  <p>{formatDigits(company.phoneNumber)}</p>
                </div> */}
              </div>
            </div>
          ))}
        </div>
        {showConfirmation && (
          <AppConfirmationDialog
            open={showConfirmation}
            onDeny={() => {
              setSelectedRow(null);
              setShowConfirmation(false);
            }}
            onConfirm={() => {
              deleteCompany();
            }}
            iconContainerColor="#fbeaea"
            icon={<MdDelete fontSize="small" color="red" />}
            dialogTitle="Delete Company"
            title={`Are you sure, you want to delete company ${selectedRow?.name}?`}
            successText="Yes"
            successBtnColor="error"
            cancelText="No"
          />
        )}
        {status === "loading" || loading ? <Loader /> : null}
        {showCompanyDetails && (
          <CompaniesDetailDialog
            open={showCompanyDetails}
            onClose={() => {
              setSelectedRow(null);
              setShowCompanyDetail(false);
            }}
            state={selectedRow}
          />
        )}
      </div>
    </>
  );
}

export default Companies;
