import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useDispatch, useSelector } from "react-redux";
import { fetchInspReport } from "../../../redux/slice/InspectionSlice/InspectionSlice";
import { Icon } from "@iconify/react";
import Jobsite from "./Jobsite";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../../Loader/Loader";
import { fetchCompanyDataPopulate } from "../../../redux/slice/CompanySlice/CompanySlice";

function Company() {
  const today = new Date().toISOString().split("T")[0];
  const halfYearAgo = new Date(new Date().setMonth(new Date().getMonth() - 6))
    .toISOString()
    .split("T")[0];

  const [startDate, setStartDate] = useState(halfYearAgo);
  const [endDate, setEndDate] = useState(today);
  const [searchTriggered, setSearchTriggered] = useState(true);
  const [jobsiteTriggered, setJobsiteTriggered] = useState(false);
  const dispatch = useDispatch();
  const [activeKey, setActiveKey] = useState("0");
  const [selectedCompany, setSelectedCompany] = useState(null);
  const { CompanyDataPopulate } = useSelector((state) => state?.Companies);
  const { InspectionReport, status } = useSelector(
    (state) => state?.Inspections
  );
  const handleStartDateChange = (date) => {
    const formattedDate = date.toLocaleDateString("en-CA");
    setStartDate(formattedDate);
  };

  const handleEndDateChange = (date) => {
    const formattedDate = date.toLocaleDateString("en-CA");
    setEndDate(formattedDate);
  };

  const handleSearchClick = () => {
    setSearchTriggered(true);
  };
  useEffect(() => {
    dispatch(fetchCompanyDataPopulate());
  }, [dispatch]);

  console.log("selected company", selectedCompany);

  useEffect(() => {
    if (searchTriggered) {
      dispatch(
        fetchInspReport({
          start: startDate,
          end: endDate,
          company: selectedCompany,
        })
      );
      setSearchTriggered(false);
      setJobsiteTriggered(true);
    }
  }, [dispatch, startDate, endDate, searchTriggered]);
  return (
    <>
      <div
        className="mb-2 d-flex justify-content-between pb-2"
        style={{ marginBottom: "140px" }}
      >
        <div className="d-flex gap-4 w-90">
          <div>
            {" "}
            <label
              htmlFor="startDate"
              className="form-label"
              style={{ paddingRight: "7px" }}
            >
              From
            </label>
            <DatePicker
              selected={startDate}
              onChange={handleStartDateChange}
              className="form-control"
              dateFormat="MM/dd/yyyy"
              placeholderText="Select Start Date"
            />
          </div>

          <div>
            {" "}
            <label
              htmlFor="endDate"
              className="form-label"
              style={{ paddingRight: "7px" }}
            >
              To
            </label>
            <DatePicker
              selected={endDate}
              onChange={handleEndDateChange}
              className="form-control"
              dateFormat="MM/dd/yyyy"
              placeholderText="Select End Date"
            />
          </div>
          <div style={{ height: "30px" }}>
            <select
              className="form-select w-90"
              value={selectedCompany || ""}
              onChange={(event) => setSelectedCompany(event.target.value)}
            >
              <option value="">Select Company</option>
              {CompanyDataPopulate?.map((item, idx) => (
                <option key={idx} value={item.id}>
                  {item.companyName}
                </option>
              ))}
            </select>
          </div>

          <button
            className="btn btn-primary"
            onClick={handleSearchClick}
            style={{ alignSelf: "center", height: "fit-content" }}
          >
            Search
          </button>
        </div>
      </div>
      <div>
        <Accordion activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
          {InspectionReport?.data?.map((report, index) => (
            <Accordion.Item key={report._id} eventKey={String(index)}>
              <Accordion.Header>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexBasis: "100%",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      fontSize: "1.5rem",
                      fontWeight: "bold",
                      flexGrow: 1,
                      minWidth: "180px",
                    }}
                  >
                    {report.company}
                    <span
                      style={{
                        fontSize: "1rem",
                        fontWeight: "bold",
                        color: "primary",
                      }}
                    >
                      {` (${report.jobSiteCount} Job Sites)`}
                    </span>
                  </span>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginRight: "40px",
                      gap: "30px",
                      fontSize: "0.875rem",
                      fontWeight: "bold",
                      paddingTop: "10px",
                      flexGrow: 3,
                      flexWrap: "wrap",
                    }}
                  >
                    <span
                      style={{
                        color: "green",
                        cursor: "not-allowed",
                      }}
                    >
                      <div
                        style={{ display: "flex" }}
                        title="Completed"
                        // onClick={() => setIconValue("Completed")}
                      >
                        <Icon
                          icon="fluent-mdl2:completed"
                          style={{ color: "green", fontSize: "22px" }}
                          data-tip="Completed"
                        />

                        <span
                          style={{
                            fontSize: "19px",
                            paddingLeft: "7px",
                          }}
                        >
                          {report.completedCount}
                        </span>
                      </div>
                    </span>

                    <span
                      style={{
                        color: "rgb(79, 195, 247)",
                        cursor: "not-allowed",
                      }}
                    >
                      <div style={{ display: "flex" }} title="Inprogress">
                        <Icon
                          icon="mdi:progress-check"
                          style={{
                            color: "rgb(79, 195, 247)",
                            fontSize: "25px",
                          }}
                        />
                        <span
                          style={{
                            fontSize: "21px",
                            paddingLeft: "7px",
                          }}
                        >
                          {report.inProgressCount}
                        </span>
                      </div>
                    </span>
                    <span style={{ color: "orange", cursor: "not-allowed" }}>
                      <div style={{ display: "flex" }} title="Pending">
                        <Icon
                          icon="material-symbols:pending-outline"
                          style={{ color: "orange", fontSize: "25px" }}
                        />
                        <span
                          style={{
                            fontSize: "21px",
                            paddingLeft: "7px",
                          }}
                        >
                          {report.pendingCount}
                        </span>
                      </div>
                    </span>
                    <span style={{ color: "red", cursor: "not-allowed" }}>
                      <div style={{ display: "flex" }} title="Cancelled">
                        <Icon
                          icon="ix:cancelled"
                          style={{ color: "red", fontSize: "22px" }}
                          data-tip="Cancelled "
                        />

                        <span
                          style={{
                            fontSize: "19px",
                            paddingLeft: "7px",
                          }}
                        >
                          {report.cancelledCount}
                        </span>
                      </div>
                    </span>

                    <span style={{ color: "grey", cursor: "not-allowed" }}>
                      <div style={{ display: "flex" }} title="OnHold">
                        <Icon
                          icon="carbon:pause-outline"
                          style={{ color: "grey", fontSize: "22px" }}
                          data-tip="OnHold "
                        />

                        <span
                          style={{
                            fontSize: "19px",
                            paddingLeft: "7px",
                          }}
                        >
                          {report.onholdCount}
                        </span>
                      </div>
                    </span>
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body className="custom-accordion-body  p-3">
                {report?.jobsites?.map((site, idx) => (
                  <Jobsite
                    key={idx}
                    data={site}
                    idx={idx}
                    start={startDate}
                    endDate={endDate}
                    index={index}
                    search={jobsiteTriggered}
                    setSearch={setJobsiteTriggered}
                    // filterValue={iconValue}
                  />
                ))}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
        {status === "loading" ? <Loader /> : null}
      </div>
    </>
  );
}

export default Company;
