import React, { useState, useContext } from "react";
import { Modal, Button, Spinner, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { UpdateReviewStatusApi } from "../../Apis/Status/UpdateReviewStatusApi";
import { SnackBarContext } from "../../context/SnackBarContext.jsx";
import { fetchApprovalInspectionData } from "../../redux/slice/InspectionSlice/InspectionSlice";
const validationSchema = Yup.object({
  status: Yup.string().required("Status is required"),
  comments: Yup.string().required("Comment is required"),
});

const ApprovalDialog = ({ open, onDeny, selectedRow }) => {
  const [loading, setLoading] = useState(false);
  const { setsnackBarData } = useContext(SnackBarContext);
  const id = selectedRow?.currentStage;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  var currentdate = new Date();
  const formik = useFormik({
    initialValues: {
      status: "",
      comments: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      console.log("loadig", loading);
      const payload = {
        status: values.status,
        comments: values.comments,
        statusUpdatedAt: currentdate,
      };

      try {
        const response = await UpdateReviewStatusApi(payload, id);
        console.log("response of API:", response);
        setsnackBarData(response?.snackBarData);
        if (response?.success) {
          dispatch(fetchApprovalInspectionData());
          if (payload?.status === "Approved") {
            navigate("/my-approval/approved");
          } else {
            navigate("/my-approval/rejected");
          }
          onDeny();
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.error("API call error:", error);
        setLoading(false);
        onDeny();
      }
    },
  });
  console.log("values", formik.values);
  return (
    <Modal show={open} onHide={onDeny} centered>
      <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              fontSize: "17px",
              fontWeight: 600,
              lineHeight: 1.5,
              textAlign: "center",
            }}
            className="d-flex align-items-center justify-content-center gap-2"
          >
            Package Stage Approval
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
            <Form.Group controlId="status" className="mb-3">
              <Form.Label>Status</Form.Label>
              <Form.Select
                name="status"
                value={formik.values.status}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik.touched.status && formik.errors.status}
              >
                <option value="">Select Status</option>
                <option value="Rejected">Rejected</option>
                <option value="Approved">Approved</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.status}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="comments" className="mb-3">
              <Form.Label>Comment</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="comments"
                placeholder="Add a comment"
                value={formik.values.comments}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik.touched.comments && formik.errors.comments}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.comments}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {loading ? (
            <Spinner animation="border" />
          ) : (
            <>
              <Button
                variant="secondary"
                onClick={onDeny}
                style={{ flex: 1, fontWeight: 600 }}
              >
                No
              </Button>
              <Button
                variant="info"
                onClick={formik.handleSubmit} // Handle form submission
                style={{ flex: 1, fontWeight: 600 }}
              >
                Yes
              </Button>
            </>
          )}
        </Modal.Footer>
      </div>
    </Modal>
  );
};

ApprovalDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onDeny: PropTypes.func.isRequired,
  selectedRow: PropTypes.object,
};

export default ApprovalDialog;
