import React from "react";
import Layout from "../Layouts/Layout";
import RequireAuth from "../components/RequireAuth/RequireAuth";
import NotFound from "../pages/NotFound/NotFound";
import SignInForm from "../pages/Authentication/SignInForm";

import Settings from "../pages/Settings/Settings";
import RolesPage from "../pages/RolesPage/RolesPage";
import UsersPage from "../pages/UsersPage/UsersPage";
import UserForm from "../components/Settings/Roles/ScreenComponents/AddUpdateUserScreen/UserForm";
import LoginVerification from "../pages/LoginVerification/LoginVerification.jsx";
import ForgotPassword from "../pages/Authentication/ForgotPassword/ForgotPassword";
import Dashboard from "../pages/Dashboard/Dashboard";
import CreateForm from "../pages/CreateForm/CreateForm";
import CreatedTemplates from "../pages/CreatedTemplates/CreatedTemplates";
import Companies from "../pages/Companies/Companies";
import CompaniesForm from "../components/Companies/CompaniesForm";
import SitesForm from "../components/Sites/SitesForm";
import Sites from "../pages/Sites/Sites";
import Inspection from "../pages/Inspection/Inspection";
import InspectionForm from "../components/Inspection/InspectionForm";
import MyInspections from "../pages/MyInspections/MyInspections";
import MyInspectionForm from "../pages/MyInspections/MyInspectionForm";
import MyApprovalForm from "../pages/MyApprovals/MyApprovalForm";
import MyApproval from "../pages/MyApprovals/MyApprovals";
import InspectionView from "../components/Inspection/InspectionView.jsx";
import HistoryForm from "../components/Inspection/HistoryForm.jsx";
import TemplateForm from "../components/Templates/TemplateForm.jsx";
import SitesView from "../components/Sites/SitesView.jsx";
import Pdf from "../pages/Pdf/Pdf.jsx";
import AddSignature from "../pages/Signature/Signature.jsx";
import OtpVerification from "../pages/LoginVerification/OtpVerification.jsx";
import Stats from "../pages/statistics/statistics.jsx";
const AdminRoutes = () => {
  const AdminRoutes = [
    {
      path: "/dashboard",
      component: (
        <RequireAuth>
          <Layout activePage={<Dashboard />} />
        </RequireAuth>
      ),
      roleName: ["Admin", "SuperAdmin"],
    },
    {
      path: "/",
      component: (
        <RequireAuth>
          <Layout activePage={<Stats parent="screen" />} />
        </RequireAuth>
      ),
      roleName: ["Admin", "SuperAdmin"],
    },

    {
      path: "/create-forms",
      component: (
        <RequireAuth>
          <Layout activePage={<CreateForm />} />
        </RequireAuth>
      ),
      roleName: ["SuperAdmin"],
    },

    {
      path: "/forms",
      component: (
        <RequireAuth>
          <Layout activePage={<CreatedTemplates />} />
        </RequireAuth>
      ),
      roleName: ["Admin", "SuperAdmin"],
    },

    {
      path: "/formview",
      component: (
        <RequireAuth>
          <Layout activePage={<TemplateForm />} />
        </RequireAuth>
      ),
      roleName: ["Admin", "SuperAdmin"],
    },
    {
      path: "/companies",
      component: (
        <RequireAuth>
          <Layout activePage={<Companies />} />
        </RequireAuth>
      ),
      roleName: ["Admin", "SuperAdmin"],
    },
    {
      path: "/companies/form",
      component: (
        <RequireAuth>
          <Layout activePage={<CompaniesForm parent="screen" />} />
        </RequireAuth>
      ),
      roleName: ["Admin", "SuperAdmin"],
    },
    {
      path: "/sites",
      component: (
        <RequireAuth>
          <Layout activePage={<Sites />} />
        </RequireAuth>
      ),
      roleName: ["Admin", "SuperAdmin"],
    },
    {
      path: "/sites/sites-view",
      component: (
        <RequireAuth>
          <Layout activePage={<SitesView />} />
        </RequireAuth>
      ),
      roleName: ["Admin", "SuperAdmin"],
    },
    {
      path: "/sites/form",
      component: (
        <RequireAuth>
          <Layout activePage={<SitesForm parent="screen" />} />
        </RequireAuth>
      ),
      roleName: ["Admin", "SuperAdmin"],
    },
    // {
    //   path: "/package",
    //   component: (
    //     <RequireAuth>
    //       <Layout activePage={<Inspection screenType="package" />} />
    //     </RequireAuth>
    //   ),
    //   roleName: ["Admin", "SuperAdmin"],
    // },
    {
      path: "/package/in-progress",
      component: (
        <RequireAuth>
          <Layout activePage={<Inspection screenType="inProgress" />} />
        </RequireAuth>
      ),
      roleName: ["Admin", "SuperAdmin"],
    },
    {
      path: "/package/pending",
      component: (
        <RequireAuth>
          <Layout activePage={<Inspection screenType="pending" />} />
        </RequireAuth>
      ),
      roleName: ["Admin", "SuperAdmin"],
    },
    {
      path: "/package/completed",
      component: (
        <RequireAuth>
          <Layout activePage={<Inspection screenType="completed" />} />
        </RequireAuth>
      ),
      roleName: ["Admin", "SuperAdmin"],
    },
    {
      path: "/package/cancelled",
      component: (
        <RequireAuth>
          <Layout activePage={<Inspection screenType="cancelled" />} />
        </RequireAuth>
      ),
      roleName: ["Admin", "SuperAdmin"],
    },
    {
      path: "/package/on-hold",
      component: (
        <RequireAuth>
          <Layout activePage={<Inspection screenType="onHold" />} />
        </RequireAuth>
      ),
      roleName: ["Admin", "SuperAdmin"],
    },
    {
      path: "/package/form",
      component: (
        <RequireAuth>
          <Layout activePage={<InspectionForm parent="screen" />} />
        </RequireAuth>
      ),
      roleName: ["Admin", "SuperAdmin"],
    },
    {
      path: "/package/package-view",
      component: (
        <RequireAuth>
          <Layout activePage={<InspectionView />} />
        </RequireAuth>
      ),
      roleName: ["Admin", "SuperAdmin"],
    },
    {
      path: "/package/package-view/form",
      component: (
        <RequireAuth>
          <Layout activePage={<HistoryForm />} />
        </RequireAuth>
      ),
      roleName: ["Admin", "SuperAdmin"],
    },
    {
      path: "/pdf",
      component: (
        <RequireAuth>
          <Layout activePage={<Pdf />} />
        </RequireAuth>
      ),
      roleName: ["Admin", "Employee", "SuperAdmin"],
    },

    {
      path: "/my-package/pending",
      component: (
        <RequireAuth>
          <Layout activePage={<MyInspections screenType="Pending" />} />
        </RequireAuth>
      ),
      roleName: ["Admin", "Employee", "SuperAdmin"],
    },
    {
      path: "/my-package/submitted",
      component: (
        <RequireAuth>
          <Layout activePage={<MyInspections screenType="Submitted" />} />
        </RequireAuth>
      ),
      roleName: ["Admin", "Employee", "SuperAdmin"],
    },
    {
      path: "/my-approval",
      component: (
        <RequireAuth>
          <Layout activePage={<MyApproval screenType="MyApproval" />} />
        </RequireAuth>
      ),
      roleName: ["Admin", "Employee", "SuperAdmin"],
    },
    {
      path: "/my-approval/pending",
      component: (
        <RequireAuth>
          <Layout activePage={<MyApproval screenType="Pending" />} />
        </RequireAuth>
      ),
      roleName: ["Admin", "Employee", "SuperAdmin"],
    },
    {
      path: "/my-approval/rejected",
      component: (
        <RequireAuth>
          <Layout activePage={<MyApproval screenType="Rejected" />} />
        </RequireAuth>
      ),
      roleName: ["Admin", "Employee", "SuperAdmin"],
    },
    {
      path: "/my-approval/approved",
      component: (
        <RequireAuth>
          <Layout activePage={<MyApproval screenType="Approved" />} />
        </RequireAuth>
      ),
      roleName: ["Admin", "Employee", "SuperAdmin"],
    },
    {
      path: "/my-package/form",
      component: (
        <RequireAuth>
          <Layout activePage={<MyInspectionForm parent="screen" />} />
        </RequireAuth>
      ),
      roleName: ["Admin", "Employee", "SuperAdmin"],
    },
    {
      path: "/my-approval/form",
      component: (
        <RequireAuth>
          <Layout activePage={<MyApprovalForm parent="screen" />} />
        </RequireAuth>
      ),
      roleName: ["Admin", "Employee", "SuperAdmin"],
    },
    {
      path: "/signature",
      component: (
        <RequireAuth>
          <Layout activePage={<AddSignature parent="screen" />} />
        </RequireAuth>
      ),
      roleName: ["Admin", "Employee", "SuperAdmin"],
    },

    {
      path: "/settings",
      component: (
        <RequireAuth>
          <Layout activePage={<Settings />} />
        </RequireAuth>
      ),
      roleName: ["Admin", "SuperAdmin"],
    },
    {
      path: "/settings/roles",
      component: (
        <RequireAuth>
          <Layout activePage={<RolesPage />} />
        </RequireAuth>
      ),
      roleName: ["Admin", "SuperAdmin"],
    },
    {
      path: "/settings/users",
      component: (
        <RequireAuth>
          <Layout activePage={<UsersPage />} />
        </RequireAuth>
      ),
      roleName: ["Admin", "SuperAdmin"],
    },
    {
      path: "/settings/users/form",
      component: (
        <RequireAuth>
          <Layout activePage={<UserForm />} />
        </RequireAuth>
      ),
      roleName: ["Admin", "SuperAdmin"],
    },

    {
      path: "/signin",
      component: <SignInForm />,
    },
    {
      path: "/forgot-password",
      component: <ForgotPassword />,
    },
    {
      path: "/login-verification",
      component: <LoginVerification />,
    },
    {
      path: "/otp-verification",
      component: <OtpVerification />,
    },
    {
      path: "*",
      component: (
        <RequireAuth>
          <Layout activePage={<NotFound />} />
        </RequireAuth>
      ),
    },
  ];

  return AdminRoutes;
};

export default AdminRoutes;
