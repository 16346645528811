import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/AxiosInstance";
import { revertAll } from "../ResetStateAction";

const initialState = {
  InspectionData: null,
  UserInspectionData: null,
  InspectionDetail: null,
  InspectionReport: null,
  JobSiteReport: null,
  ApprovalInspectionData: null,
  InspectionPopulate: null,
  JobSiteData: null,
  InspectionById: null,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

export const fetchInspectionData = createAsyncThunk(
  "Inspection/GetAll",
  async (status) => {
    const response = await axiosInstance.get(
      `/Inspection/GetAll?status=${status}&page=1&limit=50`
    );
    return response?.data;
  }
);
export const fetchUserInspectionsData = createAsyncThunk(
  "Inspection/GetUserInspections",
  async (status) => {
    const response = await axiosInstance.get(
      `Inspection/GetUserInspections?status=${status}`
    );
    return response?.data;
  }
);
export const fetchApprovalInspectionData = createAsyncThunk(
  "Inspection/GetApprovalInspections",
  async (status) => {
    const response = await axiosInstance.get(
      `Inspection/GetApprovalInspections?status=${status}`
    );
    return response?.data;
  }
);
export const fetchInspectionPopulate = createAsyncThunk(
  "Inspection/Populate",
  async () => {
    const response = await axiosInstance.get(`Inspection/Populate`);
    return response?.data;
  }
);
export const fetchJobSiteData = createAsyncThunk(
  "Inspection/GetJobSite",
  async (id) => {
    const response = await axiosInstance.get(`JobSite/Populate/${id || ""}`);
    return response?.data;
  }
);
export const fetchInspDetailData = createAsyncThunk(
  "Inspection/GetOneInspection",
  async (id) => {
    const response = await axiosInstance.get(
      `Inspection/GetOneInspectionDetail/${id || ""}`
    );
    return response?.data;
  }
);
export const fetchInspDataById = createAsyncThunk(
  "Inspection/GetInspectionById",
  async (id) => {
    const response = await axiosInstance.get(`Inspection/GetById/${id || ""}`);
    return response?.data;
  }
);
export const fetchInspReport = createAsyncThunk(
  "Inspection/InspectionReport",
  async ({ start, end, company }) => {
    const response = await axiosInstance.get(
      `Inspection/InspectionReport?from=${start}&to=${end}&company=${company}`
    );
    return response?.data;
  }
);
export const fetchJobSiteReport = createAsyncThunk(
  "Inspection/InJobsiteReport",
  async () => {
    const response = await axiosInstance.get(
      `/Inspection/JobSiteReport?from=&jobSiteId=66f6776320dc6b22b7b9c83b`
    );
    return response?.data;
  }
);

const InspectionSlice = createSlice({
  name: "InspectionSlice",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchInspectionData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchInspectionData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.InspectionData = action.payload.data;
        // state.TotalCount = action.payload.data.totalCount;
      })
      .addCase(fetchInspectionData.rejected, (state, action) => {
        state.InspectionData = null;
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchApprovalInspectionData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchApprovalInspectionData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.ApprovalInspectionData = action.payload.data;
        // state.TotalCount = action.payload.data.totalCount;
      })
      .addCase(fetchApprovalInspectionData.rejected, (state, action) => {
        state.ApprovalInspectionData = null;
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchUserInspectionsData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserInspectionsData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.UserInspectionData = action.payload.data;
        // state.TotalCount = action.payload.data.totalCount;
      })
      .addCase(fetchUserInspectionsData.rejected, (state, action) => {
        state.UserInspectionData = null;
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchJobSiteData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchJobSiteData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.JobSiteData = action.payload;
        // state.TotalCount = action.payload.data.totalCount;
      })
      .addCase(fetchJobSiteData.rejected, (state, action) => {
        state.JobSiteData = null;
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchInspectionPopulate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchInspectionPopulate.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.InspectionPopulate = action.payload.data;
        // state.TotalCount = action.payload.data.totalCount;
      })
      .addCase(fetchInspectionPopulate.rejected, (state, action) => {
        state.InspectionDetail = null;
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchInspDetailData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchInspDetailData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.InspectionDetail = action.payload;
        // state.TotalCount = action.payload.data.totalCount;
      })
      .addCase(fetchInspDetailData.rejected, (state, action) => {
        state.InspectionDetail = null;
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchInspDataById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchInspDataById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.InspectionById = action.payload;
        // state.TotalCount = action.payload.data.totalCount;
      })
      .addCase(fetchInspDataById.rejected, (state, action) => {
        state.InspectionById = null;
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchInspReport.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchInspReport.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.InspectionReport = action.payload;
      })
      .addCase(fetchInspReport.rejected, (state, action) => {
        state.InspectionReport = null;
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchJobSiteReport.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchJobSiteReport.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.JobSiteReport = action.payload;
      })
      .addCase(fetchJobSiteReport.rejected, (state, action) => {
        state.JobSiteReport = null;
        state.error = action.error.message;
        state.status = "failed";
      });
  },
});
export const selectInspectionState = (state) => state.InspectionSlice;
export default InspectionSlice.reducer;
