import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ApprovalDialog from "../../components/ConfirmationDialog/ApprovalDialog";
import Loader from "../../components/Loader/Loader";
import { fetchApprovalInspectionData } from "../../redux/slice/InspectionSlice/InspectionSlice";
import InspectionDetailDialog from "../../components/Inspection/InspectionDetailDialog/InspectionDetailDialog";
import axiosInstance from "../../utils/AxiosInstance";
import { toast } from "react-toastify";
import VerifyOTPDialog from "../../components/common/VerifyOTPDialog/VerifyOTPDialog";
import CustomGrid from "../../components/common/CustomGrid/CustomGrid";
import { SendOTPAPi } from "../../Apis/Auth/AuthApis";
import { VerifyOTPAPi } from "../../Apis/Auth/AuthApis";
import PropTypes from "prop-types";
import { fetchCompanyDataPopulate } from "../../redux/slice/CompanySlice/CompanySlice";

function MyApproval({ screenType }) {
  const [alldata, setalldata] = useState([]);
  const [rows, setrows] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  console.log(showConfirmation);
  const [openVerifyOTPDialog, setOpenVerifyOTPDialog] = useState(false);
  const [loading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [showCompanyDetails, setShowCompanyDetail] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedJobsite, setSelectedJobsite] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const [verifyOtpFor, setVerifyOtpFor] = useState("");
  const [otpSending, setOtpSending] = useState(false);
  const [duration, setDuration] = useState(0);
  const { CompanyDataPopulate } = useSelector((state) => state?.Companies);
  const [allJobSites, setAllJobSites] = useState([]);
  const { status, ApprovalInspectionData } = useSelector(
    (state) => state?.Inspections
  );

  useEffect(() => {
    let filteredJobSites;
    if (selectedCompany) {
      filteredJobSites = CompanyDataPopulate.filter(
        (item) => item.companyName === selectedCompany
      ).flatMap((company) => company.jobSites);
    } else {
      filteredJobSites = CompanyDataPopulate?.flatMap(
        (company) => company.jobSites
      );
    }
    setSelectedJobsite(null);
    setAllJobSites(filteredJobSites);
  }, [CompanyDataPopulate, selectedCompany]);

  useEffect(() => {
    if (screenType === "MyApproval") {
      dispatch(fetchApprovalInspectionData("Pending"));
    } else if (screenType === "Pending") {
      dispatch(fetchApprovalInspectionData("Pending"));
    } else if (screenType === "Approved") {
      dispatch(fetchApprovalInspectionData("Approved"));
    } else {
      dispatch(fetchApprovalInspectionData("Rejected"));
    }
    dispatch(fetchCompanyDataPopulate());
  }, [dispatch, screenType]);
  useEffect(() => {
    if (ApprovalInspectionData?.response) {
      console.log(ApprovalInspectionData);

      setalldata(ApprovalInspectionData?.response);
    } else {
      setalldata([]);
    }
  }, [ApprovalInspectionData]);
  React.useEffect(() => {
    let arr = [];
    console.log(alldata);

    if (Array.isArray(alldata) && alldata?.length > 0) {
      alldata?.forEach((item, idx) => {
        arr.push({
          id: idx + 1,
          ...item,
        });
      });
    } else {
      console.warn("alldata is not an array or it's empty:", alldata);
    }

    setrows(arr);
  }, [alldata]);

  useEffect(() => {
    if (rows) {
      console.log(rows);

      setFilteredData(rows);
    } else {
      setFilteredData([]);
    }
  }, [rows]);

  useEffect(() => {
    let filtered = rows;
    if (selectedCompany) {
      filtered = filtered.filter(
        (item) => item.companyName === selectedCompany
      );
    }
    if (selectedJobsite) {
      filtered = filtered.filter((item) => item.jobSite === selectedJobsite);
    }
    if (searchTerm) {
      const regex = new RegExp(searchTerm, "i");
      filtered = filtered.filter(
        (item) =>
          regex.test(item?.companyName) ||
          regex.test(item?.equipType) ||
          regex.test(item?.equipmentNumbers) ||
          regex.test(item?.inspectionNumber)
      );
    }

    setFilteredData(filtered);
  }, [rows, selectedCompany, selectedJobsite, searchTerm]);
  const handleView = async (data) => {
    const fetchSubmission = async () => {
      try {
        const submissionResponse = await axiosInstance.get(
          `Forms/${data.form?._id}/Submission/${data.submissionId}`
        );
        console.log("submissionResponse.data", submissionResponse.data);
        navigate("/my-approval/form", {
          state: {
            data: data,
            submission: submissionResponse.data,
          },
        });
      } catch (error) {
        console.error("Error fetching submission:", error);
      }
    };

    if (data?.submissionId) {
      fetchSubmission();
    } else {
      navigate("/my-approval/form", {
        state: {
          data: data,
        },
      });
    }
  };

  const sendOTP = async (openDialog) => {
    setOtpSending(true);
    const formData = { email: sessionStorage.getItem("userEmail") };
    const response = await SendOTPAPi(formData);

    setOtpSending(false);
    response?.snackBarData?.message &&
      response?.snackBarData?.type === "error" &&
      toast.error(response?.snackBarData?.message);

    if (response?.success) {
      setDuration(600);

      if (openDialog) {
        setOpenVerifyOTPDialog(true);
      }
    }
  };

  const verifyOTP = async (values, { resetForm, setSubmitting }) => {
    const formData = {
      email: sessionStorage.getItem("userEmail"),
      otp: values.otp,
    };
    const response = await VerifyOTPAPi(formData);

    setSubmitting(false);
    response?.snackBarData?.message &&
      response?.snackBarData?.type === "error" &&
      toast.error(response?.snackBarData?.message);

    if (response?.success) {
      resetForm();

      if (verifyOtpFor == "view") {
        handleView(selectedRow);
      } else {
        // handleSubmit();
        setShowConfirmation(true);
      }

      setVerifyOtpFor("");
      setDuration(0);
      setOpenVerifyOTPDialog(false);
    }
  };

  const columns = [
    {
      Header: "Sr",
      accessor: "id", // Accessor is the "key" in the data
      width: "20px",
    },
    {
      Header: "Package #",
      accessor: "inspectionNumber",
    },
    {
      Header: "Equipment #",
      accessor: "equipmentNumbers",
    },
    {
      Header: "Equipment Type",
      accessor: "equipType",
    },
    {
      Header: "Company Name",
      accessor: "companyName",
    },
    {
      Header: "Site Name",
      accessor: "jobSite",
    },
    {
      Header: "Current Form",
      accessor: "currentStageForm",
    },
    {
      Header: "Current Stage",
      accessor: "stage",
    },
    {
      Header: "Submission Status",
      accessor: "StageSubmissionStatus",
      Cell: (cell) => (
        <span
          style={{
            padding: "0.25rem 0.75rem",
            fontSize: "0.875rem",
            fontWeight: "600",
            color: "white",
            backgroundColor:
              cell.value === "Approved" || cell.value === "Completed"
                ? "green"
                : cell.value === "Rejected"
                ? "red"
                : cell.value === "Pending"
                ? "orange"
                : "rgb(79, 195, 247)",
            borderRadius: "9999px",
          }}
        >
          {cell.value}
        </span>
      ),
    },
    {
      Header: "Review Status",
      accessor: "myReviewStatus",
      Cell: (cell) => (
        <span
          style={{
            padding: "0.25rem 0.75rem",
            fontSize: "0.875rem",
            fontWeight: "600",
            color: "white",
            backgroundColor:
              cell.value === "Approved" || cell.value === "Completed"
                ? "green"
                : cell.value === "Rejected"
                ? "red"
                : cell.value === "Pending"
                ? "orange"
                : "rgb(79, 195, 247)",
            borderRadius: "9999px",
          }}
        >
          {cell.value}
        </span>
      ),
    },

    {
      Header: "Actions",
      accessor: "actions",

      disableSortBy: true, // Disable sorting for actions
      Cell: (cell) => (
        <div className="d-flex">
          {cell?.row?.original.myReviewStatus &&
          cell?.row?.original?.myReviewStatus !== "Approved" ? (
            <>
              <button
                className="btn btn-outline-secondary mx-2"
                onClick={() => {
                  setSelectedRow(cell?.row?.original);
                  setVerifyOtpFor("view");
                  sendOTP(true);
                  // handleView(row);
                }}
              >
                View
              </button>
              <button
                className="btn btn-outline-primary"
                type="button"
                onClick={() => {
                  if (
                    cell?.row?.original.submissionId &&
                    cell?.row?.original.StageSubmissionStatus === "Completed"
                  ) {
                    setSelectedRow(cell?.row?.original);
                    // setShowConfirmation(true);
                    setVerifyOtpFor("submit");
                    sendOTP(true);
                  } else {
                    toast.error("No response is submitted for this form yet.");
                  }
                }}
              >
                Approval
              </button>
            </>
          ) : (
            <button
              className="btn btn-outline-secondary mx-2"
              onClick={() => {
                setSelectedRow(cell?.row?.original);
                setVerifyOtpFor("view");
                sendOTP(true);
                // handleView(row);
              }}
            >
              View
            </button>
          )}
        </div>
      ),
    },
  ];
  if (screenType === "Approved" || screenType === "Rejected") {
    columns.splice(8, 0, {
      Header: "Reason",
      accessor: "myReviewcomment",
    });
  }
  return (
    <>
      <div className="mb-2 d-flex justify-content-between">
        <div className="d-flex gap-4 w-50">
          <input
            type="text"
            className="form-control w-60"
            placeholder="Search By Package #, Equip #, Equip Type"
            onChange={(e) => setSearchTerm(e.target.value)}
          />

          <select
            className="form-select w-50"
            value={selectedCompany || ""}
            onChange={(event) => setSelectedCompany(event.target.value)}
          >
            <option value="">Select Company</option>
            {CompanyDataPopulate?.map((item, idx) => (
              <option key={idx} value={item.name}>
                {item.companyName}
              </option>
            ))}
          </select>

          <select
            className="form-select w-50 "
            value={selectedJobsite || ""}
            onChange={(event) => setSelectedJobsite(event.target.value)}
          >
            <option value="">Select JobSite</option>
            {allJobSites?.map((site) => (
              <option key={site.id} value={site.name}>
                {site.name}
              </option>
            ))}
          </select>
        </div>
        <div className="w-50">
          <p style={{ textAlign: "center" }}>
            Total Records:
            <span style={{ color: "#1c4e80", fontWeight: "bold" }}>
              {" "}
              {filteredData.length}
            </span>
          </p>
        </div>
      </div>
      {ApprovalInspectionData?.pendingCount > 0 && (
        <div
          style={{
            padding: "1rem",
            backgroundColor: "#e0f2fe",
            color: "#0369a1",
            border: "1px solid #0284c7",
            borderRadius: "0.375rem",
            fontWeight: "500",
            marginRight: "15px",
          }}
        >
          <p>{`${ApprovalInspectionData?.pendingCount} more ${
            ApprovalInspectionData?.pendingCount > 1 ? "forms" : "form"
          } for approval coming soon.`}</p>
        </div>
      )}
      {status === "loading" || loading ? (
        <div className="text-center">
          <Loader />
        </div>
      ) : (
        <CustomGrid columns={columns} data={filteredData} />
      )}
      {showConfirmation && (
        <ApprovalDialog
          open={showConfirmation}
          onDeny={() => {
            setSelectedRow(null);
            setShowConfirmation(false);
          }}
          selectedRow={selectedRow}
        />
      )}
      {showCompanyDetails && (
        <InspectionDetailDialog
          open={showCompanyDetails}
          onClose={() => {
            setSelectedRow(null);
            setShowCompanyDetail(false);
          }}
          state={selectedRow}
        />
      )}
      <VerifyOTPDialog
        open={openVerifyOTPDialog}
        onClose={() => {
          setSelectedRow(null);
          setVerifyOtpFor("");
          setOpenVerifyOTPDialog(false);
        }}
        duration={duration}
        setDuration={setDuration}
        otpSending={otpSending}
        resendOTP={() => {
          sendOTP(false);
        }}
        handleSubmit={verifyOTP}
      />
    </>
  );
}
MyApproval.propTypes = {
  screenType: PropTypes.string.isRequired,
};
export default MyApproval;
