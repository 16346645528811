
import React, { useContext } from "react";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import AppDialog from "../../../../common/AppDialog/AppDialog";
import { SnackBarContext } from "../../../../../context/SnackBarContext";
import { fetchAllRolesData } from "../../../../../redux/slice/RolesSlice/RolesSlice";
import { CreateRoleAPi, UpdateRoleAPi } from "../../../../../Apis/Roles/RolesApis";
import { ThreeDots } from "react-loader-spinner";
import { Button, Form as BootstrapForm } from "react-bootstrap";

const validationSchema = yup.object({
  name: yup.string().required("Field Required!"),
  description: yup.string().required("Field Required!"),
});

function AddUpdateRoleDialog({ open, onClose, selectedRow }) {
  const dispatch = useDispatch();
  const { setsnackBarData } = useContext(SnackBarContext);

  // Formik logic moved outside the return statement
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: selectedRow ? selectedRow?.roleName : "",
      description: selectedRow ? selectedRow?.description : "",
    },
    validationSchema: validationSchema,
    onSubmit: async (data, { setSubmitting, resetForm }) => {
      const formData = {
        roleName: data?.name,
        description: data?.description,
      };

      let response;
      if (!selectedRow) {
        response = await CreateRoleAPi(formData);
      } else {
        response = await UpdateRoleAPi(selectedRow?.id, formData);
      }

      response?.snackBarData?.message && setsnackBarData(response?.snackBarData);

      if (response?.success) {
        dispatch(fetchAllRolesData());
        resetForm();
        onClose();
      }

      setSubmitting(false);
    },
  });

  // JSX inside the return
  return (
    <AppDialog
      open={open}
      onClose={onClose}
      title={selectedRow ? "Update Role" : "Add Role"}
      minHeight="auto"
    >
      <div className="my-2">
        <BootstrapForm
          noValidate
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <BootstrapForm.Group controlId="formRoleName">
            <BootstrapForm.Label>Name</BootstrapForm.Label>
            <BootstrapForm.Control
              type="text"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.errors?.name && formik.touched?.name}
            />
            <BootstrapForm.Control.Feedback type="invalid">
              {formik.errors?.name && formik.touched?.name
                ? formik.errors?.name
                : ""}
            </BootstrapForm.Control.Feedback>
          </BootstrapForm.Group>

          <BootstrapForm.Group controlId="formDescription" className="mt-3">
            <BootstrapForm.Label>Description</BootstrapForm.Label>
            <BootstrapForm.Control
              as="textarea"
              rows={5}
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                formik.errors?.description && formik.touched?.description
              }
            />
            <BootstrapForm.Control.Feedback type="invalid">
              {formik.errors?.description && formik.touched?.description
                ? formik.errors?.description
                : ""}
            </BootstrapForm.Control.Feedback>
          </BootstrapForm.Group>

          <div className="d-flex justify-content-end align-items-center mt-3 gap-3">
            {formik.isSubmitting && (
              <ThreeDots
                height="40"
                width="40"
                radius="9"
                color="#1c4e80 "
                ariaLabel="three-dots-loading"
              />
            )}
            <Button
              type="submit"
              variant="primary"
              disabled={formik.isSubmitting}
            >
              {selectedRow ? "Update" : "Add"}
            </Button>
          </div>
        </BootstrapForm>
      </div>
    </AppDialog>
  );
}

export default AddUpdateRoleDialog;

AddUpdateRoleDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  selectedRow: PropTypes.any,
};
