import React from "react";
import { Text, View, StyleSheet, Font, Image } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import Logo from "../../Assets/Images/FormLogo.png";
// Create styles
Font.register({
  family: "Helvetica-Bold",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/helvetica/v7/oU5HfY7Evdle2mP5Xai7nPesZW2xOQ-xsNqO47m55DA.ttf",
      fontWeight: "bold",
    },
  ],
});
const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
  },
  section: {
    margin: 10,
    padding: 10,
    border: "1px solid black",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderLeft: 1,
    borderRight: 0,
    borderBottom: 0,
    borderTop: 0,
    borderColor: "#000",
    marginBottom: 10,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColHeader: {
    borderBottom: 0,
    borderTop: 0,
    borderLeft: 0,
    borderRightWidth: 1,
    width: "25%",
    borderStyle: "solid",
    backgroundColor: "#E4E4E4",
    textAlign: "center",
    paddingTop: 6,
    fontFamily: "Helvetica-Bold",
  },
  tableCol: {
    borderLeft: 0,
    borderRight: 1,
    borderBottom: 1,
    borderTop: 1,
    width: "25%",
    borderStyle: "solid",
    // borderWidth: 1,
    paddingLeft: 5,
    paddingTop: 6,
    // paddingBottom: 3,
    textAlign: "center",
  },
  title: {
    // fontSize: 14,
    // marginBottom: 10,
    paddingTop: 20,
    textAlign: "center",
    fontFamily: "Helvetica-Bold",
  },
  logo: {
    height: 47,
    width: 50,
  },
});

// Create Document Component
export const WqcfHeader = ({ title, data }) => (
  <>
    {/* Row 0 */}
    <View style={styles.tableRow}>
      <View
        style={{
          ...styles.tableCol,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image src={Logo} style={styles.logo} />
      </View>
      <Text style={{ ...styles.tableCol, width: "75%", ...styles.title }}>
        {title}
      </Text>
    </View>
    {/* Row 1 */}
    <View style={styles.tableRow}>
      <Text style={styles.tableColHeader}>Unit #</Text>
      <Text style={{ ...styles.tableColHeader, width: "50%" }}>
        Description
      </Text>
      <Text style={styles.tableColHeader}>Project</Text>
    </View>
    <View style={styles.tableRow}>
      <Text style={styles.tableCol}>{data?.unitNumber || ""}</Text>
      <Text style={{ ...styles.tableCol, width: "50%" }}>
        {data?.description || ""}
      </Text>
      <Text style={styles.tableCol}>{data?.project || ""}</Text>
    </View>
    {/* Row 2 */}
    <View style={styles.tableRow}>
      <Text style={{ ...styles.tableColHeader, width: "33.33%" }}>
        Equipment Tag / Line #
      </Text>
      <Text style={styles.tableColHeader}>System</Text>
      <Text style={styles.tableColHeader}>Circuit #</Text>
      <Text style={styles.tableColHeader}>WSP #</Text>
      <Text style={styles.tableColHeader}>P&ID</Text>
    </View>
    <View style={styles.tableRow}>
      <Text style={{ ...styles.tableCol, width: "33.33%" }}>
        {data?.equipmentNumber || ""}
      </Text>
      <Text style={styles.tableCol}>{data?.system || ""}</Text>
      <Text style={styles.tableCol}>{data?.circuitNumber || ""}</Text>
      <Text style={styles.tableCol}>{data?.wspNumber || ""}</Text>
      <Text style={styles.tableCol}>{data?.pAndId || ""}</Text>
    </View>
    <View style={styles.tableRow}>
      <Text
        style={{ ...styles.tableColHeader, paddingTop: 12, width: "33.33%" }}
      >
        Pipe Spec (If applicable)
      </Text>
      <Text style={styles.tableColHeader}>Pipe size</Text>
      <Text style={styles.tableColHeader}>Flange Class</Text>
      <Text style={styles.tableColHeader}>DWG. Reviewed & Approved</Text>
      <Text style={styles.tableColHeader}></Text>
    </View>
    <View style={styles.tableRow}>
      <Text style={{ ...styles.tableCol, width: "33.33%" }}>
        {data?.pipeSpecIfApplicable || ""}
      </Text>
      <Text style={styles.tableCol}>{data?.pipeSize || ""}</Text>
      <Text style={styles.tableCol}>{data?.flangeClass || ""}</Text>
      <Text style={styles.tableCol}>{data?.dwgReviewedApproved || ""}</Text>
      <Text style={styles.tableCol}></Text>
    </View>
  </>
);

WqcfHeader.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object,
};
