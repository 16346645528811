import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserById } from "../../redux/slice/UsersSlice/UsersSlice";

function AddSignature() {
  const dispatch = useDispatch();
  const UserId = sessionStorage.getItem("LoginId");
  const { UserById } = useSelector((state) => state?.Users);

  useEffect(() => {
    dispatch(fetchUserById(UserId));
  }, [dispatch, UserId]);

  return (
    <div style={{ height: "100vh", overflowY: "scroll" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "auto",
          paddingBottom: "20px",
        }}
      >
        <div
          style={{
            backgroundColor: "#ffffff",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            padding: "20px",
            maxWidth: "600px",
            width: "90%",
            paddingBottom: "20px",
          }}
        >
          <div style={{ marginTop: "20px", fontSize: "18px" }}>
            <p>
              <span style={{ fontWeight: "700", paddingRight: "20px" }}>
                UserName:
              </span>{" "}
              {UserById?.data?.username}
            </p>
            <p>
              <span style={{ fontWeight: "700", paddingRight: "60px" }}>
                Email:
              </span>{" "}
              {UserById?.data?.email}
            </p>
          </div>
          <div
            style={{
              marginTop: "30px",
              display: "flex",
              gap: "43px",
            }}
          >
            <p style={{ fontWeight: "700" }}>Signature:</p>
            {UserById?.data?.signature ? (
              <img
                src={UserById.data.signature}
                alt="Signature"
                style={{
                  width: "250px",
                  height: "150px",
                  objectFit: "contain",
                  border: "1px solid lightgrey",
                  borderRadius: "10px",
                }}
              />
            ) : (
              <p style={{ color: "grey" }}>N/A</p>
            )}
          </div>
          <div
            style={{
              padding: "1rem",
              backgroundColor: "#e0f2fe",
              color: "#0369a1",
              border: "1px solid #0284c7",
              borderRadius: "0.375rem",
              fontWeight: "500",
              marginTop: "30px",
            }}
          >
            <p>
              If you&apos;d like to{" "}
              {UserById?.data?.signature ? "update" : "add"} your signature,
              please consult the admin for assistance.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddSignature;
