import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSummaryData } from "../../../redux/slice/DashboardSlice/DashboardSlice";
import { Row, Col, Card } from "react-bootstrap";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

function StatCards({ endDate, startDate, search, setSearch, comp, jobSiteId }) {
  const { SummaryData } = useSelector((state) => state?.Dashboard);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (search && comp) {
      dispatch(
        fetchSummaryData({
          start: startDate,
          end: endDate,
          compId: comp,
          jobSite: jobSiteId,
        })
      );
      setSearch(false);
    }
  }, [dispatch, search, comp]);

  return (
    <div className="mt-4">
      <Row>
        <Col xs={12} sm={4} md={3} xl={2} className="mb-3">
          <Card
            style={{
              borderRadius: "0.75rem",
              border: "1px solid #ECEAE2",
              backgroundColor: "#ECEAE2",
            }}
            className="text-center p-3"
          >
            <Card.Body>
              <Card.Title
                style={{
                  lineHeight: 1.5,
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                  // color: "#333",
                }}
              >
                Total
              </Card.Title>
              <Card.Text
                style={{
                  letterSpacing: 0,
                  lineHeight: 1.375,
                  fontWeight: 600,
                  fontSize: "1rem",
                }}
              >
                {SummaryData?.data?.totalInspections}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} sm={4} md={3} xl={2} className="mb-3">
          <Card
            style={{
              borderRadius: "0.75rem",
              border: "1px solid #C8E6C9",
              backgroundColor: "#C8E6C9",
              cursor: "pointer",
            }}
            className="text-center p-1 py-3"
            onClick={() => navigate("/package/completed", { state: { comp } })}
          >
            <Card.Body>
              <Card.Title
                style={{
                  lineHeight: 1.5,
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                }}
              >
                Completed
              </Card.Title>
              <Card.Text
                style={{
                  letterSpacing: 0,
                  lineHeight: 1.375,
                  fontWeight: 600,
                  fontSize: "1rem",
                }}
              >
                {SummaryData?.data?.Completed}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} sm={4} md={3} xl={2} className="mb-3">
          <Card
            style={{
              borderRadius: "0.75rem",
              border: "1px solid #FFEFD5",
              backgroundColor: "#FFEFD5",
              cursor: "pointer",
            }}
            className="text-center p-3"
            onClick={() => navigate("/package/pending", { state: { comp } })}
          >
            <Card.Body>
              <Card.Title
                style={{
                  lineHeight: 1.5,
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                }}
              >
                Pending
              </Card.Title>
              <Card.Text
                style={{
                  letterSpacing: 0,
                  lineHeight: 1.375,
                  fontWeight: 600,
                  fontSize: "1rem",
                }}
              >
                {SummaryData?.data?.Pending}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} sm={4} md={3} xl={2} className="mb-3">
          <Card
            style={{
              borderRadius: "0.75rem",
              border: "1px solid rgb(204, 235, 250) ",
              backgroundColor: "rgb(204, 235, 250) ",
              cursor: "pointer",
            }}
            className="text-center p-2 py-3"
            onClick={() =>
              navigate("/package/in-progress", { state: { comp } })
            }
          >
            <Card.Body>
              <Card.Title
                style={{
                  lineHeight: 1.5,
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                }}
              >
                InProgress
              </Card.Title>
              <Card.Text
                style={{
                  letterSpacing: 0,
                  lineHeight: 1.375,
                  fontWeight: 600,
                  fontSize: "1rem",
                }}
              >
                {SummaryData?.data?.InProgress}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} sm={4} md={3} xl={2} className="mb-3">
          <Card
            style={{
              borderRadius: "0.75rem",
              border: "#FF6A6A ",
              backgroundColor: "#FF6A6A ",
              cursor: "pointer",
            }}
            className="text-center p-2 py-3"
            onClick={() => navigate("/package/cancelled", { state: { comp } })}
          >
            <Card.Body>
              <Card.Title
                style={{
                  lineHeight: 1.5,
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                }}
              >
                Cancelled
              </Card.Title>
              <Card.Text
                style={{
                  letterSpacing: 0,
                  lineHeight: 1.375,
                  fontWeight: 600,
                  fontSize: "1rem",
                }}
              >
                {SummaryData?.data?.Cancelled}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} sm={4} md={3} xl={2} className="mb-3">
          <Card
            style={{
              borderRadius: "0.75rem",
              border: "lightgrey ",
              backgroundColor: "lightgrey",
              cursor: "pointer",
            }}
            className="text-center p-2 py-3"
            onClick={() => navigate("/package/on-hold", { state: { comp } })}
          >
            <Card.Body>
              <Card.Title
                style={{
                  lineHeight: 1.5,
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                }}
              >
                On Hold
              </Card.Title>
              <Card.Text
                style={{
                  letterSpacing: 0,
                  lineHeight: 1.375,
                  fontWeight: 600,
                  fontSize: "1rem",
                }}
              >
                {SummaryData?.data?.OnHold}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default StatCards;
StatCards.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  setSearch: PropTypes.func,
  search: PropTypes.bool,
  comp: PropTypes.string.isRequired,
  jobSiteId: PropTypes.string,
};
