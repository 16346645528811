import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { fetchUsersData } from "../../redux/slice/UsersSlice/UsersSlice";
import { CiEdit } from "react-icons/ci";
import { BreadcrumbsContext } from "../../context/BcrumbsContextProvider";
import CustomGrid from "../../components/common/CustomGrid/CustomGrid";
import StatusDialog from "./StatusDialog";
function UsersPage() {
  const isSuperAdmin = sessionStorage.getItem("isSuperAdmin");
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [statedata, setStateData] = useState({});
  const { setBreadcrumbsItems } = useContext(BreadcrumbsContext);
  const [open, setOpen] = useState(false);
  const updatestatus = (data) => {
    setStateData(data);
    setOpen(true);
  };
  const handleBreadcrumbs = () => {
    setBreadcrumbsItems([
      {
        id: 1,
        url: "/settings",
        name: "Settings",
      },
      {
        id: 2,
        url: "/settings/users",
        name: "Users",
      },
    ]);
  };

  useEffect(() => {
    handleBreadcrumbs();
    return () => {
      setBreadcrumbsItems([]);
    };
  }, [setBreadcrumbsItems]);
  const { UsersData, status } = useSelector((state) => state?.Users);

  useEffect(() => {
    dispatch(fetchUsersData());
  }, [dispatch]);
  console.log("userdata", UsersData);
  useEffect(() => {
    if (UsersData) {
      const processedData = UsersData?.allUsers?.map((item, idx) => ({
        idx: idx + 1,
        roleName: item?.role?.map((name) => name.roleName).join(", "),
        phonenumber: item?.phoneNumber
          ? (item?.countryCode?.includes("+") ? "" : "+") +
            (item?.countryCode || "") +
            item?.phoneNumber
          : "",
        ...item,
      }));
      setRows(processedData);
    } else {
      setRows([]);
    }
  }, [UsersData]);

  const columns = [
    {
      Header: "Sr",
      accessor: "idx",
      width: "20px",
    },
    {
      Header: "Name",
      accessor: "username",
    },
    {
      Header: "Role",
      accessor: "roleName",
    },
    {
      Header: "Phone",
      accessor: "phonenumber",
      Cell: ({ value }) => {
        const digits = value.replace(/\D/g, "");
        if (digits.length === 0) {
          return "";
        }
        return `+1 ${digits.slice(0, 3)} ${digits.slice(3, 6)} ${digits.slice(
          6
        )}`.trim();
      },
    },
    {
      Header: "Email",
      accessor: "email",
      width: "20px",
    },
    {
      Header: "User Code",
      accessor: "userCode",
      width: "20px",
    },
    {
      Header: "Country",
      accessor: "country",
    },
    {
      Header: "City",
      accessor: "city",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (cell) => (
        <span
          title={isSuperAdmin && " Update Status"}
          style={{
            padding: "0.25rem 0.75rem",
            fontSize: "0.875rem",
            fontWeight: "600",
            color: "white",
            backgroundColor:
              cell.value === "Active"
                ? "green"
                : cell.value === "Inactive"
                ? "red"
                : "#3b82f6",
            borderRadius: "9999px",
          }}
          onClick={() => updatestatus(cell?.row?.original)}
        >
          {cell.value}
        </span>
      ),
    },
    {
      Header: "Actions",
      accessor: "actions",

      disableSortBy: true,
      Cell: (cell) => (
        <div className="d-flex">
          <>
            <CiEdit
              title="Edit User"
              className="icon view-icon"
              style={{ marginTop: "11px" }}
              onClick={() => {
                navigate("/settings/users/form", {
                  state: cell?.row?.original,
                });
              }}
            />
          </>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="mt-2">
        <div className="d-flex justify-content-between mb-3">
          <h4></h4>
          <Button
            variant="primary"
            style={{ marginRight: "20px" }}
            onClick={() => {
              navigate("/settings/users/form");
            }}
          >
            New User
          </Button>
        </div>

        {status === "loading" ? (
          <div className="text-center">
            <Spinner animation="border" />
          </div>
        ) : (
          <div>
            <CustomGrid columns={columns} data={rows} />
          </div>
        )}
      </div>
      {isSuperAdmin && (
        <StatusDialog
          open={open}
          onClose={() => setOpen(false)}
          state={statedata}
        />
      )}
    </>
  );
}

export default UsersPage;
