import React from "react";
import {
  Page,
  Document,
  StyleSheet,
  Font,
  View,
  Text,
  Image,
} from "@react-pdf/renderer";
import { PdfHeader } from "./PdfHeader";
import PropTypes from "prop-types";
import moment from "moment";
// Create styles
Font.register({
  family: "Helvetica-Bold",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/helvetica/v7/oU5HfY7Evdle2mP5Xai7nPesZW2xOQ-xsNqO47m55DA.ttf",
      fontWeight: "bold",
    },
  ],
});
const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
  },
  section: {
    margin: 10,
    padding: 10,
    border: "1px solid black",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderLeft: 1,
    borderRight: 0,
    borderBottom: 0,
    borderTop: 0,
    borderColor: "#000",
    marginBottom: 10,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColHeader: {
    borderBottom: 0,
    borderTop: 0,
    borderLeft: 0,
    borderRightWidth: 1,
    width: "25%",
    borderStyle: "solid",
    backgroundColor: "#E4E4E4",
    textAlign: "center",
    paddingTop: 6,
    fontFamily: "Helvetica-Bold",
  },
  tableCol: {
    height: 50,
    borderLeft: 0,
    borderRight: 1,
    borderBottom: 1,
    borderTop: 0,
    width: "25%",
    borderStyle: "solid",
    paddingLeft: 5,
    paddingTop: 6,
    textAlign: "center",
    position: "relative",
  },
  tableColText: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    padding: 1,
  },
  tableColHeader1: {
    borderBottom: 1,
    borderTop: 1,
    borderLeft: 0,
    borderRightWidth: 1,
    width: "25%",
    borderStyle: "solid",
    backgroundColor: "#E4E4E4",
    textAlign: "center",
    paddingTop: 6,
    paddingLeft: 1,
  },
  title: {
    textAlign: "left",
    fontFamily: "Helvetica-Bold",
  },
  image: {
    width: "100%",
    height: "100%",
  },
});

// Create Document Component
export const ClForm = ({ data, rolesData }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.table}>
          <PdfHeader data={data} title={"CLEANLINESS FORM"} />
          <View style={styles.tableRow}>
            <Text
              style={{ ...styles.tableColHeader, fontSize: 12, width: "100%" }}
            >{`"AS FOUND" Package`}</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={{ ...styles.tableColHeader1, ...styles.title }}>
              Hold Points
            </Text>
            <Text style={styles.tableColHeader1}>Name</Text>
            <Text style={styles.tableColHeader1}>Signature</Text>
            <Text style={styles.tableColHeader1}>Date</Text>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText, ...styles.title }}>
                Operations
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText }}>
                {rolesData?.operationalmanager?.username}
              </Text>
            </View>
            <View style={styles.tableCol}>
              {rolesData?.operationalmanager?.signature && (
                <Image
                  style={{ ...styles.image }}
                  src={rolesData?.operationalmanager?.signature}
                />
              )}
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText }}>
                {rolesData?.operationalmanager?.statusUpdatedAt
                  ? moment(
                      rolesData?.operationalmanager?.statusUpdatedAt
                    ).format("MMM Do YYYY hh:mm:ss")
                  : ""}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText, ...styles.title }}>
                Process Engineer
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText }}>
                {rolesData?.processengineer?.username}
              </Text>
            </View>
            <View style={styles.tableCol}>
              {rolesData?.processengineer?.signature && (
                <Image
                  style={{ ...styles.image }}
                  src={rolesData?.processengineer?.signature}
                />
              )}
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText }}>
                {rolesData?.processengineer?.statusUpdatedAt
                  ? moment(rolesData?.processengineer?.statusUpdatedAt).format(
                      "MMM Do YYYY hh:mm:ss"
                    )
                  : ""}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText, ...styles.title }}>
                Inspector
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText }}>
                {rolesData?.qainspector?.username}
              </Text>
            </View>
            <View style={styles.tableCol}>
              {rolesData?.qainspector?.signature && (
                <Image
                  style={{ ...styles.image }}
                  src={rolesData?.qainspector?.signature}
                />
              )}
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText }}>
                {rolesData?.qainspector?.statusUpdatedAt
                  ? moment(rolesData?.qainspector?.statusUpdatedAt).format(
                      "MMM Do YYYY hh:mm:ss"
                    )
                  : ""}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text
                style={{
                  ...styles.tableColText,
                  ...styles.title,
                  top: "50%",
                }}
              >
                Initial Notes
              </Text>
            </View>
            <View style={{ ...styles.tableCol, width: "75%" }}>
              <Text
                style={{
                  ...styles.tableColText,
                  top: "50%",
                }}
              >
                {data?.initialNotes}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <Text
              style={{
                ...styles.tableColHeader,
                width: "100%",
                fontSize: 12,
                borderBottom: 1,
              }}
            >
              Cleanliness Agreement
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text
              style={{
                ...styles.tableColHeader1,
                borderTop: 0,
                ...styles.title,
              }}
            >
              Cleanliness Level
            </Text>
            <Text style={{ ...styles.tableColHeader1, borderTop: 0 }}>
              Cleanliness Method
            </Text>
            <Text style={{ ...styles.tableColHeader1, borderTop: 0 }}>
              Estimated Hours
            </Text>
            <Text style={{ ...styles.tableColHeader1, borderTop: 0 }}></Text>
          </View>
          <View style={styles.tableRow}>
            <View style={{ ...styles.tableCol }}>
              <Text
                style={{
                  ...styles.tableColText,
                }}
              >
                {data?.cleanlinessLevel}
              </Text>
            </View>{" "}
            <View style={{ ...styles.tableCol }}>
              <Text
                style={{
                  ...styles.tableColText,
                }}
              >
                {data?.cleanlinessMethod}
              </Text>
            </View>
            <View style={{ ...styles.tableCol }}>
              <Text
                style={{
                  ...styles.tableColText,
                }}
              >
                {data?.estimatedHours}
              </Text>
            </View>
            <View style={styles.tableCol}></View>
          </View>
          <View style={styles.tableRow}>
            <Text
              style={{
                ...styles.tableColHeader1,
                borderTop: 0,
                ...styles.title,
              }}
            >
              Hold Points
            </Text>
            <Text style={{ ...styles.tableColHeader1, borderTop: 0 }}>
              Name
            </Text>
            <Text style={{ ...styles.tableColHeader1, borderTop: 0 }}>
              Signature
            </Text>
            <Text style={{ ...styles.tableColHeader1, borderTop: 0 }}>
              Date
            </Text>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText, ...styles.title }}>
                Cleaning Initiate
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText }}>
                {rolesData?.qcinspector?.username}
              </Text>
            </View>
            <View style={styles.tableCol}>
              {rolesData?.qcinspector?.signature && (
                <Image
                  style={{ ...styles.image }}
                  src={rolesData?.qcinspector?.signature}
                />
              )}
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText }}>
                {rolesData?.qcinspector?.statusUpdatedAt
                  ? moment(rolesData?.qcinspector?.statusUpdatedAt).format(
                      "MMM Do YYYY hh:mm:ss"
                    )
                  : ""}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText, ...styles.title }}>
                Cleaning Complete
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText }}>
                {rolesData?.qcinspector?.username}
              </Text>
            </View>
            <View style={styles.tableCol}>
              {rolesData?.qcinspector?.signature && (
                <Image
                  style={{ ...styles.image }}
                  src={rolesData?.qcinspector?.signature}
                />
              )}
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText }}>
                {rolesData?.qcinspector?.statusUpdatedAt
                  ? moment(rolesData?.qcinspector?.statusUpdatedAt).format(
                      "MMM Do YYYY hh:mm:ss"
                    )
                  : ""}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <Text
              style={{
                ...styles.tableColHeader,
                width: "100%",
                fontSize: 12,
                borderBottom: 1,
              }}
            >
              Clean Package ( Prior to Work Scope)
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text
              style={{
                ...styles.tableColHeader1,
                borderTop: 0,
                ...styles.title,
              }}
            >
              Hold Points
            </Text>
            <Text style={{ ...styles.tableColHeader1, borderTop: 0 }}>
              Name
            </Text>
            <Text style={{ ...styles.tableColHeader1, borderTop: 0 }}>
              Signature
            </Text>
            <Text style={{ ...styles.tableColHeader1, borderTop: 0 }}>
              Date
            </Text>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText, ...styles.title }}>
                Operations
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText }}>
                {rolesData?.operationalmanager?.username}
              </Text>
            </View>
            <View style={styles.tableCol}>
              {rolesData?.operationalmanager?.signature && (
                <Image
                  style={{ ...styles.image }}
                  src={rolesData?.operationalmanager?.signature}
                />
              )}
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText }}>
                {rolesData?.operationalmanager?.statusUpdatedAt
                  ? moment(
                      rolesData?.operationalmanager?.statusUpdatedAt
                    ).format("MMM Do YYYY hh:mm:ss")
                  : ""}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText, ...styles.title }}>
                Process Engineer
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText }}>
                {rolesData?.processengineer?.username}
              </Text>
            </View>
            <View style={styles.tableCol}>
              {rolesData?.processengineer?.signature && (
                <Image
                  style={{ ...styles.image }}
                  src={rolesData?.processengineer?.signature}
                />
              )}
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText }}>
                {rolesData?.processengineer?.statusUpdatedAt
                  ? moment(rolesData?.processengineer?.statusUpdatedAt).format(
                      "MMM Do YYYY hh:mm:ss"
                    )
                  : ""}
              </Text>
            </View>
          </View>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={{ ...styles.tableCol, borderTop: 1 }}>
              <Text style={{ ...styles.tableColText, ...styles.title }}>
                Inspector
              </Text>
            </View>
            <View style={{ ...styles.tableCol, borderTop: 1 }}>
              <Text style={{ ...styles.tableColText }}>
                {rolesData?.qainspector?.username}
              </Text>
            </View>
            <View style={{ ...styles.tableCol, borderTop: 1 }}>
              {rolesData?.qainspector?.signature && (
                <Image
                  style={{ ...styles.image }}
                  src={rolesData?.qainspector?.signature}
                />
              )}
            </View>
            <View style={{ ...styles.tableCol, borderTop: 1 }}>
              <Text style={{ ...styles.tableColText }}>
                {rolesData?.qainspector?.statusUpdatedAt
                  ? moment(rolesData?.qainspector?.statusUpdatedAt).format(
                      "MMM Do YYYY hh:mm:ss"
                    )
                  : ""}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text
                style={{
                  ...styles.tableColText,
                  ...styles.title,
                  top: "50%",
                }}
              >
                Commnents
              </Text>
            </View>
            <View style={{ ...styles.tableCol, width: "75%" }}>
              <Text
                style={{
                  ...styles.tableColText,
                  top: "50%",
                }}
              >
                {data?.comments}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
ClForm.propTypes = {
  data: PropTypes.object,
  rolesData: PropTypes.any,
};
