import { React, useState, useContext, useEffect } from "react";
import { useFormik } from "formik";
import { ThreeDots } from "react-loader-spinner";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import * as Yup from "yup";
import { SnackBarContext } from "../../context/SnackBarContext";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { BreadcrumbsContext } from "../../context/BcrumbsContextProvider";
import "react-phone-input-2/lib/style.css";
import InspectionUserDialog from "./InspectionUserDialog";
import CustomGrid from "../../components/common/CustomGrid/CustomGrid";
import {
  fetchInspectionPopulate,
  fetchJobSiteData,
  fetchInspDataById,
} from "../../redux/slice/InspectionSlice/InspectionSlice";
import { fetchSitesDetailData } from "../../redux/slice/SitesSlice/SitesSlice";
import { FaTrash } from "react-icons/fa";
import { CreateInspectionApi } from "../../Apis/Inspection/InspectionApis";
import { UpdateInspectionByIdApi } from "../../Apis/Inspection/InspectionApis";
import Loader from "../../components/Loader/Loader";

function InspectionForm({ parent, onClose }) {
  const { InspectionPopulate, status, JobSiteData, InspectionById } =
    useSelector((state) => state?.Inspections);
  const inspectionData = useLocation();
  const [loading, setLoading] = useState(false);
  const { setsnackBarData } = useContext(SnackBarContext);
  const { setBreadcrumbsItems } = useContext(BreadcrumbsContext);
  const [update] = useState(inspectionData?.state?.id ? true : false);
  const [isdataLoading, setdataIsLoading] = useState(true);
  const [formIdDisabled, setFormIdDisabled] = useState([]);
  const [users, setUsers] = useState([]);
  const [userDetail, setUserDetail] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userStage, setUserStage] = useState("");
  const [selectedRow, setSelectedRow] = useState("");
  const [showVal, setShowVal] = useState(false);
  const [filteredEquipment, setFilteredEquipment] = useState([]);
  const [rows, setRows] = useState([
    { submissionAccess: [], formId: "", reviewAccess: [], stage: 1 },
  ]);
  const { SitesDetail } = useSelector((state) => state?.Sites);

  const handleUserclick = (stage, status, user) => {
    if (status === "Approved") {
      setUserDetail(false);
    } else {
      setUserDetail(true);
    }
    setSelectedRow(user);
    setUserStage(stage);
  };

  const handleInputChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };

  const addNewRow = () => {
    const nextId = rows.length + 1;
    setRows([
      ...rows,
      {
        submissionAccess: [],
        formId: "",
        reviewAccess: [],
        stage: nextId,
      },
    ]);
  };

  const deleteRow = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    const reorderedRows = updatedRows.map((row, idx) => ({
      ...row,
      stage: idx + 1,
    }));

    setRows(reorderedRows);
  };

  const getStageOptions = (stage) => {
    let options = [];
    // Loop through stages from 1 to the current stage
    for (let i = 1; i <= stage; i++) {
      if (i === stage) {
        options.push({ value: i, label: `Stay at stage ${i}` });
      } else {
        options.push({ value: i, label: `Go to stage ${i}` });
      }
    }
    return options;
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Field required!"),
    inspectionNumber: Yup.string().required("Field required!"),
    company: Yup.string().required("Field required!"),
    jobSite: Yup.string().required("Field required!"),
    equipmentNumbers: Yup.array()
      .of(Yup.string())
      .min(1, "Equipment is required!")
      .required("Equipment is required!"),
  });
  const formik = useFormik({
    initialValues: {
      jobSite: "",
      company: "",
      inspectionNumber: "",
      title: "",
      equipmentNumbers: [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      setShowVal(true);
      const isValid = rows.every((row) => {
        const isRowFilled = row.submissionAccess && row.formId;
        let hasReviewAccessButNoRules;
        if (row.reviewAccess && row.reviewAccess.length > 0) {
          hasReviewAccessButNoRules = row.stage > 1 && !row.rules;
        }
        return isRowFilled && !hasReviewAccessButNoRules;
      });

      if (!isValid) {
        setLoading(false);
        setsnackBarData({
          openToast: true,
          type: "warning",
          message: "Please fill in all fields in stages.",
        });
        return;
      }
      const arr = rows.map((row) => {
        return {
          submissionAccess: row?.submissionAccess,
          formId: row?.formId,
          stage: row?.stage,
          reviewAccess: row?.reviewAccess,
          ...(row?.rules && {
            rules: {
              prevStage: row?.rules || null,
            },
          }),
        };
      });
      const updatearr = rows.map((row) => {
        if (row?.stageId) {
          return {
            submissionAccess: row?.submissionAccess,
            stage: row?.stage,
            reviewAccess: row?.reviewAccess,
            stageId: row?.stageId,
          };
        } else {
          return {
            submissionAccess: row?.submissionAccess,
            formId: row?.formId,
            stage: row?.stage,
            reviewAccess: row?.reviewAccess,
            ...(row?.rules && {
              rules: {
                prevStage: row?.rules || null,
              },
            }),
          };
        }
      });

      let data = {
        ...values,
        stages: arr,
      };
      let dataupdate = {
        ...values,
        stages: updatearr,
      };
      let response = "";
      if (update) {
        response = await UpdateInspectionByIdApi(
          inspectionData.state?._id,
          dataupdate
        );
      } else {
        response = await CreateInspectionApi(data);
      }
      setLoading(true);
      response?.snackBarData?.message &&
        setsnackBarData(response?.snackBarData);
      if (response?.success) {
        setLoading(false);
        resetForm();
        if (parent == "dialog") {
          onClose(true);
        } else {
          navigate(-1);
        }
      } else {
        setLoading(false);
      }
    },
  });
  const handleBreadcrumbs = () => {
    setBreadcrumbsItems([
      {
        id: 1,
        url: "/package/in-progress",
        name: "Packages",
      },
      {
        id: 2,
        url: "/package/form",
        name: "Form",
      },
    ]);
  };

  useEffect(() => {
    handleBreadcrumbs();
    return () => {
      setBreadcrumbsItems([]);
    };
  }, [setBreadcrumbsItems]);
  useEffect(() => {
    dispatch(fetchInspectionPopulate());
    if (update) {
      dispatch(fetchInspDataById(inspectionData.state?._id));
    }
  }, [dispatch]);
  useEffect(() => {
    if (InspectionPopulate) {
      setdataIsLoading(false);
    }
    const extractedUsers = InspectionPopulate?.UserData?.flatMap((userObj) =>
      userObj?.users?.map(({ username, id, userCode, signature }) => ({
        username,
        id,
        userCode,
        signature,
        roleName: userObj.roleName,
        roleId: userObj.roleId,
      }))
    );
    setUsers(extractedUsers);
  }, [InspectionPopulate]);

  // Refill form on edit
  useEffect(() => {
    if (formik.values.company) {
      dispatch(fetchJobSiteData(formik.values.company));
    }
  }, [formik.values.company, dispatch]);
  useEffect(() => {
    if (formik.values.jobSite) {
      dispatch(fetchSitesDetailData(formik.values.jobSite));
    }
  }, [formik.values.jobSite, dispatch]);

  useEffect(() => {
    if (update) {
      formik.setFieldValue("company", inspectionData.state?.company?.id);
      formik.setFieldValue(
        "inspectionNumber",
        inspectionData.state?.inspectionNumber
      );
      formik.setFieldValue("title", inspectionData.state?.title);

      const updatedRows = InspectionById?.data?.stages?.map((stage) => ({
        submissionAccess:
          stage?.submissionAccess?.map((reviewer) => ({
            submitById: reviewer?.submitById,
            role: reviewer?.role,
          })) || [],
        formId:
          JobSiteData?.data?.FormsData?.find(
            (form) => form.name === stage?.formId?.name
          )?._id || "",
        reviewAccess:
          stage?.reviewers?.map((reviewer) => ({
            reviewerId: reviewer?.reviewerId,
            role: reviewer?.role,
          })) || [],
        stage: Number(stage?.stageLevel),
        stageId: stage?._id || "",
        reviewStatus: stage?.reviewStatus || "",
        rules: Number(stage?.rules?.prevstageNo) || null,
      }));
      setRows(updatedRows);
    }
  }, [update, inspectionData, InspectionById, JobSiteData]);

  useEffect(() => {
    if (update && InspectionById?.data) {
      const disabledState = rows?.map((row) => !!row.stageId);
      setFormIdDisabled(disabledState);
    }
  }, [rows]);

  useEffect(() => {
    if (update && SitesDetail?.length > 0) {
      formik.setFieldValue(
        "equipmentNumbers",
        [inspectionData.state?.equipmentNumbers?.id] || []
      );
    }
  }, [update, SitesDetail, inspectionData]);

  useEffect(() => {
    if (update && JobSiteData?.data?.JobSitesData?.length > 0) {
      formik.setFieldValue("jobSite", inspectionData.state?.jobSite?.id);
    }
  }, [update, JobSiteData, inspectionData]);

  console.log("formik", formik?.values);
  console.log("rows", rows);
  useEffect(() => {
    const filteredData =
      SitesDetail?.filter((site) =>
        formik.values?.equipmentNumbers?.includes(site?.id)
      ) || [];
    setFilteredEquipment(filteredData);
  }, [formik.values.equipmentNumbers, SitesDetail]);
  const columns = [
    {
      Header: "Serial #",
      accessor: "serialNumber",
      minWidth: 100,
      width: 120,
    },
    {
      Header: "Unit #",
      accessor: "unitNumber",
      minWidth: 100,
      width: 120,
    },
    {
      Header: "Equipment #",
      accessor: "equipmentNumber",
      minWidth: 120,
      width: 140,
    },
    {
      Header: "Description",
      accessor: "description",
    },
    {
      Header: "EQUIP TYPE",
      accessor: "equipType",
      minWidth: 100,
      width: 120,
    },
    {
      Header: "P&ID",
      accessor: "pAndId",
      minWidth: 120,
    },
    {
      Header: "National BD. #",
      accessor: "nationalBdNumber",
      minWidth: 150,
    },
    {
      Header: "Insulated",
      accessor: "insulated",
      minWidth: 100,
      width: 120,
    },
    {
      Header: "Design Pressure",
      accessor: "designPressure",
      minWidth: 150,
    },
    {
      Header: "Design Temp.",
      accessor: "designTemp",
      minWidth: 150,
    },
    {
      Header: "OPER. Pressure",
      accessor: "operPressure",
      minWidth: 150,
    },
    {
      Header: "OPER. Temp.",
      accessor: "operTemp",
      minWidth: 120,
    },
    {
      Header: "Year Built",
      accessor: "yearBuilt",
      minWidth: 100,
    },
    {
      Header: "WBS Code",
      accessor: "wbsCode",
      minWidth: 100,
    },
    {
      Header: "WSP #",
      accessor: "wspNumber",
      minWidth: 100,
    },
    {
      Header: "PWHT",
      accessor: "pwht",
      minWidth: 100,
    },
  ];

  return (
    <>
      <div style={{ overflowY: "scroll", overflowX: "hidden" }}>
        <form
          noValidate
          autoComplete="off"
          onSubmit={formik.handleSubmit}
          className="form-container"
        >
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div
                      className="col-12 col-md-6"
                      style={{ marginTop: "13px", marginBottom: "13px" }}
                    >
                      <div className="form-group">
                        <label htmlFor="name">Title</label>
                        <input
                          id="title"
                          name="title"
                          type="text"
                          className={`form-control ${
                            formik.touched.title && formik.errors.title
                              ? "is-invalid"
                              : ""
                          }`}
                          value={formik.values.title}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <div className="invalid-feedback">
                          {formik.touched.title && formik.errors.title}
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-12 col-md-6"
                      style={{ marginTop: "13px", marginBottom: "13px" }}
                    >
                      <div className="form-group">
                        <label htmlFor="inspectionNumber">Package #</label>
                        <input
                          id="inspectionNumber"
                          name="inspectionNumber"
                          type="text"
                          className={`form-control ${
                            formik.touched.inspectionNumber &&
                            formik.errors.inspectionNumber
                              ? "is-invalid"
                              : ""
                          }`}
                          value={formik.values.inspectionNumber}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <div className="invalid-feedback">
                          {formik.touched.inspectionNumber &&
                            formik.errors.inspectionNumber}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label htmlFor="company">Company</label>

                        <Select
                          required
                          id="company"
                          name="company"
                          isLoading={isdataLoading}
                          options={InspectionPopulate?.CompaniesData?.map(
                            (item) => ({
                              value: item.id,
                              label: item.companyName,
                            })
                          )}
                          value={InspectionPopulate?.CompaniesData?.map(
                            (item) => ({
                              value: item.id,
                              label: item.companyName,
                            })
                          ).find(
                            (option) => option.value === formik.values?.company
                          )}
                          onBlur={formik.handleBlur}
                          onChange={(selectedOption) => {
                            formik.setFieldValue(
                              "company",
                              selectedOption?.value
                            );
                            formik.setFieldValue("jobSite", "");
                            const updatedRowData = rows.map((row) => ({
                              ...row,
                              formId: "",
                            }));
                            setRows(updatedRowData);
                          }}
                          className={`react-select-container ${
                            formik.touched.company && formik.errors.company
                              ? "border border-danger rounded"
                              : ""
                          }`}
                          classNamePrefix="react-select"
                        />
                        <div className="text-sm text-danger">
                          {formik.touched.company && formik.errors.company}
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label htmlFor="jobSite">Job Site</label>
                        <Select
                          required
                          id="jobSite"
                          name="jobSite"
                          options={JobSiteData?.data?.JobSitesData?.map(
                            (item) => ({
                              value: item?.id,
                              label: item?.name,
                            })
                          )}
                          value={
                            JobSiteData?.data?.JobSitesData?.map((item) => ({
                              value: item?.id,
                              label: item?.name,
                            })).find(
                              (option) =>
                                option.value === formik.values?.jobSite
                            ) || null
                          }
                          onBlur={formik.handleBlur}
                          onChange={(selectedOption) => {
                            formik.setFieldValue(
                              "jobSite",
                              selectedOption?.value
                            );
                          }}
                          className={`react-select-container ${
                            formik.touched.jobSite && formik.errors.jobSite
                              ? "border border-danger rounded"
                              : ""
                          }`}
                          classNamePrefix="react-select"
                        />
                        <div className="text-sm text-danger">
                          {formik.touched.jobSite && formik.errors.jobSite}
                        </div>
                      </div>
                    </div>

                    {(loading || status === "loading") && (
                      <div className="text-center">
                        <Loader animation="border" />
                      </div>
                    )}

                    <div className=" mt-4 d-flex justify-content-between">
                      <h4 className="">Add Stages</h4>
                      <button
                        className="mb-2 btn btn-primary"
                        onClick={addNewRow}
                        type="button"
                      >
                        Add new stage
                      </button>
                    </div>
                    <hr />

                    <div>
                      <table className="table table-striped table-bordered table-hover">
                        <thead>
                          <tr>
                            <th>Sr #</th>
                            <th>Form</th>
                            <th>Submission Access</th>
                            <th>Review Access</th>
                            <th>On Rejection</th>

                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {rows?.map((row, index) => (
                            <tr key={row.id}>
                              <td>{index + 1}</td>
                              <td>
                                <Select
                                  required
                                  id="formId"
                                  name="formId"
                                  isDisabled={formIdDisabled[index]}
                                  options={JobSiteData?.data?.FormsData?.filter(
                                    (item) =>
                                      !rows.some(
                                        (row) => item?._id === row?.formId
                                      )
                                  )?.map((item) => ({
                                    value: item._id,
                                    label: item.name,
                                    data: item,
                                  }))}
                                  value={JobSiteData?.data?.FormsData?.map(
                                    (item) => ({
                                      value: item._id,
                                      label: item.name,
                                      data: item,
                                    })
                                  ).find(
                                    (option) => option.value === row.formId
                                  )}
                                  onChange={(selectedOption) => {
                                    handleInputChange(
                                      index,
                                      "formId",
                                      selectedOption?.value
                                    );
                                  }}
                                  classNamePrefix="react-select"
                                  menuPortalTarget={document.body}
                                  menuPlacement="auto"
                                  menuPosition="fixed"
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                />
                                {showVal && !row.formId && (
                                  <p
                                    style={{
                                      color: "red",
                                      fontSize: "12px",
                                      paddingLeft: "3px",
                                    }}
                                  >
                                    Field required!
                                  </p>
                                )}
                              </td>
                              <td>
                                <div
                                  className="d-flex flex-wrap p-2 border rounded"
                                  onClick={() =>
                                    handleUserclick(
                                      row?.stage,
                                      row?.reviewStatus,
                                      "submissionAccess"
                                    )
                                  }
                                  style={{
                                    minHeight: "40px",
                                    cursor: "text",
                                    backgroundColor:
                                      row?.reviewStatus === "Approved"
                                        ? "transparent"
                                        : "white",
                                  }}
                                >
                                  {row?.submissionAccess?.map((access) => {
                                    const user = users?.find(
                                      (u) =>
                                        u?.id === access.submitById &&
                                        u?.roleId === access.role
                                    );
                                    return (
                                      user && (
                                        <span
                                          key={user?.id}
                                          className="badge me-1"
                                          style={{
                                            backgroundColor: "lightgray",
                                            color: "black",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {user?.username}
                                        </span>
                                      )
                                    );
                                  })}
                                  {row?.submissionAccess?.length === 0 &&
                                    row?.reviewStatus !== "Approved" && (
                                      <span className="text-muted">
                                        Click to add users...
                                      </span>
                                    )}
                                </div>
                                {showVal &&
                                  row?.submissionAccess?.length === 0 && (
                                    <p
                                      style={{
                                        color: "red",
                                        fontSize: "12px",
                                        paddingLeft: "3px",
                                      }}
                                    >
                                      Field required!
                                    </p>
                                  )}
                              </td>
                              <td>
                                <div
                                  className="d-flex flex-wrap p-2 border rounded"
                                  onClick={() =>
                                    handleUserclick(
                                      row?.stage,
                                      row?.reviewStatus,
                                      "reviewAccess"
                                    )
                                  }
                                  style={{
                                    minHeight: "40px",
                                    cursor: "text",
                                    backgroundColor:
                                      row?.reviewStatus === "Approved"
                                        ? "transparent"
                                        : "white",
                                  }}
                                >
                                  {row?.reviewAccess?.map((access) => {
                                    const user = users?.find(
                                      (u) =>
                                        u?.id === access.reviewerId &&
                                        u?.roleId === access.role
                                    );
                                    return (
                                      user && (
                                        <span
                                          key={user?.id}
                                          className="badge me-1"
                                          style={{
                                            backgroundColor: "lightgray",
                                            color: "black",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {user?.username}
                                        </span>
                                      )
                                    );
                                  })}
                                  {row?.reviewAccess?.length === 0 &&
                                    row?.reviewStatus !== "Approved" && (
                                      <span className="text-muted">
                                        Click to add users...
                                      </span>
                                    )}
                                </div>
                              </td>
                              <td>
                                {index > 0 && (
                                  <Select
                                    required
                                    id="rules"
                                    name="rules"
                                    isDisabled={
                                      row?.reviewStatus &&
                                      row?.reviewStatus === "Approved"
                                    }
                                    options={getStageOptions(row.stage)}
                                    value={getStageOptions(row.stage).find(
                                      (option) => option.value === row.rules
                                    )}
                                    onChange={(selectedOption) => {
                                      handleInputChange(
                                        index,
                                        "rules",
                                        selectedOption?.value
                                      );
                                    }}
                                    classNamePrefix="react-select"
                                    menuPortalTarget={document.body}
                                    menuPlacement="auto"
                                    menuPosition="fixed"
                                    styles={{
                                      menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 9999,
                                      }),
                                    }}
                                  />
                                )}
                                {showVal &&
                                  row?.stage > 1 &&
                                  row?.reviewAccess?.length !== 0 &&
                                  !row?.rules && (
                                    <p
                                      style={{
                                        color: "red",
                                        fontSize: "12px",
                                        paddingLeft: "3px",
                                      }}
                                    >
                                      Field required!
                                    </p>
                                  )}
                              </td>
                              <td>
                                <button
                                  disabled={
                                    rows.length === 1 ||
                                    index === 0 ||
                                    row?.reviewStatus === "Approved" ||
                                    formIdDisabled[index]
                                  }
                                  className="btn btn-danger"
                                  onClick={() => deleteRow(index)}
                                >
                                  <FaTrash />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className=" mt-4 ">
                      <h4 className="">Add Equipment</h4>
                    </div>
                    <hr />
                    <div>
                      <div
                        className="col-12 col-md-6"
                        style={{ marginBottom: "13px" }}
                      >
                        <div className="form-group">
                          <label htmlFor="equipment">Equipment #</label>
                          <Select
                            required
                            id="equipmentNumbers"
                            name="equipmentNumbers"
                            options={
                              SitesDetail?.map((site) => ({
                                value: site.equipmentNumber,
                                label: (
                                  <>
                                    <strong>{site.equipmentNumber}</strong>,{" "}
                                    {site.equipType}
                                  </>
                                ),
                                id: site.id,
                              })) || []
                            }
                            value={
                              SitesDetail?.map((site) => ({
                                value: site.equipmentNumber,
                                label: (
                                  <>
                                    <strong>{site.equipmentNumber}</strong>,{" "}
                                    {site.equipType}
                                  </>
                                ),
                                id: site.id,
                              })).find((option) =>
                                formik.values.equipmentNumbers?.includes(
                                  option.id
                                )
                              ) || null
                            }
                            onBlur={formik.handleBlur}
                            menuPlacement="top"
                            menuPortalTarget={document.body}
                            onChange={(selectedOption) => {
                              formik.setFieldValue(
                                "equipmentNumbers",
                                selectedOption ? [selectedOption.id] : []
                              );
                            }}
                            className={`react-select-container ${
                              formik.touched.equipmentNumbers &&
                              formik.errors.equipmentNumbers
                                ? "border border-danger rounded"
                                : ""
                            }`}
                            classNamePrefix="react-select"
                          />

                          <div className="text-sm text-danger">
                            {formik.touched.equipmentNumbers &&
                              formik.errors.equipmentNumbers}
                          </div>
                        </div>
                      </div>

                      {filteredEquipment?.length > 0 && (
                        <div>
                          <p
                            style={{
                              fontWeight: 600,
                              marginTop: "5px",
                            }}
                          >
                            Equipment Details
                          </p>
                          <CustomGrid
                            columns={columns}
                            data={filteredEquipment}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-end align-items-center mt-4 gap-3">
            <ThreeDots
              height="40"
              width="40"
              radius="9"
              color="#1c4e80 "
              visible={loading}
            />
            {parent == "screen" && (
              <button
                type="button"
                className="btn-secondary"
                onClick={() => navigate(-1)}
              >
                Back
              </button>
            )}
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading || status === "loading"}
            >
              Save
            </button>
          </div>
        </form>
      </div>

      <InspectionUserDialog
        open={userDetail}
        onClose={() => setUserDetail(false)}
        state={users}
        stage={userStage}
        row={rows}
        setRow={setRows}
        selected={selectedRow}
      />
    </>
  );
}

export default InspectionForm;
InspectionForm.propTypes = {
  parent: PropTypes.string,
  onClose: PropTypes.func,
  screen: PropTypes.string,
  setFieldValue: PropTypes.func,
};
