import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import PropTypes from "prop-types";
import UserModal from "./UserDetailModal";

function BarChart({ data }) {
  const [showModal, setShowModal] = useState(false);
  const [userData, setUserData] = useState(null);

  const [chartState, setChartState] = useState({
    series: [
      {
        name: "Packages Count",
        data: [],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [],
        title: {
          text: "Roles",
        },
      },
      yaxis: {
        title: {
          text: "Count",
        },
      },
      tooltip: {
        custom: undefined,
      },
    },
  });

  useEffect(() => {
    const categories = data.map((item) => item.roleName);
    const packageCounts = data.map((item) => item.count);
    const userCounts = data.map((item) => item.userCount);
    const colors = [
      "rgb(79, 195, 247)",
      "#eab676",
      "lightgrey",
      "#FFC04D",
      "#48BF40",
      "#33FFF5",
      "rgb(204, 235, 250)",
      "#C8E6C9",
    ];

    const seriesData = packageCounts.map((count, index) => ({
      x: categories[index],
      y: count,
      fillColor: colors[index],
    }));

    setChartState({
      series: [
        {
          data: seriesData,
        },
      ],
      options: {
        ...chartState.options,
        xaxis: {
          categories,
        },
        tooltip: {
          custom: ({ series, seriesIndex, dataPointIndex }) => {
            const roleName = categories[dataPointIndex];
            const packageCount = series[seriesIndex][dataPointIndex];
            const userCount = userCounts[dataPointIndex];

            return `
              <div style="padding:10px; border:1px solid #ddd; background:#fff;">
                <strong>Role: </strong>${roleName}<br/>
                <strong>Packages Count: </strong>${packageCount}<br/>
                <strong>Users Count: </strong>${userCount}<br/>
              </div>
            `;
          },
        },
        chart: {
          ...chartState.options.chart,
          events: {
            dataPointSelection: (event, chartContext, { dataPointIndex }) => {
              if (dataPointIndex >= 0 && data[dataPointIndex]) {
                setUserData(data[dataPointIndex]);
                setShowModal(true);
              }
            },
          },
        },
      },
    });
  }, [data]);

  return (
    <div>
      <div
        style={{
          textAlign: "center",
          fontWeight: "bold",
          fontSize: "0.8em",
        }}
      >
        Users Assigned
      </div>
      <div id="chart">
        <ReactApexChart
          options={chartState.options}
          series={chartState.series}
          type="bar"
          height={chartState.options.chart.height}
        />
      </div>
      {showModal && (
        <UserModal
          open={showModal}
          onClose={() => setShowModal(false)}
          state={userData}
        />
      )}
    </div>
  );
}

BarChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      roleName: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
      userCount: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default BarChart;
