import React from "react";
import {
  Page,
  Document,
  StyleSheet,
  Font,
  View,
  Text,
} from "@react-pdf/renderer";
import { CommentFooter } from "./CommentFooter";
import { WqcfHeader } from "./WQCFHeader";
import PropTypes from "prop-types";
import moment from "moment";
// Create styles
Font.register({
  family: "Helvetica-Bold",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/helvetica/v7/oU5HfY7Evdle2mP5Xai7nPesZW2xOQ-xsNqO47m55DA.ttf",
      fontWeight: "bold",
    },
  ],
});
const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
  },
  section: {
    margin: 10,
    padding: 10,
    border: "1px solid black",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderLeft: 1,
    borderRight: 0,
    borderBottom: 0,
    borderTop: 0,
    borderColor: "#000",
    marginBottom: 10,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColHeader: {
    borderBottom: 0,
    borderTop: 0,
    borderLeft: 0,
    // fontSize: 12,
    borderRightWidth: 1,
    width: "25%",
    borderStyle: "solid",
    backgroundColor: "#E4E4E4",
    textAlign: "center",
    paddingTop: 6,
    paddingBottom: 1,
    fontFamily: "Helvetica-Bold",
  },
  tableCol: {
    borderLeft: 0,
    borderRight: 1,
    borderBottom: 1,
    borderTop: 0,
    width: "25%",
    borderStyle: "solid",
    minHeight: 25,
    // borderWidth: 1,
    paddingLeft: 5,
    // paddingTop: 6,
    // paddingBottom: 3,
    textAlign: "left",
  },
  tableColNew: {
    borderLeft: 0,
    borderRight: 1,
    borderBottom: 1,
    borderTop: 0,
    width: "25%",
    borderStyle: "solid",
    // borderWidth: 1,
    paddingLeft: 5,
    // paddingTop: 6,
    // paddingBottom: 3,
    textAlign: "center",
  },
  tableColHeader1: {
    borderBottom: 1,
    borderLeft: 0,
    borderTop: 0,
    height: 35,
    borderRightWidth: 1,
    borderStyle: "solid",
    textAlign: "center",
    paddingTop: 22,
    paddingLeft: 1,
  },
  tableColBottom: {
    minHeight: 25,
    borderLeft: 0,
    borderRight: 1,
    borderBottom: 1,
    borderTop: 0,
    width: "25%",
    borderStyle: "solid",
    // borderWidth: 1,
    paddingLeft: 5,
    paddingTop: 6,
    // paddingBottom: 3,
    textAlign: "left",
    position: "relative",
  },
  tableColText: {
    position: "absolute", // Absolutely position the text
    bottom: 0, // Align the text to the bottom
    left: 0,
    right: 0,
    padding: 1,
  },
  title: {
    textAlign: "left",
    fontFamily: "Helvetica-Bold",
  },
  tableHeaderCell: {
    textAlign: "center",
    fontFamily: "Helvetica-Bold",
    padding: 5,
  },
  footerFont: {
    fontSize: 8,
  },
});

// Create Document Component
export const WqcForm = ({ data }) => {
  console.log("data", data);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.table}>
          <WqcfHeader data={data} title={"Weld Quality Control Form (WQCF)"} />
          <View style={styles.tableRow}>
            <Text
              style={{
                ...styles.tableColHeader,
                width: "100%",
                borderBottom: 1,
                fontSize: 12,
                backgroundColor: null,
              }}
            >
              Weld Requirements
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={{ ...styles.tableColHeader, borderBottom: 1 }}>
              Quality control Item
            </Text>
            <Text
              style={{
                ...styles.tableColHeader,
                width: "10%",
                borderBottom: 1,
              }}
            ></Text>
            <Text
              style={{
                ...styles.tableColHeader,
                width: "20%",
                borderBottom: 1,
              }}
            ></Text>
            <Text style={{ ...styles.tableColHeader, borderBottom: 1 }}>
              Quality control Item
            </Text>
            <Text
              style={{
                ...styles.tableColHeader,
                width: "10%",
                borderBottom: 1,
              }}
            ></Text>
            <Text
              style={{
                ...styles.tableColHeader,
                width: "20%",
                borderBottom: 1,
              }}
            ></Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={{ ...styles.tableCol }}>
              Cleaning/Surface Prep Instructions:
            </Text>
            <Text style={{ ...styles.tableCol, width: "10%" }}>
              {data?.select1}
            </Text>
            <Text style={{ ...styles.tableCol, width: "20%" }}>
              {data?.cleaningSurfacePrepInstructions}
            </Text>
            <Text style={{ ...styles.tableCol }}>
              Package / NDE Requirements?
            </Text>
            <Text style={{ ...styles.tableCol, width: "10%" }}>
              {data?.select}
            </Text>
            <Text style={{ ...styles.tableCol, width: "20%" }}>
              {data?.inspectionNdeRequirements}
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={{ ...styles.tableCol }}>
              Bake-Out, Caustic Check Requirements:
            </Text>
            <Text style={{ ...styles.tableCol, width: "10%" }}>
              {data?.select7}
            </Text>
            <Text style={{ ...styles.tableCol, width: "20%" }}>
              {data?.bakeOutCausticCheckRequirements}
            </Text>
            <Text style={{ ...styles.tableCol }}>
              Mill Test Reports, PMI Requirements?
            </Text>
            <Text style={{ ...styles.tableCol, width: "10%" }}>
              {data?.select4}
            </Text>
            <Text style={{ ...styles.tableCol, width: "20%" }}>
              {data?.millTestReportsPmiRequirements}
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={{ ...styles.tableCol, paddingTop: 6 }}>
              Pre-Heat Requirements?
            </Text>
            <Text style={{ ...styles.tableCol, width: "10%" }}>
              {data?.select6}
            </Text>
            <Text style={{ ...styles.tableCol, width: "20%" }}>
              {data?.preHeatRequirements}
            </Text>
            <Text style={{ ...styles.tableCol, paddingTop: 6 }}>
              PWHT Requirements?
            </Text>
            <Text style={{ ...styles.tableCol, width: "10%" }}>
              {data?.select3}
            </Text>
            <Text style={{ ...styles.tableCol, width: "20%" }}>
              {data?.pwhtRequirements}
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={{ ...styles.tableCol }}>
              WPS / Welding requirement?
            </Text>
            <Text style={{ ...styles.tableCol, width: "10%" }}>
              {data?.select5}
            </Text>
            <Text style={{ ...styles.tableCol, width: "20%" }}>
              {data?.wpsWeldingRequirement}
            </Text>
            <Text style={{ ...styles.tableCol }}>
              Pressure Test Requirements?
            </Text>
            <Text style={{ ...styles.tableCol, width: "10%" }}>
              {data?.select2}
            </Text>
            <Text style={{ ...styles.tableCol, width: "20%" }}>
              {data?.pressureTestRequirements}
            </Text>
          </View>
          {/* Comment */}
          <CommentFooter data={{ comments: data?.comments1 }} />
          {/* Execution */}
          <View style={styles.tableRow}>
            <View style={styles.tableColNew}>
              <Text style={{ ...styles.tableHeaderCell, paddingBottom: 0 }}>
                WQCF Approved for Execution:
              </Text>
            </View>
            <View
              style={{
                ...styles.tableColNew,
                width: "42%",
                padding: 0,
                borderRight: 0,
                borderBottom: 0,
              }}
            >
              <View style={{ flexDirection: "column" }}>
                <Text
                  style={{
                    ...styles.tableColNew,
                    width: "100%",
                    fontSize: 8,
                    ...styles.tableHeaderCell,
                  }}
                >
                  Delek- Eng, QA Representative
                </Text>
                <Text
                  style={{
                    ...styles.tableColNew,
                    width: "100%",
                    padding: 5,
                  }}
                >
                  {data?.delekEngQaRepresentative}
                </Text>
              </View>
            </View>
            <View
              style={{
                ...styles.tableColNew,
                padding: 0,
                borderRight: 0,
                borderBottom: 0,
              }}
            >
              <View style={{ flexDirection: "column" }}>
                <Text
                  style={{
                    ...styles.tableColNew,
                    width: "100%",
                    ...styles.tableHeaderCell,
                    fontSize: 8,
                  }}
                >
                  Date
                </Text>
                <Text
                  style={{ ...styles.tableColNew, width: "100%", padding: 5 }}
                >
                  {data?.dateTime3
                    ? moment(data?.dateTime3).format("MMM Do YYYY")
                    : ""}
                </Text>
              </View>
            </View>
            <View style={styles.tableColNew}>
              <Text style={{ ...styles.tableHeaderCell, paddingBottom: 0 }}>
                WQCF Approved for Execution:
              </Text>
            </View>
            <View
              style={{
                ...styles.tableColNew,
                width: "42%",
                padding: 0,
                borderRight: 0,
                borderBottom: 0,
              }}
            >
              <View style={{ flexDirection: "column" }}>
                <Text
                  style={{
                    ...styles.tableColNew,
                    width: "100%",
                    // padding: 5,
                    ...styles.tableHeaderCell,
                    fontSize: 8,
                  }}
                >
                  Delek- Eng, QC Representative
                </Text>
                <Text
                  style={{
                    ...styles.tableColNew,
                    width: "100%",
                    padding: 5,
                  }}
                >
                  {data?.delekQcRepresentative}
                </Text>
              </View>
            </View>
            <View
              style={{
                ...styles.tableColNew,
                padding: 0,
                borderRight: 0,
                borderBottom: 0,
              }}
            >
              <View style={{ flexDirection: "column" }}>
                <Text
                  style={{
                    ...styles.tableColNew,
                    width: "100%",
                    ...styles.tableHeaderCell,
                    fontSize: 8,
                  }}
                >
                  Date
                </Text>
                <Text
                  style={{ ...styles.tableColNew, width: "100%", padding: 5 }}
                >
                  {data?.dateTime4
                    ? moment(data?.dateTime4).format("MMM Do YYYY")
                    : ""}
                </Text>
              </View>
            </View>
          </View>
          {/* Execution Verification */}
          <View style={styles.tableRow}>
            <Text
              style={{
                ...styles.tableColHeader,
                width: "100%",
                borderBottom: 1,
                fontSize: 12,
                backgroundColor: null,
              }}
            >
              WQCF Execution Verification
            </Text>
          </View>
          <View style={styles.tableRow}>
            <View style={{ ...styles.tableColBottom, width: "50%" }}>
              <Text
                style={{
                  fontFamily: "Helvetica-Bold",
                  ...styles.tableColText,
                  textAlign: "center",
                }}
              >
                Checklist Item
              </Text>
            </View>
            <View style={{ ...styles.tableColBottom, width: "32%" }}>
              <Text
                style={{
                  fontFamily: "Helvetica-Bold",
                  ...styles.tableColText,
                  textAlign: "center",
                }}
              >
                Supervisor/ Job Foreman Initials:
              </Text>
            </View>
            <View style={styles.tableColBottom}>
              <Text
                style={{
                  fontFamily: "Helvetica-Bold",
                  ...styles.tableColText,
                  textAlign: "center",
                }}
              >
                QC Rep Initials:
              </Text>
            </View>
            <View style={styles.tableColBottom}>
              <Text
                style={{
                  fontFamily: "Helvetica-Bold",
                  ...styles.tableColText,
                  textAlign: "center",
                }}
              >
                Site QA Rep. Initials:
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={{ ...styles.tableColBottom, width: "50%" }}>
              <Text
                style={{
                  ...styles.tableColText,
                }}
              >
                Welding connections match applicable ISOs/Drawings?
              </Text>
            </View>
            <View style={{ ...styles.tableColBottom, width: "32%" }}>
              <Text
                style={{
                  ...styles.tableColText,
                }}
              >
                {data?.executiveJobSupervisor}
              </Text>
            </View>
            <View style={styles.tableColBottom}>
              <Text
                style={{
                  ...styles.tableColText,
                }}
              >
                {data?.qcInspector6}
              </Text>
            </View>
            <View style={styles.tableColBottom}>
              <Text
                style={{
                  ...styles.tableColText,
                }}
              >
                {data?.qaInspector6}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={{ ...styles.tableColBottom, width: "50%" }}>
              <Text
                style={{
                  ...styles.tableColText,
                }}
              >
                Welding completed per WQCF requirements?
              </Text>
            </View>
            <View style={{ ...styles.tableColBottom, width: "32%" }}>
              <Text
                style={{
                  ...styles.tableColText,
                }}
              >
                {data?.executiveJobSupervisor1}
              </Text>
            </View>
            <View style={styles.tableColBottom}>
              <Text
                style={{
                  ...styles.tableColText,
                }}
              >
                {data?.qcInspector8}
              </Text>
            </View>
            <View style={styles.tableColBottom}>
              <Text
                style={{
                  ...styles.tableColText,
                }}
              >
                {data?.qaInspector8}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={{ ...styles.tableColBottom, width: "50%" }}>
              <Text
                style={{
                  ...styles.tableColText,
                }}
              >
                Weld NDE completed and approved?
              </Text>
            </View>
            <View style={{ ...styles.tableColBottom, width: "32%" }}>
              <Text
                style={{
                  ...styles.tableColText,
                }}
              >
                {data?.executiveJobSupervisor2}
              </Text>
            </View>
            <View style={styles.tableColBottom}>
              <Text
                style={{
                  ...styles.tableColText,
                }}
              >
                {data?.qcInspector7}
              </Text>
            </View>
            <View style={styles.tableColBottom}>
              <Text
                style={{
                  ...styles.tableColText,
                }}
              >
                {data?.qaInspector7}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={{ ...styles.tableColBottom, width: "50%" }}>
              <Text
                style={{
                  ...styles.tableColText,
                }}
              ></Text>
            </View>
            <View style={{ ...styles.tableColBottom, width: "32%" }}>
              <Text
                style={{
                  ...styles.tableColText,
                }}
              ></Text>
            </View>
            <View style={styles.tableColBottom}>
              <Text
                style={{
                  ...styles.tableColText,
                }}
              ></Text>
            </View>
            <View style={styles.tableColBottom}>
              <Text
                style={{
                  ...styles.tableColText,
                }}
              ></Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <Text
              style={{
                ...styles.tableCol,
                width: "100%",
                height: 70,
              }}
            >
              Note any discrepancies, repairs made, or rejects:
              {"\n"}
              {data?.noteAnyDiscrepanciesRepairsMadeOrRejects}
            </Text>
          </View>
          {/* Footer */}
          <View style={styles.tableRow}>
            <Text
              style={{
                ...styles.tableColNew,
                ...styles.footerFont,

                width: "50%",
              }}
            >
              Execution/Job Supervisor:
            </Text>
            <Text
              style={{
                ...styles.tableColNew,
                ...styles.footerFont,
              }}
            >
              Date:
            </Text>
            <Text
              style={{
                ...styles.tableColNew,
                ...styles.footerFont,

                width: "50%",
              }}
            >
              QC Representative:
            </Text>
            <Text
              style={{
                ...styles.tableColNew,
                ...styles.footerFont,
              }}
            >
              Date:
            </Text>
            <Text
              style={{
                ...styles.tableColNew,
                ...styles.footerFont,

                width: "50%",
              }}
            >
              Site QA Representative:
            </Text>
            <Text
              style={{
                ...styles.tableColNew,
                ...styles.footerFont,
              }}
            >
              Date:
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text
              style={{
                ...styles.tableColNew,
                ...styles.footerFont,

                width: "50%",
              }}
            >
              {data?.name1}
            </Text>
            <Text
              style={{
                ...styles.tableColNew,
                ...styles.footerFont,
              }}
            >
              {data?.dateTime
                ? moment(data?.dateTime).format("MMM Do YYYY")
                : ""}
            </Text>
            <Text
              style={{
                ...styles.tableColNew,
                ...styles.footerFont,

                width: "50%",
              }}
            >
              {data?.name2}
            </Text>
            <Text
              style={{
                ...styles.tableColNew,
                ...styles.footerFont,
              }}
            >
              {data?.dateTime1
                ? moment(data?.dateTime1).format("MMM Do YYYY")
                : ""}
            </Text>
            <Text
              style={{
                ...styles.tableColNew,
                ...styles.footerFont,

                width: "50%",
              }}
            >
              {data?.name}
            </Text>
            <Text
              style={{
                ...styles.tableColNew,
                ...styles.footerFont,
              }}
            >
              {data?.dateTime2
                ? moment(data?.dateTime2).format("MMM Do YYYY")
                : ""}
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text
              style={{
                ...styles.tableCol,
                fontSize: 8,
                width: "100%",
                textAlign: "center",
                padding: 6,
              }}
            >
              Confirms all work is complete; all workmanship has been verified
              as acceptable
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
WqcForm.propTypes = {
  data: PropTypes.object,
};
