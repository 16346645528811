import React, { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { ListGroup, Collapse } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { adminSideBarData } from "../../../Resources/SidebarData";
import { BreadcrumbsContext } from "../../../context/BcrumbsContextProvider";
import { MdLock } from "react-icons/md";
import { FiChevronUp, FiChevronDown } from "react-icons/fi";
import BrandLogo from "../../../Assets/Images/rami scope.png";
import { revertAll } from "../../../redux/slice/ResetStateAction";
import { useDispatch } from "react-redux";

function SideDrawer({ collapsed, setCollapsed }) {
  const { breadcrumbsItems, setBreadcrumbsItems } =
    useContext(BreadcrumbsContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [activeItem, setActiveItem] = useState(() => {
    return (
      localStorage.getItem("activeItem") ||
      (sessionStorage.getItem("isAdmin") ||
      sessionStorage.getItem("isSuperAdmin")
        ? "Statistics"
        : "My Packages")
    );
  });

  const [openMenu, setOpenMenu] = useState({});
  const isAdmin = sessionStorage.getItem("isAdmin");
  const isSuperAdmin = sessionStorage.getItem("isSuperAdmin");
  const isEmployee = sessionStorage.getItem("isEmployee");

  const handleBreadcrumbs = (name, url) => {
    setBreadcrumbsItems([{ id: breadcrumbsItems.length, url, name }]);
  };

  const toggleSubItems = (name) => {
    setOpenMenu((prev) => ({
      [name]: !prev[name],
    }));
  };

  const handleLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.reload();
    dispatch(revertAll());
    navigate("/signin", { replace: true });
  };

  const renderSidebarItems = () => {
    return adminSideBarData
      .filter((item) => {
        if (isSuperAdmin) return true;
        if (isAdmin) return item.name !== "Create Forms";
        if (isEmployee) {
          return (
            item.name === "My Packages" ||
            item.name === "My Approval" ||
            item.name === "Signature" ||
            item.name === "PDF"
          );
        }
        return false;
      })
      .map((item, index) => (
        <div key={index}>
          <ListGroup.Item action className="list-item-group">
            <Link
              to={item?.url}
              className={`list-link ${
                activeItem === item.name ? "active" : ""
              }`}
              onClick={() => {
                setActiveItem(item.name);
                const breadcrumbName =
                  item.url === "/package/pending"
                    ? "Pending Packages"
                    : item.url === "/my-package/pending"
                    ? "Pending Packages"
                    : item.url === "/my-approval/pending"
                    ? "Pending Packages"
                    : item.name;
                handleBreadcrumbs(breadcrumbName, item.url);
                if (item.subItems)
                  toggleSubItems(item.menuName ? item.menuName : item.name);
              }}
            >
              <div className="d-flex align-items-center">
                <div className="icon-sidebar">{item.icon("#FFD64A")}</div>
                {!collapsed && (
                  <div className="list-item-text">{item.name}</div>
                )}
                {item.subItems?.length > 0 && (
                  <div className="icon-right">
                    {openMenu[item?.name] ? (
                      <FiChevronUp style={{ fontSize: "17px" }} />
                    ) : (
                      <FiChevronDown style={{ fontSize: "17px" }} />
                    )}
                  </div>
                )}
              </div>
            </Link>
          </ListGroup.Item>

          {/* Sub-menu items */}
          {item.subItems?.length > 0 && (
            <Collapse in={openMenu[item?.name]} timeout="auto" unmountOnExit>
              <ListGroup
                className="sub-items-list"
                style={{ paddingLeft: "35px" }}
              >
                {item.subItems.map((subitem, idx) => (
                  <ListGroup.Item
                    key={idx}
                    action
                    className="sub-item-group"
                    style={{
                      backgroundColor: "#1c4e80",
                      border: "none",
                    }}
                  >
                    <Link
                      to={subitem?.url}
                      className={`list-link ${
                        activeItem === subitem.menuName ? "active" : ""
                      }`}
                      onClick={() => {
                        setActiveItem(subitem.menuName); // Update active submenu item
                        handleBreadcrumbs(subitem.name, subitem.url);
                      }}
                      style={{ color: "#FFD64A" }}
                    >
                      <div
                        className="d-flex align-items-center"
                        style={{ marginLeft: "5px" }}
                      >
                        <div className="list-item-text">{subitem.menuName}</div>
                      </div>
                    </Link>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Collapse>
          )}
        </div>
      ));
  };

  useEffect(() => {
    const openObj = {};
    adminSideBarData.forEach((item) => {
      if (item?.open !== undefined) openObj[item?.name] = item?.open;
    });
    setOpenMenu(openObj);
  }, []);
  useEffect(() => {
    localStorage.setItem("activeItem", activeItem);
  }, [activeItem]);

  return (
    <div
      className={`${
        collapsed ? "collapsed" : ""
      } align-items-center justify-content-center`}
      onMouseEnter={() => setCollapsed(false)}
      onMouseLeave={() => setCollapsed(true)}
      style={{
        width: collapsed ? "68px" : "250px",
        transition: "transform 0.3s ease",
        paddingBottom: "30px",
        overflowY: "auto",
        overflowX: "hidden",
        maxHeight: "100vh",
      }}
    >
      <Link
        to={
          isSuperAdmin
            ? "/"
            : isAdmin
            ? "/"
            : isEmployee
            ? "/my-package/pending"
            : "/"
        }
        onClick={() =>
          handleBreadcrumbs(
            isSuperAdmin
              ? "Statistics"
              : isAdmin
              ? "Statistics"
              : isEmployee
              ? "My Packages"
              : "Home",
            isSuperAdmin
              ? "/"
              : isAdmin
              ? "/"
              : isEmployee
              ? "/my-package/pending"
              : "/"
          )
        }
      >
        <div className="image-container d-flex justify-content-center p-5">
          <img
            src={BrandLogo}
            alt="logo"
            style={{
              height: "auto",
              width: collapsed ? "100%" : "100%",
              transition: "width 0.3s",
            }}
          />
        </div>
      </Link>
      <ListGroup>
        {renderSidebarItems()}
        <ListGroup.Item
          action
          className="list-item-group"
          onClick={handleLogout}
          style={{ minWidth: "55px", overflow: "hidden" }}
        >
          <Link to={"/signin"} className="list-link">
            <div className="d-flex align-items-center">
              <div className="icon-sidebar">
                <MdLock color="#FFD64A" />
              </div>
              {!collapsed && <div className="list-item-text">Log Out</div>}
            </div>
          </Link>
        </ListGroup.Item>
      </ListGroup>
    </div>
  );
}

SideDrawer.propTypes = {
  drawerOpen: PropTypes.bool,
  toggleDrawer: PropTypes.func,
  collapsed: PropTypes.bool.isRequired,
  setCollapsed: PropTypes.func.isRequired,
};

export default SideDrawer;
