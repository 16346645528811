import React from "react";
import { FormEdit } from "../../OurForms/FormEdit";
import { FormioProvider } from "../../context/FormioContext";
function CreateForm() {
  return (
    <FormioProvider baseUrl={process.env.REACT_APP_API_URL_LOCAL}>
      <FormEdit />
    </FormioProvider>
  );
}

export default CreateForm;
