import { useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import InspectionTable from "./InspectionTable";
import PropTypes from "prop-types";
import axiosInstance from "../../../utils/AxiosInstance";
import { Icon } from "@iconify/react/dist/iconify.js";

function Jobsite({ data, idx, start, endDate, search, setSearch, index }) {
  const [reportData, setReportData] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [iconValue, setIconValue] = useState({ iconVal: null, index: null });
  const [activeKey, setActiveKey] = useState(() =>
    index === 0 && idx === 0 ? "0" : null
  );

  const fetchJobSiteReport = async () => {
    try {
      const response = await axiosInstance.get(
        `/Inspection/JobSiteReport?from=${start}&to=${endDate}&jobSiteId=${data.jobsiteId}`
      );
      setReportData(response.data);
    } catch (error) {
      console.error("Failed to fetch job site report:", error);
    }
  };

  console.log("this is filter value", iconValue);

  const handleIconClick = (value, index, event) => {
    event.stopPropagation();
    setIconValue((prev) =>
      prev.iconVal === value && prev.index === index
        ? { iconVal: null, index: null }
        : { iconVal: value, index }
    );
  };

  const handleAccordionClick = () => {
    setIsExpanded((prev) => !prev);
    if (!isExpanded && !reportData) {
      fetchJobSiteReport();
      setSearch(false);
    }
  };

  useEffect(() => {
    if (index === 0 && idx === 0 && search) {
      fetchJobSiteReport();
      setIsExpanded(true);
      setSearch(false);
    }
  }, [idx, reportData, search]);

  return (
    <Accordion activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
      <Accordion.Item eventKey={String(idx)}>
        <Accordion.Header
          onClick={handleAccordionClick}
          className="custom-accordion-headerJob"
        >
          <div
            style={{
              display: "flex",
              gap: "10px",
              flexBasis: "100%",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            <span
              style={{
                fontSize: "1rem",
                fontWeight: "bold",
                flexGrow: 1,
                minWidth: "180px",
              }}
            >
              {`${idx + 1}- ${data?.name}`}
              <span
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "bold",
                  color: "primary",
                }}
              ></span>
            </span>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "38px",
                gap: "30px",
                fontSize: "0.875rem",
                fontWeight: "bold",
                paddingTop: "10px",
                flexGrow: 3,
                flexWrap: "wrap",
              }}
            >
              <span style={{ color: "green" }}>
                <div
                  style={{
                    display: "flex",
                    borderBottom:
                      iconValue?.index === idx &&
                      iconValue?.iconVal === "Completed"
                        ? `2px solid green`
                        : "none",
                    paddingBottom: "3px",
                  }}
                  title="Completed"
                  onClick={(event) => handleIconClick("Completed", idx, event)}
                >
                  <Icon
                    icon="fluent-mdl2:completed"
                    style={{ color: "green", fontSize: "20px" }}
                    data-tip="Completed Packages"
                  />

                  <span
                    style={{
                      fontSize: "18px",
                      paddingLeft: "7px",
                    }}
                  >
                    {data?.jobSiteCompletedCount}
                  </span>
                </div>
              </span>

              <span style={{ color: "rgb(79, 195, 247)" }}>
                <div
                  style={{
                    display: "flex",
                    borderBottom:
                      iconValue?.index === idx &&
                      iconValue?.iconVal === "InProgress"
                        ? `2px solid rgb(79, 195, 247)`
                        : "none",
                    paddingBottom: "3px",
                  }}
                  title="Inprogress"
                  onClick={(event) => handleIconClick("InProgress", idx, event)}
                >
                  <Icon
                    icon="mdi:progress-check"
                    style={{
                      color: "rgb(79, 195, 247)",
                      fontSize: "20px",
                    }}
                  />
                  <span
                    style={{
                      fontSize: "18px",
                      paddingLeft: "7px",
                    }}
                  >
                    {data?.jobSiteInProgressCount}
                  </span>
                </div>
              </span>
              <span style={{ color: "orange" }}>
                <div
                  style={{
                    display: "flex",
                    borderBottom:
                      iconValue?.index === idx &&
                      iconValue?.iconVal === "Pending"
                        ? `2px solid orange`
                        : "none",
                    paddingBottom: "3px",
                  }}
                  title="Pending"
                  onClick={(event) => handleIconClick("Pending", idx, event)}
                >
                  <Icon
                    icon="material-symbols:pending-outline"
                    style={{ color: "orange", fontSize: "20px" }}
                  />
                  <span
                    style={{
                      fontSize: "18px",
                      paddingLeft: "7px",
                    }}
                  >
                    {data?.jobSitePendingCount}
                  </span>
                </div>
              </span>
              <span style={{ color: "red" }}>
                <div
                  style={{
                    display: "flex",
                    borderBottom:
                      iconValue?.index === idx &&
                      iconValue?.iconVal === "Cancelled"
                        ? `2px solid red`
                        : "none",
                    paddingBottom: "3px",
                  }}
                  title="Cancelled"
                  onClick={(event) => handleIconClick("Cancelled", idx, event)}
                >
                  <Icon
                    icon="ix:cancelled"
                    style={{ color: "red", fontSize: "20px" }}
                    data-tip="Cancelled "
                  />

                  <span
                    style={{
                      fontSize: "19px",
                      paddingLeft: "7px",
                    }}
                  >
                    {data?.jobSiteCancelCount}
                  </span>
                </div>
              </span>

              <span
                style={{
                  color: "grey",
                  borderBottom:
                    iconValue?.index === idx && iconValue?.iconVal === "OnHold"
                      ? `2px solid grey`
                      : "none",
                  paddingBottom: "3px",
                }}
              >
                <div
                  style={{ display: "flex" }}
                  title="OnHold"
                  onClick={(event) => handleIconClick("OnHold", idx, event)}
                >
                  <Icon
                    icon="carbon:pause-outline"
                    style={{ color: "grey", fontSize: "20px" }}
                    data-tip="OnHold "
                  />

                  <span
                    style={{
                      fontSize: "19px",
                      paddingLeft: "7px",
                    }}
                  >
                    {data?.jobSiteOnHoldCount}
                  </span>
                </div>
              </span>
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Body className="custom-accordion-body">
          {reportData ? (
            <>
              {/* {reportData?.data?.map((itm, idx) => { */}
              {reportData.data
                ?.filter((item) => {
                  if (iconValue.iconVal && iconValue.index === idx) {
                    return item.status === iconValue.iconVal;
                  }
                  return true;
                })
                .map((itm, idx) => {
                  const isDefaultOpen = idx === 0;
                  return (
                    <InspectionTable
                      key={idx}
                      data={itm}
                      idx={idx}
                      defaultOpen={isDefaultOpen}
                    />
                  );
                })}
            </>
          ) : (
            <p>Loading...</p>
          )}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

Jobsite.propTypes = {
  data: PropTypes.shape({
    jobsiteId: PropTypes.string.isRequired,
    name: PropTypes.string,
    jobSiteInProgressCount: PropTypes.number,
    jobSitePendingCount: PropTypes.number,
    jobSiteCompletedCount: PropTypes.number,
    jobSiteCancelCount: PropTypes.number,
    jobSiteOnHoldCount: PropTypes.number,
  }).isRequired,
  idx: PropTypes.number.isRequired,
  start: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  setSearch: PropTypes.boolean,
  search: PropTypes.boolean,
  index: PropTypes.number.isRequired,
  // filterValue: PropTypes.string,
};

export default Jobsite;
