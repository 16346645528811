import React from "react";
import { Modal, Button } from "react-bootstrap";
import PropTypes from "prop-types";

const AppDialog = ({
  sxStyle,
  maxWidth,
  minHeight,
  hideClose,
  open,
  onClose,
  children,
  dividers,
  title,
  actionTitle,
  fullHeight,
}) => {
  return (
    <Modal
      show={open}
      onHide={onClose}
      size={maxWidth}
      aria-labelledby="app-dialog-title"
      style={{
        ...sxStyle,
        width: "100%",
      }}
      centered
    >
      <Modal.Header closeButton={!hideClose}>
        <Modal.Title
          id="app-dialog-title"
          style={{
            fontSize: "23px",
            fontWeight: "bold",
          }}
        >
          {title}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body
        style={{
          paddingTop: "1rem",
          height: fullHeight ? "70vh" : "100%",
          minHeight: minHeight ? minHeight : "300px",
          maxHeight: "600px",
          paddingRight: "1rem",
          paddingLeft: "1rem",
          overflowY: "auto",
          ...(dividers
            ? {
                borderTop: "1px solid #dee2e6",
                borderBottom: "1px solid #dee2e6",
              }
            : {}),
        }}
      >
        {children}
      </Modal.Body>

      {actionTitle && (
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={onClose} // Change if needed to another action
          >
            {actionTitle}
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

AppDialog.propTypes = {
  maxWidth: PropTypes.string,
  minHeight: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  dividers: PropTypes.bool,
  hideClose: PropTypes.bool,
  fullHeight: PropTypes.bool,
  actionTitle: PropTypes.string,
  sxStyle: PropTypes.object,
};

// AppDialog.defaultProps = {
//   dividers: false,
//   fullHeight: false,
//   maxWidth: "sm",
//   hideClose: false,
// };

export default AppDialog;
