import { React, useState, useEffect, useContext, useRef } from "react";
import { useFormik } from "formik";
// import { ThreeDots } from "react-loader-spinner";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchRolePopulateData } from "../../../../../redux/slice/RolesSlice/RolesSlice";
import { useDispatch } from "react-redux";
import "react-phone-input-2/lib/style.css";
import { SnackBarContext } from "../../../../../context/SnackBarContext.jsx";
import { MdDelete } from "react-icons/md";
// import { toast } from "react-toastify";
import { BreadcrumbsContext } from "../../../../../context/BcrumbsContextProvider";
import {
  Form,
  Button,
  Col,
  Row,
  InputGroup,
  FormControl,
  Spinner,
} from "react-bootstrap";

import { City, Country, State } from "country-state-city";
import { useSelector } from "react-redux";

import * as Yup from "yup";
import {
  CreateUserApi,
  UpdateUserApi,
} from "../../../../../Apis/Users/CreateUserApi";
// import PhoneInput from "react-phone-input-2";

import Select from "react-select";
import PhoneInput from "react-phone-input-2";

function UserForm() {
  // const [alldata, setalldata] = useState([]);
  const { setBreadcrumbsItems } = useContext(BreadcrumbsContext);
  const { setsnackBarData } = useContext(SnackBarContext);
  const { status } = useSelector((state) => state?.Roles);
  const dispatch = useDispatch();
  const { RolesPopulateData } = useSelector((state) => state?.Roles);
  console.log("roles data", RolesPopulateData);
  console.log(status);
  useEffect(() => {
    dispatch(fetchRolePopulateData());
  }, [dispatch]);
  const [loading, setLoading] = useState(false);
  const [states, setStates] = useState([]);
  const [showPassword, setshowPassword] = useState(false);
  const [confirmpass, setconfirmpass] = useState(false);
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const userData = useLocation();
  const navigate = useNavigate();
  const [signatureImage, setSignatureImage] = useState(null);
  const [signatureFile, setSignatureFile] = useState(null);
  const fileInputRef = useRef(null);
  const [imageValidation, setImageValidation] = useState(false);
  // const handleImageUpload = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     setSignatureFile(file);
  //     setSignatureImage(URL.createObjectURL(file));
  //   }
  // };
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setImageValidation(false);
    if (file) {
      const validExtensions = ["image/jpeg", "image/png", "image/jpg"];
      if (validExtensions.includes(file.type)) {
        setSignatureFile(file);
        setSignatureImage(URL.createObjectURL(file));
      } else {
        setImageValidation(true);
        event.target.value = "";
      }
    }
  };
  console.log("this is signature file", signatureFile);
  const handleBreadcrumbs = () => {
    setBreadcrumbsItems([
      {
        id: 1,
        url: "/settings",
        name: "Settings",
      },
      {
        id: 2,
        url: "/settings/users",
        name: "Users",
      },
      {
        id: 3,
        url: "/settings/users/form",
        name: "Form",
      },
    ]);
  };

  useEffect(() => {
    handleBreadcrumbs();
    return () => {
      setBreadcrumbsItems([]);
    };
  }, [setBreadcrumbsItems]);
  useEffect(() => {
    if (userData?.state) {
      formik.setFieldValue("lastName", userData.state?.lastName);
      formik.setFieldValue("firstName", userData.state?.firstName);
      formik.setFieldValue("username", userData.state?.username);
      formik.setFieldValue("email", userData.state?.email);
      formik.setFieldValue("password", "********");
      formik.setFieldValue("countryCode", userData.state?.countryCode);
      formik.setFieldValue("phoneNumber", userData.state?.phoneNumber);
      formik.setFieldValue("state", userData.state?.state);
      formik.setFieldValue("city", userData.state?.city);
      formik.setFieldValue("address", userData.state?.address);
      formik.setFieldValue("country", userData.state?.country);
      formik.setFieldValue("dateOfBirth", userData.state?.dateOfBirth);
      formik.setFieldValue(
        "role",
        userData.state?.role.map((item) => item?.id)
      );
      formik.setFieldValue("confirmPassword", "********");
    }
  }, [userData]);
  const validationSchema = Yup.object({
    firstName: Yup.string().required("Field required!"),
    lastName: Yup.string().required("Field required!"),
    email: Yup.string()
      .email("Enter a valid Email")
      .required("Field required!"),
    role: Yup.array()
      .of(Yup.string().required("Role is required!"))
      .min(1, "At least one role is required!")
      .required("Field required!"),
    address: Yup.string().required("Field required!"),
    password: Yup.string().required("Field required!"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Both Passwords must be the same.")
      .required("Field required!"),
  });

  const formik = useFormik({
    initialValues: {
      lastName: "",
      firstName: "",
      username: "",
      dateOfBirth: null,
      address: "",
      role: [],
      email: "",
      country: "",
      state: "",
      city: "",
      password: "",
      confirmPassword: "",
      phoneNumber: "",
      countryCode: "",
    },
    validationSchema: validationSchema,

    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        if (Array.isArray(values[key])) {
          values[key].forEach((item) => formData.append(`${key}[]`, item));
        } else {
          formData.append(key, values[key]);
        }
      });

      console.log("this is formData", formData);
      if (userData?.state) {
        const formData2 = new FormData();
        formData2.append("userId", userData?.state?.id);
        formData2.append("firstName", values?.firstName);
        formData2.append("lastName", values?.lastName);
        formData2.append("username", values?.username);
        formData2.append("phoneNumber", values?.phoneNumber);
        formData2.append("countryCode", values?.countryCode);
        formData2.append("email", values?.email);
        formData2.append("address", values?.address);
        formData2.append("city", values?.city);
        formData2.append("state", values?.state);
        formData2.append("country", values?.country);
        formData2.append("dateOfBirth", values?.dateOfBirth);
        formData2.append("signature", signatureFile);

        const response = await UpdateUserApi(formData2, userData?.state?.id);

        console.log("response.data", response);
        setsnackBarData(response?.snackBarData);
        if (response?.success) {
          // dispatch(fetchAllReceivings());
          navigate("/settings/users");
          resetForm();
          setLoading(false);
        } else {
          setLoading(false);
        }
      } else {
        let response;
        formData.append("signature", signatureFile);
        response = await CreateUserApi(formData);

        setsnackBarData(response?.snackBarData);
        console.log("response", response);
        if (response?.success) {
          // dispatch(fetchAllReceivings());
          resetForm();
          setLoading(false);
          navigate(-1);
        } else {
          setLoading(false);
        }
      }
    },
  });

  // const handleLogo = (e) => {
  //   setImage({
  //     url: URL.createObjectURL(e.target.files[0]),
  //     file: e.target.files[0],
  //   });
  // };

  useEffect(() => {
    let value = Country.getAllCountries();
    value = value?.map((data) => {
      let obj = {
        label: data?.name,
        value: data?.isoCode,
      };
      return obj;
    });
    setCountries(value);
  }, []);
  useEffect(() => {
    if (formik.values.country) {
      let selectedCountry = countries.find(
        (country) => country.label === formik.values.country
      );
      let value = State.getStatesOfCountry(selectedCountry?.value);
      console.log("selectedCountry", value);

      value = value?.map((data) => {
        let obj = {
          label: data?.name,
          value: data?.isoCode,
        };
        return obj;
      });
      setStates(value);
    }
  }, [formik.values.country, countries]);
  useEffect(() => {
    if (formik.values.state && states) {
      let selectedCountry = countries.find(
        (country) => country.label === formik.values.country
      );
      let selectedState = states.find(
        (state) => state.label === formik.values.state
      );
      let value = City.getCitiesOfState(
        selectedCountry?.value,
        selectedState?.value
      );
      console.log("value", selectedCountry?.value, selectedState?.value);
      value = value?.map((data) => {
        let obj = {
          label: data?.name,
          value: data?.name,
        };
        return obj;
      });
      setCities(value);
    } else {
      setCities([]);
    }
  }, [formik.values.state, formik.values.country, states]);
  // useEffect(() => {
  //   if (UserRolesData) {
  //     setalldata(UserRolesData);
  //   } else {
  //     setalldata([]);
  //   }
  // }, [UserRolesData]);
  const roleOptions = RolesPopulateData?.map((role) => ({
    value: role.id,
    label: role.roleName,
  }));
  console.log("formik values", formik.values);
  return (
    <div className="h-[450px] overflow-y-auto overflow-x-hidden  scrollbar-custom">
      <Form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
        <div className="pt-4">
          <h4>Personal Details</h4>
          <hr />
          <Row>
            <Col xs={12} md={6}>
              <Form.Group controlId="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    formik.errors.firstName && formik.touched.firstName
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.firstName}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="lastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.errors.lastName && formik.touched.lastName}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.lastName}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group
                controlId="username"
                style={{ marginTop: "13px", marginBottom: "13px" }}
              >
                <Form.Label>User Name</Form.Label>
                <Form.Control
                  type="text"
                  name="username"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.errors.username && formik.touched.username}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.username}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group
                controlId="dateOfBirth"
                style={{ marginTop: "13px", marginBottom: "13px" }}
              >
                <Form.Label>Date Of Birth</Form.Label>
                <Form.Control
                  type="date"
                  name="dateOfBirth"
                  value={formik.values.dateOfBirth || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    formik.errors.dateOfBirth && formik.touched.dateOfBirth
                  }
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="phoneNumber">
                <Form.Label>Phone</Form.Label>
                <InputGroup>
                  <PhoneInput
                    country={"us"}
                    disableAreaCodes={true}
                    onlyCountries={["us"]}
                    value={formik.values?.phoneNumber}
                    onChange={(value) => {
                      formik.setFieldValue("phoneNumber", value);
                    }}
                    onBlur={formik.handleBlur}
                    inputProps={{
                      name: "phoneNumber",
                      id: "phoneNumber",
                    }}
                    containerClass="react-tel-input w-100"
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>

          <h4 className="mt-4">Address Details</h4>
          <hr />
          <Row>
            <Col xs={12} md={4}>
              <Form.Group controlId="country">
                <Form.Label>Country</Form.Label>
                <Select
                  required
                  id="country"
                  name="country"
                  options={countries}
                  value={countries.find(
                    (option) => option.label === formik.values?.country
                  )}
                  onBlur={formik.handleBlur}
                  onChange={(selectedOption) => {
                    formik.setFieldValue("country", selectedOption?.label);
                    formik.setFieldValue("city", "");
                    formik.setFieldValue("state", "");
                  }}
                  className={`react-select-container ${
                    formik.touched.country && formik.errors.country
                      ? "border border-danger rounded"
                      : ""
                  }`}
                  classNamePrefix="react-select"
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group controlId="state">
                <Form.Label>State</Form.Label>
                <Select
                  id="state"
                  name="state"
                  options={states}
                  value={states.find(
                    (option) => option.label === formik.values?.state
                  )}
                  onBlur={formik.handleBlur}
                  onChange={(selectedOption) => {
                    formik.setFieldValue("state", selectedOption?.label);
                    formik.setFieldValue("city", "");
                  }}
                  className={`react-select-container ${
                    formik.touched.state && formik.errors.state
                      ? "border border-danger rounded"
                      : ""
                  }`}
                  classNamePrefix="react-select"
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group controlId="city">
                <Form.Label>City</Form.Label>
                <Select
                  id="city"
                  name="city"
                  options={cities}
                  value={cities.find(
                    (option) => option.label === formik.values?.city
                  )}
                  onBlur={formik.handleBlur}
                  onChange={(selectedOption) => {
                    formik.setFieldValue("city", selectedOption?.label);
                  }}
                  className={`react-select-container ${
                    formik.touched.city && formik.errors.city
                      ? "border border-danger rounded"
                      : ""
                  }`}
                  classNamePrefix="react-select"
                />
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group
                controlId="address"
                style={{ marginTop: "13px", marginBottom: "13px" }}
              >
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="text"
                  name="address"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.address && formik.errors.address}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.address}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <h4 className="mt-4">Signature</h4>
          <hr />
          <div className="alert alert-info mb-3" role="alert">
            <i className="bi bi-info-circle me-2"></i>
            Please ensure the image background is transparent (without any
            color). Use this link to remove the background:
            <a
              href="https://www.remove.bg/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "none",
                color: "#007bff",
                transition: "color 0.3s",
              }}
              onMouseEnter={(e) => (e.target.style.color = "#0056b3")}
              onMouseLeave={(e) => (e.target.style.color = "#007bff")}
            >
              {" "}
              Remove Background
            </a>
            <br></br>
            Only JPG, JPEG, and PNG formats are allowed!
          </div>

          {userData?.state && (
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                gap: "60px",
              }}
            >
              <p style={{ fontWeight: "700" }}>Signature:</p>
              <img
                src={userData.state?.signature}
                alt="Signature"
                style={{
                  width: "200px",
                  height: "100px",
                  objectFit: "contain",
                  border: "1px solid lightgrey",
                  borderRadius: "10px",
                }}
              />
            </div>
          )}
          <Row style={{ paddingBottom: "30px" }}>
            <Col xs={12} md={5}>
              <div style={{ marginTop: "8px" }}>
                <Form.Group
                  controlId="signature"
                  style={{ marginBottom: "13px" }}
                ></Form.Group>
                <label htmlFor="signature-update" style={{ fontWeight: "700" }}>
                  {userData?.state ? "Update Signature" : "Add Signature"}
                </label>
                <Form.Control
                  id="signature-update"
                  name="signature"
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  ref={fileInputRef}
                  onChange={handleImageUpload}
                  style={{
                    display: "block",
                    marginTop: "10px",
                    width: "90%",
                    borderRadius: "10px",
                  }}
                />
              </div>
              {imageValidation && (
                <p style={{ color: "red", marginTop: "8px" }}>
                  Only JPG, JPEG, and PNG formats are allowed!
                </p>
              )}
            </Col>
            {signatureImage && (
              <Col xs={12} md={5}>
                <div style={{ marginTop: "20px" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "30px",
                    }}
                  >
                    <p style={{ fontWeight: "700", margin: 0 }}>
                      Signature Preview:
                    </p>
                    <MdDelete
                      style={{
                        marginLeft: "8px",
                        cursor: "pointer",
                        color: "red",
                      }}
                      size={20}
                      onClick={() => {
                        setSignatureImage(null);
                        setSignatureFile(null);
                        fileInputRef.current.value = "";
                      }}
                    />
                  </div>
                  <img
                    src={signatureImage}
                    alt="Signature"
                    style={{
                      width: "200px",
                      height: "100px",
                      objectFit: "contain",
                      marginTop: "8px",
                    }}
                  />
                </div>
              </Col>
            )}
          </Row>

          <h4 className="mt-4">Credentials and Role</h4>
          <hr />
          <Row>
            <Col xs={12} md={6}>
              <Form.Group controlId="email" style={{ marginBottom: "13px" }}>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={userData?.state}
                  isInvalid={formik.touched.email && formik.errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.email}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            {/* <Col xs={12} md={6}>
              <Form.Group controlId="role" style={{ marginBottom: "13px" }}>
                <Form.Label>Role</Form.Label>
                <Select
                  // isMulti
                  name="role"
                  options={roleOptions}
                  value={roleOptions?.filter((option) =>
                    formik.values.role.includes(option.value)
                  )}
                  onChange={(selectedOptions) => {
                    const selectedValues = selectedOptions?.map(
                      (option) => option.value
                    );
                    formik.setFieldValue("role", selectedValues);
                  }}
                  isDisabled={userData?.state}
                  className="basic-single"
                  classNamePrefix="select"
                  isInvalid={formik.errors.role && formik.errors.role}
                />
                {formik.errors.role && formik.touched.role && (
                  <div className="invalid-feedback d-block">
                    {formik.errors.role}
                  </div>
                )}
              </Form.Group>
            </Col> */}
            <Col xs={12} md={6}>
              <Form.Group controlId="role" style={{ marginBottom: "13px" }}>
                <Form.Label>Role</Form.Label>
                <Select
                  name="role"
                  options={roleOptions}
                  value={roleOptions?.find(
                    (option) => option.value === formik.values.role[0]
                  )}
                  onChange={(selectedOption) => {
                    formik.setFieldValue(
                      "role",
                      selectedOption ? [selectedOption.value] : []
                    );
                  }}
                  isDisabled={userData?.state}
                  className="basic-single"
                  classNamePrefix="select"
                  isInvalid={formik.errors.role && formik.touched.role}
                />
                {formik.errors.role && formik.touched.role && (
                  <div className="invalid-feedback d-block">
                    {formik.errors.role}
                  </div>
                )}
              </Form.Group>
            </Col>

            <Col xs={12} md={6}>
              <Form.Group controlId="password">
                <Form.Label>Password</Form.Label>
                <InputGroup>
                  <FormControl
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={userData?.state}
                    isInvalid={
                      formik.touched.password && formik.errors.password
                    }
                  />
                  <Button
                    variant="outline-secondary"
                    disabled={userData?.state}
                    onClick={() => setshowPassword(!showPassword)}
                  >
                    <i
                      className={`bi ${
                        showPassword ? "bi-eye-slash" : "bi-eye"
                      }`}
                    ></i>
                  </Button>
                </InputGroup>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.password}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="confirmPassword">
                <Form.Label>Confirm Password</Form.Label>
                <InputGroup>
                  <FormControl
                    type={confirmpass ? "text" : "password"}
                    name="confirmPassword"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={userData?.state}
                    isInvalid={
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                    }
                  />
                  <Button
                    variant="outline-secondary"
                    disabled={userData?.state}
                    onClick={() => setconfirmpass(!confirmpass)}
                  >
                    <i
                      className={`bi ${
                        confirmpass ? "bi-eye-slash" : "bi-eye"
                      }`}
                    ></i>
                  </Button>
                </InputGroup>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.confirmPassword}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <div
            className="d-flex justify-content-end gap-2 mt-4"
            style={{ marginBottom: "13px" }}
          >
            <button
              type="button"
              className=" btn-secondary"
              onClick={() => navigate(-1)}
            >
              Back
            </button>

            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span className="ms-2">Submitting...</span>
                </>
              ) : (
                "Submit"
              )}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default UserForm;
