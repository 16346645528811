import axiosInstance, { axiosInstanceForm } from "../../utils/AxiosInstance";

export const CreateDocumentApi = async (data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstanceForm.post("/Document/Create", data);

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
export const DeleteDocumentApi = async (id) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.delete(`Document/Delete/${id}`);

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
