import React, { createContext, useState, useEffect } from "react";
import PropTypes from "prop-types"; // Import PropTypes
import { Formio } from "@formio/js";

const useBaseConfiguration = ({ baseUrl, projectUrl }) => {
  if (baseUrl) {
    Formio.setBaseUrl(baseUrl);
  }
  if (projectUrl) {
    Formio.setProjectUrl(projectUrl);
  }

  return {
    Formio,
    baseUrl: Formio.baseUrl,
    projectUrl: Formio.projectUrl,
  };
};

const useAuthentication = () => {
  const [token, setToken] = useState(Formio.getToken() || null);
  const [isAuthenticated, setIsAuthenticated] = useState(!!token);

  useEffect(() => {
    const handleUserEvent = async (user) => {
      if (user) {
        setToken(Formio.getToken());
        setIsAuthenticated(true);
      } else if (isAuthenticated) {
        await Formio.logout();
        setToken(null);
        setIsAuthenticated(false);
      }
    };

    const handleStaleToken = async () => {
      if (isAuthenticated) {
        const user = await Formio.currentUser();
        if (!user) {
          setToken(null);
          setIsAuthenticated(false);
        }
      }
    };

    Formio.events.on("formio.user", handleUserEvent);
    handleStaleToken();

    return () => {
      Formio.events.off("formio.user", handleUserEvent);
    };
  }, [isAuthenticated]);

  const logout = async () => {
    await Formio.logout();
    setToken(null);
    setIsAuthenticated(false);
  };

  return { token, setToken, isAuthenticated, logout };
};

export const FormioContext = createContext(null);

export function FormioProvider({ children, baseUrl, projectUrl }) {
  const formio = {
    ...useBaseConfiguration({ baseUrl, projectUrl }),
    ...useAuthentication(),
  };

  return (
    <FormioContext.Provider value={formio}>{children}</FormioContext.Provider>
  );
}

// Adding prop types validation
FormioProvider.propTypes = {
  children: PropTypes.node.isRequired,
  baseUrl: PropTypes.string,
  projectUrl: PropTypes.string,
};

FormioProvider.defaultProps = {
  baseUrl: undefined,
  projectUrl: undefined,
};
