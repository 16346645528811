import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Chart from "react-apexcharts";
import PropTypes from "prop-types";

function ColumnsChart({ data, comp, jobSite, equipType }) {
  const navigate = useNavigate();
  const compRef = useRef(comp);
  const jobSiteRef = useRef(jobSite);
  const equipTypeRef = useRef(equipType);
  useEffect(() => {
    compRef.current = comp;
    jobSiteRef.current = jobSite;
    equipTypeRef.current = equipType;
  }, [comp, jobSite, equipType]);

  const [state, setState] = useState({
    series: [],
    options: {
      chart: {
        type: "bar",
        height: 310,
        toolbar: { show: false },
      },
      plotOptions: {
        bar: { columnWidth: "20%", distributed: true },
      },
      colors: [
        "#168c1b",
        "rgb(79, 195, 247)",
        "#faa711",
        "#FF6A6A",
        "lightgrey",
      ],
      xaxis: {
        categories: [
          "Completed",
          "InProgress",
          "Pending",
          "Cancelled",
          "OnHold",
        ],
        tooltip: { enabled: true },
      },
      yaxis: { title: { text: "Count" } },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: { width: "100%" },
            legend: { position: "bottom" },
          },
        },
      ],
    },
  });

  useEffect(() => {
    // if (data && comp && jobSite) {
    setState((prevState) => ({
      ...prevState,
      series: [
        {
          name: "Packages",
          data: [
            data.completedCount || 0,
            data.inProgressCount || 0,
            data.pendingCount || 0,
            data.cancelledCount || 0,
            data.onHoldCount || 0,
          ],
        },
      ],
      options: {
        ...prevState.options,
        chart: {
          ...prevState.options.chart,
          events: {
            dataPointSelection: (event, chartContext, { dataPointIndex }) => {
              const category = state.options.xaxis.categories[dataPointIndex];
              switch (category) {
                case "Completed":
                  navigate("/package/completed", {
                    state: {
                      comp: compRef.current,
                      jobSite: jobSiteRef.current,
                      equipType: equipTypeRef.current,
                    },
                  });
                  break;
                case "InProgress":
                  navigate("/package/in-progress", {
                    state: {
                      comp: compRef.current,
                      jobSite: jobSiteRef.current,
                      equipType: equipTypeRef.current,
                    },
                  });
                  break;
                case "Pending":
                  navigate("/package/pending", {
                    state: {
                      comp: compRef.current,
                      jobSite: jobSiteRef.current,
                      equipType: equipTypeRef.current,
                    },
                  });
                  break;
                case "Cancelled":
                  navigate("/package/cancelled", {
                    state: {
                      comp: compRef.current,
                      jobSite: jobSiteRef.current,
                      equipType: equipTypeRef.current,
                    },
                  });
                  break;
                case "OnHold":
                  navigate("/package/on-hold", {
                    state: {
                      comp: compRef.current,
                      jobSite: jobSiteRef.current,
                      equipType: equipTypeRef.current,
                    },
                  });
                  break;
                default:
                  break;
              }
            },
          },
        },
      },
    }));
    // }
  }, [data, comp, jobSite]);

  return (
    <div>
      <div
        style={{ textAlign: "center", fontWeight: "bold", fontSize: "0.8em" }}
      >
        Packages Count Per site
      </div>
      <Chart
        options={state.options}
        series={state.series}
        type="bar"
        height={state.options.chart.height}
      />
    </div>
  );
}

ColumnsChart.propTypes = {
  data: PropTypes.shape({
    completedCount: PropTypes.number,
    inProgressCount: PropTypes.number,
    pendingCount: PropTypes.number,
    onHoldCount: PropTypes.number,
    cancelledCount: PropTypes.number,
  }),
  comp: PropTypes.string,
  jobSite: PropTypes.string,
  equipType: PropTypes.string,
};

export default ColumnsChart;
