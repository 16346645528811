import React, { useContext, useEffect, useRef, useState } from "react";
import { FormioProvider } from "../../context/FormioContext";
// import { Form } from "../../OurForms/Form";
import { useLocation } from "react-router-dom";
import { BreadcrumbsContext } from "../../context/BcrumbsContextProvider";
import { useNavigate } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { NewForm } from "../../OurForms/NewForm";
import { useSelector } from "react-redux";
import { fetchEquipmentApi } from "../../Apis/Inspection/InspectionApis";
function MyInspectionForm() {
  const location = useLocation();
  const formInstance = useRef(null);
  const querydata = location.state?.data;
  const submission = location.state?.submission;
  console.log("this is submission", querydata);
  const { UserRolesData } = useSelector((state) => state?.Roles);
  const navigate = useNavigate();
  const [disableCompponents, setdisableCompponents] = useState(null);
  const { setBreadcrumbsItems } = useContext(BreadcrumbsContext);
  const handleBreadcrumbs = () => {
    setBreadcrumbsItems([
      {
        id: 1,
        url: "/my-package/pending",
        name: "My Packages",
      },
      {
        id: 2,
        url: "/my-package/form",
        name: "Form",
      },
    ]);
  };
  const toCamelCase = (str) => {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
        index === 0 ? match.toLowerCase() : match.toUpperCase()
      )
      .replace(/\s+/g, "");
  };
  const handleEquipmentChange = async (
    { changed, data },
    modified,
    formInstance
  ) => {
    if (changed?.component?.key === "equipmentNumber" && modified) {
      const response = await fetchEquipmentApi(
        data.equipmentNumber,
        querydata.jobSiteId
      );
      console.log(response, querydata, data.equipmentNumber);
      if (response?.data) {
        formInstance.current.setSubmission(
          {
            ...formInstance.current.submission,
            data: {
              ...formInstance.current.submission.data,
              ...response?.data,
              // description: `DESALTER FLASH OVHD CONDENSER`,
              // pId: "D-13-173C",
              // insulated: "NO",
              // nationalBd: "9039",
              // serial: "9741",
              // designPressure: "75",
              // // unit: "004",
              // // Change this dynamically
            },
          },
          { noValidate: true, modified: false }
        );
      }
    }
  };
  useEffect(() => {
    if (UserRolesData) {
      // Define the roles that you want to check
      console.log(UserRolesData, "Yes");

      const roleKeys = [
        "qcInspector",
        "processEngineer",
        "operationalManager",
        "executiveJobSupervisor",
        "qaInspector",
      ];
      const roleArr = UserRolesData?.role?.map((role) => {
        let camelRole = toCamelCase(role.roleName);

        // Handle special case for qCInspector
        if (camelRole === "qCInspector") {
          camelRole = "qcInspector";
        } else if (camelRole === "qAInspector") {
          camelRole = "qaInspector";
        } else if (camelRole === "executive/JobSupervisor") {
          camelRole = "executiveJobSupervisor";
        }

        return camelRole;
      });
      console.log(roleArr);

      let obj = {};

      // Create the object with key-value pairs only for matching roles
      roleKeys.forEach((key) => {
        // Check if the role exists in the user data
        console.log(key);

        if (!roleArr?.includes(key)) {
          obj[key] = true;
          obj[`${key}1`] = true;
          obj[`${key}2`] = true;
          obj[`${key}3`] = true;
          obj[`${key}4`] = true;
          obj[`${key}5`] = true;
          obj[`${key}6`] = true;
          obj[`${key}7`] = true;
          obj[`${key}8`] = true;
          obj[`${key}9`] = true;
        }
      });

      console.log(obj);

      // Check if obj is empty, if it is, set it to null
      if (Object.keys(obj).length === 0) {
        setdisableCompponents(null); // Set null if obj is empty
      } else {
        setdisableCompponents({
          ...obj,
        }); // Set the object if it contains any key-value pairs
      }
    }
  }, [UserRolesData]);

  useEffect(() => {
    handleBreadcrumbs();
    return () => {
      setBreadcrumbsItems([]);
    };
  }, [setBreadcrumbsItems]);

  return (
    <div style={{ height: "100vh", overflowY: "scroll" }}>
      <div
        style={{
          display: "flex",
          // justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <button
          onClick={() => navigate(-1)}
          style={{
            color: "black",
            cursor: "pointer",
            marginRight: "16px",
            marginBottom: "7px",
          }}
        >
          <IoMdArrowBack fontSize="24px" />
        </button>
        <h1 style={{ fontSize: "24px", fontWeight: "bold" }}>
          {querydata?.form?.title}
        </h1>
        {/* Back button */}
      </div>
      <p>Form ID: {querydata?.form?._id}</p>
      <FormioProvider baseUrl={process.env.REACT_APP_API_URL_LOCAL}>
        {submission &&
        querydata?.mySubmissionStatus == "Submitted" &&
        disableCompponents ? (
          <NewForm
            formInstance={formInstance}
            title={querydata?.form?.name}
            src={`Forms/${querydata?.form?._id}`}
            submission={submission}
            inspectionStageId={querydata?.currentStage}
            submissionId={querydata?.submissionId}
            options={{
              readOnly: true,
              hideSumbit: true,

              hide: {
                submit: true,
              },
              disabled: {
                ...disableCompponents,
              },
            }}
          />
        ) : submission ? (
          disableCompponents && (
            <NewForm
              src={`Forms/${querydata?.form?._id}`}
              submission={submission}
              formInstance={formInstance}
              onChange={({ changed, data }, flags, modified) => {
                console.log(data, modified, changed, flags);
                handleEquipmentChange(
                  { changed, data },
                  modified,
                  formInstance
                );
              }}
              options={{
                disabled: {
                  ...disableCompponents,
                },
              }}
              inspectionStageId={querydata?.currentStage}
              submissionId={querydata?.submissionId}
            />
          )
        ) : (
          disableCompponents && (
            <NewForm
              src={`Forms/${querydata?.form?._id}`}
              formInstance={formInstance}
              inspectionStageId={querydata?.currentStage}
              options={{
                disabled: {
                  ...disableCompponents,
                },
              }}
              onChange={({ changed, data }, flags, modified) => {
                console.log(data, modified, changed, flags);
                handleEquipmentChange(
                  { changed, data },
                  modified,
                  formInstance
                );
              }}
            />
          )
        )}
      </FormioProvider>
    </div>
  );
}

export default MyInspectionForm;
