import { useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import PropTypes from "prop-types";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import UserModal from "./UserModal";
// import { Icon } from "@iconify/react";
import ProgressBar from "react-bootstrap/ProgressBar";
function CustomToggle({ children, eventKey, isExpanded, setIsExpanded }) {
  const decoratedOnClick = useAccordionButton(eventKey, () => {
    setIsExpanded(!isExpanded);
  });

  return (
    <button
      type="button"
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        border: "none",
        padding: "10px",
        textAlign: "left",
        cursor: "pointer",
      }}
      onClick={decoratedOnClick}
    >
      {isExpanded ? (
        <FaChevronDown style={{ marginRight: "10px", color: "white" }} />
      ) : (
        <FaChevronRight style={{ marginRight: "10px", color: "white" }} />
      )}
      {children}
    </button>
  );
}

CustomToggle.propTypes = {
  children: PropTypes.node.isRequired,
  eventKey: PropTypes.string.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  setIsExpanded: PropTypes.func,
};

function InspectionTable({ data, defaultOpen }) {
  const [showModal, setShowModal] = useState(false);
  const [userData, setUserData] = useState("");
  const [isExpanded, setIsExpanded] = useState(defaultOpen);

  const defaultOpenInspectionNumber = defaultOpen
    ? data._id.inspectionNumber
    : null;
  const [activeKey, setActiveKey] = useState(defaultOpenInspectionNumber);

  const handleModal = (data) => {
    setUserData(data);
    setShowModal(true);
  };
  useEffect(() => {
    if (defaultOpen) {
      setActiveKey(data._id.inspectionNumber);
    } else {
      setActiveKey(null);
    }
  }, [defaultOpen, data]);

  return (
    <Accordion activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
      <Card>
        <Card.Header
          style={{
            backgroundColor:
              data?.status === "Completed"
                ? "green"
                : data?.status === "Pending"
                ? "orange"
                : data?.status === "InProgress"
                ? "rgb(79, 195, 247)"
                : data?.status === "OnHold"
                ? "grey"
                : data?.status === "Cancelled"
                ? "red"
                : "initial",
            color: "white",
          }}
        >
          <CustomToggle
            eventKey={data?._id?.inspectionNumber}
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
          >
            {/* {data._id.inspectionNumber} - {data._id.inspectionTitle} */}
            <div
              style={{
                display: "flex",
                gap: "10px",
                flexBasis: "100%",
                flexWrap: "wrap",
                alignItems: "center",
                color: "white",
              }}
            >
              <span
                style={{
                  // fontSize: "1.5rem",
                  // fontWeight: "bold",
                  flexGrow: 1,
                  minWidth: "180px",
                }}
              >
                {data?._id?.inspectionNumber} - {data?.equipmentNumber} -{" "}
                {data?.equipType}
              </span>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: "40px",
                  gap: "30px",
                  fontSize: "0.875rem",
                  fontWeight: "bold",
                  paddingTop: "10px",
                  flexGrow: 3,
                  flexWrap: "wrap",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div
                    style={{ flex: "1", maxWidth: "100%", marginRight: "10px" }}
                  >
                    <ProgressBar
                      now={data?.avgInspectionCompletionRate}
                      min={0}
                      label={`${Math.floor(
                        data?.avgInspectionCompletionRate
                      )}%`}
                      variant="success"
                      style={{ height: "20px", width: "150px" }}
                    />
                    <span
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "30px",
                        transform: "translate(-50%, -50%)",
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      {data?.avgInspectionCompletionRate <= 0 ? "0%" : " "}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey={data?._id?.inspectionNumber}>
          <Card.Body>
            <div style={{ display: "flex", gap: "50px" }}>
              <h5>Stages</h5>
              <div
                style={{
                  position: "absolute",
                  right: "0",
                }}
              >
                <div
                  style={{
                    display: "inline-block",
                    padding: "6px 6px",
                    backgroundColor: "red",
                    color: "white",
                    borderRadius: "7px",
                    fontWeight: "bold",
                    fontSize: "0.8em",
                    textAlign: "center",
                  }}
                ></div>{" "}
                Rejected{" "}
                <div
                  style={{
                    display: "inline-block",
                    padding: "6px 6px",
                    backgroundColor: "green",
                    color: "white",
                    borderRadius: "7px",
                    fontWeight: "bold",
                    fontSize: "0.8em",
                    textAlign: "center",
                    marginLeft: "15px",
                  }}
                ></div>{" "}
                Completed
                <div
                  style={{
                    display: "inline-block",
                    padding: "6px 6px",
                    backgroundColor: "orange",
                    color: "white",
                    borderRadius: "7px",
                    fontWeight: "bold",
                    fontSize: "0.8em",
                    textAlign: "center",
                    marginLeft: "15px",
                  }}
                ></div>{" "}
                Pending
                {/* <div
                  style={{
                    display: "inline-block",
                    padding: "6px 6px",
                    backgroundColor: "orange",
                    color: "white",
                    borderRadius: "7px",
                    fontWeight: "bold",
                    fontSize: "0.8em",
                    textAlign: "center",
                    marginLeft: "15px",
                  }}
                ></div>{" "}
                Cancelled
                <div
                  style={{
                    display: "inline-block",
                    padding: "6px 6px",
                    backgroundColor: "grey",
                    color: "white",
                    borderRadius: "7px",
                    fontWeight: "bold",
                    fontSize: "0.8em",
                    textAlign: "center",
                    marginLeft: "15px",
                  }}
                ></div>{" "}
                OnHold */}
              </div>
            </div>
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <tr>
                  <th
                    style={{
                      backgroundColor: "#1c4e80 ",
                      color: "white",
                      border: "1px solid #ddd",
                      padding: "8px",
                      textAlign: "center",
                    }}
                  >
                    Form Name
                  </th>
                  <th
                    style={{
                      backgroundColor: "#1c4e80 ",
                      color: "white",
                      border: "1px solid #ddd",
                      padding: "8px",
                      textAlign: "center",
                    }}
                  >
                    Status
                  </th>
                  <th
                    style={{
                      backgroundColor: "#1c4e80 ",
                      color: "white",
                      border: "1px solid #ddd",
                      padding: "8px",
                      textAlign: "center",
                    }}
                  >
                    Submitters
                  </th>
                  <th
                    style={{
                      backgroundColor: "#1c4e80 ",
                      color: "white",
                      border: "1px solid #ddd",
                      padding: "8px",
                      textAlign: "center",
                    }}
                  >
                    Reviewers
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.stages?.map((stage, idx) => (
                  <tr key={idx}>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        textAlign: "center",
                      }}
                    >
                      {stage?.formName}
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        fontWeight: "bold",
                        padding: "8px",
                        textAlign: "center",
                        color:
                          stage?.stageStatus == "Approved" ? "green" : "orange",
                      }}
                    >
                      {stage?.stageStatus}
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        textAlign: "center",
                      }}
                    >
                      {stage?.submitters?.map((submitter) => (
                        <div
                          title="User Details"
                          onClick={() => handleModal(submitter)}
                          style={{
                            fontWeight: "bolder",
                            fontSize: "14px",
                            textAlign: "center",
                            padding: "3px",
                          }}
                          key={submitter.submitterId}
                        >
                          <div
                            style={{
                              display: "inline-block",
                              padding: "4px 4px",
                              backgroundColor:
                                submitter?.status == "Submitted"
                                  ? "green"
                                  : submitter?.status == "Rejected"
                                  ? "red"
                                  : "orange",
                              color: "white",
                              borderRadius: "7px",
                              fontWeight: "bold",
                              fontSize: "0.8em",
                              textAlign: "center",
                              marginLeft: "15px",
                            }}
                          ></div>{" "}
                          {submitter.role}
                        </div>
                      ))}
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                      {stage?.reviewers?.map((reviewer) => (
                        <div
                          title="User Details"
                          onClick={() => handleModal(reviewer)}
                          style={{
                            fontWeight: "bolder",
                            fontSize: "14px",
                            textAlign: "center",
                            padding: "3px",
                          }}
                          key={reviewer.reviewerId}
                        >
                          <div
                            style={{
                              display: "inline-block",
                              padding: "4px 4px",
                              backgroundColor:
                                reviewer?.status == "Rejected"
                                  ? "red"
                                  : reviewer?.status == "Approved"
                                  ? "green"
                                  : "orange",

                              color: "white",
                              borderRadius: "7px",
                              fontWeight: "bold",
                              fontSize: "0.8em",
                              textAlign: "center",
                              marginLeft: "15px",
                            }}
                          ></div>{" "}
                          {reviewer?.role}
                        </div>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {showModal && (
              <UserModal
                open={showModal}
                onClose={() => setShowModal(false)}
                state={userData}
              />
            )}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

InspectionTable.propTypes = {
  defaultOpen: PropTypes.boolean,
  data: PropTypes.shape({
    avgInspectionCompletionRate: PropTypes.number.isRequired,
    totalStages: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    equipmentNumber: PropTypes.string.isRequired,
    equipType: PropTypes.string.isRequired,
    currentStage: PropTypes.shape({
      formName: PropTypes.string.isRequired,
      stageLevel: PropTypes.number.isRequired,
    }),
    _id: PropTypes.shape({
      inspectionTitle: PropTypes.string.isRequired,
      inspectionNumber: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    }).isRequired,
    stages: PropTypes.arrayOf(
      PropTypes.shape({
        formName: PropTypes.string.isRequired,
        stageStatus: PropTypes.string.isRequired,
        submitters: PropTypes.arrayOf(
          PropTypes.shape({
            username: PropTypes.string.isRequired,
            submitterId: PropTypes.string.isRequired,
          })
        ).isRequired,
        reviewers: PropTypes.arrayOf(
          PropTypes.shape({
            username: PropTypes.string.isRequired,
            reviewerId: PropTypes.string.isRequired,
          })
        ).isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default InspectionTable;
