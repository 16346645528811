import React, { createContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

export const BreadcrumbsContext = createContext();

function BcrumbsContextProvider({ children }) {
  const location = useLocation();
  const [breadcrumbsItems, setBreadcrumbsItems] = useState(() => {
    const storedBreadcrumbs = localStorage.getItem("breadcrumbs");
    return storedBreadcrumbs ? JSON.parse(storedBreadcrumbs) : [];
  });

  useEffect(() => {
    // Get path segments from URL
    const pathSegments = location.pathname.split("/").filter(Boolean);

    // Generate breadcrumb items from path segments
    const newBreadcrumbs = pathSegments.map((segment, index) => {
      const url = `/${pathSegments.slice(0, index + 1).join("/")}`;
      // Convert each segment to title case
      const name = segment.charAt(0).toUpperCase() + segment.slice(1);
      return { id: index, name, url };
    });

    setBreadcrumbsItems(newBreadcrumbs);
    localStorage.setItem("breadcrumbs", JSON.stringify(newBreadcrumbs));
  }, [location]);

  const clearBreadcrumbs = () => {
    setBreadcrumbsItems([]);
    localStorage.removeItem("breadcrumbs");
  };

  return (
    <BreadcrumbsContext.Provider
      value={{ breadcrumbsItems, setBreadcrumbsItems, clearBreadcrumbs }}
    >
      {children}
    </BreadcrumbsContext.Provider>
  );
}

BcrumbsContextProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export default BcrumbsContextProvider;
