import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import { fetchInspectionData } from "../../redux/slice/InspectionSlice/InspectionSlice";
import InspectionDetailDialog from "../../components/Inspection/InspectionDetailDialog/InspectionDetailDialog";
import CustomGrid from "../../components/common/CustomGrid/CustomGrid";
import { FaEye } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";
import { MdEdit } from "react-icons/md";
import UpdateStatusDialog from "../../components/Inspection/UpdateStatusDialog";
import PropTypes from "prop-types";
import { fetchCompanyDataPopulate } from "../../redux/slice/CompanySlice/CompanySlice";
function Inspection({ screenType }) {
  const [alldata, setalldata] = useState([]);
  const [rows, setrows] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [showCompanyDetails, setShowCompanyDetail] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  // const [selectedCompanyId, setSelectedCompanyId] = useState("");
  const [selectedJobsite, setSelectedJobsite] = useState(null);
  const [allJobSites, setAllJobSites] = useState([]);
  const [searchTerm, setSearchTerm] = useState(null);
  const { InspectionData, status } = useSelector((state) => state?.Inspections);
  const { CompanyDataPopulate, statusPopulate } = useSelector(
    (state) => state?.Companies
  );
  const location = useLocation();
  let comp = location?.state?.comp;
  const jobSite = location?.state?.jobSite;
  const equipType = location?.state?.equipType;
  useEffect(() => {
    if (screenType === "inspection") {
      dispatch(fetchInspectionData("Pending"));
    } else if (screenType === "pending") {
      dispatch(fetchInspectionData("Pending"));
    } else if (screenType === "inProgress") {
      dispatch(fetchInspectionData("InProgress"));
    } else if (screenType === "completed") {
      dispatch(fetchInspectionData("Completed"));
    } else if (screenType === "cancelled") {
      dispatch(fetchInspectionData("Cancelled"));
    } else {
      dispatch(fetchInspectionData("OnHold"));
    }

    setSearchTerm(null);
    setSelectedJobsite(null);
    setSelectedCompany(null);
    dispatch(fetchCompanyDataPopulate());
  }, [dispatch, screenType]);

  useEffect(() => {
    let filteredJobSites;
    if (selectedCompany) {
      filteredJobSites = CompanyDataPopulate.filter(
        (item) => item.companyName === selectedCompany
      ).flatMap((company) => company.jobSites);
    } else {
      filteredJobSites = CompanyDataPopulate?.flatMap(
        (company) => company.jobSites
      );
    }
    setSelectedJobsite(null);
    setAllJobSites(filteredJobSites);
    if (jobSite) {
      const selectedName =
        allJobSites?.find((item) => item?.id === jobSite)?.name || "";
      setSelectedJobsite(selectedName);
    } else {
      setSelectedJobsite(null);
    }
  }, [CompanyDataPopulate, selectedCompany, jobSite]);

  useEffect(() => {
    if (comp) {
      const selectedName =
        CompanyDataPopulate?.find((item) => item?.id === comp)?.companyName ||
        "";
      setSelectedCompany(selectedName);
    } else {
      setSelectedCompany("");
    }

    if (equipType) {
      setSearchTerm(equipType);
    } else {
      setSearchTerm(null);
    }
  }, [comp, location, screenType, equipType, CompanyDataPopulate]);

  useEffect(() => {
    if (InspectionData) {
      setalldata(InspectionData);
    } else {
      setalldata([]);
    }
  }, [InspectionData]);

  React.useEffect(() => {
    let arr = [];
    if (alldata) {
      alldata?.data?.forEach((item, idx) => {
        return arr.push({
          id: idx + 1,
          companyName: item?.company?.companyName,
          name: item?.jobSite?.name,
          equipmentNo: item?.equipmentNumbers?.equipmentNumber,
          equipmentType: item?.equipmentNumbers?.equipType,
          ...item,
        });
      });
    }
    setrows(arr);
  }, [alldata]);
  useEffect(() => {
    if (rows) {
      setFilteredData(rows);
    } else {
      setFilteredData([]);
    }
  }, [rows]);

  useEffect(() => {
    let filtered = rows;
    if (selectedCompany) {
      filtered = filtered?.filter(
        (item) => item.companyName === selectedCompany
      );
    }
    if (selectedJobsite) {
      filtered = filtered?.filter((item) => item.name === selectedJobsite);
    }
    if (searchTerm) {
      const regex = new RegExp(searchTerm, "i");

      filtered = filtered?.filter(
        (item) =>
          regex.test(item.equipmentType) ||
          regex.test(item.equipmentNo) ||
          regex.test(item.inspectionNumber) ||
          regex.test(item.companyName) ||
          regex.test(item.name)
      );
    }

    setFilteredData(filtered);
  }, [rows, selectedCompany, selectedJobsite, searchTerm]);
  console.log("filtered rows", filteredData);
  const columns = [
    {
      Header: "Sr",
      accessor: "id",
      width: "20px",
    },
    {
      Header: "Company Name",
      accessor: "companyName",
    },
    {
      Header: "Site",
      accessor: "name",
    },
    {
      Header: "Package #",
      accessor: "inspectionNumber",
    },

    {
      Header: "Equipment #",
      accessor: "equipmentNo",
    },
    {
      Header: "Equipment Type",
      accessor: "equipmentType",
    },
    {
      Header: "Current Form",
      accessor: "currentStageForm",
    },

    {
      Header: "Current Stage",
      accessor: "CurrentStage",
      Cell: (cell) => (
        <span>{`${cell?.row?.original?.currentStage} of ${cell?.row?.original?.totalStages}`}</span>
      ),
    },

    {
      Header: "Status",
      accessor: "status",
      Cell: (cell) => (
        <div className="flex items-center">
          <span
            style={{
              padding: "0.25rem 0.75rem",
              fontSize: "0.875rem",
              fontWeight: "600",
              color: "white",
              backgroundColor:
                cell.value === "Completed" || cell.value === "Approved"
                  ? "green"
                  : cell.value === "Pending"
                  ? "orange"
                  : cell.value === "Cancelled"
                  ? "red"
                  : cell.value === "OnHold"
                  ? "grey"
                  : "rgb(79, 195, 247)",
              borderRadius: "9999px",
            }}
          >
            {cell.value}
          </span>
          {cell?.row?.original?.status !== "Cancelled" &&
            cell?.row?.original?.status !== "Completed" && (
              <button
                title="Update Package status"
                onClick={() => {
                  setShowConfirmation(true);
                  setSelectedRow(cell?.row?.original);
                }}
              >
                <MdEdit
                  className="icon view-icon"
                  style={{ fontSize: "13px" }}
                />
              </button>
            )}
        </div>
      ),
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => (
        <div className="flex items-center">
          {screenType === "pending" && (
            <button
              title="Edit Package"
              disabled={
                cell?.row?.original?.status === "Cancelled" ||
                cell?.row?.original?.status === "Completed"
              }
              onClick={() => {
                navigate("/package/form", { state: cell?.row?.original });
              }}
            >
              <CiEdit
                className={`icon view-icon ${
                  cell?.row?.original?.status === "Completed" ||
                  cell?.row?.original?.status === "Cancelled"
                    ? "opacity-50"
                    : ""
                }`}
              />
            </button>
          )}

          <button
            title="View Package"
            onClick={() => {
              navigate("/package/package-view", {
                state: {
                  data: cell?.row?.original?._id,
                },
              });
            }}
          >
            <FaEye className="w-5 h-5 icon view-icon" />
          </button>
        </div>
      ),
    },
  ];
  if (screenType === "onHold" || screenType === "cancelled") {
    columns.splice(8, 0, {
      Header: "Reason",
      accessor: "statusChangereason",
    });
  }
  return (
    <>
      <div className="mb-2 d-flex justify-content-between">
        <div className="d-flex gap-4 w-50">
          <input
            type="text"
            className="form-control w-60"
            placeholder="Search By Package #, Equip #, Equip Type"
            value={searchTerm || ""}
            onChange={(e) => setSearchTerm(e.target.value)}
          />

          <select
            className="form-select w-50"
            value={selectedCompany}
            onChange={(event) => setSelectedCompany(event.target.value)}
          >
            <option value="">Select Company</option>
            {CompanyDataPopulate?.map((item, idx) => (
              <option key={idx} value={item.companyName}>
                {item.companyName}
              </option>
            ))}
          </select>

          <select
            className="form-select w-50 "
            value={selectedJobsite || ""}
            onChange={(event) => setSelectedJobsite(event.target.value)}
          >
            <option value="">Select JobSite</option>
            {allJobSites?.map((site) => (
              <option key={site.id} value={site.name}>
                {site.name}
              </option>
            ))}
          </select>
        </div>
        <div style={{ textAlign: "right" }}>
          <p>
            Total Records:
            <span style={{ color: "#1c4e80", fontWeight: "bold" }}>
              {" "}
              {filteredData.length}
            </span>
          </p>
        </div>
        {screenType === "pending" && (
          <button
            className="btn btn-primary "
            style={{ marginRight: "20px" }}
            onClick={() => navigate("/package/form")}
          >
            New
          </button>
        )}
      </div>
      {status === "loading" || statusPopulate == "loading" ? (
        <div className="text-center">
          <Loader />
        </div>
      ) : (
        <>
          <CustomGrid columns={columns} data={filteredData} />
        </>
      )}
      {showConfirmation && (
        <UpdateStatusDialog
          open={showConfirmation}
          onDeny={() => {
            setSelectedRow(null);
            setShowConfirmation(false);
          }}
          selectedRow={selectedRow}
        />
      )}

      {showCompanyDetails && (
        <InspectionDetailDialog
          open={showCompanyDetails}
          onClose={() => {
            setSelectedRow(null);
            setShowCompanyDetail(false);
          }}
          state={selectedRow}
        />
      )}
    </>
  );
}
Inspection.propTypes = {
  screenType: PropTypes.string.isRequired,
};
export default Inspection;
