import { useEffect, useRef, useState } from "react";
import { EventEmitter, Form as FormClass } from "@formio/js";
import structuredClone from "@ungap/structured-clone";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
// import { BlobProvider } from "@react-pdf/renderer";
// import { FfcForm } from "../components/Prints/FFC_form";
// import { ClForm } from "../components/Prints/CL_form";
// import { IcForm } from "../components/Prints/IC_form";
// import { PtForm } from "../components/Prints/PT_form";
// import { VcForm } from "../components/Prints/VC_form";
// import { EbrForm } from "../components/Prints/EBR_form";
// import { WscForm } from "../components/Prints/WSC_form";
// import { WqcForm } from "../components/Prints/WQC_form";
const getDefaultEmitter = () => {
  return new EventEmitter({
    wildcard: false,
    maxListeners: 0,
  });
};
// const printForm = (elementRef) => {
//   const printContent = elementRef.current.innerHTML;
//   const originalContent = document.body.innerHTML;

//   // Replace the body content with form content
//   document.body.innerHTML = `
//     <html>
//       <head>
//         <title>Print</title>
//         <style>
//           /* Add styles for print */
//           @media print {
//             body {
//               font-family: Arial, sans-serif;
//               margin: 0;
//               padding: 0;
//             }
//             /* Adjust styles for mobile */
//             @media only screen and (max-width: 600px) {
//               body {
//                 font-size: 12px;
//               }
//             }
//           }
//         </style>
//       </head>
//       <body>
//         ${printContent}
//         <script>
//           window.onload = function() {
//             window.print();
//             window.onafterprint = function() {
//               window.close();
//             };
//           };
//         </script>
//       </body>
//     </html>
//   `;

//   // Trigger the print dialog
//   window.print();

//   // Restore original body content after printing
//   document.body.innerHTML = originalContent;
// };
// const printForm = (elementRef) => {
//   const printContent = elementRef.current.innerHTML;

//   // Open a new window for printing
//   const printWindow = window.open("", "_blank");

//   // Write content to the new window and include responsive styles
//   printWindow.document.write();
// };

const onAnyEvent = (handlers, ...args) => {
  const [event, ...outputArgs] = args;
  if (event.startsWith("formio.")) {
    const funcName = `on${event.charAt(7).toUpperCase()}${event.slice(8)}`;
    switch (funcName) {
      case "onPrevPage":
        if (handlers.onPrevPage)
          handlers.onPrevPage(outputArgs[0], outputArgs[1]);
        break;
      case "onNextPage":
        if (handlers.onNextPage)
          handlers.onNextPage(outputArgs[0], outputArgs[1]);
        break;
      case "onCancelSubmit":
        if (handlers.onCancelSubmit) handlers.onCancelSubmit();
        break;
      case "onCancelComponent":
        if (handlers.onCancelComponent)
          handlers.onCancelComponent(outputArgs[0]);
        break;
      case "onChange":
        if (handlers.onChange)
          handlers.onChange(outputArgs[0], outputArgs[1], outputArgs[2]);
        break;
      case "onCustomEvent":
        if (handlers.onCustomEvent) handlers.onCustomEvent(outputArgs[0]);
        break;
      case "onComponentChange":
        if (handlers.onComponentChange)
          handlers.onComponentChange(outputArgs[0]);
        break;
      case "onSubmit":
        if (handlers.onSubmit) handlers.onSubmit(outputArgs[0], outputArgs[1]);
        break;
      case "onSubmitDone":
        if (handlers.onSubmitDone) handlers.onSubmitDone(outputArgs[0]);
        break;
      case "onSubmitError":
        if (handlers.onSubmitError) handlers.onSubmitError(outputArgs[0]);
        break;
      case "onFormLoad":
        if (handlers.onFormLoad) handlers.onFormLoad(outputArgs[0]);
        break;
      case "onError":
        if (handlers.onError) handlers.onError(outputArgs[0]);
        break;
      case "onRender":
        if (handlers.onRender) handlers.onRender(outputArgs[0]);
        break;
      case "onAttach":
        if (handlers.onAttach) handlers.onAttach(outputArgs[0]);
        break;
      case "onBuild":
        if (handlers.onBuild) handlers.onBuild(outputArgs[0]);
        break;
      case "onFocus":
        if (handlers.onFocus) handlers.onFocus(outputArgs[0]);
        break;
      case "onBlur":
        if (handlers.onBlur) handlers.onBlur(outputArgs[0]);
        break;
      case "onInitialized":
        if (handlers.onInitialized) handlers.onInitialized();
        break;
      case "onLanguageChanged":
        if (handlers.onLanguageChanged) handlers.onLanguageChanged();
        break;
      case "onBeforeSetSubmission":
        if (handlers.onBeforeSetSubmission)
          handlers.onBeforeSetSubmission(outputArgs[0]);
        break;
      case "onSaveDraftBegin":
        if (handlers.onSaveDraftBegin) handlers.onSaveDraftBegin();
        break;
      case "onSaveDraft":
        if (handlers.onSaveDraft) handlers.onSaveDraft(outputArgs[0]);
        break;
      case "onRestoreDraft":
        if (handlers.onRestoreDraft) handlers.onRestoreDraft(outputArgs[0]);
        break;
      case "onSubmissionDeleted":
        if (handlers.onSubmissionDeleted)
          handlers.onSubmissionDeleted(outputArgs[0]);
        break;
      case "onRequestDone":
        if (handlers.onRequestDone) handlers.onRequestDone();
        break;
      default:
        break;
    }
  }
  if (handlers.otherEvents && handlers.otherEvents[event]) {
    handlers.otherEvents[event](...outputArgs);
  }
};

const createWebformInstance = async (
  FormConstructor,
  formSource,
  element,
  authToken,
  options = {}
) => {
  if (!options?.events) {
    options.events = getDefaultEmitter();
  }

  if (!options.headers) {
    options.headers = {};
  }

  // Add the Authorization header to the options
  options.headers["Authorization"] = `Bearer ${authToken}`;
  console.log(" options.headers", options.headers);
  if (typeof formSource !== "string") {
    formSource = structuredClone(formSource);
  }
  const promise = FormConstructor
    ? new FormConstructor(element, formSource, options)
    : new FormClass(element, formSource, options);
  const instance = await promise.ready;
  return instance;
};

// Define effective props (aka I want to rename these props but also maintain backwards compatibility)
const getEffectiveProps = (props) => {
  const { FormClass, formioform, form, src, formReady, onFormReady } = props;
  const formConstructor = FormClass !== undefined ? FormClass : formioform;

  const formSource = form !== undefined ? form : src;

  const formReadyCallback = onFormReady !== undefined ? onFormReady : formReady;

  return { formConstructor, formSource, formReadyCallback };
};

export const NewForm = (props) => {
  const renderElement = useRef(null);
  const { formConstructor, formSource, formReadyCallback } =
    getEffectiveProps(props);
  const {
    src,
    form,
    submission,
    url,
    options,
    formioform,
    formReady,
    FormClass,
    style,
    className,
    inspectionStageId,
    submissionId,
    formInstance,
    // isPrint,
    // rolesData,
    // title,
    ...handlers
  } = props;
  const [instanceIsReady, setInstanceIsReady] = useState(false);
  console.log(src, form, formioform, formReady, FormClass, url);
  const navigate = useNavigate();

  const authToken = sessionStorage.getItem("access_token");
  useEffect(() => {
    const element = renderElement.current;

    if (element) {
      // Define the ResizeObserver
      const observer = new ResizeObserver(
        debounce((entries) => {
          entries.forEach((entry) => {
            console.log("Resizing detected:", entry);
          });
        }, 300) // Increased debounce delay to 300ms for more stability
      );

      // Start observing the element
      observer.observe(element);

      // Cleanup function
      return () => {
        if (element) {
          observer.unobserve(element);
        }
      };
    } else {
      console.warn("renderElement is not available for ResizeObserver");
    }
  }, [renderElement]);
  useEffect(() => {
    let ignore = false;
    const createInstance = async () => {
      if (renderElement.current === null) {
        console.warn("Form element not found");
        return;
      }

      if (typeof formSource === "undefined") {
        console.warn("Form source not found");
        return;
      }

      const instance = await createWebformInstance(
        formConstructor,
        formSource,
        renderElement.current,
        authToken,
        options
      );

      if (instance) {
        if (ignore) {
          instance.destroy(true);
          return;
        }
        if (typeof formSource === "string") {
          instance.src = formSource;
        } else if (typeof formSource === "object") {
          instance.form = formSource;

          if (url) {
            instance.url = url;
          }
        }

        if (formReadyCallback) {
          formReadyCallback(instance);
        }
        formInstance.current = instance;
        setInstanceIsReady(true);
      } else {
        console.warn("Failed to create form instance");
      }
    };

    createInstance();
    return () => {
      ignore = true;
      if (formInstance.current) {
        formInstance.current.destroy(true);
      }
    };
  }, [
    formConstructor,
    formReadyCallback,
    formSource,
    options,
    url,
    submission,
  ]);

  useEffect(() => {
    if (
      instanceIsReady &&
      formInstance.current &&
      Object.keys(handlers).length > 0
    ) {
      formInstance.current.onAny((...args) => onAnyEvent(handlers, ...args));
    }

    return () => {
      if (
        instanceIsReady &&
        formInstance.current &&
        Object.keys(handlers).length > 0
      ) {
        formInstance.current.offAny((...args) => onAnyEvent(handlers, ...args));
      }
    };
  }, [instanceIsReady, handlers]);

  useEffect(() => {
    if (instanceIsReady && formInstance.current && submission) {
      formInstance.current.submission = submission;
    }
  }, [instanceIsReady, submission]);
  useEffect(() => {
    if (instanceIsReady && formInstance.current) {
      // Add inspectionStageId to the submission data when the form loads
      if (formInstance.current.submission) {
        formInstance.current.submission = {
          ...formInstance.current.submission,
          inspectionStageId: inspectionStageId,
          submissionId: submissionId || "",
          status: "Saved",
          userId: sessionStorage.getItem("LoginId"),
        };
      }
      formInstance.current.on("submit", (submission) => {
        // Call the custom onSubmit handler passed from the parent component
        if (handlers.onSubmit) {
          handlers.onSubmit(submission);
          navigate(-1);
        }
        navigate(-1);
      });
    }

    return () => {
      if (instanceIsReady && formInstance.current) {
        formInstance.current.off("submit");
      }
    };
  }, [instanceIsReady, handlers, inspectionStageId]);
  console.log(submission);

  return (
    <>
      {" "}
      {/* {isPrint && (
        <div className="d-flex justify-content-end">
          <BlobProvider
            document={
              title === "finFanClosureForm" ? (
                <FfcForm data={submission?.data} rolesData={rolesData} />
              ) : title === "cleanlinessForm" ? (
                <ClForm data={submission?.data} rolesData={rolesData} />
              ) : title === "internalClosureForm" ? (
                <IcForm data={submission?.data} rolesData={rolesData} />
              ) : title === "hydroTs" ? (
                <PtForm
                  data={submission?.data}
                  rolesData={rolesData}
                  text="TS"
                />
              ) : title === "hydroSs" ? (
                <PtForm
                  data={submission?.data}
                  rolesData={rolesData}
                  text="SS"
                />
              ) : title === "vesselClosureForm" ? (
                <VcForm data={submission?.data} rolesData={rolesData} />
              ) : title === "bndlRls" ? (
                <EbrForm data={submission?.data} rolesData={rolesData} />
              ) : title === "wscf" ? (
                <WscForm data={submission?.data} />
              ) : title === "wqcf" ? (
                <WqcForm data={submission?.data} />
              ) : (
                <></>
              )
            }
          >
            {({ url, loading }) =>
              loading ? (
                "Loading document..."
              ) : (
                <a
                  href={url}
                  color="black"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button
                    className="btn btn-outline-primary m-2"
                    // onClick={() => printForm(renderElement)}
                  >
                    Print
                  </button>
                </a>
              )
            }
          </BlobProvider>
        </div>
      )} */}
      <div className={className} style={style} ref={renderElement} />{" "}
    </>
  );
};
NewForm.propTypes = {
  src: PropTypes.string,
  isPrint: PropTypes.bool, // The src prop should be a string or undefined
  form: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // form can be either a string (JSON) or an object
  submission: PropTypes.object, // submission is expected to be an object (it can contain form data)
  url: PropTypes.string, // url should be a string
  options: PropTypes.object, // options should be an object (for Formio options)
  formioform: PropTypes.object, // formioform should be an object
  formReady: PropTypes.bool, // formReady is a boolean indicating if the form is ready
  FormClass: PropTypes.func, // FormClass is expected to be a constructor function (class component)
  style: PropTypes.object, // style should be an object (for inline styles)
  className: PropTypes.string, // className should be a string
  formConstructor: PropTypes.func, // formConstructor is expected to be a function
  formSource: PropTypes.string, // formSource is expected to be a string
  formReadyCallback: PropTypes.func, // formReadyCallback is expected to be a function
  // handlers spread should all be functions (for event handlers like onChange, onSubmit, etc.)
  //   ...Object.fromEntries(Object.keys(props).map((key) => [key, PropTypes.func])), // assuming all remaining handlers are functions
  inspectionStageId: PropTypes.string, // Add this prop to pass the inspectionStageId
  submissionId: PropTypes.string, // Add this prop to pass the submissionId
  formInstance: PropTypes.any, // Add this prop to pass the submissionId
  title: PropTypes.any, // Add this prop to pass the submissionId
  rolesData: PropTypes.any, // Add this prop to pass the submissionId
};
