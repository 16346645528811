import axiosInstance from "../../utils/AxiosInstance";

export const CreateUserApi = async (data) => {
  let snackBarData = {};
  try {
    const response = await axiosInstance.post("/User/Create", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log("response", response.data);

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const UpdateUserApi = async (data, userId) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.put(`/User/Update/${userId}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log("response", response.data);

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const UpdateUserStatusApi = async (data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.put(`/User/UpdateUserStatus`, data);
    console.log("response", response.data);

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const AddSignatureApi = async (id, data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.post(
      `/User/AddUserSignature/${id || ""}`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("response", response.data);

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
