import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
// import { FormioProvider } from "./context/FormioContext";
// import { FormEdit } from "./OurForms/FormEdit";
import { AuthProvider } from "./context/useAuth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Provider store={store}>
      <AuthProvider>
        <App />
      </AuthProvider>
      {/* <FormioProvider baseUrl="http://192.168.0.119:5050/v1">
             <FormEdit/>
             </FormioProvider> */}
    </Provider>
    <ToastContainer
      position="top-right"
      autoClose={3000}
      hideProgressBar={true}
      theme="colored"
    />
  </BrowserRouter>

  // </React.StrictMode>
);
