import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/AxiosInstance";
import { revertAll } from "../ResetStateAction";

const initialState = {
  SitesData: null,
  SitesDetail: null,
  JobSiteCount: null,
  EquipTypes: null,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

export const fetchSitesData = createAsyncThunk("JobSite/GetAll", async () => {
  const response = await axiosInstance.get(`JobSite/GetAll`);
  return response?.data;
});
export const fetchSitesDetailData = createAsyncThunk(
  "JobSite/GetJobsiteExcelData",
  async (id) => {
    const response = await axiosInstance.get(
      `JobSite/GetJobsiteExcelData/${id || ""}`
    );
    return response?.data;
  }
);
export const fetchJobSiteCount = createAsyncThunk(
  "JobSite/GetJobSiteCount ",
  async ({ id, start, end, type }) => {
    const response = await axiosInstance.get(
      `/Inspection/JobSiteInspectionCount?from=${start}&to=${end}&jobSiteId=${
        id || ""
      }&equipType=${type}`
    );
    return response?.data;
  }
);

export const fetchEquipTypes = createAsyncThunk(
  "populate/EquipTypes ",
  async ({ id }) => {
    const response = await axiosInstance.get(
      `/JobSite/EquipTypesByJobsite/${id || ""}`
    );
    return response?.data;
  }
);
const SiteSlice = createSlice({
  name: "SiteSlice",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchSitesData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSitesData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.SitesData = action.payload.data;
        // state.TotalCount = action.payload.data.totalCount;
      })
      .addCase(fetchSitesData.rejected, (state, action) => {
        state.SitesData = null;
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchSitesDetailData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSitesDetailData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.SitesDetail = action.payload.data;
        // state.TotalCount = action.payload.data.totalCount;
      })
      .addCase(fetchSitesDetailData.rejected, (state, action) => {
        state.SitesDetail = null;
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchJobSiteCount.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchJobSiteCount.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.JobSiteCount = action.payload.data;
        // state.TotalCount = action.payload.data.totalCount;
      })
      .addCase(fetchJobSiteCount.rejected, (state, action) => {
        state.JobSiteCount = null;
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchEquipTypes.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchEquipTypes.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.EquipTypes = action.payload.data;
      })
      .addCase(fetchEquipTypes.rejected, (state, action) => {
        state.EquipTypes = null;
        state.error = action.error.message;
        state.status = "failed";
      });
  },
});

export default SiteSlice.reducer;
