import { useRef, useState, useContext } from "react";
import { Form } from "./Form";
import PropTypes from "prop-types";
// import { useFormioContext } from "../hooks/useFormioContext";
import { FormBuilder } from "./FormBuilder.jsx";
import { SaveFormApi, UpdateFormApi } from "../Apis/Forms/FormAPIs.js";
import { SnackBarContext } from "../context/SnackBarContext.jsx";

import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
const DEFAULT_INITAL_FORM = {
  title: "",
  name: "",
  path: "",
  display: "form",
  type: "form",
  components: [],
};

export const DEFAULT_SETTINGS_FORM = {
  display: "form",
  components: [
    {
      label: "Columns",
      columns: [
        {
          components: [
            {
              label: "<b>Form Title</b>",
              labelPosition: "left-left",
              applyMaskOn: "change",
              tableView: true,
              validate: {
                required: true,
              },
              key: "title",
              type: "textfield",
              input: true,
              defaultValue: " ",
            },
            {
              label: "<b>Form Name</b>",
              labelPosition: "left-left",
              applyMaskOn: "change",
              applyValidationOn: "change",
              tableView: true,
              calculateValue: "value = _.camelCase(data.name);",
              validate: {
                required: true,
              },
              key: "name",
              type: "textfield",
              input: true,
              defaultValue: " ",
            },
            {
              label: "Columns",
              columns: [
                // {
                //   components: [
                //     {
                //       label: "<b>Path</b>",
                //       applyMaskOn: "change",
                //       tableView: true,
                //       calculateValue:
                //         "value = _.camelCase(data.path).toLowerCase();",
                //       validate: {
                //         required: true,
                //       },
                //       key: "path",
                //       type: "textfield",
                //       input: true,
                //     },
                //   ],
                //   width: 6,
                //   offset: 0,
                //   push: 0,
                //   pull: 0,
                //   size: "md",
                //   currentWidth: 6,
                // },
                {
                  components: [
                    {
                      label: "<b>Display As</b>",
                      widget: "choicesjs",
                      tableView: true,
                      data: {
                        values: [
                          {
                            label: "Form",
                            value: "form",
                          },
                          // {
                          //   label: "Wizard",
                          //   value: "wizard",
                          // },
                          // {
                          //   label: "PDF",
                          //   value: "pdf",
                          // },
                        ],
                      },
                      validate: {
                        required: true,
                      },
                      key: "display",
                      type: "select",
                      input: true,
                      defaultValue: "form",
                    },
                  ],
                  width: 6,
                  offset: 0,
                  push: 0,
                  pull: 0,
                  size: "md",
                  currentWidth: 6,
                },
                {
                  components: [
                    {
                      label: "Tags",
                      placeholder: "Add a tag",
                      tableView: false,
                      key: "tags",
                      type: "tags",
                      input: true,
                    },
                  ],
                  offset: 0,
                  push: 0,
                  pull: 0,
                  size: "md",
                  currentWidth: 3,
                  width: 3,
                },
              ],
              key: "columns",
              type: "columns",
              input: false,
              tableView: false,
            },
          ],
          width: 9,
          offset: 0,
          push: 0,
          pull: 0,
          size: "md",
          currentWidth: 9,
        },
      ],
      key: "columns1",
      type: "columns",
      input: false,
      tableView: false,
    },
  ],
};

const DEFAULT_SETTINGS_FORM_OPTIONS = {
  noAlerts: true, // Disable default alert
  pristine: true,
};
const DEFAULT_COMPONENTS = {};

export const FormEdit = ({
  initialForm = DEFAULT_INITAL_FORM,
  settingsForm = DEFAULT_SETTINGS_FORM,
  settingsFormOptions = DEFAULT_SETTINGS_FORM_OPTIONS,
  components = DEFAULT_COMPONENTS,
  builderOptions,
  Builder,
  // onSaveForm,
  // saveFormFn,
  onSettingsFormReady,
  onBuilderReady,
  height,
  edit,
  // resetForm,
}) => {
  // const { Formio } = useFormioContext();
  const { setsnackBarData } = useContext(SnackBarContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    Container = ({ children }) => (
      <div
        style={{
          overflowY: "auto",
          maxHeight: `${height || 90}vh`,
          padding: "15px",
        }}
      >
        {children}
      </div>
    ),
    SettingsFormContainer = ({ children }) => <div>{children}</div>,
    BuilderContainer = ({ children }) => <div>{children}</div>,
    SaveButtonContainer = ({ children }) => (
      <div className="mb-5">{children}</div>
    ),
    SaveButton = ({ onClick }) => (
      <button
        onClick={onClick}
        className="btn btn-primary"
        style={{ float: "right" }}
        type="button"
      >
        {isSubmitting ? (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        ) : (
          "Save Form"
        )}
      </button>
    ),
  } = components;
  const settingsFormData = useRef({
    title: initialForm.title,
    name: initialForm.name,
    // path: initialForm.path,
    display: initialForm.display,
  });
  const currentForm = useRef(initialForm);
  const builderRef = useRef(null);
  const handleSaveForm = async () => {
    const formToSave = {
      ...currentForm.current,
      ...settingsFormData.current,
    };
    const { title, name, display } = formToSave;
    if (!title) {
      toast.error("Please enter form title");
      return;
    }
    if (!name) {
      toast.error("Please enter form name");
      return;
    }
    if (!display) {
      toast.error("Please select display");
      return;
    }
    setIsSubmitting(true);
    if (edit) {
      try {
        const response = await UpdateFormApi(formToSave, initialForm?._id);
        console.log("response data", response);
        setsnackBarData(response?.snackBarData);
      } catch (error) {
        console.error("Error saving form", error);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      try {
        const response = await SaveFormApi(formToSave);
        setsnackBarData(response?.snackBarData);

        console.log("response data", response);
      } catch (error) {
        console.error("Error saving form", error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleBuilderReady = (builder) => {
    builderRef.current = builder;
    if (onBuilderReady) {
      onBuilderReady(builder);
    }
  };

  return (
    <Container>
      <SettingsFormContainer>
        <Form
          src={settingsForm}
          onFormReady={onSettingsFormReady}
          options={settingsFormOptions}
          submission={{
            data: {
              title: initialForm.title,
              name: initialForm.name,
              path: initialForm.path,
              display: initialForm.display,
            },
          }}
          onChange={({ changed, data }, flags, modified) => {
            console.log(data, modified);
            if (modified) {
              if (changed.component.key === "display") {
                builderRef.current?.setDisplay(data.display);
              }
              settingsFormData.current = data;
            }
          }}
        />
      </SettingsFormContainer>
      <BuilderContainer>
        <FormBuilder
          initialForm={initialForm}
          options={builderOptions}
          Builder={Builder}
          onBuilderReady={handleBuilderReady}
          onChange={(form) => {
            currentForm.current = form;
          }}
        />
      </BuilderContainer>
      <SaveButtonContainer>
        <SaveButton onClick={handleSaveForm} />
      </SaveButtonContainer>
    </Container>
  );
};
FormEdit.propTypes = {
  initialForm: PropTypes.object, // initialForm should be an object
  settingsForm: PropTypes.object, // settingsForm should be an object
  settingsFormOptions: PropTypes.object, // settingsFormOptions should be an object
  components: PropTypes.object, // components should be an object
  builderOptions: PropTypes.object, // builderOptions should be an object
  Builder: PropTypes.func, // Builder is a required function (component or class)
  onSaveForm: PropTypes.func, // onSaveForm should be a function (optional)
  saveFormFn: PropTypes.func, // saveFormFn should be a function (optional)
  onSettingsFormReady: PropTypes.func, // onSettingsFormReady should be a function (optional)
  onBuilderReady: PropTypes.func, // onBuilderReady should be a function (optional)
  height: PropTypes.any,
  edit: PropTypes.bool,
  // resetForm: PropTypes.func,
};
