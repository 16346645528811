import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";

function GetOtp({ values, errors, touched, handleChange, handleBlur }) {
  console.log("values", values);
  return (
    // <Form>
    <Form.Group className="mb-3">
      <Form.Label>Email</Form.Label>
      <Form.Control
        type="email"
        name="email"
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        isInvalid={touched.email && !!errors.email}
        placeholder="Enter your email"
      />
      {touched.email && errors.email && (
        <Form.Control.Feedback type="invalid">
          {errors.email}
        </Form.Control.Feedback>
      )}
    </Form.Group>
    // </Form>
  );
}

export default GetOtp;

GetOtp.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
};
