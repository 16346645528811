import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import CustomGrid from "../../components/common/CustomGrid/CustomGrid";
import AddPdfModel from "./ModelforAddPdf/AddPdfModel";
import { MdAddBox, MdDelete } from "react-icons/md";
import { fetchPdfData } from "../../redux/slice/PdfSlice/PdfSlice";
import { fetchCompanyDataPopulate } from "../../redux/slice/CompanySlice/CompanySlice";
import { CreateDocumentApi, DeleteDocumentApi } from "../../Apis/Pdf/Pdf";
import { SnackBarContext } from "../../context/SnackBarContext";
import PropTypes from "prop-types";
import AppConfirmationDialog from "../../components/ConfirmationDialog/ConfirmationDialog";

function Pdf() {
  const [alldata, setalldata] = useState([]);
  const [rows, setrows] = useState([]);
  const dispatch = useDispatch();
  const [openAddPdfModel, setOpenAddPdfModel] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedJobsite, setSelectedJobsite] = useState(null);
  const [allJobSites, setAllJobSites] = useState([]);
  const { PdfData, status } = useSelector((state) => state?.pdf);
  const { CompanyDataPopulate, statusPopulate } = useSelector(
    (state) => state?.Companies
  );

  const { UserRolesData } = useSelector((state) => state?.Roles);
  const { setsnackBarData } = useContext(SnackBarContext);

  useEffect(() => {
    dispatch(fetchPdfData());
    dispatch(fetchCompanyDataPopulate());
  }, [dispatch]);

  useEffect(() => {
    if (PdfData) {
      setalldata(PdfData);
    } else {
      setalldata([]);
    }
  }, [PdfData]);
  useEffect(() => {
    let filteredJobSites;
    if (selectedCompany) {
      filteredJobSites = CompanyDataPopulate.filter(
        (item) => item.companyName === selectedCompany
      ).flatMap((company) => company.jobSites);
    } else {
      filteredJobSites = CompanyDataPopulate?.flatMap(
        (company) => company.jobSites
      );
    }

    setSelectedJobsite(null);
    setAllJobSites(filteredJobSites);
  }, [CompanyDataPopulate, selectedCompany]);
  React.useEffect(() => {
    let arr = [];
    if (alldata) {
      alldata?.forEach((item, idx) => {
        return arr.push({
          _id: idx + 1,
          AddedBy: item?.addedBy?.username,
          filename: item?.filename,
          SiteName: item?.jobSite?.name,
          CompanyName: item?.jobSite?.company?.companyName,
          ...item,
        });
      });
    }
    setrows(arr);
  }, [alldata]);

  useEffect(() => {
    if (rows) {
      setFilteredData(rows);
    } else {
      setFilteredData([]);
    }
  }, [rows]);

  const columns = [
    {
      Header: "Sr",
      accessor: "_id",
      width: "20px",
    },
    {
      Header: "Title",
      accessor: "title",
    },
    // {
    //   Header: "Company Name",
    //   accessor: "CompanyName",
    // },

    {
      Header: "Company Name",
      accessor: "CompanyName",
    },
    {
      Header: "Site Name",
      accessor: "SiteName",
    },

    {
      Header: "Date",
      accessor: "createdAt",
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        return date.toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
      },
    },
    {
      Header: "Added By",
      accessor: "AddedBy",
    },
    {
      Header: "Pdf Link",
      accessor: "filename",
      Cell: PdfLinkCell,
      width: "100px",
    },
    {
      Header: "Description",
      accessor: "description",
      Cell: PdfDescriptionCell,
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => (
        <div className="flex items-center">
          {UserRolesData?.role?.map((r) => r.roleName).includes("Admin") ||
          UserRolesData?.role
            ?.map((r) => r.roleName)
            .includes("Super Admin") ? (
            <button
              onClick={() => {
                setShowConfirmation(true);
                setSelectedRow(cell?.row?.original);
                console.log("UserRolesData", UserRolesData?.role[0]?.roleName);
              }}
            >
              <MdDelete className="w-4 h-4 icon delete-icon" />
            </button>
          ) : (
            <span style={{ color: "grey" }}>N/A</span>
          )}
        </div>
      ),
    },
  ];
  const deleteDocument = async () => {
    setLoading(true);
    const response = await DeleteDocumentApi(selectedRow?.id);
    if (response.success) {
      setShowConfirmation(false);
      dispatch(fetchPdfData());
      dispatch(fetchCompanyDataPopulate());
    }
    response?.snackBarData?.message && setsnackBarData(response?.snackBarData);
    setSelectedRow(null);
    setLoading(false);
  };
  console.log("selected company", selectedCompany);
  useEffect(() => {
    let filtered = rows;
    if (selectedJobsite) {
      filtered = filtered.filter(
        (item) => item?.jobSite?.name === selectedJobsite
      );
    }
    if (selectedCompany) {
      filtered = filtered.filter(
        (item) => item?.jobSite?.company?.companyName === selectedCompany
      );
    }
    if (searchTerm) {
      const regex = new RegExp(searchTerm, "i");
      filtered = filtered.filter(
        (item) =>
          regex.test(item.title) ||
          regex.test(item.description) ||
          regex.test(item?.jobSite?.name) ||
          regex.test(item?.jobSite?.company?.companyName)
      );
    }

    setFilteredData(filtered);
  }, [rows, selectedJobsite, searchTerm, selectedCompany]);

  const handleAddPdfSubmit = async (formData) => {
    try {
      const response = await CreateDocumentApi(formData);
      if (response?.snackBarData?.type === "success") {
        dispatch(fetchPdfData());
        setOpenAddPdfModel(false);
        setsnackBarData({
          message: response.snackBarData.message,
          type: "success",
        });
      } else {
        setsnackBarData({
          message: response?.snackBarData?.message || "Failed to add document",
          type: "error",
        });
      }
    } catch (error) {
      setsnackBarData({
        message: "Error in adding document. Please try again.",
        type: "error",
      });
    }
  };
  return (
    <>
      <div className="mb-2 d-flex justify-content-between">
        <div className="d-flex gap-4 w-50">
          <input
            type="text"
            className="form-control w-60"
            placeholder="Search By Title , Jobsite , Description"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <select
            className="form-select w-50 "
            aria-label="Default select example"
            value={selectedCompany}
            onChange={(event) => setSelectedCompany(event.target.value)}
          >
            <option value="">Select Company</option>
            {CompanyDataPopulate?.map((item, idx) => (
              <option key={idx} value={item.name}>
                {item.companyName}
              </option>
            ))}
          </select>
          <select
            className="form-select w-50 "
            aria-label="Default select example"
            value={selectedJobsite}
            onChange={(event) => setSelectedJobsite(event.target.value)}
          >
            <option value="">Select Jobsite</option>
            {allJobSites?.map((site) => (
              <option key={site.id} value={site.name}>
                {site.name}
              </option>
            ))}
          </select>
        </div>
        <div className="w-50">
          <p style={{ textAlign: "center" }}>
            Total Records:
            <span style={{ color: "#1c4e80", fontWeight: "bold" }}>
              {" "}
              {filteredData.length}
            </span>
          </p>
        </div>
        {(UserRolesData?.role?.map((r) => r.roleName).includes("Admin") ||
          UserRolesData?.role
            ?.map((r) => r.roleName)
            .includes("Super Admin")) && (
          <button
            className="btn btn-primary "
            style={{ marginRight: "20px" }}
            onClick={() => setOpenAddPdfModel(true)}
          >
            New
          </button>
        )}
      </div>
      {status === "loading" || statusPopulate === "loading" || loading ? (
        <div className="text-center">
          <Loader />
        </div>
      ) : (
        <CustomGrid columns={columns} data={filteredData} />
      )}
      {openAddPdfModel && (
        <AddPdfModel
          open={openAddPdfModel}
          onDeny={() => setOpenAddPdfModel(false)}
          icon={<MdAddBox color="#3183c8" />}
          dialogTitle="Add PDF"
          successText="Add PDF"
          successBtnColor={"success"}
          cancelText="Cancel"
          CompanyPopulate={CompanyDataPopulate}
          onSubmit={handleAddPdfSubmit}
        />
      )}
      {showConfirmation && (
        <AppConfirmationDialog
          open={showConfirmation}
          onDeny={() => {
            setSelectedRow(null);
            setShowConfirmation(false);
          }}
          onConfirm={() => {
            deleteDocument();
          }}
          iconContainerColor="#fbeaea"
          // icon={<MdDelete fontSize="small" color="red" />}
          dialogTitle="Delete Document"
          title={`Are you sure, you want to delete Document ${selectedRow?.title}?`}
          successText="Yes"
          successBtnColor="primary"
          cancelText="No"
        />
      )}
    </>
  );
}

export default Pdf;

const PdfLinkCell = ({ value }) => (
  <a href={value || "#"} target="_blank" rel="noopener noreferrer">
    {value ? "View PDF" : "No PDF Available"}
  </a>
);

PdfLinkCell.propTypes = {
  value: PropTypes.string,
};
const PdfDescriptionCell = ({ value }) => {
  return (
    <span
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      title={value}
      className="text-truncate"
      style={{
        maxWidth: "250px",
        display: "inline-block",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      {value}
    </span>
  );
};

PdfDescriptionCell.propTypes = {
  value: PropTypes.string.isRequired,
};
PdfDescriptionCell.propTypes = {
  value: PropTypes.string.isRequired,
};
