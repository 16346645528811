import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import PropTypes from "prop-types";

function EquipmentLineChart({ data }) {
  const [chartState, setChartState] = useState({
    series: [
      {
        name: "Usage Count",
        data: [],
      },
    ],
    options: {
      chart: {
        type: "line",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      stroke: {
        curve: "smooth",
      },
      dataLabels: {
        enabled: true,
      },
      xaxis: {
        categories: [],
        title: {
          text: "Equipment Type",
        },
      },
      yaxis: {
        title: {
          text: "Usage Count",
        },
      },
      tooltip: {
        custom: undefined,
      },
    },
  });

  useEffect(() => {
    const categories = data.map((item) =>
      item.equipType ? item.equipType : "Unknown"
    );
    const usageCounts = data.map((item) => item.count);
    const inspectionMapping = data.reduce((acc, item) => {
      acc[item.equipType] = item.inspectionNumbers || [];
      return acc;
    }, {});

    setChartState({
      series: [
        {
          name: "Usage Count",
          data: usageCounts,
        },
      ],
      options: {
        ...chartState.options,
        xaxis: {
          categories,
        },
        tooltip: {
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            const equipmentNumber = w.globals.categoryLabels[dataPointIndex];
            const inspections =
              inspectionMapping[equipmentNumber]?.join(", ") || "None";

            return `
                <div style="padding:10px; border:1px solid #ddd; background:#fff;">
                  <strong>Type: </strong>${equipmentNumber}<br/>
                  <strong>Count: </strong>${series[seriesIndex][dataPointIndex]}<br/>
                  <strong>Packages #: </strong>${inspections}<br/>
                </div>
              `;
          },
        },
      },
    });
  }, [data]);

  return (
    <div>
      <div
        style={{
          textAlign: "center",
          fontWeight: "bold",
          fontSize: "0.8em",
        }}
      >
        Equipment Type Usage
      </div>
      <div id="chart">
        <ReactApexChart
          options={chartState.options}
          series={chartState.series}
          type="line"
          height={chartState.options.chart.height}
        />
      </div>
    </div>
  );
}

EquipmentLineChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      equipmentId: PropTypes.string.isRequired,
      equipmentNumber: PropTypes.string.isRequired,
      serialNumber: PropTypes.string,
      usageCount: PropTypes.number.isRequired,
      inspectionNumbers: PropTypes.arrayOf(PropTypes.string),
    })
  ).isRequired,
};

export default EquipmentLineChart;
