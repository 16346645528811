import axiosInstance from "../../utils/AxiosInstance";

export const CreateInspectionApi = async (data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.post("/Inspection/Create", data);

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const UpdateInspectionApi = async (id, data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.patch(`/JobSite/Update/${id}`, data);

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const UpdateInspectionByIdApi = async (id, data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.patch(
      `/Inspection/UpdateInspectionById/${id}`,
      data
    );

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const DeleteInspectionApi = async (id) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.delete(`/JobSite/Delete/${id}`);

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
export const fetchEquipmentApi = async (no, id) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.get(
      `/JobSite/GetByEquipmentNumber?jobsiteId=${id}&equipmentNumber=${no}`
    );

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const UpdateInspectionStatusApi = async (data, id) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.patch(
      `/Inspection/UpdateStatus/${id}`,
      data
    );

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
