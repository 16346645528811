import React from "react";
import { FaLock } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { revertAll } from "../../redux/slice/ResetStateAction";

function NoAccess() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogout = () => {
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("LoginId");
    sessionStorage.removeItem("isAdmin");
    sessionStorage.removeItem("isSuperAdmin");
    sessionStorage.removeItem("isEmployee");
    dispatch(revertAll());
    localStorage.clear();
    navigate("/signin", { replace: true });
  };
  return (
    // <main style={{ color: "red" }}>
    //   {/* <h1>You don&apos;t have access to this Page</h1> */}
    // </main>
    <div className="d-flex justify-content-center align-items-center vh-100">
      <div
        className="text-staet p-4"
        style={{
          backgroundColor: "#f8f9fa",
          borderRadius: "8px",
          border: "1px solid #ddd",
          display: "flex",
        }}
      >
        <FaLock size={200} className="text-warning mb-3" />
        <div>
          <h1 className="text-primary">Whoops!</h1>
          <p className="lead">
            This URL is valid but you are not authorized for this content.
            Please contact <a href="#">Administrator</a>.
          </p>
          <p className="text-muted">
            You are currently signed in as:{" "}
            <strong>{sessionStorage.getItem("userEmail")}</strong>
          </p>

          <div className="mb-3">
            <a href="/signin" className="d-block mb-2" onClick={handleLogout}>
              Sign in as a different user
            </a>
            {/* <a href="#" className="d-block">Request access</a> */}
          </div>

          <button className="btn btn-secondary" onClick={() => navigate(-1)}>
            Go back
          </button>
        </div>
      </div>
    </div>
  );
}

export default NoAccess;
