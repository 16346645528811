import React from "react";

function NotFound() {
  return (
    <main style={{ color: "red" }}>
      <h1>Page Not Found!</h1>
    </main>
  );
}

export default NotFound;
