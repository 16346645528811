import React, { useContext, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Ensure Toastify CSS is imported
import { SnackBarContext } from "../../context/SnackBarContext";

const SnackBar = () => {
  const { snackBarData, setsnackBarData } = useContext(SnackBarContext);

  useEffect(() => {
    if (snackBarData?.openToast) {
      const toastType = snackBarData.type === "success" ? toast.success : snackBarData.type === "error" ? toast.error : toast.info;
      toastType(snackBarData.message, {
        onClose: () => handleClose(),
      });
    }
  }, [snackBarData]);

  const handleClose = () => {
    setsnackBarData({
      openToast: false,
      type: "",
      message: "",
    });
  };

  return (
    <>
      <ToastContainer position="top-right" theme="colored" autoClose={6000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};

export default SnackBar;
