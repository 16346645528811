import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SnackBarContext } from "../../context/SnackBarContext";
import AppConfirmationDialog from "../../components/ConfirmationDialog/ConfirmationDialog";
import Loader from "../../components/Loader/Loader";
import { MdDelete } from "react-icons/md";
import SitesDetailDialog from "../../components/Sites/SitesDetailDialog/SitesDetailDialog";
import { CiEdit } from "react-icons/ci";
import { fetchSitesData } from "../../redux/slice/SitesSlice/SitesSlice";
import { DeleteSiteApi } from "../../Apis/Sites/SitesApis";
import { fetchCompanyPopulate } from "../../redux/slice/CompanySlice/CompanySlice";
import CustomGrid from "../../components/common/CustomGrid/CustomGrid";

function Sites() {
  const [alldata, setalldata] = useState([]);
  const [rows, setrows] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [showCompanyDetails, setShowCompanyDetail] = useState(false);
  const { setsnackBarData } = useContext(SnackBarContext);

  const { SitesData, status } = useSelector((state) => state?.Sites);
  const { CompanyPopulate, statusPopulate } = useSelector(
    (state) => state?.Companies
  );

  useEffect(() => {
    dispatch(fetchSitesData());
    dispatch(fetchCompanyPopulate());
  }, [dispatch]);

  useEffect(() => {
    if (SitesData) {
      setalldata(SitesData);
    } else {
      setalldata([]);
    }
  }, [SitesData]);

  const deleteCompany = async () => {
    setLoading(true);
    setShowConfirmation(false);

    const response = await DeleteSiteApi(selectedRow?.id);

    response?.snackBarData?.message && setsnackBarData(response?.snackBarData);
    if (response?.success) {
      dispatch(fetchSitesData());
      setSelectedRow(null);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    let arr = [];
    if (alldata) {
      alldata?.forEach((item, idx) => {
        arr.push({
          id: idx + 1,
          ...item,
        });
      });
    }
    setrows(arr);
  }, [alldata]);

  useEffect(() => {
    if (rows) {
      setFilteredData(rows);
    } else {
      setFilteredData([]);
    }
  }, [rows]);

  const handleFilterChange = (event) => {
    const value = event.target.value;
    console.log(value);
    const regex = new RegExp(value, "i"); // 'i' flag makes the search case-insensitive
    let filteredData = rows;
    const filtered = filteredData.filter(
      (item) =>
        regex.test(item?.name) ||
        regex.test(item?.email) ||
        regex.test(item?.phoneNumber) ||
        regex.test(item?.companyId) ||
        regex.test(item?.jobsiteNo)
    );

    setFilteredData(filtered);
  };
  const columns = [
    {
      Header: "ID",
      accessor: "id", // Accessor is the "key" in the data
      width: "20px",
    },
    {
      Header: "Job Site #",
      accessor: "jobsiteNo",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Company Name",
      accessor: "companyName",
    },
    // {
    //   Header: "Phone",
    //   accessor: "phoneNumber",
    // },
    {
      Header: "Phone",
      accessor: "phoneNumber",
      Cell: ({ value }) => {
        const digits = value.replace(/\D/g, "");

        return `+1 ${digits.slice(0, 3)} ${digits.slice(3, 6)} ${digits.slice(
          6
        )}`;
      },
    },

    {
      Header: "Equipments",
      accessor: "equipments",
      disableSortBy: true,
      Cell: (cell) => (
        <div className="flex items-center">
          <button
            className="btn btn-outline-secondary mx-2 "
            onClick={() => {
              setSelectedRow(cell?.row?.original);
              navigate("/sites/sites-view", {
                state: {
                  data: cell?.row?.original?._id,
                },
              });
            }}
          >
            View
          </button>
        </div>
      ),
    },
    {
      Header: "Actions",
      accessor: "actions",

      disableSortBy: true, // Disable sorting for actions
      Cell: (cell) => (
        <div className="flex items-center">
          <CiEdit
            title="Edit Site"
            className="icon view-icon"
            onClick={() => {
              setSelectedRow(cell?.row?.original);
              navigate("/sites/form", { state: cell?.row?.original });
              // console.log("selected",cell?.row?.original);
            }}
          />
        </div>
      ),
    },
  ];
  return (
    <>
      <div className="mb-2 d-flex justify-content-between">
        <div className="d-flex gap-4 w-50">
          <input
            type="text"
            className="form-control w-50"
            placeholder="Search By Name, Site #, Email or Phone"
            onChange={handleFilterChange}
          />

          <select
            className="form-select w-50 "
            aria-label="Default select example"
            onChange={handleFilterChange}
          >
            <option value="">Select Company</option>

            {CompanyPopulate?.map((item, idx) => (
              <option key={idx} value={item?.id}>
                {item?.companyName}
              </option>
            ))}
          </select>
        </div>
        <div className="w-50">
          <p style={{ textAlign: "center" }}>
            Total Records:
            <span style={{ color: "#1c4e80", fontWeight: "bold" }}>
              {" "}
              {filteredData.length}
            </span>
          </p>
        </div>
        <button
          className="btn btn-primary"
          style={{ marginRight: "20px" }}
          onClick={() => navigate("/sites/form")}
        >
          New
        </button>
      </div>

      {status === "loading" || statusPopulate === "loading" || loading ? (
        <div className="text-center">
          <Loader />
        </div>
      ) : (
        <CustomGrid columns={columns} data={filteredData} />
      )}
      {showConfirmation && (
        <AppConfirmationDialog
          open={showConfirmation}
          onDeny={() => {
            setSelectedRow(null);
            setShowConfirmation(false);
          }}
          onConfirm={() => {
            deleteCompany();
          }}
          iconContainerColor="#fbeaea"
          icon={<MdDelete color="red" />}
          dialogTitle="Delete Site"
          title={`Are you sure, you want to delete site ${selectedRow?.name}?`}
          successText="Yes"
          successBtnColor="danger"
          cancelText="No"
        />
      )}
      {/* {status === "loading" || statusPopulate === "loading" || loading ? (
        <Loader />
      ) : null} */}
      {showCompanyDetails && (
        <SitesDetailDialog
          open={showCompanyDetails}
          onClose={() => {
            setSelectedRow(null);
            setShowCompanyDetail(false);
          }}
          state={selectedRow}
        />
      )}
    </>
  );
}

export default Sites;
