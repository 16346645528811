import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useAuth } from "../../context/useAuth";
import jwtDecode from "jwt-decode";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

function RequireAuth({ children }) {
  const [token, setToken] = useState(sessionStorage.getItem("access_token"));
  const [verified, setVerified] = useState(
    sessionStorage.getItem("isVerified")
  );

  const { user, signup } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    setToken(sessionStorage.getItem("access_token"));
    setVerified(sessionStorage.getItem("isVerified"));
  }, [user]);
  if (!user || !token) {
    return <Navigate to="/signin" />;
  } else if (!verified) {
    navigate("/login-verification");
  } else {
    const { exp } = jwtDecode(token);
    const expiryTime = new Date(moment.unix(exp).format()).getTime();
    const currentTime = new Date().getTime();

    if (currentTime <= expiryTime) {
      return children;
    } else {
      sessionStorage.removeItem("access_token");
      sessionStorage.removeItem("LoginId");
      signup(null);
      navigate("/signin", { replace: true });
    }
  }
}

export default RequireAuth;
RequireAuth.propTypes = {
  children: PropTypes.any.isRequired,
};
