import React, { useContext, useEffect, useRef, useState } from "react";
import { FormioProvider } from "../../context/FormioContext";
// import { Form } from "../../OurForms/Form";
import { useLocation } from "react-router-dom";
import { BreadcrumbsContext } from "../../context/BcrumbsContextProvider";
import { useNavigate } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { useSelector } from "react-redux";
import { NewForm } from "../../OurForms/NewForm";
function MyApprovalForm() {
  const location = useLocation();
  const data = location.state?.data;
  const submission = location.state.submission;
  const navigate = useNavigate();
  const { UserRolesData } = useSelector((state) => state?.Roles);
  const [rolesData, setrolesData] = useState(null);
  const [disableCompponents, setdisableCompponents] = useState(null);
  const formInstance = useRef();
  const { setBreadcrumbsItems } = useContext(BreadcrumbsContext);
  const handleBreadcrumbs = () => {
    setBreadcrumbsItems([
      {
        id: 1,
        url: "/my-approval",
        name: "My Approval",
      },
      {
        id: 2,
        url: "/my-approval/form",
        name: "Form",
      },
    ]);
  };
  const toCamelCase = (str) => {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
        index === 0 ? match.toLowerCase() : match.toUpperCase()
      )
      .replace(/\s+/g, "");
  };

  useEffect(() => {
    if (UserRolesData) {
      // Define the roles that you want to check
      console.log(UserRolesData);

      const roleKeys = [
        "qcInspector",
        "processEngineer",
        "operationalManager",
        "executiveJobSupervisor",
        "qaInspector",
      ];
      const roleArr = UserRolesData?.role?.map((role) => {
        let camelRole = toCamelCase(role.roleName);
        console.log(camelRole);

        // Handle special case for qCInspector
        if (camelRole === "qCInspector") {
          camelRole = "qcInspector";
        } else if (camelRole === "qAInspector") {
          camelRole = "qaInspector";
        } else if (camelRole === "executive/JobSupervisor") {
          camelRole = "executiveJobSupervisor";
        }

        return camelRole;
      });
      console.log(roleArr);

      let obj = {};

      // Create the object with key-value pairs only for matching roles
      roleKeys.forEach((key) => {
        // Check if the role exists in the user data
        console.log(key);

        if (!roleArr?.includes(key)) {
          obj[key] = true;
          obj[`${key}1`] = true;
          obj[`${key}2`] = true;
          obj[`${key}3`] = true;
          obj[`${key}4`] = true;
          obj[`${key}5`] = true;
          obj[`${key}6`] = true;
          obj[`${key}7`] = true;
          obj[`${key}8`] = true;
          obj[`${key}9`] = true;
        }
      });

      console.log(obj);

      // Check if obj is empty, if it is, set it to null
      if (Object.keys(obj).length === 0) {
        setdisableCompponents(null); // Set null if obj is empty
      } else {
        setdisableCompponents({
          ...obj,
        }); // Set the object if it contains any key-value pairs
      }
    }
  }, [UserRolesData]);
  useEffect(() => {
    handleBreadcrumbs();
    return () => {
      setBreadcrumbsItems([]);
    };
  }, [setBreadcrumbsItems]);
  useEffect(() => {
    if (submission.reviewersSubmissions) {
      const result = submission?.reviewersSubmissions?.reduce((acc, review) => {
        if (review.status === "Approved") {
          // Check if status is "Approved"
          const roleName = review?.role?.roleName
            .toLowerCase()
            .replace(/\s+/g, "");
          acc[
            roleName == "executive/jobsupervisor"
              ? "executivejobsupervisor"
              : roleName
          ] = {
            username: review?.reviewerId?.username,
            statusUpdatedAt: review?.statusUpdatedAt,
            signature: review?.reviewerId?.signature, // Assuming "signature" is equivalent to comments
          };
        }
        return acc;
      }, {});

      setrolesData(result);
    }
  }, [submission]);
  console.log(rolesData);

  return (
    <div style={{ height: "100vh", overflowY: "scroll" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <button
          onClick={() => navigate(-1)}
          style={{
            color: "black",
            cursor: "pointer",
            marginRight: "16px",
            marginBottom: "7px",
          }}
        >
          <IoMdArrowBack fontSize="24px" />
        </button>
        <h1 style={{ fontSize: "24px", fontWeight: "bold" }}>
          {data?.form?.title}
        </h1>
      </div>
      <p>Form ID: {data?.form?._id}</p>
      <FormioProvider baseUrl={process.env.REACT_APP_API_URL_LOCAL}>
        {submission &&
        data?.myReviewStatus == "Approved" &&
        disableCompponents ? (
          <NewForm
            rolesData={rolesData}
            isPrint={true}
            formInstance={formInstance}
            src={`Forms/${data?.form?._id}`}
            title={data?.form?.name}
            submission={submission}
            inspectionStageId={data.currentStage}
            submissionId={data?.submissionId}
            options={{
              readOnly: true,
              hideSumbit: true,
              hide: {
                submit: true,
              },
              disabled: {
                ...disableCompponents,
              },
            }}
          />
        ) : (
          submission &&
          disableCompponents && (
            <NewForm
              title={data?.form?.name}
              formInstance={formInstance}
              options={{
                disabled: {
                  ...disableCompponents,
                },
              }}
              src={`Forms/${data?.form?._id}`}
              submissionId={data?.submissionId}
              submission={submission}
              inspectionStageId={data.currentStage} // Pass inspectionStageId here
            />
          )
        )}
      </FormioProvider>
    </div>
  );
}

export default MyApprovalForm;
