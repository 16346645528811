import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/AxiosInstance";
import { revertAll } from "../ResetStateAction";

const initialState = {
  CompanyData: null,
  CompanyPopulate: null,
  CompanyDataPopulate: null,
  TotalCount: 0,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  statusPopulate: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'

  error: null,
};

export const fetchCompanyData = createAsyncThunk("Company/GetAll", async () => {
  const response = await axiosInstance.get(`Company/GetAll`);
  return response?.data;
});

export const fetchCompanyPopulate = createAsyncThunk(
  "Company/Populate",
  async () => {
    const response = await axiosInstance.get(`Company/Populate`);
    return response?.data;
  }
);

export const fetchCompanyDataPopulate = createAsyncThunk(
  "Company/CompanyPopulate",
  async () => {
    const response = await axiosInstance.get(`/Company/CompaniesFilter`);
    return response?.data;
  }
);
const CompanySlice = createSlice({
  name: "CompanySlice",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchCompanyData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCompanyData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.CompanyData = action.payload.data;
        // state.TotalCount = action.payload.data.totalCount;
      })
      .addCase(fetchCompanyData.rejected, (state, action) => {
        state.CompanyData = null;
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchCompanyPopulate.pending, (state) => {
        state.statusPopulate = "loading";
      })
      .addCase(fetchCompanyPopulate.fulfilled, (state, action) => {
        state.statusPopulate = "succeeded";
        state.message = action.payload.message;
        state.CompanyPopulate = action.payload.data;
      })
      .addCase(fetchCompanyPopulate.rejected, (state, action) => {
        state.CompanyPopulate = null;
        state.error = action.error.message;
        state.statusPopulate = "failed";
      })
      .addCase(fetchCompanyDataPopulate.pending, (state) => {
        state.statusPopulate = "loading";
      })
      .addCase(fetchCompanyDataPopulate.fulfilled, (state, action) => {
        state.statusPopulate = "succeeded";
        state.message = action.payload.message;
        state.CompanyDataPopulate = action.payload.data;
      })
      .addCase(fetchCompanyDataPopulate.rejected, (state, action) => {
        state.CompanyDataPopulate = null;
        state.error = action.error.message;
        state.statusPopulate = "failed";
      });
  },
});

export default CompanySlice.reducer;
