import React, { useContext, useState } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useAuth } from "../../context/useAuth";
import { SnackBarContext } from "../../context/SnackBarContext";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { BreadcrumbsContext } from "../../context/BcrumbsContextProvider";
import { LoginAPi } from "../../Apis/Auth/AuthApis";
import bgImg from "../../Assets/Images/Authentication/ai-image.jpeg";
import BrandLogo from "../../Assets/Images/no background RAMI.png";
import { fetchUserRolesData } from "../../redux/slice/RolesSlice/RolesSlice";
import { useDispatch } from "react-redux";
const validationSchema = yup.object().shape({
  email: yup.string("Please enter email").required("Email is required"),
  password: yup.string("Enter Password").required("Password is required"),
});

function SignInForm() {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { user, signup, setUserRoles } = useAuth();
  const { setsnackBarData } = useContext(SnackBarContext);
  const { setBreadcrumbsItems } = useContext(BreadcrumbsContext);

  console.log("user", user);
  const dispatch = useDispatch();
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      const response = await LoginAPi(values);

      setsnackBarData(response?.snackBarData);
      if (response?.success) {
        dispatch(fetchUserRolesData(response?.data?.user?.id));
        signup(response?.data?.user?.id, response?.data?.isVerified);
        setUserRoles({
          isAdmin: response?.data?.isAdmin,
          isEmployee: response?.data?.isEmployee,
          isSuperAdmin: response?.data?.isSuperAdmin,
        });
        if (response?.data?.isVerified) {
          if (response?.data?.isSuperAdmin) {
            setBreadcrumbsItems([{ id: 1, url: "/", name: "Statistics" }]);
            navigate("/");
          } else if (response?.data?.isAdmin) {
            setBreadcrumbsItems([{ id: 1, url: "/", name: "Statistics" }]);
            navigate("/");
          } else if (response?.data?.isEmployee) {
            setBreadcrumbsItems([
              { id: 1, url: "/my-package/pending", name: "My Packages" },
            ]);
            navigate("/my-package/pending");
          }
        } else {
          navigate("/login-verification");
        }

        resetForm();
      }
      setSubmitting(false);
    },
  });

  return (
    <div
      className="main-container"
      style={{ backgroundImage: `url(${bgImg})` }}
    >
      <div className="overlay"></div>
      <div className="card-container">
        <div className="white-container">
          {/* <div className="heading-container"> </div> */}
          <div className="image-container d-flex justify-content-center p-3">
            <img
              style={{ height: "auto", width: "100%" }}
              src={BrandLogo}
              alt="logo"
              className="brandLogo"
            />
          </div>
          <Form onSubmit={formik.handleSubmit} className="form-container">
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik.touched.email && formik.errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.email}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formPassword">
              <Form.Label>Password</Form.Label>
              <div className="password-wrapper">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.password && formik.errors.password}
                />
                <span
                  className="password-toggle-icon"
                  onClick={handleTogglePasswordVisibility}
                >
                  {showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                </span>
              </div>
              <Form.Control.Feedback type="invalid">
                {formik.errors.password}
              </Form.Control.Feedback>
            </Form.Group>

            <div className="d-flex justify-content-end">
              <Button
                variant="link"
                className="text-warning"
                onClick={() => navigate("/forgot-password")}
              >
                Forgot Password?
              </Button>
            </div>

            <Button
              type="submit"
              variant="primary"
              className="w-100"
              disabled={formik.isSubmitting}
            >
              {formik.isSubmitting ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Sign In"
              )}
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default SignInForm;
