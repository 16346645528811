import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { fetchInspDetailData } from "../../redux/slice/InspectionSlice/InspectionSlice";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/AxiosInstance";
import CompanyModal from "./CompanyModal";
import HistoryModal from "./HistoryModal";
import { BreadcrumbsContext } from "../../context/BcrumbsContextProvider";
import CustomGrid from "../../components/common/CustomGrid/CustomGrid";
import Loader from "../Loader/Loader";
import moment from "moment";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import { WqcForm } from "../Prints/WQC_form";
import { ClForm } from "../Prints/CL_form";
import { FfcForm } from "../Prints/FFC_form";
import { EbrForm } from "../Prints/EBR_form";
import { PtForm } from "../Prints/PT_form";
import { IcForm } from "../Prints/IC_form";
import { VcForm } from "../Prints/VC_form";
import { WscForm } from "../Prints/WSC_form";
import { IoMdArrowBack } from "react-icons/io";
function InspectionView() {
  const dispatch = useDispatch();
  const { InspectionDetail, status } = useSelector(
    (state) => state?.Inspections
  );

  const { setBreadcrumbsItems } = useContext(BreadcrumbsContext);
  const [subHistory, setSubHistory] = useState([]);
  const navigate = useNavigate();
  const [insData, setInsData] = useState({});
  const [filterData, setFilterData] = useState([]);
  const location = useLocation();
  const data = location.state?.data;
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [filteredEquipment, setFilteredEquipment] = useState([]);
  function HandleHistory(data) {
    setSubHistory(data);
    setShowModal2(true);
  }
  const handleBreadcrumbs = () => {
    setBreadcrumbsItems([
      {
        id: 1,
        url: "/package/pending",
        name: "Packages",
      },
      {
        id: 2,
        url: "/package/package-view",
        name: "PackageView",
      },
    ]);
  };

  useEffect(() => {
    handleBreadcrumbs();
    return () => {
      setBreadcrumbsItems([]);
    };
  }, [setBreadcrumbsItems]);
  useEffect(() => {
    dispatch(fetchInspDetailData(data));
  }, [dispatch]);
  useEffect(() => {
    let arr = [];
    if (InspectionDetail) {
      setInsData(InspectionDetail?.data);
      InspectionDetail?.data?.stagesdata?.forEach((item, idx) => {
        return arr.push({
          idx: idx + 1,
          username1:
            item?.reviewers
              ?.map((reviewer) => reviewer.reviewerId?.username)
              .join(", ") || "N/A",
          username2:
            item?.submissionAccess
              ?.map((reviewer) => reviewer.submitById?.username)
              .join(", ") || "N/A",
          title: item?.formId?.title || "N/A",
          updateAt: item?.updatedAt
            ? moment(item.updatedAt).format("DD MMM YYYY")
            : "N/A",
          ...item,
        });
      });
    }
    setFilterData(arr);
  }, [InspectionDetail]);
  const handleView = async (subdata, formid) => {
    const fetchSubmission = async () => {
      try {
        const submissionResponse = await axiosInstance.get(
          `Forms/${formid}/Submission/${subdata}`
        );
        navigate("/package/package-view/form", {
          state: {
            data: {
              form: formid,
              id: subdata,
            },
            submission: submissionResponse.data,
          },
        });
      } catch (error) {
        console.error("Error fetching submission:", error);
      }
    };

    if (data) {
      fetchSubmission();
    } else {
      navigate("/my-package/form", {
        state: {
          data: data,
        },
      });
    }
  };
  const columns = [
    // {
    //     Header: "Sr",
    //     accessor: "idx", // Accessor is the "key" in the data
    //     width: "20px",
    // },
    {
      Header: "Stage #",
      accessor: "stageLevel",
      width: "20px",
    },

    {
      Header: "Submitters",
      accessor: "username2",
      width: "40px",
    },
    {
      Header: "Reviewers",
      accessor: "username1",
      width: "40px",
    },
    {
      Header: "Form Title",
      accessor: "title",
      width: "40px",
    },
    {
      Header: "Updated On",
      accessor: "updateAt",
      width: "40px",
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        return date.toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
      },
    },
    {
      Header: "Submission",
      accessor: "latest",
      width: "20px",

      disableSortBy: true,
      Cell: (cell) => (
        <>
          {cell?.row?.original?.latestSubmission &&
          cell?.row?.original?.latestSubmission !== null ? (
            <button
              className="btn btn-outline-secondary mx-2 "
              onClick={() => {
                handleView(
                  cell?.row?.original?.latestSubmission?.id,
                  cell?.row?.original?.formId?._id
                );
              }}
            >
              View
            </button>
          ) : (
            <span style={{ paddingLeft: "10px" }}>N/A</span>
          )}
        </>
      ),
    },
    {
      Header: "Approval Status",
      accessor: "reviewStatus",
      width: "20px",
      Cell: (cell) => (
        <span
          style={{
            padding: "0.25rem 0.75rem",
            fontSize: "0.875rem",
            fontWeight: "600",
            color: "white",
            backgroundColor:
              cell.value === "Completed" || cell.value === "Approved"
                ? "green"
                : cell.value === "Rejected"
                ? "red"
                : cell.value === "Pending"
                ? "orange"
                : "rgb(79, 195, 247)",
            borderRadius: "9999px",
          }}
        >
          {cell.value}
        </span>
      ),
    },

    {
      Header: " History",
      accessor: "history",
      width: "20px",

      disableSortBy: true,
      Cell: (cell) => (
        <>
          {cell?.row?.original?.submissionHistory &&
          cell.row.original.submissionHistory.length > 0 ? (
            <button
              className="btn btn-outline-secondary mx-2 "
              onClick={() => {
                // HandleHistory(cell?.row?.original?.submissionHistory);
                HandleHistory(cell?.row?.original);
              }}
            >
              View
            </button>
          ) : (
            <span style={{ paddingLeft: "10px" }}>N/A</span>
          )}
        </>
      ),
    },
  ];
  useEffect(() => {
    setFilteredEquipment(InspectionDetail?.data?.equipmentNumbers || []);
  }, [InspectionDetail]);
  const columns2 = [
    {
      Header: "Serial #",
      accessor: "serialNumber",
      minWidth: 100,
      width: 120,
    },
    {
      Header: "Unit #",
      accessor: "unitNumber",
      minWidth: 100,
      width: 120,
    },
    {
      Header: "Equipment #",
      accessor: "equipmentNumber",
      minWidth: 120,
      width: 140,
    },
    {
      Header: "Description",
      accessor: "description",
    },
    {
      Header: "EQUIP TYPE",
      accessor: "equipType",
      minWidth: 100,
      width: 120,
    },
    {
      Header: "P&ID",
      accessor: "pAndId",
      minWidth: 120,
    },
    {
      Header: "National BD. #",
      accessor: "nationalBdNumber",
      minWidth: 150,
    },
    {
      Header: "Insulated",
      accessor: "insulated",
      minWidth: 100,
      width: 120,
    },
    {
      Header: "Design Pressure",
      accessor: "designPressure",
      minWidth: 150,
    },
    {
      Header: "Design Temp.",
      accessor: "designTemp",
      minWidth: 150,
    },
    {
      Header: "OPER. Pressure",
      accessor: "operPressure",
      minWidth: 150,
    },
    {
      Header: "OPER. Temp.",
      accessor: "operTemp",
      minWidth: 120,
    },
    {
      Header: "Year Built",
      accessor: "yearBuilt",
      minWidth: 100,
    },
    {
      Header: "WBS Code",
      accessor: "wbsCode",
      minWidth: 100,
    },
    {
      Header: "WSP #",
      accessor: "wspNumber",
      minWidth: 100,
    },
    {
      Header: "PWHT",
      accessor: "pwht",
      minWidth: 100,
    },
  ];
  const getFormComponent = (formName) => {
    switch (formName) {
      case "cleanlinessForm":
        return ClForm;
      case "finFanClosureForm":
        return FfcForm;
      case "wqcf":
        return WqcForm;
      case "bndlRls":
        return EbrForm;
      case "hydroTs":
        return PtForm;
      case "hydroSs":
        return PtForm;
      case "internalClosureForm":
        return IcForm;
      case "vesselClosureForm":
        return VcForm;
      case "wscf":
        return WscForm;
      default:
        console.warn(`No component found for form: ${formName}`);
        return null;
    }
  };

  const generatePdfBlob = async (
    FormComponent,
    formData,
    text,
    reviewersData
  ) => {
    try {
      console.log(reviewersData, "sdksjkj");

      const result = reviewersData?.reduce((acc, review) => {
        if (review.status === "Approved") {
          // Check if status is "Approved"
          const roleName = review?.role?.roleName
            .toLowerCase()
            .replace(/\s+/g, "");

          acc[
            roleName == "executive/jobsupervisor"
              ? "executivejobsupervisor"
              : roleName
          ] = {
            username: review?.reviewerId?.username,
            statusUpdatedAt: review?.statusUpdatedAt,
            signature: review?.reviewerId?.signature, // Assuming "signature" is equivalent to comments
          };
        }
        return acc;
      }, {});
      console.log(result, "sdksjkj");
      return await pdf(
        <FormComponent data={formData} rolesData={result} text={text} />
      ).toBlob();
    } catch (error) {
      console.error("Error generating PDF blob:", error);
      return null;
    }
  };

  const addFormToZip = async (zip, form, formData, reviewersData) => {
    const formName = form.formId?.name;
    const fileName = `${form?.formId?.title}.pdf`;

    let text;
    if (formName === "hydroTs") {
      text = "TS";
    } else if (formName === "hydroSs") {
      text = "SS";
    }

    if (formData) {
      const pdfBlob = await generatePdfBlob(
        getFormComponent(formName),
        formData,
        text ? text : undefined,
        reviewersData
      );
      if (pdfBlob) {
        zip.file(fileName, pdfBlob);
      }
    } else {
      console.error(`Form ${formName} is missing submission data`);
    }
  };

  const handleDownloadZip = async () => {
    const zip = new JSZip();
    const forms = insData?.stagesdata || [];

    for (const item of forms) {
      await addFormToZip(
        zip,
        item,
        item?.latestSubmission?.data || {},
        item?.reviewers || null
      );
    }

    try {
      const zipFile = await zip.generateAsync({ type: "blob" });
      saveAs(zipFile, `${insData?.inspectionNumber}.zip`);
    } catch (error) {
      console.error("Error generating ZIP file:", error);
    }
  };

  return (
    <div className="h-[450px] overflow-y-auto overflow-x-hidden  scrollbar-custom ">
      <div className="w-full flex custom-layout " style={{ gap: "30px" }}>
        {/* Left column */}
        <div className="left-column ">
          <div className="d-flex ">
            <button
              onClick={() => navigate(-1)}
              style={{
                color: "black",
                cursor: "pointer",
                marginRight: "16px",
              }}
            >
              <IoMdArrowBack fontSize="24px" />
            </button>
            <p
              style={{ color: "#1c4e80", fontWeight: 700, fontSize: "larger" }}
            >
              {" "}
              Package Detail
            </p>
          </div>
          <div className="card bg-light">
            <ul
              className="list-group list-group-flush"
              style={{ marginLeft: "5px", marginRight: "10px" }}
            >
              <li className="list-group-item bg-light">
                <div className="viewtextd " style={{ gap: "113px" }}>
                  <p style={{ fontWeight: 600 }}>Package #</p>
                  <p>{insData?.inspectionNumber}</p>
                </div>
              </li>
              <li className="list-group-item bg-light">
                <div className="viewtextd " style={{ gap: "153px" }}>
                  <p style={{ fontWeight: 600 }}>Title</p>
                  <p>{insData?.title}</p>
                </div>
              </li>
              <li className=" list-group-item bg-light ">
                <div className="viewtextd " style={{ gap: "70px" }}>
                  <p style={{ fontWeight: 600 }}>Company Name</p>
                  <p
                    onClick={() => setShowModal(true)}
                    style={{
                      borderRadius: "8888px",
                      padding: "0.15rem 0.75rem",
                      fontSize: "0.875rem",
                      fontWeight: "500",
                      textAlign: "center",
                      height: " 30px",
                      backgroundColor: "lightgrey",
                      cursor: "pointer",
                    }}
                  >
                    {insData?.companyName}
                  </p>
                </div>
              </li>
              <li className="list-group-item bg-light">
                <div className="viewtextd " style={{ gap: "113px" }}>
                  <p style={{ fontWeight: 600 }}>Site Name</p>
                  <p>{insData?.jobSiteName}</p>
                </div>
              </li>
              <li className="list-group-item bg-light">
                <div className="viewtextd " style={{ gap: "75px" }}>
                  <p style={{ fontWeight: 600 }}>Package Status</p>
                  <p
                    style={{
                      color:
                        insData?.inspectionstatus === "Pending"
                          ? "orange"
                          : insData?.inspectionstatus === "InProgress"
                          ? "rgb(79, 195, 247)"
                          : insData?.inspectionstatus === "Approved" ||
                            insData?.inspectionstatus === "Completed"
                          ? "green"
                          : insData?.inspectionstatus === "Rejected"
                          ? "red"
                          : "inherit",
                      border:
                        insData?.inspectionstatus === "Pending"
                          ? "2px solid orange "
                          : insData?.inspectionstatus === "InProgress"
                          ? "2px solid rgb(79, 195, 247) "
                          : insData?.inspectionstatus === "Approved" ||
                            insData?.inspectionstatus === "Completed"
                          ? "2px solid green"
                          : insData?.inspectionstatus === "Rejected"
                          ? "2px solid red"
                          : "none",
                      borderRadius: "8888px",
                      padding: "0.15rem 0.75rem",
                      fontSize: "0.875rem",
                      fontWeight: "600",
                      width: "auto",
                      textAlign: "center",
                      height: " 32px",
                    }}
                  >
                    {insData?.inspectionstatus}
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>

        {/* Right column */}
        <div className="right-column ">
          <div>
            <p className="title">Stages</p>
            <div className="row">
              <div className="col-6 col-sm-4 mb-3 text-center">
                <div className="card bg-light stage-card">
                  <div className="card-body">
                    <p className="card-title font-weight-bold">Total Stages</p>
                    <p className="card-value text-primary font-weight-bold">
                      {insData?.totalStagesCount}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-5 col-sm-4 mb-3 text-center">
                <div className="card bg-light stage-card">
                  <div className="card-body">
                    <p className="card-title font-weight-bold">
                      Pending Stages
                    </p>
                    <p className="card-value text-warning font-weight-bold">
                      {insData?.pendingStagesCount}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-6 col-sm-4 mb-3 text-center">
                <div className="card bg-light stage-card">
                  <div className="card-body">
                    <p className="card-title font-weight-bold">
                      Completed Stages
                    </p>
                    <p className="card-value text-success font-weight-bold">
                      {insData?.completedStagesCount}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginRight: "20px" }}>
            <p style={{ color: "#1c4e80", fontWeight: 600 }}>Stages Status</p>
            <div className="card bg-light">
              <ul
                className="list-group list-group-flush "
                style={{ marginLeft: "5px", marginRight: "10px" }}
              >
                {insData && (
                  <>
                    <li className="list-group-item bg-light">
                      <div className="viewtext">
                        <div className="stage-header">
                          <p style={{ fontWeight: 700 }}>Current Stage</p>
                        </div>
                        <div className="stage-info">
                          <p style={{ fontWeight: 600 }}>Stage No.</p>
                          <p>{insData.currentStage?.stageNo || "N/A"}</p>
                        </div>
                        <div className="stage-status">
                          <p style={{ fontWeight: 600 }}>Status</p>
                          {insData.currentStage?.status ? (
                            <p
                              style={{
                                color:
                                  insData.currentStage?.status === "Pending"
                                    ? "orange"
                                    : insData.currentStage?.status ===
                                        "Approved" ||
                                      insData.currentStage?.status ===
                                        "Completed"
                                    ? "green"
                                    : insData.currentStage?.status ===
                                      "Rejected"
                                    ? "red"
                                    : "inherit",
                                border:
                                  insData.currentStage?.status === "Pending"
                                    ? "2px solid orange "
                                    : insData.currentStage?.status ===
                                        "Approved" ||
                                      insData.currentStage?.status ===
                                        "Completed"
                                    ? "2px solid green"
                                    : insData.currentStage?.status ===
                                      "Rejected"
                                    ? "2px solid red"
                                    : "none",
                                borderRadius: "9999px",
                                padding: "0.25rem 0.75rem",
                                fontSize: "0.875rem",
                                fontWeight: "600",
                                width: "95px",
                                textAlign: "center",
                              }}
                            >
                              {insData.currentStage?.status || "N/A"}
                            </p>
                          ) : (
                            <p>N/A</p>
                          )}
                        </div>
                      </div>
                    </li>

                    {insData.previousStage && (
                      <li className="list-group-item bg-light">
                        <div className="viewtext">
                          <div className="stage-header">
                            <p style={{ fontWeight: 700 }}>Previous Stage</p>
                          </div>
                          <div className="stage-info">
                            <p style={{ fontWeight: 600 }}>Stage No.</p>
                            <p>{insData.previousStage?.stageNo}</p>
                          </div>
                          <div className="stage-status">
                            <p style={{ fontWeight: 600 }}>Status</p>
                            <p
                              style={{
                                color:
                                  insData.previousStage?.status === "Pending"
                                    ? "orange"
                                    : insData.previousStage?.status ===
                                        "Approved" ||
                                      insData.previousStage?.status ===
                                        "Completed"
                                    ? "green"
                                    : insData.previousStage?.status ===
                                      "Rejected"
                                    ? "red"
                                    : "inherit",
                                border:
                                  insData.previousStage?.status === "Pending"
                                    ? "2px solid orange "
                                    : insData.previousStage?.status ===
                                        "Approved" ||
                                      insData.previousStage?.status ===
                                        "Completed"
                                    ? "2px solid green"
                                    : insData.previousStage?.status ===
                                      "Rejected"
                                    ? "2px solid red"
                                    : "none",
                                borderRadius: "9999px",
                                padding: "0.25rem 0.75rem",
                                fontSize: "0.875rem",
                                fontWeight: "600",
                                width: "95px",
                                textAlign: "center",
                              }}
                            >
                              {insData.previousStage?.status}
                            </p>
                          </div>
                        </div>
                      </li>
                    )}

                    {insData.nextStage && (
                      <li className="list-group-item bg-light">
                        <div className="viewtext">
                          <div className="stage-header">
                            <p style={{ fontWeight: 700 }}>Next Stage</p>
                          </div>
                          <div className="stage-info">
                            <p style={{ fontWeight: 600 }}>Stage No.</p>
                            <p>{insData.nextStage?.stageNo}</p>
                          </div>
                          <div className="stage-status">
                            <p style={{ fontWeight: 600 }}>Status</p>
                            <p
                              style={{
                                color:
                                  insData.nextStage?.status === "Pending"
                                    ? "orange"
                                    : insData.nextStage?.status ===
                                        "Approved" ||
                                      insData.nextStage?.status === "Completed"
                                    ? "green"
                                    : insData.nextStage?.status === "Rejected"
                                    ? "red"
                                    : "inherit",
                                border:
                                  insData.nextStage?.status === "Pending"
                                    ? "2px solid orange"
                                    : insData.nextStage?.status ===
                                        "Approved" ||
                                      insData.nextStage?.status === "Completed"
                                    ? "2px solid green"
                                    : insData.nextStage?.status === "Rejected"
                                    ? "2px solid red"
                                    : "none",
                                borderRadius: "9999px",
                                padding: "0.25rem 0.75rem",
                                fontSize: "0.875rem",
                                fontWeight: "600",
                                width: "95px",
                                textAlign: "center",
                              }}
                            >
                              {insData.nextStage?.status}
                            </p>
                          </div>
                        </div>
                      </li>
                    )}
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-end w-60 mr-12">
        <button
          onClick={handleDownloadZip}
          className="btn btn-outline-primary m-2"
        >
          Download ZIP
        </button>
      </div>

      <div>
        <p style={{ color: "#1c4e80", fontWeight: 600, marginTop: "5px" }}>
          Stages Detail
        </p>
        <CustomGrid columns={columns} data={filterData} />
      </div>
      {filteredEquipment?.length > 0 && (
        <div
          style={{
            paddingBottom: "35px",
          }}
        >
          <p
            style={{
              color: "#1c4e80",
              fontWeight: 600,
              marginTop: "5px",
            }}
          >
            Euipments Detail
          </p>
          <CustomGrid columns={columns2} data={filteredEquipment} />
        </div>
      )}
      {status === "loading" && (
        <div className="text-center">
          <Loader />
        </div>
      )}

      <CompanyModal
        open={showModal}
        onClose={() => setShowModal(false)}
        state={insData?.companyData}
      />
      <HistoryModal
        open={showModal2}
        onClose={() => setShowModal2(false)}
        state={subHistory}
      />
    </div>
  );
}
export default InspectionView;
