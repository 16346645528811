import React, { useContext, useEffect, useRef } from "react";
import { FormioProvider } from "../../context/FormioContext";
import { Form } from "../../OurForms/Form";
import { useLocation, useNavigate } from "react-router-dom";
import { BreadcrumbsContext } from "../../context/BcrumbsContextProvider";
import { IoMdArrowBack } from "react-icons/io";
function HistoryForm() {
  const formInstance = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state?.data;
  const submission = location.state?.submission;
  const { setBreadcrumbsItems } = useContext(BreadcrumbsContext);
  const handleBreadcrumbs = () => {
    setBreadcrumbsItems([
      {
        id: 1,
        url: "/package/pending",
        name: "Packages",
      },
      {
        id: 2,
        url: -1,
        name: "PackageView",
      },
      {
        id: 3,
        url: "/package/package-view/form",
        name: "Form",
      },
    ]);
  };

  useEffect(() => {
    handleBreadcrumbs();
    return () => {
      setBreadcrumbsItems([]);
    };
  }, [setBreadcrumbsItems]);
  return (
    <div style={{ height: "100vh", overflowY: "scroll" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <button
          onClick={() => navigate(-1)}
          style={{
            color: "black",
            cursor: "pointer",
            marginRight: "16px",
            marginBottom: "7px",
          }}
        >
          <IoMdArrowBack fontSize="24px" />
        </button>
        {/* <h1 style={{ fontSize: "24px", fontWeight: "bold" }}>{data?.form?.title}</h1> */}
      </div>

      <p>Form ID: {data?.form}</p>
      <FormioProvider baseUrl={process.env.REACT_APP_API_URL_LOCAL}>
        {submission ? (
          <Form
            formInstance={formInstance}
            src={`Forms/${data?.form}`}
            submission={submission}
            inspectionStageId={data.currentStage}
            submissionId={data?.submissionId}
            // options={{
            //   disabled: {
            //     submit: true,
            //   },
            // }}
          />
        ) : (
          <Form
            formInstance={formInstance}
            src={`Forms/${data?.form}`}
            submission={submission}
            inspectionStageId={data.currentStage}
            submissionId={data?.submissionId}
            // options={{
            //   disabled: {
            //     submit: true,
            //   },
            // }}
          />
        )}
      </FormioProvider>
    </div>
  );
}

export default HistoryForm;
