import { configureStore } from "@reduxjs/toolkit";
import DashboardSlice from "./slice/DashboardSlice/DashboardSlice";
import RolesSlice from "./slice/RolesSlice/RolesSlice";
import UsersSlice from "./slice/UsersSlice/UsersSlice";
import ContactSlice from "./slice/ContactsSlice/ContactSlice";
import PdfSlice from "./slice/PdfSlice/PdfSlice";
import TemplatesSlice from "./slice/TemplatesSlice/TemplatesSlice";
import CompanySlice from "./slice/CompanySlice/CompanySlice";
import SitesSlice from "./slice/SitesSlice/SitesSlice";
import InspectionSlice from "./slice/InspectionSlice/InspectionSlice";

const store = configureStore({
  reducer: {
    Dashboard: DashboardSlice,
    Roles: RolesSlice,
    Users: UsersSlice,
    Contacts: ContactSlice,
    Companies: CompanySlice,
    Sites: SitesSlice,
    Inspections: InspectionSlice,
    pdf: PdfSlice,
    Templates: TemplatesSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
