import React from "react";
import {
  Page,
  Document,
  StyleSheet,
  Font,
  View,
  Text,
  Image,
} from "@react-pdf/renderer";
import { PdfHeader } from "./PdfHeader";
import { CommentFooter } from "./CommentFooter";
import PropTypes from "prop-types";
import moment from "moment";
// Create styles
Font.register({
  family: "Helvetica-Bold",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/helvetica/v7/oU5HfY7Evdle2mP5Xai7nPesZW2xOQ-xsNqO47m55DA.ttf",
      fontWeight: "bold",
    },
  ],
});
const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
  },
  section: {
    margin: 10,
    padding: 10,
    border: "1px solid black",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderLeft: 1,
    borderRight: 0,
    borderBottom: 0,
    borderTop: 0,
    borderColor: "#000",
    marginBottom: 10,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColHeader: {
    borderBottom: 0,
    borderTop: 0,
    borderLeft: 0,
    borderRightWidth: 1,
    width: "25%",
    borderStyle: "solid",
    backgroundColor: "#E4E4E4",
    textAlign: "center",
    paddingTop: 6,
    fontFamily: "Helvetica-Bold",
  },
  tableCol: {
    height: 50,
    borderLeft: 0,
    borderRight: 1,
    borderBottom: 1,
    borderTop: 0,
    width: "25%",
    borderStyle: "solid",
    paddingLeft: 5,
    paddingTop: 6,
    textAlign: "center",
    position: "relative",
  },
  tableColText: {
    position: "absolute", // Absolutely position the text
    bottom: 0, // Align the text to the bottom
    left: 0,
    right: 0,
    padding: 1,
  },
  tableColHeader1: {
    borderBottom: 1,
    borderTop: 1,
    borderLeft: 0,
    borderRightWidth: 1,
    width: "25%",
    borderStyle: "solid",
    backgroundColor: "#fcfafa",
    textAlign: "center",
    paddingLeft: 1,
    paddingTop: 6,
  },
  title: {
    textAlign: "left",
    fontFamily: "Helvetica-Bold",
  },
  image: {
    width: "100%",
    height: "100%",
  },
});

// Create Document Component
export const EbrForm = ({ data, rolesData }) => {
  console.log("data", data);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.table}>
          <PdfHeader data={data} title={"Exchanger Bundle Release Form"} />
          <View style={styles.tableRow}>
            <Text style={{ ...styles.tableColHeader, width: "100%" }}>
              Release Verification
            </Text>
          </View>
          {/* Row 1 */}
          {/* Row 2 */}
          <View style={styles.tableRow}>
            <Text
              style={{
                ...styles.tableColHeader1,
                ...styles.title,
                paddingLeft: 1,
              }}
            >
              Hold Points
            </Text>
            <Text style={styles.tableColHeader1}>QA Name</Text>
            <Text style={styles.tableColHeader1}>QA Signature</Text>
            <Text style={styles.tableColHeader1}>Date</Text>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText, ...styles.title }}>
                Shell Internal Inspected
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText }}>
                {rolesData?.qainspector?.username}
              </Text>
            </View>
            <View style={styles.tableCol}>
              {rolesData?.qainspector?.signature && (
                <Image
                  style={{ ...styles.image }}
                  src={rolesData?.qainspector?.signature}
                />
              )}
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText }}>
                {rolesData?.qainspector?.statusUpdatedAt
                  ? moment(rolesData?.qainspector?.statusUpdatedAt).format(
                      "MMM Do YYYY hh:mm:ss"
                    )
                  : ""}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText, ...styles.title }}>
                Bundle Inspected
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText }}>
                {rolesData?.qainspector?.username}
              </Text>
            </View>
            <View style={styles.tableCol}>
              {rolesData?.qainspector?.signature && (
                <Image
                  style={{ ...styles.image }}
                  src={rolesData?.qainspector?.signature}
                />
              )}
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText }}>
                {rolesData?.qainspector?.statusUpdatedAt
                  ? moment(rolesData?.qainspector?.statusUpdatedAt).format(
                      "MMM Do YYYY hh:mm:ss"
                    )
                  : ""}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText, ...styles.title }}>
                Bundle/Exchanger Mate Flanges Inspected
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText }}>
                {rolesData?.qainspector?.username}
              </Text>
            </View>
            <View style={styles.tableCol}>
              {rolesData?.qainspector?.signature && (
                <Image
                  style={{ ...styles.image }}
                  src={rolesData?.qainspector?.signature}
                />
              )}
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText }}>
                {rolesData?.qainspector?.statusUpdatedAt
                  ? moment(rolesData?.qainspector?.statusUpdatedAt).format(
                      "MMM Do YYYY hh:mm:ss"
                    )
                  : ""}
              </Text>
            </View>
          </View>{" "}
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText, ...styles.title }}>
                Shell Cover Inspected
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText }}>
                {rolesData?.qainspector?.username}
              </Text>
            </View>
            <View style={styles.tableCol}>
              {rolesData?.qainspector?.signature && (
                <Image
                  style={{ ...styles.image }}
                  src={rolesData?.qainspector?.signature}
                />
              )}
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText }}>
                {rolesData?.qainspector?.statusUpdatedAt
                  ? moment(rolesData?.qainspector?.statusUpdatedAt).format(
                      "MMM Do YYYY hh:mm:ss"
                    )
                  : ""}
              </Text>
            </View>
          </View>{" "}
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText, ...styles.title }}>
                Channel Inspected
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText }}>
                {rolesData?.qainspector?.username}
              </Text>
            </View>
            <View style={styles.tableCol}>
              {rolesData?.qainspector?.signature && (
                <Image
                  style={{ ...styles.image }}
                  src={rolesData?.qainspector?.signature}
                />
              )}
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText }}>
                {rolesData?.qainspector?.statusUpdatedAt
                  ? moment(rolesData?.qainspector?.statusUpdatedAt).format(
                      "MMM Do YYYY hh:mm:ss"
                    )
                  : ""}
              </Text>
            </View>
          </View>{" "}
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText, ...styles.title }}>
                Channel Cover Inspected
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText }}>
                {rolesData?.qainspector?.username}
              </Text>
            </View>
            <View style={styles.tableCol}>
              {rolesData?.qainspector?.signature && (
                <Image
                  style={{ ...styles.image }}
                  src={rolesData?.qainspector?.signature}
                />
              )}
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText }}>
                {rolesData?.qainspector?.statusUpdatedAt
                  ? moment(rolesData?.qainspector?.statusUpdatedAt).format(
                      "MMM Do YYYY hh:mm:ss"
                    )
                  : ""}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText, ...styles.title }}>
                Floating Head Inspected
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText }}>
                {rolesData?.qainspector?.username}
              </Text>
            </View>
            <View style={styles.tableCol}>
              {rolesData?.qainspector?.signature && (
                <Image
                  style={{ ...styles.image }}
                  src={rolesData?.qainspector?.signature}
                />
              )}
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText }}>
                {rolesData?.qainspector?.statusUpdatedAt
                  ? moment(rolesData?.qainspector?.statusUpdatedAt).format(
                      "MMM Do YYYY hh:mm:ss"
                    )
                  : ""}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <Text
              style={{
                ...styles.tableColHeader,
                width: "100%",
                borderBottom: 1,
              }}
            >
              Required Release Signatures
            </Text>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText, ...styles.title }}>
                Bundle Release QC Inspector
              </Text>
            </View>
            <View style={styles.tableCol}>
              {rolesData?.qcinspector?.signature && (
                <Image
                  style={{ ...styles.image }}
                  src={rolesData?.qcinspector?.signature}
                />
              )}
            </View>
            <View style={styles.tableCol}>
              {rolesData?.qcinspector?.signature && (
                <Image
                  style={{ ...styles.image }}
                  src={rolesData?.qcinspector?.signature}
                />
              )}
            </View>
            <View style={styles.tableCol}>
              {rolesData?.qcinspector?.signature && (
                <Image
                  style={{ ...styles.image }}
                  src={rolesData?.qcinspector?.signature}
                />
              )}
            </View>
          </View>{" "}
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText, ...styles.title }}>
                Bundle Release QA Inspector
              </Text>
            </View>
            <View style={styles.tableCol}>
              {rolesData?.qainspector?.signature && (
                <Image
                  style={{ ...styles.image }}
                  src={rolesData?.qainspector?.signature}
                />
              )}
            </View>
            <View style={styles.tableCol}>
              {rolesData?.qainspector?.signature && (
                <Image
                  style={{ ...styles.image }}
                  src={rolesData?.qainspector?.signature}
                />
              )}
            </View>
            <View style={styles.tableCol}>
              {rolesData?.qainspector?.signature && (
                <Image
                  style={{ ...styles.image }}
                  src={rolesData?.qainspector?.signature}
                />
              )}
            </View>
          </View>
          <CommentFooter data={data} />
        </View>
      </Page>
    </Document>
  );
};

EbrForm.propTypes = {
  data: PropTypes.object,
  rolesData: PropTypes.any,
};
