import React from "react";
import TemplatesTable from "../../components/Templates/TemplatesTable";

function CreatedTemplates() {
  return (
    <div>
      <TemplatesTable />
    </div>
  );
}

export default CreatedTemplates;
