import React from "react";
import {
  Page,
  Document,
  StyleSheet,
  Font,
  View,
  Text,
  Image,
} from "@react-pdf/renderer";

import { WscfHeader } from "./WSCFHeader";
import { CommentFooter } from "./CommentFooter";
import PropTypes from "prop-types";
import moment from "moment";

// Create styles
Font.register({
  family: "Helvetica-Bold",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/helvetica/v7/oU5HfY7Evdle2mP5Xai7nPesZW2xOQ-xsNqO47m55DA.ttf",
      fontWeight: "bold",
    },
  ],
});
const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
  },
  section: {
    margin: 10,
    padding: 10,
    border: "1px solid black",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderLeft: 1,
    borderRight: 0,
    borderBottom: 0,
    borderTop: 0,
    borderColor: "#000",
    marginBottom: 10,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColHeader: {
    borderBottom: 0,
    borderTop: 0,
    borderLeft: 0,
    borderRightWidth: 1,
    width: "25%",
    borderStyle: "solid",
    backgroundColor: "#E4E4E4",
    textAlign: "center",
    paddingTop: 6,
    fontFamily: "Helvetica-Bold",
  },
  tableCol: {
    height: 50,
    borderLeft: 0,
    borderRight: 1,
    borderBottom: 1,
    borderTop: 0,
    width: "25%",
    borderStyle: "solid",
    paddingLeft: 5,
    paddingTop: 6,
    textAlign: "center",
    position: "relative",
  },
  tableColText: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    padding: 1,
  },
  tableColHeader1: {
    borderBottom: 1,
    borderTop: 1,
    borderLeft: 0,
    borderRightWidth: 1,
    width: "25%",
    borderStyle: "solid",
    backgroundColor: "#E4E4E4",
    textAlign: "center",
    paddingTop: 6,
    paddingLeft: 1,
  },
  title: {
    textAlign: "left",
    fontFamily: "Helvetica-Bold",
  },
  image: {
    width: "100%",
    height: "100%",
  },
});

// Create Document Component
export const WscForm = ({ data }) => {
  console.log("data", data);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.table}>
          <WscfHeader data={data} title={"Work Scope Completion Form"} />
          <View style={styles.tableRow}>
            <Text style={{ ...styles.tableColHeader, width: "100%" }}>
              PACKAGE SIGN OFF
            </Text>
          </View>
          <View style={styles.tableRow}>
            <View style={{ ...styles.tableColHeader1, ...styles.title }}>
              <Text style={{ ...styles.tableColText }}>Hold Points</Text>
            </View>
            <View style={{ ...styles.tableCol, height: 30, borderTop: 1 }}>
              <Text style={{ ...styles.tableColText }}>QC Signature</Text>
            </View>
            <View style={{ ...styles.tableCol, height: 30, borderTop: 1 }}>
              <Text style={{ ...styles.tableColText }}>QA Signature</Text>
            </View>
            <View style={{ ...styles.tableCol, height: 30, borderTop: 1 }}>
              <Text style={{ ...styles.tableColText }}>DATE</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText, ...styles.title }}>
                Package Complete
              </Text>
            </View>
            <View style={styles.tableCol}>
              {data?.qcInspector && (
                <Image style={{ ...styles.image }} src={data?.qcInspector} />
              )}
            </View>
            <View style={styles.tableCol}>
              {data?.qaInspector && (
                <Image style={{ ...styles.image }} src={data?.qaInspector} />
              )}
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText }}>
                {data?.dateTime
                  ? moment(data?.dateTime).format("MMM Do YYYY")
                  : ""}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText, ...styles.title }}>
                RR Complete
              </Text>
            </View>
            <View style={styles.tableCol}>
              {data?.qcInspector1 && (
                <Image style={{ ...styles.image }} src={data?.qcInspector1} />
              )}
            </View>
            <View style={styles.tableCol}>
              {data?.qaInspector1 && (
                <Image style={{ ...styles.image }} src={data?.qaInspector1} />
              )}
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText }}>
                {data?.dateTime1
                  ? moment(data?.dateTime1).format("MMM Do YYYY")
                  : ""}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText, ...styles.title }}>
                Welding map/ Log
              </Text>
            </View>
            <View style={styles.tableCol}>
              {data?.qcInspector2 && (
                <Image style={{ ...styles.image }} src={data?.qcInspector2} />
              )}
            </View>
            <View style={styles.tableCol}>
              {data?.qaInspector2 && (
                <Image style={{ ...styles.image }} src={data?.qaInspector2} />
              )}
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText }}>
                {data?.dateTime2
                  ? moment(data?.dateTime2).format("MMM Do YYYY")
                  : ""}
              </Text>
            </View>
          </View>{" "}
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText, ...styles.title }}>
                NDE Complete
              </Text>
            </View>
            <View style={styles.tableCol}>
              {data?.qcInspector3 && (
                <Image style={{ ...styles.image }} src={data?.qcInspector3} />
              )}
            </View>
            <View style={styles.tableCol}>
              {" "}
              {data?.qaInspector3 && (
                <Image style={{ ...styles.image }} src={data?.qaInspector3} />
              )}
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText }}>
                {data?.dateTime3
                  ? moment(data?.dateTime3).format("MMM Do YYYY")
                  : ""}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText, ...styles.title }}>
                Mechanical Complete
              </Text>
            </View>
            <View style={styles.tableCol}>
              {data?.qcInspector4 && (
                <Image style={{ ...styles.image }} src={data?.qcInspector4} />
              )}
            </View>
            <View style={styles.tableCol}>
              {data?.qaInspector4 && (
                <Image style={{ ...styles.image }} src={data?.qaInspector4} />
              )}
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableColText }}>
                {data?.dateTime4
                  ? moment(data?.dateTime4).format("MMM Do YYYY")
                  : ""}
              </Text>
            </View>
          </View>
          <CommentFooter data={data} />
        </View>
      </Page>
    </Document>
  );
};
WscForm.propTypes = {
  data: PropTypes.object,
};
