import React from "react";
import {
  Page,
  Document,
  StyleSheet,
  Font,
  View,
  Text,
  Image,
} from "@react-pdf/renderer";
import { PdfHeader } from "./PdfHeader";
import { CommentFooter } from "./CommentFooter";
import moment from "moment/moment";
import PropTypes from "prop-types";
// Create styles
Font.register({
  family: "Helvetica-Bold",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/helvetica/v7/oU5HfY7Evdle2mP5Xai7nPesZW2xOQ-xsNqO47m55DA.ttf",
      fontWeight: "bold",
    },
  ],
});
const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
  },
  section: {
    margin: 10,
    padding: 10,
    border: "1px solid black",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderLeft: 1,
    borderRight: 0,
    borderBottom: 0,
    borderTop: 0,
    borderColor: "#000",
    marginBottom: 10,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColHeader: {
    borderBottom: 0,
    borderTop: 0,
    borderLeft: 0,
    borderRightWidth: 1,
    width: "25%",
    borderStyle: "solid",
    backgroundColor: "#E4E4E4",
    textAlign: "center",
    paddingTop: 6,
    fontFamily: "Helvetica-Bold",
  },
  tableCol: {
    height: 30,
    borderLeft: 0,
    borderRight: 1,
    borderBottom: 1,
    borderTop: 0,
    width: "25%",
    borderStyle: "solid",
    paddingLeft: 5,
    paddingTop: 6,
    textAlign: "center",
    position: "relative",
  },
  tableColText: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    padding: 1,
  },
  tableColHeader1: {
    borderBottom: 1,
    borderTop: 1,
    borderLeft: 0,
    borderRightWidth: 1,
    width: "25%",
    borderStyle: "solid",
    backgroundColor: "#E4E4E4",
    textAlign: "center",
    paddingTop: 6,
    paddingLeft: 1,
  },
  title: {
    fontFamily: "Helvetica-Bold",
  },
  image: {
    width: "100%",
    height: "100%",
  },
});

// Create Document Component
export const PtForm = ({ data, text, rolesData }) => {
  console.log("data", data);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.table}>
          <PdfHeader data={data} title={"Pressure Test"} />
          <View style={styles.tableRow}>
            <Text style={{ ...styles.tableColHeader, width: "100%" }}>
              Pressure Test Requirements
            </Text>
          </View>
          <View style={styles.tableRow}>
            <View
              style={{
                ...styles.tableCol,
                borderTop: 1,
                borderRight: 0.5,
              }}
            >
              <Text
                style={{
                  ...styles.tableColText,
                  ...styles.title,
                  textAlign: "left",
                }}
              >
                Additional Test Comments
              </Text>
            </View>
            <View
              style={{
                ...styles.tableCol,
                borderTop: 1,
                width: "75%",
              }}
            >
              <Text style={{ ...styles.tableColText }}>
                {data?.additionalTestComments}
              </Text>
            </View>
          </View>
          <View style={{ ...styles.tableRow, width: "100%" }}>
            <Text
              style={{
                ...styles.tableColHeader1,
                fontFamily: "Helvetica-Bold",
                width: "33.33%",
                borderTop: 0,
              }}
            >
              Required Test Pressure
            </Text>
            <Text
              style={{
                ...styles.tableColHeader1,
                fontFamily: "Helvetica-Bold",
                width: "33.33%",
                borderTop: 0,
              }}
            >
              Required Hold Time
            </Text>
            <Text
              style={{
                ...styles.tableColHeader1,
                fontFamily: "Helvetica-Bold",
                width: "33.33%",
                borderTop: 0,
              }}
            >
              Required Test Medium
            </Text>
          </View>
          <View style={{ ...styles.tableRow, width: "100%" }}>
            <View style={{ ...styles.tableCol, width: "33.33%" }}>
              <Text style={{ ...styles.tableColText }}>
                {text == "SS"
                  ? data?.requiredTestPressureSS
                  : data?.requiredTestPressureTS}
              </Text>
            </View>
            <View style={{ ...styles.tableCol, width: "33.33%" }}>
              <Text style={{ ...styles.tableColText }}>
                {text == "SS"
                  ? data?.requiredHoldTimeSS
                  : data?.requiredHoldTimeTS}
              </Text>
            </View>
            <View style={{ ...styles.tableCol, width: "33.33%" }}>
              <Text style={{ ...styles.tableColText }}>
                {text == "SS"
                  ? data?.requiredTestMediumSS
                  : data?.requiredTestMediumTS}
              </Text>
            </View>
          </View>
          <View style={{ ...styles.tableRow }}>
            <View
              style={{
                ...styles.tableCol,
                borderRight: 0,
              }}
            >
              <Text
                style={{
                  ...styles.tableColText,
                  ...styles.title,
                  top: "50%",
                }}
              >
                Authorized signature
              </Text>
            </View>
            <View
              style={{
                ...styles.tableCol,
                width: "75%",
              }}
            >
              {data?.authorizedSignature && (
                <Image
                  style={{ ...styles.image }}
                  src={data.authorizedSignature}
                />
              )}
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={{ ...styles.tableCol, width: "100%", height: 20 }}>
              <Text style={{ ...styles.tableColText, textAlign: "left" }}>
                <Text style={{ ...styles.tableColText, ...styles.title }}>
                  Note
                </Text>
                : Mechanical Completion, drawing boundaries, FBU, WMS, and NDE
                shall verified prior testing
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <Text style={{ ...styles.tableColHeader, width: "100%" }}>
              Actual Test information
            </Text>
          </View>
          <View style={styles.tableRow}>
            <View style={{ ...styles.tableCol, width: "33.33%", borderTop: 1 }}>
              <Text style={{ ...styles.tableColText, ...styles.title }}>
                Actual Test Pressure
              </Text>
            </View>
            <View style={{ ...styles.tableCol, width: "33.33%", borderTop: 1 }}>
              <Text style={{ ...styles.tableColText, ...styles.title }}>
                Actual Hold Time
              </Text>
            </View>
            <View style={{ ...styles.tableCol, width: "33.33%", borderTop: 1 }}>
              <Text style={{ ...styles.tableColText, ...styles.title }}>
                Actual Test Medium
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={{ ...styles.tableCol, width: "33.33%" }}>
              <Text style={{ ...styles.tableColText }}>
                {data?.actualTestPressure}
              </Text>
            </View>
            <View style={{ ...styles.tableCol, width: "33.33%" }}>
              <Text style={{ ...styles.tableColText }}>
                {data?.actualHoldTime}
              </Text>
            </View>
            <View style={{ ...styles.tableCol, width: "33.33%" }}>
              <Text style={{ ...styles.tableColText }}>
                {data?.actualTestMedium}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={{ ...styles.tableCol, width: "33.33%" }}>
              <Text style={{ ...styles.tableColText, ...styles.title }}>
                Start Time
              </Text>
            </View>
            <View style={{ ...styles.tableCol, width: "33.33%" }}>
              <Text style={{ ...styles.tableColText, ...styles.title }}>
                End Time
              </Text>
            </View>
            <View style={{ ...styles.tableCol, width: "33.33%" }}>
              <Text style={{ ...styles.tableColText, ...styles.title }}>
                Test Date
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={{ ...styles.tableCol, width: "33.33%" }}>
              <Text style={{ ...styles.tableColText }}>
                {data?.startTime && data?.startTime !== "00/00/0000"
                  ? moment(data?.startTime, "HH:mm").format("hh:mm A")
                  : ""}
              </Text>
            </View>
            <View style={{ ...styles.tableCol, width: "33.33%" }}>
              <Text style={{ ...styles.tableColText }}>
                {data?.endTime && data?.endTime !== "00/00/0000"
                  ? moment(data?.endTime, "HH:mm").format("hh:mm A")
                  : ""}
              </Text>
            </View>
            <View style={{ ...styles.tableCol, width: "33.33%" }}>
              <Text style={{ ...styles.tableColText }}>
                {" "}
                {data?.testDate && data?.testDate !== "00/00/0000"
                  ? moment(data?.testDate).format("MMM Do YYYY hh:mm:ss")
                  : ""}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={{ ...styles.tableCol, width: "33.33%" }}>
              <Text style={{ ...styles.tableColText, ...styles.title }}>
                Gauge (G1)
              </Text>
            </View>
            <View style={{ ...styles.tableCol, width: "33.33%" }}>
              <Text style={{ ...styles.tableColText, ...styles.title }}>
                Range
              </Text>
            </View>
            <View style={{ ...styles.tableCol, width: "33.33%" }}>
              <Text style={{ ...styles.tableColText, ...styles.title }}>
                Cal. Date
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={{ ...styles.tableCol, width: "33.33%" }}>
              <Text style={{ ...styles.tableColText }}>{data?.gaugeG1}</Text>
            </View>
            <View style={{ ...styles.tableCol, width: "33.33%" }}>
              <Text style={{ ...styles.tableColText }}>{data?.range}</Text>
            </View>
            <View style={{ ...styles.tableCol, width: "33.33%" }}>
              <Text style={{ ...styles.tableColText }}>
                {" "}
                {data?.calDate && data?.calDate !== "00/00/0000"
                  ? moment(data?.calDate).format("MMM Do YYYY hh:mm:ss")
                  : ""}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={{ ...styles.tableCol, width: "33.33%" }}>
              <Text style={{ ...styles.tableColText, ...styles.title }}>
                Gauge (G2)
              </Text>
            </View>
            <View style={{ ...styles.tableCol, width: "33.33%" }}>
              <Text style={{ ...styles.tableColText, ...styles.title }}>
                Range
              </Text>
            </View>
            <View style={{ ...styles.tableCol, width: "33.33%" }}>
              <Text style={{ ...styles.tableColText, ...styles.title }}>
                Cal. Date
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={{ ...styles.tableCol, width: "33.33%" }}>
              <Text style={{ ...styles.tableColText }}>{data?.gaugeG2}</Text>
            </View>
            <View style={{ ...styles.tableCol, width: "33.33%" }}>
              <Text style={{ ...styles.tableColText }}>{data?.range1}</Text>
            </View>
            <View style={{ ...styles.tableCol, width: "33.33%" }}>
              <Text style={{ ...styles.tableColText }}>
                {data?.calDate1 && data?.calDate1 !== "00/00/0000"
                  ? moment(data?.calDate1).format("MMM Do YYYY hh:mm:ss")
                  : ""}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <Text style={{ ...styles.tableColHeader, width: "100%" }}>
              FLANGE BOLT-UP (FBU) TAG LIST
            </Text>
          </View>{" "}
          <View style={styles.tableRow}>
            <View style={{ ...styles.tableCol, width: "33.40%", borderTop: 1 }}>
              <Text style={{ ...styles.tableColText }}>
                FLANGE BOLT-UP (FBU) TAG LIST
              </Text>
            </View>
            <View style={{ ...styles.tableCol, width: "66.60%", borderTop: 1 }}>
              <Text style={{ ...styles.tableColText }}>
                {data?.flangeBoltUpFbuTagList1}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <Text style={{ ...styles.tableColHeader, width: "100%" }}>
              FIELD WELD (FW) TAG LIST
            </Text>
          </View>
          <View style={styles.tableRow}>
            <View style={{ ...styles.tableCol, width: "33.40%", borderTop: 1 }}>
              <Text style={{ ...styles.tableColText }}>
                Test Package List Number or FW number for each weld
              </Text>
            </View>
            <View style={{ ...styles.tableCol, width: "66.60%", borderTop: 1 }}>
              <Text style={{ ...styles.tableColText }}>
                {data?.testPackageListNumberOrFwNumberForEachWeld}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <Text style={{ ...styles.tableColHeader, width: "100%" }}>
              REQUIRED SIGNATURES
            </Text>
          </View>
          <View style={styles.tableRow}>
            <View style={{ ...styles.tableCol, width: "33.33%", borderTop: 1 }}>
              <Text style={{ ...styles.tableColText, ...styles.title }}>
                QC Signature
              </Text>
            </View>
            <View style={{ ...styles.tableCol, width: "33.33%", borderTop: 1 }}>
              <Text style={{ ...styles.tableColText, ...styles.title }}>
                Name
              </Text>
            </View>
            <View style={{ ...styles.tableCol, width: "33.33%", borderTop: 1 }}>
              <Text style={{ ...styles.tableColText, ...styles.title }}>
                Date
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={{ ...styles.tableCol, height: 50, width: "33.33%" }}>
              {" "}
              {rolesData?.qcinspector?.signature && (
                <Image
                  style={{ ...styles.image }}
                  src={rolesData?.qcinspector?.signature}
                />
              )}
            </View>
            <View style={{ ...styles.tableCol, height: 50, width: "33.33%" }}>
              <Text style={{ ...styles.tableColText }}>
                {rolesData?.qcinspector?.username}{" "}
              </Text>
            </View>
            <View style={{ ...styles.tableCol, height: 50, width: "33.33%" }}>
              <Text style={{ ...styles.tableColText }}>
                {" "}
                {rolesData?.qcinspector?.statusUpdatedAt
                  ? moment(rolesData?.qcinspector?.statusUpdatedAt).format(
                      "MMM Do YYYY hh:mm:ss"
                    )
                  : ""}
              </Text>
            </View>
          </View>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={{ ...styles.tableCol, borderTop: 1, width: "33.33%" }}>
              <Text style={{ ...styles.tableColText, ...styles.title }}>
                QA Signature
              </Text>
            </View>
            <View style={{ ...styles.tableCol, borderTop: 1, width: "33.33%" }}>
              <Text style={{ ...styles.tableColText, ...styles.title }}>
                Name
              </Text>
            </View>
            <View style={{ ...styles.tableCol, borderTop: 1, width: "33.33%" }}>
              <Text style={{ ...styles.tableColText, ...styles.title }}>
                Date
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={{ ...styles.tableCol, height: 50, width: "33.33%" }}>
              {" "}
              {rolesData?.qainspector?.signature && (
                <Image
                  style={{ ...styles.image }}
                  src={rolesData?.qainspector?.signature}
                />
              )}
            </View>
            <View style={{ ...styles.tableCol, height: 50, width: "33.33%" }}>
              <Text style={{ ...styles.tableColText }}>
                {rolesData?.qainspector?.username}{" "}
              </Text>
            </View>
            <View style={{ ...styles.tableCol, height: 50, width: "33.33%" }}>
              <Text style={{ ...styles.tableColText }}>
                {" "}
                {rolesData?.qainspector?.statusUpdatedAt
                  ? moment(rolesData?.qainspector?.statusUpdatedAt).format(
                      "MMM Do YYYY hh:mm:ss"
                    )
                  : ""}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={{ ...styles.tableCol, width: "33.33%" }}>
              <Text style={{ ...styles.tableColText, ...styles.title }}>
                Operation Signature
              </Text>
            </View>
            <View style={{ ...styles.tableCol, width: "33.33%" }}>
              <Text style={{ ...styles.tableColText, ...styles.title }}>
                Name
              </Text>
            </View>
            <View style={{ ...styles.tableCol, width: "33.33%" }}>
              <Text style={{ ...styles.tableColText, ...styles.title }}>
                Date
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={{ ...styles.tableCol, height: 50, width: "33.33%" }}>
              {" "}
              {rolesData?.operationalmanager?.signature && (
                <Image
                  style={{ ...styles.image }}
                  src={rolesData?.operationalmanager?.signature}
                />
              )}
            </View>
            <View style={{ ...styles.tableCol, height: 50, width: "33.33%" }}>
              <Text style={{ ...styles.tableColText }}>
                {rolesData?.operationalmanager?.username}{" "}
              </Text>
            </View>
            <View style={{ ...styles.tableCol, height: 50, width: "33.33%" }}>
              <Text style={{ ...styles.tableColText }}>
                {" "}
                {rolesData?.operationalmanager?.statusUpdatedAt
                  ? moment(
                      rolesData?.operationalmanager?.statusUpdatedAt
                    ).format("MMM Do YYYY hh:mm:ss")
                  : ""}
              </Text>
            </View>
          </View>
          <CommentFooter data={data} />
        </View>
      </Page>
    </Document>
  );
};
PtForm.propTypes = {
  data: PropTypes.object,
  text: PropTypes.string,
  rolesData: PropTypes.any,
};
