import axios from "axios";
const handleUnauthorized = (error) => {
  if (error.response && error.response.status === 401) {
    if (sessionStorage.getItem("access_token")) {
      console.error("Unauthorized: Invalid or expired token.");
      sessionStorage.removeItem("LoginId");
      sessionStorage.removeItem("access_token", { path: "/" });
      window.location.reload();
    }
  }
  return Promise.reject(error);
};

const BaseUrl =
  process.env.REACT_APP_API_URL_TYPE === "local"
    ? process.env.REACT_APP_API_URL_LOCAL
    : process.env.REACT_APP_API_URL_TEST;

const axiosInstance = axios.create({
  baseURL: BaseUrl,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
  },
});
export const axiosInstanceForm = axios.create({
  baseURL: BaseUrl,
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
  },
});
export const axiosInstanceBusinessAuth = axios.create({
  baseURL: BaseUrl,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionStorage.getItem("businessId")}`,
  },
});

// some way of changing it
// export const setToken = (newLocale) => {
//   token = newLocale;
// };

// register a synchronous request interceptor
axiosInstance.interceptors.request.use(
  (config) => ({
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
    },
  }),
  null,
  { synchronous: true }
);
axiosInstanceForm.interceptors.request.use(
  (config) => ({
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
    },
  }),
  null,
  { synchronous: true }
);
axiosInstanceBusinessAuth.interceptors.request.use(
  (config) => ({
    ...config,
    headers: {
      ...config.headers,
    },
  }),
  null,
  { synchronous: true }
);
axiosInstance.interceptors.response.use(
  (response) => response,
  handleUnauthorized
);
axiosInstanceForm.interceptors.response.use(
  (response) => response,
  handleUnauthorized
);
axiosInstanceBusinessAuth.interceptors.response.use(
  (response) => response,
  handleUnauthorized
);

export default axiosInstance;
