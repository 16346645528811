import axiosInstance from "../../utils/AxiosInstance";

export const SaveFormApi = async (data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.post("/Forms/Create", data);

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
export const UpdateFormApi = async (data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.put(`Forms/Update/${data?._id}`, data);

    // snackBarData = {
    //   type: response?.data?.success ? "success" : "danger",
    //   message: response?.data?.msg,
    //   openToast: true,
    // };
    snackBarData = {
      type: response ? "success" : "error",
      message: response ? "Template Updated!" : "Template update failed",
      openToast: true,
    };

    return { ...response, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
export const DeleteFormApi = async (id) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.delete(`Forms/Delete/${id}`);

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
