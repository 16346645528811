import { useContext, useEffect } from "react";
import { BreadcrumbsContext } from "../../context/BcrumbsContextProvider";
import Roles from "../../components/Settings/Roles/Roles";

function Settings() {
  const {  setBreadcrumbsItems } = useContext(BreadcrumbsContext);

  const handleBreadcrumbs = (name, url) => {
    setBreadcrumbsItems([
      // {
      //   id: breadcrumbsItems.length,
      //   url: "/",
      //   name: "Dashboard",
      // },
      {
        id:  1,
        url: "/settings",
        name: "Settings",
      },
      {
        id:  2,
        url: url,
        name: name,
      },
    ]);
  };
  useEffect(() => {
    const currentPage = window.location.pathname;
    if (currentPage.includes("/settings/roles")) {
      handleBreadcrumbs("Roles", "/settings/roles");
    } else if (currentPage.includes("/settings/users")) {
      handleBreadcrumbs("Users", "/settings/users");
    } else {
      setBreadcrumbsItems([]);  
    }
  }, [location.pathname, setBreadcrumbsItems]);

  return (
    <div className="p-0">
      <div style={{ display: "flex", flexDirection: "column", gap: "40px" }}>
        <Roles handleBreadcrumbs={handleBreadcrumbs} />
     
      </div>
    </div>
  );
}

export default Settings;
