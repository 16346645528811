import React from "react";
import Chart from "react-apexcharts";
import PropTypes from "prop-types";

function LineChart({ data }) {
  const chartOptions = {
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      type: "datetime",
      categories: data.map((item) => item.date),
      labels: {
        formatter: (value) => {
          const date = new Date(value);
          return date.toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
          });
        },
      },
    },
    yaxis: {
      title: {
        // text: "Count",
      },
    },
    stroke: {
      curve: "smooth",
    },
    dataLabels: {
      enabled: false,
    },
  };

  const chartSeries = [
    {
      name: "Count",
      data: data.map((item) => item.count),
    },
  ];

  return (
    <div>
      <div
        style={{
          textAlign: "center",
          fontWeight: "bold",
          fontSize: "0.8em",
        }}
      >
        Trends Over Time
      </div>
      <div id="chart">
        <Chart
          options={chartOptions}
          series={chartSeries}
          type="line"
          height={350}
        />
      </div>
    </div>
  );
}

export default LineChart;
LineChart.propTypes = {
  data: PropTypes.array,
};
