import { React, useState, useEffect, useRef, useContext } from "react";
import { useFormik } from "formik";
import { ThreeDots } from "react-loader-spinner";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { City, Country, State } from "country-state-city";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompanyData } from "../../redux/slice/CompanySlice/CompanySlice";
import { fetchTemplatesData } from "../../redux/slice/TemplatesSlice/TemplatesSlice";
import {
  CreateCompanyApi,
  UpdateCompanyApi,
} from "../../Apis/Companies/CompaniesApis";
import PropTypes from "prop-types";
import { BreadcrumbsContext } from "../../context/BcrumbsContextProvider";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Loader from "../../components/Loader/Loader";

function CompaniesForm({ parent, onClose, setFieldValue }) {
  const companyData = useLocation();
  const { setBreadcrumbsItems } = useContext(BreadcrumbsContext);
  const handleBreadcrumbs = () => {
    setBreadcrumbsItems([
      {
        id: 1,
        url: "/companies",
        name: "Companies",
      },
      {
        id: 2,
        url: "/companies/form",
        name: "Form",
      },
    ]);
  };

  useEffect(() => {
    handleBreadcrumbs();
    return () => {
      setBreadcrumbsItems([]);
    };
  }, [setBreadcrumbsItems]);
  const [loading, setLoading] = useState(false);
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);
  const { TemplatesData, status } = useSelector((state) => state?.Templates);
  const [cities, setCities] = useState([]);
  const [image, setImage] = useState(null);
  const [update] = useState(companyData?.state?.id ? true : false);
  const inputRef = useRef(null);
  console.log("image url", image);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(fetchTemplatesData());
  }, [dispatch]);
  useEffect(() => {
    if (update) {
      console.log("companyData", companyData?.state);
      formik.setFieldValue("name", companyData.state?.companyName);
      formik.setFieldValue("email", companyData.state?.email);
      formik.setFieldValue("phone", companyData.state?.phoneNumber);
      formik.setFieldValue("state", companyData.state?.state);
      formik.setFieldValue("country", companyData.state?.country);
      formik.setFieldValue("city", companyData.state?.city);
      formik.setFieldValue("address", companyData.state?.address);
      formik.setFieldValue(
        "forms",
        companyData.state?.forms?.map((form) => form?.formId)
      );
      setImage({
        url: companyData?.state?.profileImage,
      });
    }
  }, [companyData]);
  const validationSchema = Yup.object({
    phone: Yup.string().required("Field required!"),
    email: Yup.string()
      .email("Enter a valid Email")
      .required("Field required!"),

    address: Yup.string().required("Field required!"),
    state: states.length
      ? Yup.string().required("Field required!")
      : Yup.string(),
    country: Yup.string().required("Field required!"),

    name: Yup.string().required("Field required!"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      address: "",
      email: "",
      state: "",
      city: "",
      country: "",
      phone: "",
      forms: [],
    },
    validationSchema: validationSchema,

    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      const formData = new FormData();
      formData.append("companyName", values?.name);
      formData.append("address", values?.address);
      formData.append("email", values?.email);
      formData.append("country", values?.country);
      formData.append("state", values?.state);
      formData.append("city", values?.city);
      formData.append("phoneNumber", values?.phone);
      formData.append("forms", JSON.stringify(values.forms));

      image?.file
        ? formData.append("profileImage", image?.file)
        : image === null
        ? formData.append("profileImage", "")
        : formData.append("profileImage", companyData.state?.imageName);
      console.log("this is form data", formData);
      if (companyData?.state?.id) {
        const response = await UpdateCompanyApi(
          companyData?.state?._id,
          formData
        );
        setLoading(false);
        if (response?.success) {
          dispatch(fetchCompanyData());
          resetForm();
          navigate(-1);
        } else {
          toast.error(response.data.msg);
        }
      } else {
        const response = await CreateCompanyApi(formData);
        setLoading(false);
        if (response?.success) {
          navigate("/companies");
          resetForm();
          if (parent == "dialog") {
            onClose(true);
            setFieldValue("companyId", response?.data?.companyId);
          } else {
            navigate("/companies");
          }
        } else {
          toast.error(response.data.msg);
        }
      }
    },
  });
  useEffect(() => {
    let value = Country.getAllCountries();
    value = value?.map((data) => {
      let obj = {
        label: data?.name,
        value: data?.isoCode,
      };
      return obj;
    });
    setCountries(value);
  }, [companyData]);
  useEffect(() => {
    if (formik.values.country) {
      let selectedCountry = countries.find(
        (country) => country.label === formik.values.country
      );

      let value = State.getStatesOfCountry(selectedCountry?.value);
      console.log("selectedCountry", value, selectedCountry);

      value = value?.map((data) => {
        let obj = {
          label: data?.name,
          value: data?.isoCode,
        };
        return obj;
      });
      setStates(value);
    }
  }, [formik.values.country, countries]);
  console.log("formik.values", formik.values);
  useEffect(() => {
    if (formik.values.state && states) {
      let selectedCountry = countries.find(
        (country) => country.label === formik.values.country
      );
      let selectedState = states.find(
        (state) => state.label === formik.values.state
      );
      let value = City.getCitiesOfState(
        selectedCountry?.value,
        selectedState?.value
      );
      console.log("value", selectedCountry?.value, selectedState?.value);
      value = value?.map((data) => {
        let obj = {
          label: data?.name,
          value: data?.name,
        };
        return obj;
      });
      setCities(value);
    } else {
      setCities([]);
    }
  }, [formik.values.state, formik.values.country, states]);
  const handleLogo = (e) => {
    setImage({
      url: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    });
  };

  return (
    <div style={{ overflowY: "scroll", overflowX: "hidden", height: "100vh" }}>
      <form
        noValidate
        autoComplete="off"
        onSubmit={formik.handleSubmit}
        className="form-container"
      >
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-md-2 d-flex flex-column align-items-center justify-content-start">
                {/* <div
                  className="position-relative w-100"
                  style={{ maxWidth: "140px", height: "140px" }}
                >
                  {image?.url ? (
                    <div className="d-flex justify-content-center align-items-center position-relative w-100 h-100 rounded-circle">
                      <img
                        src={image?.url}
                        alt="Image"
                        className="w-100 h-100 rounded-circle"
                      />
                      <button
                        className="btn btn-danger position-absolute top-0 end-0 p-1"
                        onClick={() => {
                          setImage(null);
                        }}
                      >
                        &times;
                      </button>
                    </div>
                  ) : (
                    <div className="w-100 h-100 d-flex justify-content-center align-items-center cursor-pointer rounded-circle border">
                      <span>Upload Logo</span>
                    </div>
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    className="position-absolute top-0 start-0 w-100 h-100 opacity-0 cursor-pointer"
                    onChange={handleLogo}
                    ref={inputRef}
                  />
                </div> */}
                <div
                  className="position-relative w-100"
                  style={{ maxWidth: "140px", height: "140px" }}
                >
                  {image?.url ? (
                    <div className="d-flex justify-content-center align-items-center position-relative w-100 h-100 rounded-circle">
                      <img
                        src={image?.url}
                        alt="Image"
                        className="w-100 h-100 rounded-circle"
                        style={{
                          width: "148px",
                          height: "148px",
                          objectFit: "contain",
                          border: "2px solid #ddd",
                          padding: "2px",
                        }}
                      />
                      <button
                        className="btn btn-danger position-absolute top-0 end-0 p-1"
                        style={{ zIndex: 2 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setImage(null);
                        }}
                      >
                        &times;
                      </button>
                    </div>
                  ) : (
                    <div className="w-100 h-100 d-flex justify-content-center align-items-center cursor-pointer rounded-circle border">
                      <span>Upload Logo</span>
                    </div>
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    className="position-absolute top-0 start-0 w-100 h-100 opacity-0 cursor-pointer"
                    style={{ zIndex: 1 }}
                    onChange={handleLogo}
                    ref={inputRef}
                  />
                </div>
              </div>
              <div className="col-12 col-md-10">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label htmlFor="name">Name</label>
                      <input
                        id="name"
                        name="name"
                        type="text"
                        className={`form-control ${
                          formik.touched.name && formik.errors.name
                            ? "is-invalid"
                            : ""
                        }`}
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <div className="invalid-feedback">
                        {formik.touched.name && formik.errors.name}
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label htmlFor="phone">Phone</label>
                      <PhoneInput
                        country={"us"}
                        disableAreaCodes={true}
                        onlyCountries={["us"]}
                        value={formik.values?.phone}
                        onChange={(value) => {
                          formik.setFieldValue("phone", value);
                        }}
                        onBlur={formik.handleBlur}
                        inputProps={{
                          name: "phone",
                          id: "phone",
                        }}
                        containerClass="react-tel-input w-100"
                        inputClass={`form-control w-100 ${
                          formik.touched.phone && formik.errors.phone
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                      <div className="invalid-feedback">
                        {formik.touched.phone && formik.errors.phone}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <input
                        id="email"
                        type="email"
                        name="email"
                        className={`form-control ${
                          formik.touched.email && formik.errors.email
                            ? "is-invalid"
                            : ""
                        }`}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <div className="invalid-feedback">
                        {formik.touched.email && formik.errors.email}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label htmlFor="forms">Form</label>
                      <Select
                        id="forms"
                        name="forms"
                        isMulti
                        options={TemplatesData?.map((item) => ({
                          value: item._id,
                          label: item.name,
                        }))}
                        value={TemplatesData?.filter((item) =>
                          formik.values.forms?.includes(item?._id)
                        )?.map((item) => ({
                          value: item._id,
                          label: item.name,
                        }))}
                        onChange={(selectedOptions) => {
                          const selectedIds = selectedOptions
                            ? selectedOptions.map((option) => option.value)
                            : [];
                          formik.setFieldValue("forms", selectedIds);
                        }}
                        classNamePrefix="react-select"
                        menuPortalTarget={document.body}
                        menuPlacement="auto"
                        menuPosition="fixed"
                        styles={{
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: 9999,
                          }),
                        }}
                      />

                      <div className="invalid-feedback">
                        {formik.touched.email && formik.errors.email}
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    {/* <h4 className="mb-3">Company Address</h4> */}
                    {/* <hr /> */}
                    <div className="form-group">
                      <label htmlFor="address">Address</label>
                      <textarea
                        id="address"
                        name="address"
                        className={`form-control ${
                          formik.touched.address && formik.errors.address
                            ? "is-invalid"
                            : ""
                        }`}
                        rows="3"
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <div className="invalid-feedback">
                        {formik.touched.address && formik.errors.address}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group">
                      <label htmlFor="country">Country</label>
                      <Select
                        required
                        id="country"
                        name="country"
                        options={countries}
                        value={countries.find(
                          (option) => option.label === formik.values?.country
                        )}
                        onBlur={formik.handleBlur}
                        onChange={(selectedOption) => {
                          formik.setFieldValue(
                            "country",
                            selectedOption?.label
                          );
                          formik.setFieldValue("city", "");
                          formik.setFieldValue("state", "");
                        }}
                        className={`react-select-container ${
                          formik.touched.country && formik.errors.country
                            ? "border border-danger rounded"
                            : ""
                        }`}
                        classNamePrefix="react-select"
                      />
                      <div className="text-sm text-danger">
                        {formik.touched.country && formik.errors.country}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group">
                      <label htmlFor="state">State</label>
                      <Select
                        id="state"
                        name="state"
                        options={states}
                        value={states.find(
                          (option) => option.label === formik.values?.state
                        )}
                        onBlur={formik.handleBlur}
                        onChange={(selectedOption) => {
                          formik.setFieldValue("state", selectedOption?.label);
                          formik.setFieldValue("city", "");
                        }}
                        className={`react-select-container ${
                          formik.touched.state && formik.errors.state
                            ? "border border-danger rounded"
                            : ""
                        }`}
                        classNamePrefix="react-select"
                      />
                      <div className="text-sm text-danger">
                        {formik.touched.state && formik.errors.state}
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-4">
                    <div className="form-group">
                      <label htmlFor="city">City</label>
                      <Select
                        id="city"
                        name="city"
                        options={cities}
                        value={cities.find(
                          (option) => option.label === formik.values?.city
                        )}
                        onBlur={formik.handleBlur}
                        onChange={(selectedOption) => {
                          formik.setFieldValue("city", selectedOption?.label);
                        }}
                        className="react-select-container "
                        classNamePrefix="react-select"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {status === "loading" && (
          <div className="text-center">
            <Loader animation="border" />
          </div>
        )}
        <div className="d-flex justify-content-end align-items-center mt-4 gap-3">
          <ThreeDots
            height="40"
            width="40"
            radius="9"
            color="#1c4e80 "
            visible={loading}
          />
          {parent == "screen" && (
            <button
              type="button"
              className=" btn-secondary"
              onClick={() => navigate(-1)}
            >
              Back
            </button>
          )}
          <button type="submit" className="btn btn-primary" disabled={loading}>
            Save
          </button>
        </div>
      </form>
    </div>
  );
}

export default CompaniesForm;
CompaniesForm.propTypes = {
  parent: PropTypes.string,
  onClose: PropTypes.func,
  screen: PropTypes.string,
  setFieldValue: PropTypes.func,
};
