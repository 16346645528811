import React from "react";
import { Text, View, StyleSheet, Font } from "@react-pdf/renderer";
import PropTypes from "prop-types";
// Create styles
Font.register({
  family: "Helvetica-Bold",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/helvetica/v7/oU5HfY7Evdle2mP5Xai7nPesZW2xOQ-xsNqO47m55DA.ttf",
      fontWeight: "bold",
    },
  ],
});
const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
  },
  section: {
    margin: 10,
    padding: 10,
    border: "1px solid black",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderLeft: 1,
    borderRight: 0,
    borderBottom: 0,
    borderTop: 0,
    borderColor: "#000",
    marginBottom: 10,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColHeader: {
    borderBottom: 0,
    borderTop: 0,
    borderLeft: 0,
    borderRightWidth: 1,
    borderStyle: "solid",
    backgroundColor: "#E4E4E4",
    textAlign: "center",
    paddingTop: 6,
    fontFamily: "Helvetica-Bold",
    width: "100%",
  },
  tableCol: {
    width: "100%",
    borderLeft: 0,
    borderRight: 1,
    borderBottom: 1,
    borderTop: 1,
    borderStyle: "solid",
    // borderWidth: 1,
    paddingLeft: 5,
    paddingTop: 6,
    // paddingBottom: 3,
    textAlign: "justify",
    height: 70,
  },
});

// Create Document Component
export const CommentFooter = ({ data }) => (
  <>
    {" "}
    <View style={styles.tableRow}>
      <Text style={styles.tableColHeader}>Comments</Text>
    </View>
    <View style={styles.tableRow}>
      <View style={styles.tableCol}>
        <Text> {data?.comments || data?.comments1} </Text>
      </View>
    </View>
  </>
);
CommentFooter.propTypes = {
  data: PropTypes.object,
};
