import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AppConfirmationDialog from "../../components/ConfirmationDialog/ConfirmationDialog";
import Loader from "../../components/Loader/Loader";
import { fetchUserInspectionsData } from "../../redux/slice/InspectionSlice/InspectionSlice";
import InspectionDetailDialog from "../../components/Inspection/InspectionDetailDialog/InspectionDetailDialog";

import axiosInstance from "../../utils/AxiosInstance";
import { toast } from "react-toastify";
import { FaRegSave } from "react-icons/fa";
import { SnackBarContext } from "../../context/SnackBarContext";
import VerifyOTPDialog from "../../components/common/VerifyOTPDialog/VerifyOTPDialog";
import { SendOTPAPi, VerifyOTPAPi } from "../../Apis/Auth/AuthApis";
import { BreadcrumbsContext } from "../../context/BcrumbsContextProvider";
import CustomGrid from "../../components/common/CustomGrid/CustomGrid";
import PropTypes from "prop-types";
import { fetchCompanyDataPopulate } from "../../redux/slice/CompanySlice/CompanySlice";

function MyInspections({ screenType }) {
  const [alldata, setalldata] = useState([]);
  const [rows, setrows] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [showCompanyDetails, setShowCompanyDetail] = useState(false);
  const [openVerifyOTPDialog, setOpenVerifyOTPDialog] = useState(false);
  const [duration, setDuration] = useState(0);
  const [otpSending, setOtpSending] = useState(false);
  const [verifyOtpFor, setVerifyOtpFor] = useState("");
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedJobsite, setSelectedJobsite] = useState(null);
  const { setsnackBarData } = useContext(SnackBarContext);
  const { CompanyDataPopulate } = useSelector((state) => state?.Companies);
  const [allJobSites, setAllJobSites] = useState([]);
  const [searchTerm, setSearchTerm] = useState(null);
  const { breadcrumbsItems, setBreadcrumbsItems } =
    useContext(BreadcrumbsContext);
  const handleBreadcrumbs = () => {
    setBreadcrumbsItems([
      // {
      //   id: breadcrumbsItems.length,
      //   url: "/",
      //   name: "Dashboard",
      // },
      {
        id: breadcrumbsItems.length,
        url: "/my-package/pending",
        name: "My Packages",
      },
      {
        id: breadcrumbsItems.length + 1,
        url: "/my-package/form",
        name: "Form",
      },
    ]);
  };

  useEffect(() => {
    let filteredJobSites;
    if (selectedCompany) {
      filteredJobSites = CompanyDataPopulate.filter(
        (item) => item.companyName === selectedCompany
      ).flatMap((company) => company.jobSites);
    } else {
      filteredJobSites = CompanyDataPopulate?.flatMap(
        (company) => company.jobSites
      );
    }

    setSelectedJobsite(null);
    setAllJobSites(filteredJobSites);
  }, [CompanyDataPopulate, selectedCompany]);

  useEffect(() => {
    return () => {
      const currentPage = window.location.pathname;
      if (!currentPage.includes("/my-package/form")) {
        setBreadcrumbsItems([]);
      }
    };
  }, [setBreadcrumbsItems]);
  const { UserInspectionData, status } = useSelector(
    (state) => state?.Inspections
  );
  const userId = sessionStorage.getItem("LoginId");
  console.log("userid", userId);
  useEffect(() => {
    if (screenType === "MyInspection") {
      dispatch(fetchUserInspectionsData("Pending"));
    } else if (screenType === "Pending") {
      dispatch(fetchUserInspectionsData("Pending"));
    } else {
      dispatch(fetchUserInspectionsData("Submitted"));
    }
    dispatch(fetchCompanyDataPopulate());
  }, [dispatch, screenType]);

  useEffect(() => {
    if (UserInspectionData?.response) {
      setalldata(UserInspectionData?.response);
    } else {
      setalldata([]);
    }
  }, [UserInspectionData]);

  React.useEffect(() => {
    let arr = [];
    if (Array.isArray(alldata) && alldata.length > 0) {
      alldata?.forEach((item, idx) => {
        arr.push({
          id: idx + 1,
          ...item,
        });
      });
    } else {
      console.warn("alldata is not an array or it's empty:", alldata);
    }

    setrows(arr);
  }, [alldata]);

  React.useEffect(() => {
    if (rows.length > 0) {
      setFilteredData(rows);
    } else {
      setFilteredData([]);
    }
  }, [rows]);
  useEffect(() => {
    let filtered = rows;
    if (selectedCompany) {
      filtered = filtered.filter(
        (item) => item.companyName === selectedCompany
      );
    }
    if (selectedJobsite) {
      filtered = filtered.filter((item) => item.jobSite === selectedJobsite);
    }
    if (searchTerm) {
      const regex = new RegExp(searchTerm, "i");
      filtered = filtered.filter(
        (item) =>
          regex.test(item?.companyName) ||
          regex.test(item?.equipType) ||
          regex.test(item?.equipmentNumbers) ||
          regex.test(item?.inspectionNumber)
      );
    }

    setFilteredData(filtered);
  }, [rows, selectedCompany, selectedJobsite, searchTerm]);

  const handleSubmit = async () => {
    if (selectedRow?.submissionId) {
      setLoading(true);
      try {
        const submissionResponse = await axiosInstance.get(
          `Forms/${selectedRow?.form?._id}/Submission/${selectedRow?.submissionId}`
        );
        let data = {
          data: submissionResponse?.data?.data,
          metadata: submissionResponse?.data?.metadata,
          // state: "completed",
          _vnote: "",
          inspectionStageId:
            submissionResponse?.data?.inspectionStageId ||
            selectedRow?.currentStage,
          status: "Submitted",
          userId: userId,
        };
        try {
          const response = await axiosInstance.put(
            `Forms/${selectedRow?.form?._id}/Submission/${selectedRow?.submissionId}`,
            data
          );
          setsnackBarData(response?.snackBarData);
          console.log("responseSubmit", response);
          if (response.data.success) {
            dispatch(fetchUserInspectionsData(screenType));
          }
          setLoading(false);
          setShowConfirmation(false);
        } catch (error) {
          toast.error("Error posting submission:", error);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);

        toast.error("Error fetching submission:", error);
      }
    } else {
      toast.error("No response is submitted for this form yet.");
    }
  };

  const handleView = async (data) => {
    // Fetch submission details (the filled form data)
    const fetchSubmission = async () => {
      try {
        const submissionResponse = await axiosInstance.get(
          `Forms/${data.form?._id}/Submission/${data.submissionId}`
        );
        console.log("submissionResponse.data", submissionResponse.data);
        navigate("/my-package/form", {
          state: {
            data: data,
            submission: submissionResponse.data,
          },
        });
      } catch (error) {
        console.error("Error fetching submission:", error);
      }
    };

    if (data?.submissionId) {
      fetchSubmission();
    } else {
      navigate("/my-package/form", {
        state: {
          data: data,
        },
      });
    }
  };

  const sendOTP = async (openDialog) => {
    setOtpSending(true);
    const formData = { email: sessionStorage.getItem("userEmail") };
    const response = await SendOTPAPi(formData);

    setOtpSending(false);
    response?.snackBarData?.message && setsnackBarData(response?.snackBarData);

    if (response?.success) {
      setDuration(600);

      if (openDialog) {
        setOpenVerifyOTPDialog(true);
      }
    }
  };

  const verifyOTP = async (values, { resetForm, setSubmitting }) => {
    const formData = {
      email: sessionStorage.getItem("userEmail"),
      otp: values.otp,
    };
    const response = await VerifyOTPAPi(formData);

    setSubmitting(false);
    response?.snackBarData?.message && setsnackBarData(response?.snackBarData);

    if (response?.success) {
      resetForm();

      if (verifyOtpFor == "view") {
        handleView(selectedRow);
      } else {
        handleSubmit();
      }

      setVerifyOtpFor("");
      setDuration(0);
      setOpenVerifyOTPDialog(false);
    }
  };
  console.log("selectedrow", selectedRow);
  const columns = [
    {
      Header: "Sr",
      accessor: "id", // Accessor is the "key" in the data
      width: "20px",
    },
    {
      Header: "Package #",
      accessor: "inspectionNumber",
    },
    {
      Header: "Equipment #",
      accessor: "equipmentNumbers",
    },
    {
      Header: "Equipment Type",
      accessor: "equipType",
    },
    {
      Header: "Company Name",
      accessor: "companyName",
    },
    {
      Header: "Site Name",
      accessor: "jobSite",
    },
    {
      Header: "Current Form",
      accessor: "currentStageForm",
    },
    {
      Header: "Current Stage",
      accessor: "stage",
    },
    {
      Header: "Status",
      accessor: "mySubmissionStatus",
      Cell: (cell) => (
        <span
          style={{
            padding: "0.25rem 0.75rem",
            fontSize: "0.875rem",
            fontWeight: "600",
            color: "white",
            backgroundColor:
              cell.value === "Completed" ||
              cell.value === "Submitted" ||
              cell.value === "Approved"
                ? "green"
                : cell.value === "Rejected"
                ? "red"
                : cell.value === "Pending"
                ? "orange"
                : "rgb(79, 195, 247)",
            borderRadius: "9999px",
          }}
        >
          {cell.value}
        </span>
      ),
    },
    {
      Header: "Review Status",
      accessor: "StageReviewStatus",
      Cell: (cell) => (
        <span
          style={{
            padding: "0.25rem 0.75rem",
            fontSize: "0.875rem",
            fontWeight: "600",
            color: "white",
            backgroundColor:
              cell.value === "Completed" ||
              cell.value === "Submitted" ||
              cell.value === "Approved"
                ? "green"
                : cell.value === "Rejected"
                ? "red"
                : cell.value === "Pending"
                ? "orange"
                : "rgb(79, 195, 247)",
            borderRadius: "9999px",
          }}
        >
          {cell.value}
        </span>
      ),
    },
    {
      Header: "Actions",
      accessor: "actions",

      disableSortBy: true,
      Cell: (cell) => (
        <div className="d-flex">
          {cell?.row?.original.mySubmissionStatus &&
            (cell?.row?.original.mySubmissionStatus !== "Submitted" ? (
              <>
                <button
                  className="btn btn-outline-secondary mx-2 "
                  onClick={() => {
                    setSelectedRow(cell?.row?.original);
                    // setVerifyOtpFor("view");
                    // sendOTP(true);
                    handleView(cell?.row?.original);
                    handleBreadcrumbs();
                    // handleView(row);
                  }}
                >
                  View
                </button>
                <button
                  className="btn btn-outline-primary"
                  type="button"
                  onClick={() => {
                    if (
                      cell?.row?.original.submissionId &&
                      cell?.row?.original.mySubmissionStatus !== "Submitted"
                    ) {
                      setSelectedRow(cell?.row?.original);
                      // setVerifyOtpFor("submit");
                      setShowConfirmation(true);
                    } else {
                      toast.error(
                        "No response is submitted for this form yet."
                      );
                    }
                  }}
                >
                  Submit
                </button>
              </>
            ) : (
              <button
                className="btn btn-outline-secondary mx-2"
                onClick={() => {
                  setSelectedRow(cell?.row?.original);
                  // setVerifyOtpFor("view");
                  // sendOTP(true);
                  // handleView(row);
                  handleView(cell?.row?.original);
                }}
              >
                View
              </button>
            ))}
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="mb-2 d-flex justify-content-between">
        <div className="d-flex gap-4 w-50">
          <input
            type="text"
            className="form-control w-60"
            placeholder="Search By Package #, Equip #, Equip Type"
            onChange={(e) => setSearchTerm(e.target.value)}
          />

          <select
            className="form-select w-50"
            value={selectedCompany}
            onChange={(event) => setSelectedCompany(event.target.value)}
          >
            <option value="">Select Company</option>
            {CompanyDataPopulate?.map((item, idx) => (
              <option key={idx} value={item.name}>
                {item.companyName}
              </option>
            ))}
          </select>
          <select
            className="form-select w-50 "
            value={selectedJobsite || ""}
            onChange={(event) => setSelectedJobsite(event.target.value)}
          >
            <option value="">Select JobSite</option>
            {allJobSites?.map((site) => (
              <option key={site.id} value={site.name}>
                {site.name}
              </option>
            ))}
          </select>
        </div>
        <div className="w-50">
          <p style={{ textAlign: "center" }}>
            Total Records:
            <span style={{ color: "#1c4e80", fontWeight: "bold" }}>
              {" "}
              {filteredData.length}
            </span>
          </p>
        </div>
      </div>
      {UserInspectionData?.pendingCount > 0 && (
        <div
          style={{
            padding: "1rem",
            backgroundColor: "#e0f2fe",
            color: "#0369a1",
            border: "1px solid #0284c7",
            borderRadius: "0.375rem",
            fontWeight: "500",
            marginRight: "15px",
          }}
        >
          <p>{`${UserInspectionData?.pendingCount}  more forms will be coming for submission once the previous stage is approved.`}</p>
        </div>
      )}
      {status === "loading" || loading ? (
        <div className="text-center">
          <Loader />
        </div>
      ) : (
        <CustomGrid columns={columns} data={filteredData} />
      )}
      {showConfirmation && (
        <AppConfirmationDialog
          open={showConfirmation}
          onDeny={() => {
            setSelectedRow(null);
            setVerifyOtpFor("");
            setShowConfirmation(false);
          }}
          onConfirm={() => {
            // sendOTP(true);
            handleSubmit();
          }}
          iconContainerColor="#fbeaea"
          icon={<FaRegSave color="#0DCAF0" fontSize="medium" />}
          dialogTitle="Submit Form"
          title={`Are you sure, you want to submit form, ${selectedRow?.title}? 
          \n This action can't be undone.`}
          successText="Yes"
          successBtnColor="info"
          cancelText="No"
        />
      )}
      {showCompanyDetails && (
        <InspectionDetailDialog
          open={showCompanyDetails}
          onClose={() => {
            setSelectedRow(null);
            setShowCompanyDetail(false);
          }}
          state={selectedRow}
        />
      )}

      <VerifyOTPDialog
        open={openVerifyOTPDialog}
        onClose={() => {
          setSelectedRow(null);
          setVerifyOtpFor("");
          setOpenVerifyOTPDialog(false);
        }}
        duration={duration}
        setDuration={setDuration}
        otpSending={otpSending}
        resendOTP={() => {
          sendOTP(false);
        }}
        handleSubmit={verifyOTP}
      />
    </>
  );
}

MyInspections.propTypes = {
  screenType: PropTypes.string.isRequired,
};
export default MyInspections;
