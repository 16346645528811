import { useEffect, useRef, useState } from "react";
import { FormBuilder as FormioFormBuilder } from "@formio/js";
import structuredClone from "@ungap/structured-clone";
import PropTypes from "prop-types";

const toggleEventHandlers = (builder, handlers, shouldAttach = true) => {
  const fn = shouldAttach ? "on" : "off";
  const {
    onSaveComponent,
    onEditComponent,
    onUpdateComponent,
    onDeleteComponent,
    onChange,
  } = handlers;

  builder.instance[fn](
    "saveComponent",
    (
      component,
      original,
      parent,
      path,
      index,
      isNew,
      originalComponentSchema
    ) => {
      onSaveComponent?.(
        component,
        original,
        parent,
        path,
        index,
        isNew,
        originalComponentSchema
      );
      onChange?.(structuredClone(builder.instance.form));
    }
  );
  builder.instance[fn]("updateComponent", (component) => {
    onUpdateComponent?.(component);
    onChange?.(structuredClone(builder.instance.form));
  });
  builder.instance[fn]("removeComponent", (component, parent, path, index) => {
    onDeleteComponent?.(component, parent, path, index);
    onChange?.(structuredClone(builder.instance.form));
  });

  builder.instance[fn]("cancelComponent", (component) => {
    onUpdateComponent?.(component);
    onChange?.(structuredClone(builder.instance.form));
  });

  builder.instance[fn]("editComponent", (component) => {
    onEditComponent?.(component);
    onChange?.(structuredClone(builder.instance.form));
  });

  builder.instance[fn]("addComponent", () => {
    onChange?.(structuredClone(builder.instance.form));
  });

  builder.instance[fn]("pdfUploaded", () => {
    onChange?.(structuredClone(builder.instance.form));
  });
};

const createBuilderInstance = async (
  element,
  BuilderConstructor = FormioFormBuilder,
  form = { display: "form", components: [] },
  options = {}
) => {
  options = Object.assign({}, options);
  form = Object.assign({}, form);

  const instance = new BuilderConstructor(element, form, options);

  await instance.ready;
  return instance;
};

const DEFAULT_FORM = { display: "form", components: [] };

export const FormBuilder = ({
  options,
  Builder,
  initialForm = DEFAULT_FORM,
  onBuilderReady,
  onChange,
  onDeleteComponent,
  onEditComponent,
  onSaveComponent,
  onUpdateComponent,
}) => {
  const builder = useRef(null);
  const renderElement = useRef(null);
  const [instanceIsReady, setInstanceIsReady] = useState(false);

  useEffect(() => {
    let ignore = false;
    const createInstance = async () => {
      if (!renderElement.current) {
        console.warn(
          "FormBuilder render element not found, cannot render builder."
        );
        return;
      }
      const instance = await createBuilderInstance(
        renderElement.current,
        Builder,
        structuredClone(initialForm),
        options
      );
      if (instance) {
        if (ignore) {
          instance.instance.destroy(true);
          return;
        }

        if (onBuilderReady) {
          onBuilderReady(instance);
        }
        builder.current = instance;
        setInstanceIsReady(true);
      } else {
        console.warn("Failed to create FormBuilder instance");
      }
    };

    createInstance();

    return () => {
      ignore = true;
      setInstanceIsReady(false);
      if (builder.current) {
        builder.current.instance.destroy(true);
      }
    };
  }, [Builder, initialForm, options, onBuilderReady]);

  useEffect(() => {
    if (instanceIsReady && builder.current) {
      toggleEventHandlers(builder.current, {
        onChange,
        onDeleteComponent,
        onEditComponent,
        onSaveComponent,
        onUpdateComponent,
      });
    }

    return () => {
      if (instanceIsReady && builder.current) {
        toggleEventHandlers(
          builder.current,
          {
            onChange,
            onDeleteComponent,
            onEditComponent,
            onSaveComponent,
            onUpdateComponent,
          },
          false
        );
      }
    };
  }, [
    instanceIsReady,
    onChange,
    onDeleteComponent,
    onEditComponent,
    onSaveComponent,
    onUpdateComponent,
  ]);

  return <div ref={renderElement}></div>;
};

// Prop types for validation
FormBuilder.propTypes = {
  options: PropTypes.object,
  Builder: PropTypes.func,
  initialForm: PropTypes.shape({
    display: PropTypes.string,
    components: PropTypes.array,
  }),
  onBuilderReady: PropTypes.func,
  onChange: PropTypes.func,
  onDeleteComponent: PropTypes.func,
  onEditComponent: PropTypes.func,
  onSaveComponent: PropTypes.func,
  onUpdateComponent: PropTypes.func,
};
