import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/AxiosInstance";
import { revertAll } from "../ResetStateAction";

const initialState = {
  PdfData: [],
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

export const fetchPdfData = createAsyncThunk(
  "Document/GetUserDocuments",
  async () => {
    const response = await axiosInstance.get(`Document/GetUserDocuments`);
    return response?.data;
  }
);

const PdfSlice = createSlice({
  name: "PdfSlice",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchPdfData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPdfData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.PdfData = action.payload.data;
        // state.TotalCount = action.payload.data.totalCount;
      })
      .addCase(fetchPdfData.rejected, (state, action) => {
        state.PdfData = null;
        state.error = action.error.message;
        state.status = "failed";
      });
  },
});

export default PdfSlice.reducer;
