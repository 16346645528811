// import React, { useEffect, useState } from "react";
// import { Form, Button } from "react-bootstrap";
// import bgImg from "../../Assets/Images/Authentication/ai-image.jpeg";
// import BrandLogo from "../../Assets/Images/no background RAMI.png";
// import { Form as FormikForm, Formik } from "formik";
// import * as yup from "yup";
// import PropTypes from "prop-types";
// import OTPInput from "react-otp-input";
// import { ThreeDots } from "react-loader-spinner";

// const validationSchema = yup.object().shape({
//   otp: yup
//     .string()
//     .min(4, "Please add 4-digits OTP!")
//     .required("Field Required!"),
// });
// function OtpVerification({
//   duration,
//   setDuration,
//   otpSending,
//   resendOTP,
//   handleSubmit,
// }) {
//   const [duration, setDuration] = useState(0);
//   useEffect(() => {
//     const countdown = setInterval(() => {
//       if (duration > 0) {
//         setDuration(duration - 1);
//       } else {
//         clearInterval(countdown);
//       }
//     }, 1000);

//     return () => clearInterval(countdown);
//   }, [duration]);

//   const formatTime = (time) => {
//     const minutes = Math.floor(time / 60);
//     const seconds = time % 60;
//     return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
//   };

//   return (
//     <div
//       className="main-container"
//       style={{ backgroundImage: `url(${bgImg})` }}
//     >
//       <div className="overlay"></div>
//       <div className="card-container">
//         <div className="white-container">
//           {/* <div className="heading-container"> </div> */}
//           <div className="image-container d-flex justify-content-center p-3">
//             <img
//               style={{ height: "auto", width: "100%" }}
//               src={BrandLogo}
//               alt="logo"
//               className="brandLogo"
//             />
//           </div>
//           <Formik
//             initialValues={{
//               otp: "",
//             }}
//             validationSchema={validationSchema}
//             onSubmit={handleSubmit}
//           >
//             {({
//               values,
//               errors,
//               touched,
//               handleBlur,
//               setFieldValue,
//               isSubmitting,
//             }) => (
//               <FormikForm>
//                 <Form.Group className="mb-3">
//                   <div className="d-flex justify-content-center">
//                     <div className="d-flex flex-column gap-2">
//                       <OTPInput
//                         value={values.otp}
//                         onChange={(val) => setFieldValue("otp", val)}
//                         placeholder={true}
//                         numInputs={4}
//                         renderInput={(props) => (
//                           <Form.Control
//                             type="text"
//                             name="otp"
//                             onBlur={handleBlur}
//                             {...props}
//                             className="otp-input"
//                           />
//                         )}
//                         inputStyle={{
//                           width: "80px",
//                           height: "3rem",
//                           margin: "0 0.5rem",
//                           fontSize: "2rem",
//                           borderRadius: "0.25rem",
//                           border: "1px solid rgba(0,0,0,0.3)",
//                         }}
//                       />
//                       {touched?.otp && errors?.otp && (
//                         <p className="text-danger fs-6">{errors?.otp}</p>
//                       )}
//                     </div>
//                   </div>
//                 </Form.Group>

//                 <div className="d-flex justify-content-between align-items-center mt-3">
//                   <span>Expires in {formatTime(duration)}</span>

//                   <div className="d-flex align-items-center gap-2">
//                     <span>Didn’t receive OTP?</span>
//                     <Button
//                       variant="link"
//                       disabled={otpSending}
//                       onClick={() => {
//                         resendOTP();
//                       }}
//                     >
//                       Resend
//                     </Button>
//                   </div>
//                 </div>

//                 <div className="d-flex justify-content-end mt-3">
//                   <div className="d-flex align-items-center gap-3">
//                     <ThreeDots
//                       height="40"
//                       width="40"
//                       radius="9"
//                       color="#1c4e80 "
//                       visible={isSubmitting || otpSending}
//                     />

//                     <Button
//                       type="submit"
//                       variant="primary"
//                       disabled={isSubmitting || !duration}
//                     >
//                       Verify OTP
//                     </Button>
//                   </div>
//                 </div>
//               </FormikForm>
//             )}
//           </Formik>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default OtpVerification;
// OtpVerification.propTypes = {
//   open: PropTypes.bool,
//   onClose: PropTypes.func,
//   duration: PropTypes.number,
//   setDuration: PropTypes.func,
//   otpSending: PropTypes.bool,
//   resendOTP: PropTypes.func,
//   handleSubmit: PropTypes.func,
// };

import React, { useEffect, useContext, useState } from "react";
import { Form, Button } from "react-bootstrap";
import bgImg from "../../Assets/Images/Authentication/ai-image.jpeg";
import BrandLogo from "../../Assets/Images/no background RAMI.png";
import { Form as FormikForm, Formik } from "formik";
import * as yup from "yup";
import PropTypes from "prop-types";
import OTPInput from "react-otp-input";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import { VerifyOTPAPi } from "../../Apis/Auth/AuthApis";
import { BreadcrumbsContext } from "../../context/BcrumbsContextProvider";
import { useNavigate } from "react-router-dom";
import { UserVerification } from "../../Apis/Auth/AuthApis";
import { useLocation } from "react-router-dom";
const validationSchema = yup.object().shape({
  otp: yup
    .string()
    .min(4, "Please add 4-digits OTP!")
    .required("Field Required!"),
});

function OtpVerification() {
  const isAdmin = sessionStorage.getItem("isAdmin");
  const isSuperAdmin = sessionStorage.getItem("isSuperAdmin");
  const isEmployee = sessionStorage.getItem("isEmployee");
  const { setBreadcrumbsItems } = useContext(BreadcrumbsContext);
  const location = useLocation();
  const { otpSending } = location.state;
  const navigate = useNavigate();
  // const [countdown, setCountdown] = useState(duration);
  const [duration, setDuration] = useState(600);
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const verifyOTP = async (values, { resetForm, setSubmitting }) => {
    const formData = {
      email: sessionStorage.getItem("userEmail"),
      otp: values.otp,
    };
    const response = await VerifyOTPAPi(formData);

    setSubmitting(false);
    response?.snackBarData?.message &&
      (response?.snackBarData?.type === "error"
        ? toast.error(response?.snackBarData?.message)
        : toast.info(response?.snackBarData?.message));

    if (response?.success) {
      resetForm();
      setDuration(0);
      const response2 = await UserVerification();
      console.log(response2);
      if (isSuperAdmin) {
        setBreadcrumbsItems([{ id: 1, url: "/", name: "Dashboard" }]);
        navigate("/");
      } else if (isAdmin) {
        setBreadcrumbsItems([{ id: 1, url: "/", name: "Dashboard" }]);
        navigate("/");
      } else if (isEmployee) {
        setBreadcrumbsItems([
          { id: 1, url: "/my-inspection/pending", name: "My Inspection" },
        ]);
        navigate("/my-inspection/pending");
      }
    }
  };
  useEffect(() => {
    const countdown = setInterval(() => {
      if (duration > 0) {
        setDuration(duration - 1);
      } else {
        clearInterval(countdown);
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, [duration]);
  return (
    <div
      className="main-container"
      style={{ backgroundImage: `url(${bgImg})` }}
    >
      <div className="overlay"></div>
      <div className="card-container">
        <div className="white-container">
          <div className="image-container d-flex justify-content-center p-3">
            <img
              style={{ height: "auto", width: "100%" }}
              src={BrandLogo}
              alt="logo"
              className="brandLogo"
            />
          </div>
          <Formik
            initialValues={{ otp: "" }}
            validationSchema={validationSchema}
            onSubmit={verifyOTP}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              setFieldValue,
              isSubmitting,
            }) => (
              <FormikForm>
                <Form.Group className="mb-3">
                  <div className="d-flex justify-content-center">
                    <div
                      className="d-flex flex-column gap-2 "
                      style={{
                        margin: "20px",
                      }}
                    >
                      <OTPInput
                        value={values.otp}
                        onChange={(val) => setFieldValue("otp", val)}
                        placeholder={true}
                        numInputs={4}
                        renderInput={(props) => (
                          <Form.Control
                            type="text"
                            name="otp"
                            onBlur={handleBlur}
                            {...props}
                            className="otp-input"
                          />
                        )}
                        inputStyle={{
                          width: "70px",
                          height: "3rem",
                          margin: "0 0.5rem",
                          fontSize: "2rem",
                          borderRadius: "0.25rem",
                          border: "1px solid rgba(0,0,0,0.3)",
                        }}
                      />
                      {touched?.otp && errors?.otp && (
                        <p className="text-danger fs-6">{errors?.otp}</p>
                      )}
                    </div>
                  </div>
                </Form.Group>

                <div className="d-flex justify-content-between align-items-center mt-3">
                  <span>Expires in {formatTime(duration)}</span>

                  <div className="d-flex align-items-center gap-2">
                    <span>Didn’t receive OTP?</span>
                    <Button
                      variant="link"
                      disabled={otpSending}
                      // onClick={state.resendOTP}
                    >
                      Resend
                    </Button>
                  </div>
                </div>

                <div className="d-flex justify-content-end mt-3">
                  <div className="d-flex align-items-center gap-3">
                    <ThreeDots
                      height="40"
                      width="40"
                      radius="9"
                      color="#1c4e80 "
                      visible={isSubmitting || otpSending}
                    />

                    <Button
                      type="submit"
                      variant="primary"
                      disabled={isSubmitting || !duration}
                      style={{ marginRight: "10px" }}
                    >
                      Verify OTP
                    </Button>
                  </div>
                </div>
              </FormikForm>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default OtpVerification;

OtpVerification.propTypes = {
  state: PropTypes.object,
};
