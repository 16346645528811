import React, { useContext } from "react";
// import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { BreadcrumbsContext } from "../../../context/BcrumbsContextProvider";
import "bootstrap/dist/css/bootstrap.min.css";

function Navbar() {
  // const navigate = useNavigate();
  const { breadcrumbsItems } = useContext(BreadcrumbsContext);

  // const handleBackClick = () => {
  //   // Go back one step in the history stack
  //   navigate(-1);
  // };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ flex: 1 }}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            {breadcrumbsItems?.map((item, index) => (
              <h6
                key={item.id}
                className={`breadcrumb-item ${
                  breadcrumbsItems.length === index + 1 ? "active" : ""
                }`}
                aria-current={
                  breadcrumbsItems.length === index + 1 ? "page" : undefined
                }
                style={{
                  color:
                    breadcrumbsItems.length === index + 1 ? "#aaa" : "#aaa",
                  fontWeight:
                    breadcrumbsItems.length === index + 1 ? "bolder" : "",
                }}
              >
                {item.name}
              </h6>
            ))}
          </ol>
        </nav>
      </div>

      {/* Show back button only if there is more than one breadcrumb */}
      {/* {breadcrumbsItems.length > 1 && (
        <button
          onClick={handleBackClick}
          style={{
            background: "none",
            border: "none",
            color: "#007bff",
            cursor: "pointer",
            fontSize: "1rem",
            marginLeft: "10px",
          }}
        >
          Back
        </button>
      )} */}
    </div>
  );
}

export default Navbar;

Navbar.propTypes = {
  toggleDrawer: PropTypes.func,
  drawerOpen: PropTypes.bool,
};
