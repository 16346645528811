// import { Box } from "@mui/material";
// import React from "react";
import { Triangle } from "react-loader-spinner";

// const useStyle = makeStyles(() => {
//   return {
//     childDiv: {
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",
//       width: "100%",
//       height: "100%",
//     },
//     parent: {
//       position: "fixed",
//       top: "0",
//       bottom: "0",
//       left: "0",
//       right: "0",
//       width: "100vw",
//       height: "100vh",
//       background: "rgba(0, 0, 0, 0.26)",
//       transition: "opacity 500ms",
//       padding: " 60px",
//       zIndex: 9999,
//     },
//   };
// });
// function Loader() {
//   const { parent, childDiv } = useStyle();
//   return (
//     <Box className={parent}>
//       <Box className={childDiv}>
//         <Triangle
//           sx={{ color: (theme) => theme.palette.primary.main }}
//           height="100"
//           width="100"
//           color="#f79c35"
//           secondaryColor="#f79c35"
//           ariaLabel="triangle-loading"
//           wrapperStyle={{}}
//           wrapperClassName=""
//           visible={true}
//         />
//       </Box>
//     </Box>
//   );
// }

// export default Loader;

import React from "react";

function Loader() {
  return (
    <div
      className="d-flex justify-content-center align-items-center position-fixed top-0 bottom-0 start-0 end-0 vw-100 vh-100 bg-dark bg-opacity-50"
      style={{ zIndex: 9999 }}
    >
      <Triangle
        sx={{ color: (theme) => theme.palette.primary.main }}
        height="100"
        width="100"
        color="#1c4e80 "
        secondaryColor="#1c4e80 "
        ariaLabel="triangle-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
      />
    </div>
  );
}

export default Loader;
