import React, { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { SnackBarContext } from "../../../context/SnackBarContext";
import {
  ForgotPasswordAPi,
  ResetPasswordAPi,
  VerifyOTPAPi,
} from "../../../Apis/Auth/AuthApis";
import GetOtp from "./GetOtp/GetOtp";
import VerifyOTP from "./VerifyOTP/VerifyOTP";
import ResetPassword from "./ResetPassword/ResetPassword";
import { useAuth } from "../../../context/useAuth";
import bgImg from "../../../Assets/Images/Authentication/ai-image.jpeg";
import BrandLogo from "../../../Assets/Images/no background RAMI.png";

const validationSchema = yup.object().shape({
  email: yup.string().when("step", {
    is: (step) => step === "email",
    then: () =>
      yup
        .string()
        .email("Please enter valid email")
        .required("Field Required!"),
    otherwise: () => yup.string(),
  }),
  otp: yup.string().when("step", {
    is: (step) => step === "verify",
    then: () =>
      yup
        .string()
        .min(4, "Please add 4-digits OTP!")
        .required("Field Required!"),
    otherwise: () => yup.string(),
  }),
  password: yup.string().when("step", {
    is: (step) => step === "reset",
    then: () =>
      yup
        .string()
        .min(6, "Password must be at least 6 characters")
        .required("Field Required!"),
    otherwise: () => yup.string(),
  }),
  confirmPassword: yup.string().when("step", {
    is: (step) => step === "reset",
    then: () =>
      yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match")
        .required("Field Required!"),
    otherwise: () => yup.string(),
  }),
});

function ForgotPassword() {
  const [otpSending, setOtpSending] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();
  const { setsnackBarData } = useContext(SnackBarContext);

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user, navigate]);

  const resendOTP = async (values, setFieldValue) => {
    setOtpSending(true);
    const formData = { email: values.email };
    const response = await ForgotPasswordAPi(formData);
    setOtpSending(false);
    response?.snackBarData?.message && setsnackBarData(response?.snackBarData);

    if (response?.success) {
      setFieldValue("duration", 600);
    }
  };

  const handleSubmit = async (
    values,
    { resetForm, setSubmitting, setFieldValue, setTouched }
  ) => {
    if (values.step === "email") {
      const formData = { email: values.email };
      const response = await ForgotPasswordAPi(formData);

      setSubmitting(false);
      setsnackBarData(response?.snackBarData);

      if (response?.success) {
        setFieldValue("duration", 600);
        setFieldValue("step", "verify");
        setTouched({ otp: false, password: false, confirmPassword: false });
      }
    } else if (values.step === "verify") {
      const formData = { email: values.email, otp: values.otp };
      const response = await VerifyOTPAPi(formData);

      setSubmitting(false);
      response?.snackBarData?.message &&
        setsnackBarData(response?.snackBarData);

      if (response?.success) {
        setFieldValue("step", "reset");
        setTouched({ password: false, confirmPassword: false });
      }
    } else {
      const formData = { email: values.email, newPassword: values.password };
      const response = await ResetPasswordAPi(formData);

      setSubmitting(false);
      response?.snackBarData?.message &&
        setsnackBarData(response?.snackBarData);

      if (response?.success) {
        resetForm();
        navigate("/signin");
      }
    }
  };

  return (
    <div
      className="main-container"
      style={{ backgroundImage: `url(${bgImg})` }}
    >
      <div className="overlay"></div>
      <div className="card-container">
        <div className="white-container">
          <div className="image-container d-flex justify-content-center p-3">
            <img
              style={{ height: "auto", width: "100%" }}
              src={BrandLogo}
              alt="logo"
              className="brandLogo"
            />
          </div>
          <Formik
            initialValues={{
              step: "email",
              email: "",
              otp: "",
              duration: 0,
              password: "",
              confirmPassword: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
              isSubmitting,
            }) => (
              <Form>
                {values.step === "email" ? (
                  <GetOtp
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                ) : values.step === "verify" ? (
                  <VerifyOTP
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    otpSending={otpSending}
                    resendOTP={() => resendOTP(values, setFieldValue)}
                  />
                ) : (
                  <ResetPassword
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                )}

                <Button
                  type="submit"
                  variant="primary"
                  className="w-100"
                  disabled={
                    isSubmitting ||
                    (values.step === "verify" && !values.duration)
                  }
                >
                  {values.step === "email"
                    ? "Get OTP"
                    : values.step === "verify"
                    ? "Verify OTP"
                    : "Reset Password"}
                </Button>

                <div className="d-flex justify-content-end">
                  <Button
                    variant="link"
                    className="text-warning"
                    onClick={() => navigate("/signin")}
                  >
                    Sign In
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
