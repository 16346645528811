import React from "react";
import AppDialog from "../common/AppDialog/AppDialog";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/AxiosInstance";
function HistoryModal({ open, onClose, state }) {
  const navigate = useNavigate();
  const handleView = async (data) => {
    const fetchSubmission = async () => {
      try {
        const submissionResponse = await axiosInstance.get(
          `Forms/${data?.form}/Submission/${data?.id}`
        );
        console.log("submissionResponse.data", submissionResponse.data);
        navigate("/package/package-view/form", {
          state: {
            data: data,
            submission: submissionResponse.data,
            InspId: state?.inspectionId,
          },
        });
      } catch (error) {
        console.error("Error fetching submission:", error);
      }
    };

    if (data?.id) {
      fetchSubmission();
    } else {
      navigate("/my-package/form", {
        state: {
          data: data,
        },
      });
    }
  };
  return (
    <AppDialog
      open={open}
      onClose={() => {
        onClose(false);
      }}
      title="History"
      minHeight="auto"
      maxWidth="lg"
    >
      <div>
        <div className="table-responsive" style={{ maxHeight: "300px" }}>
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th scope="col" style={{ width: "10%" }}>
                  Sr.
                </th>
                <th scope="col" style={{ width: "20%" }}>
                  Status
                </th>
                <th scope="col" style={{ width: "20%" }}>
                  Rejected at
                </th>
                <th scope="col" style={{ width: "20%" }}>
                  Rejected By
                </th>
                <th scope="col" style={{ width: "20%" }}>
                  Form
                </th>
              </tr>
            </thead>
            <tbody>
              {state?.submissionHistory?.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td
                    style={{
                      color:
                        item?.status === "Rejected"
                          ? "red"
                          : item?.status === "Approved"
                          ? "#22c55e"
                          : "black",
                    }}
                  >
                    {item?.status || "N/A"}
                  </td>
                  <td>
                    {" "}
                    {item?.rejectionStage?.length > 0
                      ? `Stage  ${item.rejectionStage}`
                      : "N/A"}
                  </td>
                  <td>
                    {item?.rejectedBy?.username?.length > 0
                      ? item?.rejectedBy?.username
                      : "N/A"}
                  </td>
                  <td>
                    <button
                      className="btn btn-outline-secondary mx-2 "
                      onClick={() => {
                        handleView(item?.submissionId);
                      }}
                    >
                      View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </AppDialog>
  );
}

export default HistoryModal;

HistoryModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  state: PropTypes.any,
};
