import axiosInstance from "../../utils/AxiosInstance";

export const UpdateReviewStatusApi = async (data, id) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.patch(
      `/Inspection/UpdateReviewStatus/${id}`,
      data
    );

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
