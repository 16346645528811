import React from "react";
import Loader from "../../components/Loader/Loader";
import { useSelector } from "react-redux";
// import StatCards from "../../components/Dashboard/StatCards/StatCards";
// import StatCharts from "../../components/Dashboard/StatCharts/StatCharts";
import Company from "../../components/Dashboard/CompanyAccordion/Company";
function Dashboard() {
  const { status } = useSelector((state) => state?.Dashboard);

  return (
    <main
      style={{
        maxHeight: "100vh",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <Company />
      {/* <StatCards />
      <StatCharts /> */}

      {status === "loading" ? <Loader /> : null}
    </main>
  );
}

export default Dashboard;
