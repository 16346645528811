import React, { useMemo } from "react";
import { useTable, useSortBy, useFilters } from "react-table";
import { Table, Form, InputGroup } from "react-bootstrap";
import PropTypes from "prop-types";

const DefaultColumnFilter = ({
  column: { filterValue, setFilter, Header },
}) => (
  <InputGroup>
    <Form.Control
      placeholder={`Search ${Header}`}
      value={filterValue || ""}
      onChange={(e) => setFilter(e.target.value || undefined)}
    />
  </InputGroup>
);

DefaultColumnFilter.propTypes = {
  column: PropTypes.shape({
    filterValue: PropTypes.string,
    setFilter: PropTypes.func.isRequired,
    Header: PropTypes.string.isRequired,
  }).isRequired,
};

const CustomGrid = ({ columns, data }) => {
  //   const [filterVisibility, setFilterVisibility] = useState({});

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        defaultColumn,
      },
      useFilters, // Use filters hook
      useSortBy // Use sorting hook
    );

  // Toggle visibility for a specific column's filter
  //   const toggleFilter = (header) => {
  //     setFilterVisibility((prevState) => ({
  //       ...prevState,
  //       [header]: !prevState[header],
  //     }));
  //   };

  return (
    // <div style={{ overflowX: 'auto', width:"100%"}} className="scrollbar-custom">
    <div className="custom-grid">
      <Table
        {...getTableProps()}
        striped
        bordered
        hover
        // responsive
        className="custom-grid"
      >
        <thead>
          {headerGroups.map((headerGroup, idx) => (
            <tr key={idx} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, idx) => (
                <th
                  key={idx}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{
                    width: column.width,
                    minWidth: column.minWidth || "10px", // Set minWidth
                  }}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>

                    {/* <Button
                      variant="outline-secondary"
                      size="sm"
                      onClick={() => toggleFilter(column.Header)}
                      className="ml-2"
                    >
                      <FiMoreVertical />
                    </Button> */}
                  </div>
                  {/* <div>
                    {filterVisibility[column.Header] && column.canFilter
                      ? column.render("Filter")
                      : null}
                  </div> */}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, idx) => {
            prepareRow(row);
            return (
              <tr key={idx} {...row.getRowProps()}>
                {row.cells.map((cell, idx) => (
                  <td
                    key={idx}
                    {...cell.getCellProps()}
                    style={{ width: cell.column.width }}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

CustomGrid.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string,
      accessor: PropTypes.string,
      Cell: PropTypes.func, // Cell is a function that returns JSX
      width: PropTypes.string, // Define the width for the column
      minWidth: PropTypes.string, // Define the minimum width for the column
    })
  ).isRequired,
  data: PropTypes.any,
};

export default CustomGrid;
