import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/AxiosInstance";
import { revertAll } from "../ResetStateAction";

const initialState = {
  SummaryData: null,
  TrendsData: null,
  OpportunitySuccessRateData: null,
  EquipmentsData: null,
  RolesCount: null,
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
};

export const fetchSummaryData = createAsyncThunk(
  "/Dashboard/Summary",
  async ({ start, end, compId, jobSite }) => {
    const response = await axiosInstance.get(
      `/Dashboard/Summary?from=${start}&to=${end}&company=${compId}&jobSite=${jobSite}`
    );
    return response?.data;
  }
);

export const fetchTrendsData = createAsyncThunk(
  "/Dashboard/Trends",
  async ({ start, end, compId, jobSite }) => {
    const response = await axiosInstance.get(
      `/Dashboard/Trends?from=${start}&to=${end}&company=${compId}&jobSite=${jobSite}`
    );
    return response?.data;
  }
);
export const fetchEquipmentsData = createAsyncThunk(
  "/inspections/Equipments",
  async ({ start, end, compId, jobSite }) => {
    const response = await axiosInstance.get(
      `Inspection/EquipmentNoGraph?from=${start}&to=${end}&company=${compId}&jobSite=${jobSite}`
    );
    return response?.data;
  }
);

export const fetchRolesCountData = createAsyncThunk(
  "/Dashboard/RolesCount",
  async ({ start, end, compId, jobSite }) => {
    const response = await axiosInstance.get(
      `Inspection/CompanyWiseRoleCount?from=${start}&to=${end}&company=${compId}&jobSite=${jobSite}`
    );
    return response?.data;
  }
);

export const fetchOpportunitySuccessRateData = createAsyncThunk(
  "/Dashboard/OpportunitySuccessRate",
  async (payload) => {
    const response = await axiosInstance.get(
      `/Dashboard/OpportunitySuccessRate?startDate=${payload?.startDate}&endDate=${payload?.endDate}`
    );
    return response?.data?.data;
  }
);

const DashboardSlice = createSlice({
  name: "DashboardSlice",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchSummaryData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSummaryData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.isSuccess = action.payload.isSuccess;
        state.SummaryData = action.payload;
      })
      .addCase(fetchSummaryData.rejected, (state, action) => {
        state.OpportunitySummaryData = null;
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchTrendsData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTrendsData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.isSuccess = action.payload.isSuccess;
        state.TrendsData = action.payload;
      })
      .addCase(fetchTrendsData.rejected, (state, action) => {
        state.OpportunityRevenueSummaryData = null;
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchOpportunitySuccessRateData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOpportunitySuccessRateData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.isSuccess = action.payload.isSuccess;
        state.OpportunitySuccessRateData = action.payload;
      })
      .addCase(fetchOpportunitySuccessRateData.rejected, (state, action) => {
        state.OpportunitySuccessRateData = null;
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchRolesCountData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchRolesCountData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.isSuccess = action.payload.isSuccess;
        state.RolesCount = action.payload;
      })
      .addCase(fetchRolesCountData.rejected, (state, action) => {
        state.RolesCount = null;
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchEquipmentsData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchEquipmentsData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.isSuccess = action.payload.isSuccess;
        state.EquipmentsData = action.payload;
      })
      .addCase(fetchEquipmentsData.rejected, (state, action) => {
        state.EquipmentsData = null;
        state.error = action.error.message;
        state.status = "failed";
      });
  },
});

export default DashboardSlice.reducer;
