import React from "react";
import { Text, View, StyleSheet, Font } from "@react-pdf/renderer";
import PropTypes from "prop-types";

// Create styles
Font.register({
  family: "Helvetica-Bold",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/helvetica/v7/oU5HfY7Evdle2mP5Xai7nPesZW2xOQ-xsNqO47m55DA.ttf",
      fontWeight: "bold",
    },
  ],
});
const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
  },
  section: {
    margin: 10,
    padding: 10,
    border: "1px solid black",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderLeft: 1,
    borderRight: 0,
    borderBottom: 0,
    borderTop: 0,
    borderColor: "#000",
    marginBottom: 10,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColHeader: {
    borderBottom: 0,
    borderTop: 0,
    borderLeft: 0,
    borderRightWidth: 1,
    width: "25%",
    borderStyle: "solid",
    backgroundColor: "#E4E4E4",
    textAlign: "center",
    paddingTop: 6,
    fontFamily: "Helvetica-Bold",
  },
  tableCol: {
    borderLeft: 0,
    borderRight: 1,
    borderBottom: 1,
    borderTop: 1,
    width: "25%",
    borderStyle: "solid",
    paddingLeft: 5,
    paddingTop: 6,
    textAlign: "center",
  },
  title: {
    paddingTop: 20,
    textAlign: "center",
    fontFamily: "Helvetica-Bold",
  },
});

// Create Document Component
export const WscfHeader = ({ title, data }) => (
  <>
    {/* Row 0 */}
    <View style={styles.tableRow}>
      <Text style={{ ...styles.tableCol, ...styles.title }}>0091010</Text>
      <Text style={{ ...styles.tableCol, width: "75%", ...styles.title }}>
        {title}
      </Text>
    </View>
    {/* Row 1 */}
    <View style={styles.tableRow}>
      <Text style={styles.tableColHeader}>Equipment #</Text>
      <Text style={{ ...styles.tableColHeader, width: "50%" }}>
        Description
      </Text>
      <Text style={styles.tableColHeader}>Project</Text>
    </View>
    <View style={styles.tableRow}>
      <Text style={styles.tableCol}>{data?.equipmentNumber || ""}</Text>
      <Text style={{ ...styles.tableCol, width: "50%" }}>
        {data?.description || ""}
      </Text>
      <Text style={styles.tableCol}>{data?.project || ""}</Text>
    </View>
    {/* Row 2 */}
    <View style={styles.tableRow}>
      <Text style={styles.tableColHeader}>Unit #</Text>
      <Text style={styles.tableColHeader}>System</Text>
      <Text style={styles.tableColHeader}>WBS Code</Text>
      <Text style={styles.tableColHeader}>WSP #</Text>
      <Text style={styles.tableColHeader}>P&ID</Text>
    </View>
    <View style={styles.tableRow}>
      <Text style={styles.tableCol}>{data?.unitNumber || ""}</Text>
      <Text style={styles.tableCol}>{data?.system || ""}</Text>
      <Text style={styles.tableCol}>{data?.wbsCode || ""}</Text>
      <Text style={styles.tableCol}>{data?.wspNumber || ""}</Text>
      <Text style={styles.tableCol}>{data?.pAndId || ""}</Text>
    </View>
  </>
);

WscfHeader.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object,
};
