import React from "react";
import PropTypes from "prop-types";
import { Modal, Button, Spinner } from "react-bootstrap";

const AppConfirmationDialog = ({
  open,
  onDeny,
  onConfirm,
  iconContainerColor,
  icon,
  dialogTitle,
  title,
  successText,
  successBtnColor,
  cancelText,
  loading,
  okButton,
}) => {
  // Styles object to keep the original styling
  const styles = {
    mainContainer: {
      padding: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      gap: "30px",
    },
    iconContainer: {
      marginBottom: "5px",
      backgroundColor: iconContainerColor,
      padding: "15px",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    title: {
      fontSize: "17px",
      fontWeight: 600,
      lineHeight: 1.5,
      textAlign: "center",
    },
    subtitle: {
      fontSize: "12px",
      fontWeight: 600,
      color: "GrayText",
      lineHeight: 1.5,
      textAlign: "center",
    },
    button: {
      fontWeight: 600,
    },
  };

  return (
    <Modal show={open} onHide={onDeny} centered>
      <div style={styles.container}>
        {/* <div
            style={{
              ...styles.iconContainer,
              marginBottom: "10px", // Adjust margin for better spacing
            }}
          >
            {icon}
          </div> */}
        <Modal.Header closeButton>
          <Modal.Title
            style={styles.title}
            className="d-flex align-items-center justify-content-center gap-2"
          >
            {" "}
            {icon}
            {dialogTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={styles.subtitle}>{title}</p>
        </Modal.Body>
        <Modal.Footer>
          {loading ? (
            <Spinner animation="border" />
          ) : (
            <>
              <Button
                variant="secondary"
                onClick={onDeny}
                style={{ flex: 1, ...styles.button }}
              >
                {cancelText}
              </Button>
              {!okButton && (
                <Button
                  variant={successBtnColor}
                  onClick={onConfirm}
                  style={{ flex: 1, ...styles.button }}
                >
                  {successText}
                </Button>
              )}
            </>
          )}
        </Modal.Footer>
      </div>
    </Modal>
  );
};

AppConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  onDeny: PropTypes.func,
  onConfirm: PropTypes.func,
  iconContainerColor: PropTypes.string,
  icon: PropTypes.node,
  dialogTitle: PropTypes.string,
  title: PropTypes.string,
  successText: PropTypes.string,
  successBtnColor: PropTypes.string,
  cancelText: PropTypes.string,
  loading: PropTypes.bool,
  okButton: PropTypes.any,
};

export default AppConfirmationDialog;
