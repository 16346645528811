import React, { useEffect, useState, useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import CustomGrid from "../../components/common/CustomGrid/CustomGrid";
import { fetchSitesDetailData } from "../../redux/slice/SitesSlice/SitesSlice";
import Loader from "../Loader/Loader";
import { BreadcrumbsContext } from "../../context/BcrumbsContextProvider";
import * as XLSX from "xlsx";
import { CreateExcelApi } from "../../Apis/Sites/SitesApis";
import { SnackBarContext } from "../../context/SnackBarContext";
import { FaTrash } from "react-icons/fa";

function SitesView() {
  const dispatch = useDispatch();
  const { SitesDetail, status } = useSelector((state) => state?.Sites);
  const [filterData, setFilterData] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false); // Added loading state
  const location = useLocation();
  const data = location.state?.data;
  const { setBreadcrumbsItems } = useContext(BreadcrumbsContext);
  const { setsnackBarData } = useContext(SnackBarContext);

  const fileInputRef = useRef(null);

  const handleBreadcrumbs = () => {
    setBreadcrumbsItems([
      {
        id: 1,
        url: "/sites",
        name: "Sites",
      },
      {
        id: 2,
        url: "/sites/sites-view",
        name: "Sites View",
      },
    ]);
  };

  useEffect(() => {
    handleBreadcrumbs();
    return () => {
      setBreadcrumbsItems([]);
    };
  }, [setBreadcrumbsItems]);

  useEffect(() => {
    dispatch(fetchSitesDetailData(data));
  }, [dispatch, data]);

  useEffect(() => {
    const arr =
      SitesDetail?.map((item, idx) => ({
        idx: idx + 1,
        ...item,
      })) || [];
    setFilterData(arr);
  }, [SitesDetail]);

  const columns = [
    {
      Header: "#",
      accessor: "idx",
      minWidth: 100,
      width: 120,
    },
    {
      Header: "Unit #",
      accessor: "unitNumber",
      minWidth: 100,
      width: 120,
    },
    {
      Header: "Equipment #",
      accessor: "equipmentNumber",
      minWidth: 120,
      width: 140,
    },
    {
      Header: "Serial #",
      accessor: "serialNumber",
      minWidth: 100,
      width: 120,
    },
    {
      Header: "Description",
      accessor: "description",
    },
    {
      Header: "EQUIP TYPE",
      accessor: "equipType",
      minWidth: 100,
      width: 120,
    },
    {
      Header: "P&ID",
      accessor: "pAndId",
      minWidth: 120,
    },
    {
      Header: "National BD. #",
      accessor: "nationalBdNumber",
      minWidth: 150,
    },
    {
      Header: "Insulated",
      accessor: "insulated",
      minWidth: 100,
      width: 120,
    },
    {
      Header: "Design Pressure",
      accessor: "designPressure",
      minWidth: 150,
    },
    {
      Header: "Design Temp.",
      accessor: "designTemp",
      minWidth: 150,
    },
    {
      Header: "OPER. Pressure",
      accessor: "operPressure",
      minWidth: 150,
    },
    {
      Header: "OPER. Temp.",
      accessor: "operTemp",
      minWidth: 120,
    },
    {
      Header: "Year Built",
      accessor: "yearBuilt",
      minWidth: 100,
    },
    {
      Header: "WBS Code",
      accessor: "wbsCode",
      minWidth: 100,
    },
    {
      Header: "WSP #",
      accessor: "wspNumber",
      minWidth: 100,
    },
    {
      Header: "PWHT",
      accessor: "pwht",
      minWidth: 100,
    },
    {
      Header: "Required Test Pressure SS",
      accessor: "requiredTestPressureSS",
      minWidth: 150,
    },
    {
      Header: "Required Hold Time SS",
      accessor: "requiredHoldTimeSS",
      minWidth: 150,
    },
    {
      Header: "Required Test Medium SS",
      accessor: "requiredTestMediumSS",
      minWidth: 150,
    },
    {
      Header: "Required Test Pressure TS",
      accessor: "requiredTestPressureTS",
      minWidth: 150,
    },
    {
      Header: "Required Hold Time TS",
      accessor: "requiredHoldTimeTS",
      minWidth: 150,
    },
    {
      Header: "Required Test Medium TS",
      accessor: "requiredTestMediumTS",
      minWidth: 150,
    },
  ];

  const exportToExcel = () => {
    const headers = columns.map((column) => column.Header);
    const ws = XLSX.utils.aoa_to_sheet([headers]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sites Data");
    XLSX.writeFile(wb, "SitesData.xlsx");
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (!file) {
      setSelectedFile(null);
      setFileName("");
      return;
    }
    setSelectedFile(file);
    setFileName(file.name);
    e.target.value = "";
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleUploadSubmit = async () => {
    if (selectedFile) {
      setLoading(true);
      const formData = new FormData();
      formData.append("excelfile", selectedFile);
      formData.append("jobsiteId", data);
      try {
        const response = await CreateExcelApi(formData);
        if (response && response.success) {
          dispatch(fetchSitesDetailData(data));
          setsnackBarData({
            openToast: true,
            type: response.snackBarData?.type || "success",
            message: response.msg || "File uploaded successfully.",
          });
        } else {
          setsnackBarData({
            openToast: true,
            type: "error",
            message: response?.msg || "Failed to upload the file.",
          });
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        setsnackBarData({
          type: "error",
          message: "Error uploading file.",
          openToast: true,
        });
      } finally {
        setLoading(false);
        setSelectedFile(null);
        setFileName("");
        fileInputRef.current.value = "";
      }
    } else {
      setsnackBarData({
        type: "error",
        message: "Please select a file to upload.",
        openToast: true,
      });
    }
  };

  const handleClearFile = () => {
    setSelectedFile(null);
    setFileName("");
    fileInputRef.current.value = "";
  };

  return (
    <>
      <div className="mb-2 d-flex justify-content-between align-items-center">
        <button
          className="btn btn-primary"
          style={{ marginRight: "20px" }}
          onClick={exportToExcel}
          disabled={loading}
        >
          Extract to Excel
        </button>
        <div className="d-flex gap-2" style={{ justifyContent: "flex-end" }}>
          <input
            type="file"
            accept=".xlsx, .xls"
            onChange={handleFileChange}
            style={{ display: "none" }}
            ref={fileInputRef}
          />
          <button
            className="btn btn-secondary"
            onClick={handleUploadClick}
            disabled={loading}
          >
            Upload Excel File
          </button>
          <button
            className="btn btn-primary"
            onClick={handleUploadSubmit}
            disabled={loading || selectedFile == null}
          >
            Submit
          </button>
        </div>
      </div>
      {fileName && (
        <div
          className="mb-3 d-flex  gap-2"
          style={{
            textAlign: "right",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <strong>Selected File:</strong>
          <button type="button" className="btn btn-secondary">
            {fileName}
          </button>
          <button
            aria-label="clear"
            onClick={handleClearFile}
            size="small"
            style={{ marginLeft: "8px" }}
          >
            <FaTrash />
          </button>
        </div>
      )}
      {status === "loading" || loading ? (
        <div className="text-center">
          <Loader />
        </div>
      ) : (
        <div>
          <p style={{ color: "#1c4e80", fontWeight: 600, marginTop: "5px" }}>
            Equipment Detail
          </p>
          <CustomGrid columns={columns} data={filterData} />
        </div>
      )}
    </>
  );
}

export default SitesView;
