import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { FaUsers } from "react-icons/fa"; // Using FontAwesome Icons

function Roles({ handleBreadcrumbs }) {
  const navigate = useNavigate();

  const handleNavigation = (path, name) => {
    navigate(path);
    handleBreadcrumbs(name, path);
  };

  return (
    <div className="py-4">
      {/* <h3>Roles</h3>
      <p>Manage all your roles and user related settings in the system.</p> */}

      <div className="paperBox shadow">
        <div className="paperContentBox">
          {/* Roles Card */}
          {/* <div
            onClick={() => handleNavigation("/settings/roles", "Roles")}
            className="settingBoxA"
            id="settings-roles"
          >
            <div className="contentBox">
              <FaCogs size={40} />
              <div className="textBox">
                <h6>Roles</h6>
                <p className="setingText">
                  Add, edit or delete roles from the system.
                </p>
              </div>
            </div>
          </div> */}

          {/* Users Card */}
          <div
            onClick={() => handleNavigation("/settings/users", "Users")}
            className="settingBoxA"
            id="settings-users"
          >
            <div className="contentBox">
              <FaUsers size={40} />
              <div className="textBox">
                <h6>Users</h6>
                <p className="setingText">
                  Add, edit or delete users from the system.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Roles;

Roles.propTypes = {
  handleBreadcrumbs: PropTypes.func.isRequired,
};
