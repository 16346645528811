import React, { useContext, useEffect } from "react";
import { FormioProvider } from "../../context/FormioContext";
import { Form } from "../../OurForms/Form";
import { useLocation, useNavigate } from "react-router-dom";
import { BreadcrumbsContext } from "../../context/BcrumbsContextProvider";
import { IoMdArrowBack } from "react-icons/io";
function TemplateForm() {
  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state;
  console.log("History data id", data);

  const { setBreadcrumbsItems } = useContext(BreadcrumbsContext);
  const handleBreadcrumbs = () => {
    setBreadcrumbsItems([
      {
        id: 1,
        url: "/",
        name: "Forms",
      },
      {
        id: 2,
        url: "/formview",
        name: "FormView",
      },
    ]);
  };

  useEffect(() => {
    handleBreadcrumbs();
    return () => {
      setBreadcrumbsItems([]);
    };
  }, [setBreadcrumbsItems]);
  return (
    <div style={{ height: "100vh", overflowY: "scroll" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <button
          onClick={() => navigate(-1)}
          style={{
            color: "black",
            cursor: "pointer",
            marginRight: "16px",
            marginBottom: "7px",
          }}
        >
          <IoMdArrowBack fontSize="24px" />
        </button>
        {/* <h1 style={{ fontSize: "24px", fontWeight: "bold" }}>{data?.form?.title}</h1> */}
      </div>

      <p>Form ID: {data}</p>
      <FormioProvider baseUrl={process.env.REACT_APP_API_URL_LOCAL}>
        <Form src={`Forms/${data}?type=Original`} />
      </FormioProvider>
    </div>
  );
}

export default TemplateForm;
