import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/AxiosInstance";
import { revertAll } from "../ResetStateAction";

const initialState = {
  UsersPopulateData: null,
  UsersData: null,
  UserById: null,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

export const fetchUsersPopulateData = createAsyncThunk(
  "/User/Populate",
  async () => {
    const response = await axiosInstance.get(`/User/Populate`);
    return response?.data;
  }
);
export const fetchUsersData = createAsyncThunk("User/GetAll", async () => {
  const response = await axiosInstance.get(`User/GetAll`);
  return response?.data;
});
export const fetchUserById = createAsyncThunk(
  "User/GetUserById",
  async (id) => {
    const response = await axiosInstance.get(`/User/GetUserById/${id || " "}`);
    return response?.data;
  }
);

const UsersSlice = createSlice({
  name: "UsersSlice",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchUsersPopulateData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUsersPopulateData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.UsersPopulateData = action.payload.data;
      })
      .addCase(fetchUsersPopulateData.rejected, (state, action) => {
        state.UsersPopulateData = null;
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchUsersData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUsersData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.UsersData = action.payload.data;
      })
      .addCase(fetchUsersData.rejected, (state, action) => {
        state.UsersData = null;
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchUserById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.UserById = action.payload;
      })
      .addCase(fetchUserById.rejected, (state, action) => {
        state.UsersData = null;
        state.error = action.error.message;
        state.status = "failed";
      });
  },
});

export default UsersSlice.reducer;
