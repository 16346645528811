import React, { useEffect } from "react";
import PropTypes from "prop-types";
import OTPInput from "react-otp-input";
import { Form, Button, Alert } from "react-bootstrap";

function VerifyOTP({
  values,
  errors,
  touched,
  handleBlur,
  setFieldValue,
  otpSending,
  resendOTP,
}) {
  useEffect(() => {
    const countdown = setInterval(() => {
      if (values?.duration > 0) {
        setFieldValue("duration", values?.duration - 1);
      } else {
        clearInterval(countdown);
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, [values?.duration]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return (
    <div>
      <Form.Group className="mb-3">
        <OTPInput
          value={values.otp}
          onChange={(val) => setFieldValue("otp", val)}
          placeholder={true}
          numInputs={4}
          renderInput={(props) => (
            <Form.Control
              type="text"
              name="otp"
              onBlur={handleBlur}
              {...props}
              className="otp-input"
            />
          )}
          inputStyle={{
            width: "67px",
            height: "3rem",
            margin: "0 0.5rem",
            fontSize: "2rem",
            borderRadius: "0.25rem",
            border: "1px solid rgba(0,0,0,0.3)",
          }}
        />
        {touched?.otp && errors?.otp && (
          <Alert variant="danger" className="mt-2">
            {errors?.otp}
          </Alert>
        )}
      </Form.Group>

      <div
        className="d-flex justify-content-between align-items-center mt-3"
        style={{ marginTop: "6px", marginBottom: "15px" }}
      >
        <span>Expires in {formatTime(values?.duration)}</span>

        <div className="d-flex align-items-center gap-2">
          <span>Didn’t receive OTP?</span>
          <Button
            variant="link"
            disabled={otpSending}
            onClick={() => {
              if (!otpSending) resendOTP();
            }}
          >
            Resend
          </Button>
        </div>
      </div>
    </div>
  );
}

export default VerifyOTP;

VerifyOTP.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleBlur: PropTypes.func,
  setFieldValue: PropTypes.func,
  otpSending: PropTypes.bool,
  resendOTP: PropTypes.func,
};
