import React from "react";
import {
  Page,
  Document,
  StyleSheet,
  Font,
  View,
  Text,
  Image,
} from "@react-pdf/renderer";
import { PdfHeader } from "./PdfHeader";
import { CommentFooter } from "./CommentFooter";
import PropTypes from "prop-types";
import moment from "moment";
// Create styles
Font.register({
  family: "Helvetica-Bold",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/helvetica/v7/oU5HfY7Evdle2mP5Xai7nPesZW2xOQ-xsNqO47m55DA.ttf",
      fontWeight: "bold",
    },
  ],
});
const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
  },
  section: {
    margin: 10,
    padding: 10,
    border: "1px solid black",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderLeft: 1,
    borderRight: 0,
    borderBottom: 0,
    borderTop: 0,
    borderColor: "#000",
    marginBottom: 10,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColHeader: {
    borderBottom: 0,
    borderTop: 0,
    borderLeft: 0,
    borderRightWidth: 1,
    width: "25%",
    borderStyle: "solid",
    backgroundColor: "#E4E4E4",
    textAlign: "center",
    paddingTop: 6,
    fontFamily: "Helvetica-Bold",
  },
  tableColHeader2: {
    borderBottom: 1,
    borderTop: 0,
    borderLeft: 0,
    borderRightWidth: 1,
    width: "25%",
    borderStyle: "solid",
    // backgroundColor: "#E4E4E4",
    textAlign: "center",
    paddingTop: 6,
    fontFamily: "Helvetica-Bold",
  },
  tableCol: {
    height: 50,
    borderLeft: 0,
    borderRight: 1,
    borderBottom: 1,
    borderTop: 0,
    width: "25%",
    borderStyle: "solid",
    // borderWidth: 1,
    paddingLeft: 5,
    paddingTop: 6,
    // paddingBottom: 3,
    textAlign: "center",
    position: "relative",
  },
  tableColText: {
    position: "absolute", // Absolutely position the text
    bottom: 0, // Align the text to the bottom
    left: 0,
    right: 0,
    padding: 1,
  },
  tableColHeader1: {
    borderBottom: 1,
    borderTop: 1,
    borderLeft: 0,
    borderRightWidth: 1,
    width: "25%",
    borderStyle: "solid",
    backgroundColor: "#fcfafa",
    textAlign: "center",
    paddingTop: 6,
    paddingLeft: 1,
    // fontFamily: "Helvetica-Bold",
  },
  title: {
    // fontSize: 14,
    // marginBottom: 10,
    textAlign: "left",

    fontFamily: "Helvetica-Bold",
  },
  image: {
    width: "100%",
    height: "100%",
  },
});

// Create Document Component
export const IcForm = ({ data, rolesData }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.table}>
        <PdfHeader data={data} title={"Internal Closure Form"} />
        <View style={styles.tableRow}>
          <Text style={{ ...styles.tableColHeader, width: "100%" }}>
            Closure Verification
          </Text>
        </View>
        {/* Row 1 */}
        {/* Row 2 */}
        <View style={styles.tableRow}>
          <Text style={{ ...styles.tableColHeader1, ...styles.title }}>
            Hold Points
          </Text>
          <Text style={styles.tableColHeader1}>QA Name</Text>
          <Text style={styles.tableColHeader1}>QA Signature</Text>
          <Text style={styles.tableColHeader1}>Date</Text>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableCol}>
            <Text style={{ ...styles.tableColText, ...styles.title }}>
              Internal Inspected
            </Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={{ ...styles.tableColText }}>
              {rolesData?.qainspector?.username}
            </Text>
          </View>
          <View style={styles.tableCol}>
            {rolesData?.qainspector?.signature && (
              <Image
                style={{ ...styles.image }}
                src={rolesData?.qainspector?.signature}
              />
            )}
          </View>
          <View style={styles.tableCol}>
            <Text style={{ ...styles.tableColText }}>
              {rolesData?.qainspector?.statusUpdatedAt
                ? moment(rolesData?.qainspector?.statusUpdatedAt).format(
                    "MMM Do YYYY hh:mm:ss"
                  )
                : ""}
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableCol}>
            <Text style={{ ...styles.tableColText, ...styles.title }}>
              Repairs Complete{" "}
            </Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={{ ...styles.tableColText }}>
              {rolesData?.qainspector?.username}
            </Text>
          </View>
          <View style={styles.tableCol}>
            {rolesData?.qainspector?.signature && (
              <Image
                style={{ ...styles.image }}
                src={rolesData?.qainspector?.signature}
              />
            )}
          </View>
          <View style={styles.tableCol}>
            <Text style={{ ...styles.tableColText }}>
              {rolesData?.qainspector?.statusUpdatedAt
                ? moment(rolesData?.qainspector?.statusUpdatedAt).format(
                    "MMM Do YYYY hh:mm:ss"
                  )
                : ""}
            </Text>
          </View>
        </View>
        {/* Process Engineer Verification */}
        <View style={styles.tableRow}>
          <Text
            style={{
              ...styles.tableColHeader2,
              ...styles.title,
              textAlign: "center",
              width: "100%",
            }}
          >
            Process Engineer Verification
          </Text>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableCol}>
            <Text style={{ ...styles.tableColText, ...styles.title }}>
              Internal Hardware Installed and Inspected (Process/Operations)
            </Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={{ ...styles.tableColText }}>
              {rolesData?.processengineer?.username}
            </Text>
          </View>
          <View style={styles.tableCol}>
            {rolesData?.processengineer?.signature && (
              <Image
                style={{ ...styles.image }}
                src={rolesData?.processengineer?.signature}
              />
            )}
          </View>
          <View style={styles.tableCol}>
            <Text style={{ ...styles.tableColText }}>
              {rolesData?.processengineer?.statusUpdatedAt
                ? moment(rolesData?.processengineer?.statusUpdatedAt).format(
                    "MMM Do YYYY hh:mm:ss"
                  )
                : ""}
            </Text>
          </View>
        </View>
        {/* Internal closure */}
        <View style={styles.tableRow}>
          <Text
            style={{
              ...styles.tableColHeader2,
              width: "100%",
            }}
          >
            Internal closure
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text
            style={{
              ...styles.tableColHeader2,
            }}
          >
            Release Type
          </Text>
          <Text
            style={{
              ...styles.tableColHeader2,
            }}
          >
            Release Description
          </Text>{" "}
          <Text
            style={{
              ...styles.tableColHeader2,
            }}
          >
            Signature
          </Text>{" "}
          <Text
            style={{
              ...styles.tableColHeader2,
            }}
          >
            Date
          </Text>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableCol}>
            <Text style={{ ...styles.tableColText }}>{data.releaseType}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={{ ...styles.tableColText }}>
              {data.releaseDescription}
            </Text>
          </View>
          <View style={styles.tableCol}>
            {rolesData?.processengineer?.signature && (
              <Image
                style={{ ...styles.image }}
                src={rolesData?.processengineer?.signature}
              />
            )}
          </View>
          <View style={styles.tableCol}>
            <Text style={{ ...styles.tableColText }}>
              {rolesData?.processengineer?.statusUpdatedAt
                ? moment(rolesData?.processengineer?.statusUpdatedAt).format(
                    "MMM Do YYYY hh:mm:ss"
                  )
                : ""}
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View
            style={{ ...styles.tableCol, position: "static", width: "100%" }}
          >
            <Text style={{ ...styles.title }}>Comments</Text>
            <Text style={{ textAlign: "left" }}>{data?.comments}</Text>
          </View>
        </View>
        {/* Required  Release Signatures */}
        <View style={styles.tableRow}>
          <Text
            style={{ ...styles.tableColHeader, width: "100%", borderBottom: 1 }}
          >
            Required Release Signatures
          </Text>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableCol}>
            <Text style={{ ...styles.tableColText, ...styles.title }}>
              Operation Closure
            </Text>
          </View>
          <View style={styles.tableCol}>
            {rolesData?.operationalmanager?.signature && (
              <Image
                style={{ ...styles.image }}
                src={rolesData?.operationalmanager?.signature}
              />
            )}
          </View>
          <View style={styles.tableCol}>
            {rolesData?.operationalmanager?.signature && (
              <Image
                style={{ ...styles.image }}
                src={rolesData?.operationalmanager?.signature}
              />
            )}
          </View>
          <View style={styles.tableCol}>
            {rolesData?.operationalmanager?.signature && (
              <Image
                style={{ ...styles.image }}
                src={rolesData?.operationalmanager?.signature}
              />
            )}
          </View>
        </View>{" "}
        <View style={styles.tableRow}>
          <View style={styles.tableCol}>
            <Text style={{ ...styles.tableColText, ...styles.title }}>
              Internal Closure QC Inspector
            </Text>
          </View>
          <View style={styles.tableCol}>
            {rolesData?.qcinspector?.signature && (
              <Image
                style={{ ...styles.image }}
                src={rolesData?.qcinspector?.signature}
              />
            )}
          </View>
          <View style={styles.tableCol}>
            {rolesData?.qcinspector?.signature && (
              <Image
                style={{ ...styles.image }}
                src={rolesData?.qcinspector?.signature}
              />
            )}
          </View>
          <View style={styles.tableCol}>
            {rolesData?.qcinspector?.signature && (
              <Image
                style={{ ...styles.image }}
                src={rolesData?.qcinspector?.signature}
              />
            )}
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableCol}>
            <Text style={{ ...styles.tableColText, ...styles.title }}>
              QA Inspector Closure
            </Text>
          </View>
          <View style={styles.tableCol}>
            {rolesData?.qainspector?.signature && (
              <Image
                style={{ ...styles.image }}
                src={rolesData?.qainspector?.signature}
              />
            )}
          </View>
          <View style={styles.tableCol}>
            {rolesData?.qainspector?.signature && (
              <Image
                style={{ ...styles.image }}
                src={rolesData?.qainspector?.signature}
              />
            )}
          </View>
          <View style={styles.tableCol}>
            {rolesData?.qainspector?.signature && (
              <Image
                style={{ ...styles.image }}
                src={rolesData?.qainspector?.signature}
              />
            )}
          </View>
        </View>
        <CommentFooter data={data} />
      </View>
    </Page>
  </Document>
);
IcForm.propTypes = {
  data: PropTypes.object,
  rolesData: PropTypes.any,
};
