import React from "react";
import AppDialog from "../../common/AppDialog/AppDialog";
import PropTypes from "prop-types";
import moment from "moment";

function InspectionDetailDialog({ open, onClose, state }) {
  return (
    <AppDialog
      open={open}
      onClose={() => {
        onClose(false);
      }}
      title="Company Details"
      minHeight="auto"
      maxWidth="md"
    >
      <div>
        <h5 className="text-primary">General Details</h5>
        <hr />

        <div className="row mb-3">
          <div className="col-md-6 mb-3">
            <strong>Name</strong>
            <p>{state?.name || "N/A"}</p>
          </div>
          <div className="col-md-6 mb-3">
            <strong>Type</strong>
            <p>{state?.type || "N/A"}</p>
          </div>
          <div className="col-md-6 mb-3">
            <strong>Phone</strong>
            <p>{state?.phone || "N/A"}</p>
          </div>
          <div className="col-md-6 mb-3">
            <strong>Email</strong>
            <p>{state?.email || "N/A"}</p>
          </div>
          <div className="col-md-6 mb-3">
            <strong>Created By</strong>
            <p>{state?.addedByUser?.name || "N/A"}</p>
          </div>
          <div className="col-md-6 mb-3">
            <strong>Updated By</strong>
            <p>{state?.updatedByUser?.name || "N/A"}</p>
          </div>
          <div className="col-md-6 mb-3">
            <strong>Created At</strong>
            <p>
              {state?.createdAt
                ? moment(state?.createdAt).format("Do MMM-YYYY")
                : "N/A"}
            </p>
          </div>
          <div className="col-md-6 mb-3">
            <strong>Updated At</strong>
            <p>
              {state?.updatedAt
                ? moment(state?.updatedAt).format("Do MMM-YYYY")
                : "N/A"}
            </p>
          </div>
        </div>
      </div>

      <div>
        <h5 className="text-primary">Address Details</h5>
        <hr />

        <div className="row mb-3">
          <div className="col-12 mb-3">
            <strong>Address</strong>
            <p>{state?.address || "N/A"}</p>
          </div>
          <div className="col-md-4 mb-3">
            <strong>State</strong>
            <p>{state?.state || "N/A"}</p>
          </div>
          <div className="col-md-4 mb-3">
            <strong>City</strong>
            <p>{state?.city || "N/A"}</p>
          </div>
          <div className="col-md-4 mb-3">
            <strong>Zip Code</strong>
            <p>{state?.zipCode || "N/A"}</p>
          </div>
        </div>
      </div>

      {state?.notes?.length > 0 && (
        <div className="mt-3">
          <h5 className="text-primary">Notes</h5>
          <hr />

          <div className="table-responsive" style={{ maxHeight: "300px" }}>
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th scope="col" style={{ width: "5%" }}>
                    Sr.
                  </th>
                  <th scope="col">Notes</th>
                  <th scope="col" style={{ width: "20%" }}>
                    Created By
                  </th>
                  <th scope="col" style={{ width: "20%" }}>
                    Updated By
                  </th>
                </tr>
              </thead>
              <tbody>
                {state?.notes?.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td dangerouslySetInnerHTML={{ __html: item?.note }}></td>
                    <td>{item?.createdByUser?.name || "N/A"}</td>
                    <td>{item?.updatedByUser?.name || "N/A"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </AppDialog>
  );
}

export default InspectionDetailDialog;

InspectionDetailDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  state: PropTypes.any,
};
