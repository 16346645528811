import axiosInstance from "../../utils/AxiosInstance";

export const LoginAPi = async (data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.post("/Auth/Login", data);
    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    if (response?.data?.success) {
      // Save tokens and user data to session storage
      sessionStorage.setItem("access_token", response?.data?.data?.token);
      sessionStorage.setItem("LoginId", response?.data?.data?.user?.id);
      sessionStorage.setItem("isVerified", response?.data?.data?.isVerified);
      sessionStorage.setItem("userEmail", response?.data?.data?.user?.email);

      if (response.data?.data?.isSuperAdmin) {
        sessionStorage.setItem(
          "isSuperAdmin",
          response.data?.data?.isSuperAdmin
        );
      }
      if (response.data?.data?.isEmployee) {
        sessionStorage.setItem("isEmployee", response.data?.data?.isEmployee);
      }
      if (response.data?.data?.isAdmin) {
        sessionStorage.setItem("isAdmin", response.data?.data?.isAdmin);
      }
    }

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const ForgotPasswordAPi = async (data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.post("/Auth/ForgetPassword", data);

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const SendOTPAPi = async (data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.post("/Auth/SendOtp", data);

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const VerifyOTPAPi = async (data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.post("/Auth/VerifyOTP", data);

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const ResetPasswordAPi = async (data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.post("/Auth/ResetPassword", data);

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const UserVerification = async () => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.get(
      "/User/UserisVerified?isVerified=true"
    );

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };
    if (response?.data?.success) {
      sessionStorage.setItem("isVerified", response?.data?.data?.isVerified, {
        path: "/",
      });
    }

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
