import React, { createContext, useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(sessionStorage.getItem("LoginId"));
  const [isVerified, setIsVerified] = useState(
    sessionStorage.getItem("isVerified")
  );
  const [userRoles, setUserRoles] = useState({
    isAdmin: sessionStorage.getItem("isAdmin"),
    isEmployee: sessionStorage.getItem("isEmployee"),
    isSuperAdmin: sessionStorage.getItem("isSuperAdmin"),
  });
  const navigate = useNavigate();

  const signup = (user, verified) => {
    setUser(user);
    setIsVerified(verified);
  };

  const setRoles = (userRoles) => {
    setUserRoles(userRoles);
  };

  useEffect(() => {
    if (isVerified === "false") {
      navigate("/signin");
    }
  }, [isVerified, navigate]);

  return (
    <AuthContext.Provider
      value={{ user, signup, userRoles, setUserRoles, setRoles, isVerified }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

AuthProvider.propTypes = {
  children: PropTypes.any.isRequired,
};
