import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Button, Tooltip } from "react-bootstrap";
import moment from "moment";
import { FaEdit, FaTrashAlt } from "react-icons/fa"; // Using FontAwesome Icons
import {
  fetchAllRolesData,
  fetchRolePopulateData,
} from "../../redux/slice/RolesSlice/RolesSlice";
import AddUpdateRoleDialog from "../../components/Settings/Roles/ScreenComponents/AddUpdateRoleDialog/AddUpdateRoleDialog";
import Loader from "../../components/Loader/Loader";
import AppConfirmationDialog from "../../components/ConfirmationDialog/ConfirmationDialog";
import { DeleteRoleAPi } from "../../Apis/Roles/RolesApis";

function RolesPage() {
  const [alldata, setalldata] = useState([]);
  const [rows, setrows] = useState([]);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  console.log("showConfirmation", showConfirmation);
  const dispatch = useDispatch();
  const { AllRolesData, status } = useSelector((state) => state?.Roles);

  useEffect(() => {
    dispatch(fetchAllRolesData());
    dispatch(fetchRolePopulateData());
  }, [dispatch]);

  useEffect(() => {
    if (AllRolesData) {
      setalldata(AllRolesData);
    } else {
      setalldata([]);
    }
  }, [AllRolesData]);

  const deleteRole = async () => {
    setLoading(true);
    const response = await DeleteRoleAPi(selectedRow?.id);
    if (response.success) {
      setShowConfirmation(false);
      dispatch(fetchAllRolesData());
    }
    setSelectedRow(null);
    setLoading(false);
  };
// console.log("selected row", selectedRow);
  const CustomToolbar = () => {
    return (
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div>
          <Button
            variant="primary"
            size="sm"
            onClick={() => setShowAddDialog(true)}
          >
            New
          </Button>
        </div>
      </div>
    );
  };

  const columns = [
    { field: "idx", headerName: "Sr", minWidth: 50 },
    {
      field: "roleName",
      headerName: "Name",
      minWidth: 170,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      minWidth: 120,
      valueFormatter: (value) => moment(new Date(value)).format("MM/DD/YYYY"),
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      minWidth: 120,
      valueFormatter: (value) => moment(new Date(value)).format("MM/DD/YYYY"),
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 70,
      renderCell: (row) => (
        <div className="d-flex">
          <Tooltip title="Edit">
            <Button
              variant="primary"
              size="sm"
              onClick={() => {
                setSelectedRow(row);
                setShowAddDialog(true);
              }}
              className="me-2"
            >
              <FaEdit />
            </Button>
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              variant="danger"
              size="sm"
              onClick={() => {
                setShowConfirmation(true);
                setSelectedRow(row);
              }}
            >
              <FaTrashAlt />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const arr = alldata.map((item, idx) => ({
      idx: idx + 1,
      ...item,
      createdAt: moment(item.createdAt).format("MM/DD/YYYY"),
      updatedAt: moment(item.updatedAt).format("MM/DD/YYYY"),
    }));
    setrows(arr);
  }, [alldata]);

  return (
    <>
      <CustomToolbar />

      <Table striped bordered hover>
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column.field}>{column.headerName}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <tr key={row.id}>
              <td>{row.idx}</td>
              <td>{row.roleName}</td>
              <td>{row.createdAt}</td>
              <td>{row.updatedAt}</td>
              <td>
                {
                  <div className="d-flex">
                    <FaEdit
                      onClick={() => {
                        setSelectedRow(row);
                        setShowAddDialog(true);
                      }}
                    />

                    <FaTrashAlt
                      onClick={() => {
                        setShowConfirmation(true);
                        setSelectedRow(row);
                      }}
                    />
                  </div>
                }
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {loading || status === "loading" ? <Loader /> : null}

      <AddUpdateRoleDialog
        open={showAddDialog}
        onClose={() => setShowAddDialog(false)}
        selectedRow={selectedRow}
      />

      {showConfirmation && (
        <AppConfirmationDialog
          onDeny={() => setShowConfirmation(false)}
          open={showConfirmation}
          onConfirm={deleteRole}
          iconContainerColor="#fbeaea"
          icon={<FaTrashAlt className="text-danger" />}
          dialogTitle="Delete Role"
          title={`Are you sure you want to delete ${selectedRow?.roleName}?`}
          successText="Yes"
          successBtnColor="danger"
          cancelText="No"
        />
      )}
    </>
  );
}

export default RolesPage;
