import { FaEdit } from "react-icons/fa";
import { FaCog, FaBook, FaFileSignature } from "react-icons/fa";
import {
  MdApartment,
  MdConstruction,
  MdOutlineFactCheck,
  MdPictureAsPdf,
} from "react-icons/md";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { MdOutlineDashboard } from "react-icons/md";
import { ImStatsBars } from "react-icons/im";

export const adminSideBarData = [
  {
    name: "Statistics",
    icon: (color) => <ImStatsBars color={color} />,
    url: "/",
    subItems: [],
    roleName: "Admin",
  },
  {
    name: "Dashboard",
    icon: (color) => <MdOutlineDashboard color={color} />,
    url: "/dashboard",
    subItems: [],
    roleName: "Admin",
  },
  {
    name: "Create Forms",
    icon: (color) => <FaEdit color={color} />,
    url: "/create-forms",
    subItems: [],
    roleName: "Admin",
  },
  {
    name: "Forms",
    icon: (color) => <FaBook color={color} />,
    url: "/forms",
    subItems: [],
    roleName: "Admin",
  },
  {
    name: "Companies",
    icon: (color) => <MdApartment color={color} />,
    url: "/companies",
    subItems: [],
    roleName: "Admin",
  },
  {
    name: "Sites",
    icon: (color) => <MdConstruction color={color} />,
    url: "/sites",
    subItems: [],
    roleName: "Admin",
  },
  {
    name: "Packages",
    icon: (color) => <MdOutlineFactCheck color={color} />,
    url: "/package/pending",
    open: false,
    subItems: [
      {
        name: "Pending Packages",
        menuName: "Pending",
        url: "/package/pending",
      },
      {
        name: "In Progress Packages",
        menuName: "In Progress",
        url: "/package/in-progress",
      },
      {
        name: "Completed Packages",
        menuName: "Completed",
        url: "/package/completed",
      },
      {
        name: "Cancelled Packages",
        menuName: "Cancelled",
        url: "/package/cancelled",
      },
      {
        name: "On Hold Packages",
        menuName: "On Hold",
        url: "/package/on-hold",
      },
    ],
    roleName: "Admin",
  },

  {
    name: "My Packages",
    icon: (color) => <MdOutlineFactCheck color={color} />,
    url: "/my-package/pending",
    open: false,
    subItems: [
      {
        name: "Pending Packages",
        menuName: "Pending",
        url: "/my-package/pending",
      },
      {
        name: "Submitted Packages",
        menuName: "Submitted",
        url: "/my-package/submitted",
      },
    ],
    roleName: "Admin",
  },
  {
    name: "My Approval",
    icon: (color) => <IoMdCheckmarkCircleOutline color={color} />,
    url: "/my-approval/pending",
    open: false,
    subItems: [
      {
        name: "Pending Packages",
        menuName: "Pending",
        url: "/my-approval/pending",
      },
      {
        name: "Rejected Packages",
        menuName: "Rejected",
        url: "/my-approval/rejected",
      },
      {
        name: "Approved Packages",
        menuName: "Approved",
        url: "/my-approval/approved",
      },
    ],
    roleName: "Admin",
  },

  {
    name: "PDF",
    icon: (color) => <MdPictureAsPdf color={color} />,
    url: "/pdf",
    subItems: [],
    roleName: "Admin",
  },
  {
    name: "Signature",
    icon: (color) => <FaFileSignature color={color} />,
    url: "/signature",
    subItems: [],
    roleName: "Admin",
  },
  {
    name: "Settings",
    icon: (color) => <FaCog color={color} />,
    url: "/settings",
    subItems: [],
    roleName: "Admin",
  },
];
