import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchTrendsData,
  fetchEquipmentsData,
} from "../../../redux/slice/DashboardSlice/DashboardSlice";
import LineChart from "./charts/LineChart";
import { fetchJobSiteData } from "../../../redux/slice/InspectionSlice/InspectionSlice";
import {
  fetchJobSiteCount,
  fetchEquipTypes,
} from "../../../redux/slice/SitesSlice/SitesSlice";
import Loader from "../../Loader/Loader";
import PropTypes from "prop-types";
import { fetchRolesCountData } from "../../../redux/slice/DashboardSlice/DashboardSlice";
import EquipmentLineChart from "./charts/EquipmentLineChart";
import ColumnsChart from "./charts/ColumnsChart";
import BarChart from "./charts/BarChart";

function StatCharts({
  endDate,
  startDate,
  search,
  setSearch,
  comp,
  jobSiteId,
}) {
  const dispatch = useDispatch();
  const [selectedJobsite, setSelectedJobsite] = useState(null);
  const [selectedEquipmentType, setSelectedEquipmentType] = useState(null);
  const { JobSiteData, status } = useSelector((state) => state?.Inspections);
  const { TrendsData, RolesCount, EquipmentsData } = useSelector(
    (state) => state.Dashboard
  );
  const { JobSiteCount, EquipTypes } = useSelector((state) => state.Sites);
  const formattedData = TrendsData
    ? TrendsData?.data?.map((item) => ({
        date: item.date,
        count: item.count,
      }))
    : [];

  useEffect(() => {
    if (search && comp) {
      dispatch(
        fetchTrendsData({
          start: startDate,
          end: endDate,
          compId: comp,
          jobSite: jobSiteId,
        })
      );
      dispatch(
        fetchRolesCountData({
          start: startDate,
          end: endDate,
          compId: comp,
          jobSite: jobSiteId,
        })
      );
      dispatch(
        fetchEquipmentsData({
          start: startDate,
          end: endDate,
          compId: comp,
          jobSite: jobSiteId,
        })
      );
      setSelectedEquipmentType(null);
    }
  }, [dispatch, search, comp]);

  useEffect(() => {
    if (comp && search) {
      dispatch(fetchJobSiteData(comp));
      setSelectedEquipmentType(null);
      setSelectedJobsite(null);
    }
  }, [comp, search]);

  useEffect(() => {
    if (selectedJobsite) {
      dispatch(
        fetchJobSiteCount({
          id: selectedJobsite,
          start: startDate,
          end: endDate,
          type: selectedEquipmentType,
        })
      );
      dispatch(
        fetchEquipTypes({
          id: selectedJobsite,
        })
      );
      setSelectedEquipmentType(null);
      setSearch(false);
    }
  }, [selectedJobsite]);

  useEffect(() => {
    if (selectedEquipmentType) {
      dispatch(
        fetchJobSiteCount({
          id: selectedJobsite,
          start: startDate,
          end: endDate,
          type: selectedEquipmentType,
        })
      );
    }
  }, [selectedEquipmentType]);
  useEffect(() => {
    if (JobSiteData?.data?.JobSitesData?.length > 0 && !jobSiteId) {
      const defaultJobsite = JobSiteData.data.JobSitesData[0]?.id;
      setSelectedJobsite(defaultJobsite);
    } else if (jobSiteId) {
      setSelectedJobsite(jobSiteId);
    }
  }, [JobSiteData]);

  return (
    <div className="mb-4 " style={{ marginTop: "50px" }}>
      <div className="row">
        <div className="col-12 col-xl-7 mb-3">
          <div className="bg-white shadow rounded p-3">
            <div className="mb-3" style={{ minHeight: "235px" }}>
              <EquipmentLineChart
                data={EquipmentsData ? EquipmentsData?.data : []}
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-xl-5 ">
          <div className="bg-white shadow rounded p-3">
            <div className="mb-2 d-flex justify-content-between pb-2">
              <div className="d-flex gap-4 w-90">
                <select
                  className="form-select w-60 "
                  aria-label="Default select example"
                  disabled={jobSiteId}
                  value={selectedJobsite || ""}
                  onChange={(event) => setSelectedJobsite(event.target.value)}
                >
                  <option value="">Select JobSite</option>
                  {JobSiteData &&
                  Array.isArray(JobSiteData?.data?.JobSitesData) &&
                  JobSiteData?.data?.JobSitesData?.length > 0 ? (
                    JobSiteData?.data?.JobSitesData?.map((site) => (
                      <option key={site.id} value={site?.id}>
                        {site.name}
                      </option>
                    ))
                  ) : (
                    <option disabled>No job sites available</option>
                  )}
                </select>

                <select
                  className="form-select w-60 "
                  value={selectedEquipmentType || ""}
                  onChange={(event) =>
                    setSelectedEquipmentType(event.target.value)
                  }
                >
                  <option value="null">All</option>
                  {EquipTypes &&
                  Array.isArray(EquipTypes) &&
                  EquipTypes.length > 0 ? (
                    EquipTypes?.map((site, index) => (
                      <option key={index} value={site?.equipType}>
                        {site.equipType}
                      </option>
                    ))
                  ) : (
                    <option disabled>No job sites available</option>
                  )}
                </select>
              </div>
            </div>
            <div style={{ minHeight: "235px" }}>
              <ColumnsChart
                data={
                  JobSiteCount && selectedJobsite && JobSiteCount.length > 0
                    ? JobSiteCount[0]
                    : {}
                }
                comp={comp}
                jobSite={selectedJobsite}
                equipType={selectedEquipmentType}
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-xl-12 " style={{ marginTop: "10px" }}>
          <div className="bg-white shadow rounded p-3">
            <BarChart data={RolesCount ? RolesCount?.data : []} />
          </div>
        </div>

        <div className="col-12 col-xl-12 " style={{ marginTop: "30px" }}>
          <div className="bg-white shadow rounded p-3">
            <LineChart data={formattedData} />
          </div>
        </div>
      </div>
      {status === "loading" ? <Loader /> : null}
    </div>
  );
}

export default StatCharts;
StatCharts.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  setSearch: PropTypes.func,
  search: PropTypes.bool,
  comp: PropTypes.string,
  jobSiteId: PropTypes.string,
};
