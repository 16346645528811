import axiosInstance, { axiosInstanceForm } from "../../utils/AxiosInstance";

export const CreateCompanyApi = async (data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstanceForm.post("/Company/Create", data);

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const UpdateCompanyApi = async (id, data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstanceForm.patch(`/Company/Update/${id}`, data);

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
export const DeleteCompanyApi = async (id) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.delete(`/Company/Delete/${id}`);

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
