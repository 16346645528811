import React from "react";
import AppDialog from "../../common/AppDialog/AppDialog";
import PropTypes from "prop-types";

function CompaniesDetailDialog({ open, onClose, state }) {
  console.log("state", state);
  function formatDigits(value) {
    // Check for null, undefined, or empty values
    if (!value || typeof value !== "string") {
      return "";
    }

    // Remove non-digit characters
    const digits = value.replace(/\D/g, "");

    // Return an empty string if there are no digits
    if (digits.length === 0) {
      return "";
    }

    // Format the string into groups separated by spaces
    return `+1 ${digits.slice(0, 3)} ${digits.slice(3, 6)} ${digits.slice(
      6
    )}`.trim();
  }
  return (
    <AppDialog
      open={open}
      onClose={() => {
        onClose(false);
      }}
      title="Company Details"
      minHeight="auto"
      maxWidth="lg"
    >
      <div>
        <div className="row mb-3">
          <div className="col-md-6 mb-3">
            <strong>Name</strong>
            <p>{state?.companyName || "N/A"}</p>
          </div>
          <div className="col-md-6 mb-3">
            <strong>Phone</strong>
            <p>{formatDigits(state?.phoneNumber) || "N/A"}</p>
          </div>
          <div className="col-md-6 mb-3">
            <strong>Email</strong>
            <p>{state?.email || "N/A"}</p>
          </div>
        </div>
      </div>

      <div>
        <div className="row mb-3">
          <div className="col-12 mb-3">
            <strong>Address</strong>
            <p>{state?.address || "N/A"}</p>
          </div>
          <div className="col-md-4 mb-3">
            <strong>Country</strong>
            <p>{state?.country || "N/A"}</p>
          </div>
          <div className="col-md-4 mb-3">
            <strong>State</strong>
            <p>{state?.state || "N/A"}</p>
          </div>
          <div className="col-md-4 mb-3">
            <strong>City</strong>
            <p>{state?.city || "N/A"}</p>
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-12 mb-3">
          <strong>Forms</strong>
          <div>
            {state?.forms?.map((form, index) => (
              <span
                key={index}
                style={{
                  backgroundColor: "#f5f5f5",
                  borderRadius: "8px",
                  padding: "4px 8px",
                  marginRight: "5px",
                  display: "inline-block",
                  marginTop: "10px",
                }}
              >
                {form?.title}
              </span>
            ))}
          </div>
        </div>
      </div>
    </AppDialog>
  );
}

export default CompaniesDetailDialog;

CompaniesDetailDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  state: PropTypes.any,
};
