import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Spinner, Form, Row, Col } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { MdDelete } from "react-icons/md";
import { fetchCompanyDataPopulate } from "../../../redux/slice/CompanySlice/CompanySlice";
const AddPdfModel = ({
  open,
  onDeny,
  icon,
  dialogTitle,
  successText,
  cancelText,
  successBtnColor,
  onSubmit,
}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [allJobSites, setAllJobSites] = useState([]);
  const { CompanyDataPopulate } = useSelector((state) => state?.Companies);
  const styles = {
    container: {
      padding: "20px",
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    formGroup: {
      marginBottom: "20px",
    },
    title: {
      fontSize: "17px",
      fontWeight: 600,
      textAlign: "center",
    },

    removeIcon: {
      cursor: "pointer",
      marginLeft: "10px",
      width: "30px",
      height: "25px",
    },
    fileInputContainer: {
      display: "flex",
      alignItems: "center",
    },
  };

  const fileInputRef = useRef(null);
  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      company: "",
      documents: null,
      jobsiteId: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required"),
      description: Yup.string().required("Description is required"),
      documents: Yup.mixed().required("A file is required"),
      company: Yup.string().required("Company is required"),
      jobsiteId: Yup.string().required("Jobsite selection is required"),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append("title", values.title);
        formData.append("description", values.description);
        formData.append("documents", values.documents);
        formData.append("jobsiteId", values.jobsiteId);

        await onSubmit(formData);
      } catch (error) {
        console.error("Error in submission", error);
      } finally {
        formik.resetForm();
        fileInputRef.current.value = "";
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    dispatch(fetchCompanyDataPopulate());
  }, [dispatch]);
  console.log("company", formik.values);
  useEffect(() => {
    let filteredJobSites;
    if (formik.values.company) {
      filteredJobSites = CompanyDataPopulate.filter(
        (item) => item.id === formik.values.company
      ).flatMap((company) => company.jobSites);
    } else {
      filteredJobSites = CompanyDataPopulate?.flatMap(
        (company) => company.jobSites
      );
    }
    setAllJobSites(filteredJobSites);
  }, [CompanyDataPopulate, formik.values.company]);

  const handleRemoveFile = () => {
    formik.setFieldValue("documents", null);
    fileInputRef.current.value = "";
  };
  return (
    <Modal show={open} onHide={onDeny} centered>
      <div style={styles.container}>
        <Modal.Header closeButton>
          <Modal.Title
            style={styles.title}
            className="d-flex align-items-center justify-content-center gap-2"
          >
            {icon}
            {dialogTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group controlId="formTitle" style={styles.formGroup}>
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}
                placeholder="Enter title"
                isInvalid={!!formik.errors.title && formik.touched.title}
                onBlur={formik.handleBlur}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.title}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formDescription" style={styles.formGroup}>
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                placeholder="Enter description"
                isInvalid={
                  !!formik.errors.description && formik.touched.description
                }
                onBlur={formik.handleBlur}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.description}
              </Form.Control.Feedback>
            </Form.Group>

            <Row>
              <Col md={6}>
                <Form.Group controlId="formDropdown1" style={styles.formGroup}>
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control
                    as="select"
                    name="company"
                    value={formik.values.company}
                    onChange={formik.handleChange}
                    isInvalid={
                      !!formik.errors.company && formik.touched.company
                    }
                    onBlur={formik.handleBlur}
                  >
                    <option value="">Select an option</option>
                    {CompanyDataPopulate?.map((item) => (
                      <option key={item?.id} value={item?.id}>
                        {item?.companyName}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.company}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group
                  controlId="formDropdownJobSite"
                  style={styles.formGroup}
                >
                  <Form.Label>Job Site</Form.Label>
                  <Form.Control
                    as="select"
                    name="jobsiteId"
                    value={formik.values.jobsiteId}
                    onChange={formik.handleChange}
                    isInvalid={
                      !!formik.errors.jobsiteId && formik.touched.jobsiteId
                    }
                    onBlur={formik.handleBlur}
                  >
                    <option value="">Select a job site</option>
                    {allJobSites?.map((site) => (
                      <option key={site.id} value={site.id}>
                        {site.name}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.jobsiteId}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Form.Group controlId="formFile">
              <Form.Label>Upload File (PDF only)</Form.Label>
              <div style={styles.fileInputContainer}>
                <Form.Control
                  type="file"
                  name="documents"
                  ref={fileInputRef}
                  accept=".pdf"
                  onChange={(event) => {
                    const file = event.currentTarget.files[0];
                    if (file && file.type !== "application/pdf") {
                      formik.setFieldError(
                        "documents",
                        "Only PDF files are allowed"
                      );
                    } else {
                      formik.setFieldValue("documents", file);
                    }
                  }}
                  isInvalid={
                    !!formik.errors.documents && formik.touched.documents
                  }
                  onBlur={formik.handleBlur}
                />
                {formik.values.documents && (
                  <MdDelete
                    onClick={handleRemoveFile}
                    color="black"
                    style={styles.removeIcon}
                  />
                )}
              </div>
              <Form.Control.Feedback type="invalid">
                {formik.errors.documents}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {loading ? (
            <Spinner animation="border" />
          ) : (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                style={{
                  width: "40%",
                  textAlign: "center",
                  marginRight: "10px",
                  fontWeight: 600,
                }}
                variant="outline-secondary"
                onClick={onDeny}
              >
                {cancelText}
              </Button>
              <Button
                variant={successBtnColor}
                style={{ width: "80%", textAlign: "center" }}
                type="submit"
                onClick={formik.handleSubmit}
              >
                {successText}
              </Button>
            </div>
          )}
        </Modal.Footer>
      </div>
    </Modal>
  );
};

AddPdfModel.propTypes = {
  open: PropTypes.bool.isRequired,
  onDeny: PropTypes.func.isRequired,
  icon: PropTypes.node,
  dialogTitle: PropTypes.string.isRequired,
  successText: PropTypes.string.isRequired,
  cancelText: PropTypes.string.isRequired,
  CompanyPopulate: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
  successBtnColor: PropTypes.any,
};

export default AddPdfModel;
