import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { IoMdArrowBack } from "react-icons/io";

import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { fetchTemplatesData } from "../../redux/slice/TemplatesSlice/TemplatesSlice";
import { FormioProvider } from "../../context/FormioContext";
import { FormEdit } from "../../OurForms/FormEdit";
import { CiEdit } from "react-icons/ci";
import Loader from "../Loader/Loader";
import CustomGrid from "../../components/common/CustomGrid/CustomGrid";
import { useNavigate } from "react-router-dom";

function TemplatesTable() {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  // const [rows] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  // const [showConfirmation, setShowConfirmation] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [loading] = useState(false);
  // const { setsnackBarData } = useContext(SnackBarContext);
  const [filteredData, setFilteredData] = useState([]);
  const dispatch = useDispatch();
  const { TemplatesData, status } = useSelector((state) => state?.Templates);
  const isSuperAdmin = sessionStorage.getItem("isSuperAdmin");
  // const deleteTemplate = async () => {
  //   setLoading(true);
  //   const response = await DeleteFormApi(selectedRow?._id);
  //   if (response.success) {
  //     // setShowConfirmation(false);
  //     dispatch(fetchTemplatesData());
  //   }
  //   response?.snackBarData?.message && setsnackBarData(response?.snackBarData);
  //   setSelectedRow(null);
  //   setLoading(false);
  // };
  useEffect(() => {
    dispatch(fetchTemplatesData());
  }, [dispatch]);
  useEffect(() => {
    if (TemplatesData && TemplatesData?.length > 0) {
      const processedData = TemplatesData?.map((item, idx) => ({
        id: idx + 1,
        ...item,
      }));
      setRows(processedData);
    } else {
      setRows([]);
    }
  }, [TemplatesData]);

  React.useEffect(() => {
    if (rows.length > 0) {
      setFilteredData(rows);
    } else {
      setFilteredData([]);
    }
  }, [rows]);
  const handleFilterChange = (event) => {
    const value = event.target.value;
    console.log(value);
    const regex = new RegExp(value, "i"); // 'i' flag makes the search case-insensitive

    const filtered = filteredData?.filter(
      (item) => regex.test(item?.title) || regex.test(item?.name)
    );

    setFilteredData(filtered);
  };

  const columns = [
    {
      Header: "Sr",
      accessor: "id", // Accessor is the "key" in the data
      width: "20px",
    },
    {
      Header: "Title",
      accessor: "title",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Type",
      accessor: "type",
    },
    {
      Header: "Form",
      accessor: "form",
      width: "20px",

      disableSortBy: true,
      Cell: (cell) => (
        <>
          <button
            className="btn btn-outline-secondary mx-2 "
            onClick={() => {
              navigate("/formview", { state: cell?.row?.original?._id });
            }}
          >
            View
          </button>
        </>
      ),
    },
    {
      Header: "Actions",
      accessor: "actions",

      disableSortBy: true, // Disable sorting for actions
      Cell: (cell) => (
        <div>
          {isSuperAdmin ? (
            <CiEdit
              title="Edit Form"
              className="icon view-icon"
              onClick={() => {
                setOpenForm(true);
                setSelectedRow(cell?.row?.original);
              }}
            />
          ) : (
            <span style={{ color: "grey" }}>N/A</span>
          )}
          {/* <MdDelete
            title="Edit Template"
            color="red"
            onClick={() => {
              setShowConfirmation(true);
              setSelectedRow(cell?.row?.original);
            }}
          /> */}
          {/* <CiEdit title="View" className="icon view-icon"/>
          <CiEdit title="Edit" className="icon edit-icon" />
          <CiEdit title="Delete" className="icon delete-icon"/> */}
        </div>
      ),
    },
  ];
  return (
    <>
      <div className="mb-2 d-flex justify-content-between">
        <div className="w-50">
          <input
            type="text"
            className="form-control w-50"
            placeholder="Search By Title or Name"
            onChange={handleFilterChange}
          />
        </div>

        <div className="w-50">
          <p style={{ textAlign: "center" }}>
            Total Records:
            <span style={{ color: "#1c4e80", fontWeight: "bold" }}>
              {" "}
              {filteredData.length}
            </span>
          </p>
        </div>
      </div>
      <div className=" mt-2 ">
        <div className="d-flex justify-content-between mb-3">
          {/* <h4>Templates</h4> */}
          {openForm && selectedRow && (
            <Button
              style={{
                color: "black",
                cursor: "pointer",
                backgroundColor: " white",
                border: "none",
              }}
              onClick={() => {
                setSelectedRow(null);
                setOpenForm(false);
              }}
            >
              <IoMdArrowBack fontSize="24px" />
            </Button>
          )}
        </div>

        {!openForm ? (
          <div>
            <CustomGrid columns={columns} data={filteredData} />
          </div>
        ) : null}
      </div>
      {status === "loading" || loading ? <Loader /> : null}

      {openForm && selectedRow && (
        <FormioProvider baseUrl={process.env.REACT_APP_API_URL_LOCAL}>
          <FormEdit
            initialForm={selectedRow}
            height={80}
            edit={true}
            resetForm={() => {
              setSelectedRow(null);
              setOpenForm(false);
              dispatch(fetchTemplatesData());
            }}
          />
        </FormioProvider>
      )}
      {/* {showConfirmation && (
        <AppConfirmationDialog
          open={showConfirmation}
          onDeny={() => setShowConfirmation(false)}
          onConfirm={deleteTemplate}
          iconContainerColor="#fbeaea"
          icon={<FaTrashAlt className="text-danger" />}
          dialogTitle="Delete Template"
          title={`Are you sure you want to delete ${selectedRow?.title}?`}
          successText="Yes"
          successBtnColor="danger"
          cancelText="No"
          loading={loading}
        />
      )} */}
    </>
  );
}

export default TemplatesTable;
