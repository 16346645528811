import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/AxiosInstance";
import { revertAll } from "../ResetStateAction";

const initialState = {
  TemplatesData: null,

  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

export const fetchTemplatesData = createAsyncThunk(
  "Template/GetAll",
  async () => {
    const response = await axiosInstance.get(
      `Forms/GetAll?type=form&limit=10&skip=0`
    );
    return response;
  }
);

const TemplatesSlice = createSlice({
  name: "TemplatesSlice",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)

      .addCase(fetchTemplatesData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTemplatesData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.TemplatesData = action.payload.data?.forms;
      })
      .addCase(fetchTemplatesData.rejected, (state, action) => {
        state.TemplatesData = null;
        state.error = action.error.message;
        state.status = "failed";
      });
  },
});

export default TemplatesSlice.reducer;
