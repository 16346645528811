import React, { useState, useEffect } from "react";
import AppDialog from "../common/AppDialog/AppDialog";
import PropTypes from "prop-types";

function InspectionUserDialog({
  open,
  onClose,
  state,
  stage,
  row,
  setRow,
  selected,
}) {
  const [filteredData, setFilteredData] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [allSelectedUser, setAllSelectedUser] = useState([]);
  const [selectedRole, setSelectedRole] = useState("all");
  const handleClose = () => {
    onClose(false);
    const updatedRows = row.map((rowItem) => {
      if (rowItem.stage === stage) {
        if (selected === "reviewAccess") {
          const reviewAccess = selectedUsers.map((user) => ({
            reviewerId: user.submitById,
            role: user.role,
          }));

          return {
            ...rowItem,
            reviewAccess,
          };
        } else {
          return {
            ...rowItem,
            submissionAccess: selectedUsers,
          };
        }
      }
      return rowItem;
    });

    setRow(updatedRows);
    setSelectedUsers([]);
    setFilteredData([]);
    setAllSelectedUser([]);
    setSelectedRole("all");
  };

  const handleFilterChange = (event) => {
    setSelectedRole(event.target.value);
    const value = event.target.value;
    const regex = new RegExp(value, "i");
    if (value === "all") {
      setAllSelectedUser(allSelectedUser);
    } else {
      const filtered = state?.filter((item) => regex.test(item?.roleName));
      setFilteredData(filtered);
    }
  };

  const uniqueRoles = [...new Set(state?.map((user) => user.roleName))];

  const handleCheckboxChange = (submitById, role) => {
    setSelectedUsers((prevSelected) => {
      const isSelected = prevSelected.find(
        (user) => user.submitById === submitById && user.role === role
      );
      const updatedSelected = isSelected
        ? prevSelected.filter(
            (user) => user.submitById !== submitById && user.role !== role
          )
        : [...prevSelected, { submitById, role }];

      return updatedSelected;
    });

    setAllSelectedUser(selectedUsers);
  };

  useEffect(() => {
    let selectedRow = row?.find((item) => item.stage == stage);
    if (selectedRow) {
      if (selected === "reviewAccess") {
        const reviewAccess = selectedRow?.reviewAccess.map((user) => ({
          submitById: user.reviewerId,
          role: user.role,
        }));
        setSelectedUsers(reviewAccess);
        setAllSelectedUser(reviewAccess);
      } else {
        setSelectedUsers(selectedRow.submissionAccess);
        setAllSelectedUser(selectedRow.submissionAccess);
      }
    }
  }, [stage, selected, filteredData]);
  const filteredselectedData = state?.filter(
    (item) =>
      allSelectedUser.some((selected) => selected.submitById === item.id) &&
      allSelectedUser.some((user) => user.role === item.roleId)
  );
  const displayedData =
    selectedRole === "all" ? filteredselectedData : filteredData;

  return (
    <AppDialog
      open={open}
      onClose={handleClose}
      title="Users"
      minHeight="auto"
      maxWidth="lg"
    >
      <div className="alert alert-info mb-3" role="alert">
        <i className="bi bi-info-circle me-2"></i>
        You can only add users whose signatures are available.
      </div>

      <div className="mb-4 d-flex justify-content-between">
        <div className="d-flex gap-4 w-50">
          <select className="form-select w-70" onChange={handleFilterChange}>
            <option value="all">Select Role</option>
            {uniqueRoles?.map((role, index) => (
              <option key={index} value={role}>
                {role}
              </option>
            ))}
          </select>
          <button className="btn btn-primary" onClick={handleClose}>
            Save
          </button>
        </div>
      </div>
      <div>
        <div
          className="table-responsive"
          style={{ maxHeight: "300px", overflowY: "auto" }}
        >
          <table className="table table-striped table-hover">
            <thead style={{ position: "sticky", top: "0", zIndex: 1 }}>
              <tr>
                <th
                  scope="col"
                  style={{
                    width: "5%",
                    backgroundColor: "#1c4e80",
                    color: "white",
                  }}
                />
                <th
                  scope="col"
                  style={{
                    width: "8%",
                    backgroundColor: "#1c4e80",
                    color: "white",
                  }}
                >
                  Sr.
                </th>
                <th
                  scope="col"
                  style={{
                    width: "20%",
                    backgroundColor: "#1c4e80",
                    color: "white",
                  }}
                >
                  User Name
                </th>
                <th
                  scope="col"
                  style={{
                    width: "20%",
                    backgroundColor: "#1c4e80",
                    color: "white",
                  }}
                >
                  User Code
                </th>
                <th
                  scope="col"
                  style={{
                    width: "20%",
                    backgroundColor: "#1c4e80",
                    color: "white",
                  }}
                >
                  Role Name
                </th>
                <th
                  scope="col"
                  style={{
                    width: "20%",
                    backgroundColor: "#1c4e80",
                    color: "white",
                  }}
                >
                  Signature
                </th>
              </tr>
            </thead>
            <tbody>
              {displayedData?.map((item, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="checkbox"
                      disabled={!item?.signature}
                      checked={selectedUsers.some(
                        (user) =>
                          user.submitById === item.id &&
                          user.role === item.roleId
                      )}
                      onChange={() =>
                        handleCheckboxChange(item.id, item.roleId)
                      }
                    />
                  </td>
                  <td>{index + 1}</td>
                  <td>{item?.username}</td>
                  <td>{item?.userCode}</td>
                  <td>{item?.roleName}</td>
                  <td>
                    {item?.signature ? (
                      "Available"
                    ) : (
                      <span style={{ color: "gray" }}>N/A</span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </AppDialog>
  );
}

export default InspectionUserDialog;

InspectionUserDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  state: PropTypes.array,
  stage: PropTypes.number,
  row: PropTypes.array,
  setRow: PropTypes.func,
  selected: PropTypes.string,
};
