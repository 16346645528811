import React, { useState, useEffect } from "react";
import Loader from "../../components/Loader/Loader";
import { useSelector, useDispatch } from "react-redux";
import StatCharts from "../../components/statistics/StatCharts/StatCharts";
import StatCards from "../../components/statistics/StatCards/StatCards";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fetchCompanyDataPopulate } from "../../redux/slice/CompanySlice/CompanySlice";
import moment from "moment/moment";

function Stats() {
  const { status } = useSelector((state) => state?.Dashboard);
  const today = new Date().toISOString().split("T")[0];
  const halfYearAgo = new Date(new Date().setMonth(new Date().getMonth() - 6))
    .toISOString()
    .split("T")[0];

  const [startDate, setStartDate] = useState(halfYearAgo);
  const [endDate, setEndDate] = useState(today);
  const [searchTriggered, setSearchTriggered] = useState(true);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const { CompanyDataPopulate } = useSelector((state) => state?.Companies);
  const [selectedJobsite, setSelectedJobsite] = useState(null);
  const [allJobSites, setAllJobSites] = useState([]);

  const [companyDetails, setCompanyDetails] = useState({
    companyName: "",
    siteName: "",
    startDate,
    endDate,
  });
  const handleStartDateChange = (date) => {
    const formattedDate = date.toISOString().split("T")[0];
    setStartDate(formattedDate);
  };
  const dispatch = useDispatch();

  const handleEndDateChange = (date) => {
    const formattedDate = date.toISOString().split("T")[0];
    setEndDate(formattedDate);
  };

  const handleSearchClick = () => {
    setSearchTriggered(true);
  };

  useEffect(() => {
    dispatch(fetchCompanyDataPopulate());
  }, [dispatch]);
  useEffect(() => {
    if (CompanyDataPopulate?.length > 0) {
      const defaultCompany = CompanyDataPopulate[0]?.id;
      setSelectedCompany(defaultCompany);
    }
  }, [CompanyDataPopulate, dispatch]);

  useEffect(() => {
    let filteredJobSites;
    if (selectedCompany) {
      filteredJobSites = CompanyDataPopulate.filter(
        (item) => item.id === selectedCompany
      ).flatMap((company) => company.jobSites);
    } else {
      filteredJobSites = CompanyDataPopulate?.flatMap(
        (company) => company.jobSites
      );
    }
    setSelectedJobsite(null);
    setAllJobSites(filteredJobSites);
  }, [CompanyDataPopulate, selectedCompany]);

  console.log("jobsite data", allJobSites);
  useEffect(() => {
    if (selectedCompany && searchTriggered) {
      const selected = CompanyDataPopulate?.find(
        (item) => item.id === selectedCompany
      );

      let selected2 = null;
      if (selectedJobsite) {
        selected2 = allJobSites?.find((item) => item.id === selectedJobsite);
      }
      setCompanyDetails({
        companyName: selected?.companyName,
        siteName: selected2?.name,
        startDate: startDate,
        endDate: endDate,
      });
    }
  }, [searchTriggered, selectedCompany, startDate, endDate]);
  console.log("comapny detail, ", companyDetails);
  return (
    <main
      style={{
        maxHeight: "100vh",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <div
        className="mb-2 d-flex justify-content-between "
        style={{ paddingBottom: "20px" }}
      >
        <div className="d-flex gap-4 w-60">
          <div className=" w-50 d-flex gap-2">
            {" "}
            <label
              htmlFor="startDate"
              className="form-label"
              style={{ paddingRight: "7px" }}
            >
              From
            </label>
            <DatePicker
              selected={startDate}
              onChange={handleStartDateChange}
              className="form-control"
              dateFormat="MM/dd/yyyy"
              placeholderText="Select Start Date"
            />
          </div>

          <div className=" w-50 d-flex gap-2">
            {" "}
            <label
              htmlFor="endDate"
              className="form-label"
              style={{ paddingRight: "7px" }}
            >
              To
            </label>
            <DatePicker
              selected={endDate}
              onChange={handleEndDateChange}
              className="form-control"
              dateFormat="MM/dd/yyyy"
              placeholderText="Select End Date"
            />
          </div>
          <select
            className="form-select w-50 "
            aria-label="Default select example"
            value={selectedCompany}
            onChange={(event) => setSelectedCompany(event.target.value)}
          >
            <option value=" ">Select Company</option>
            {CompanyDataPopulate?.map((item, idx) => (
              <option key={idx} value={item?.id}>
                {item?.companyName}
              </option>
            ))}
          </select>
          <select
            className="form-select w-50 "
            value={selectedJobsite || ""}
            onChange={(event) => setSelectedJobsite(event.target.value)}
          >
            <option value="">Select JobSite</option>
            {allJobSites?.map((site) => (
              <option key={site.id} value={site.id}>
                {site.name}
              </option>
            ))}
          </select>
          <button
            className="btn btn-primary"
            onClick={handleSearchClick}
            style={{ alignSelf: "center", height: "fit-content" }}
          >
            Search
          </button>
        </div>
      </div>
      {companyDetails?.companyName && companyDetails?.siteName ? (
        <p style={{ fontSize: "20px" }}>
          Showing results of company:{" "}
          <span style={{ fontWeight: "bold" }}>
            {companyDetails?.companyName}
          </span>{" "}
          , Site:{" "}
          <span style={{ fontWeight: "bold" }}>{companyDetails?.siteName}</span>{" "}
          from
          <span style={{ fontWeight: "bold" }}>
            {" "}
            {moment(companyDetails?.startDate).format("MM/DD/YYYY")}{" "}
          </span>{" "}
          to
          <span style={{ fontWeight: "bold" }}>
            {" "}
            {moment(companyDetails?.endDate).format("MM/DD/YYYY")}
          </span>
        </p>
      ) : (
        <p style={{ fontSize: "20px" }}>
          Showing results of company:{" "}
          <span style={{ fontWeight: "bold" }}>
            {companyDetails?.companyName}
          </span>{" "}
          from
          <span style={{ fontWeight: "bold" }}>
            {" "}
            {moment(companyDetails?.startDate).format("MMM D, YYYY")}{" "}
          </span>{" "}
          to
          <span style={{ fontWeight: "bold" }}>
            {" "}
            {moment(companyDetails?.endDate).format("MMM D, YYYY")}
          </span>
        </p>
      )}

      <StatCards
        endDate={endDate}
        startDate={startDate}
        search={searchTriggered}
        setSearch={setSearchTriggered}
        comp={selectedCompany}
        jobSiteId={selectedJobsite}
      />
      <div className="divider"></div>
      <StatCharts
        endDate={endDate}
        startDate={startDate}
        search={searchTriggered}
        setSearch={setSearchTriggered}
        comp={selectedCompany}
        jobSiteId={selectedJobsite}
      />

      {status === "loading" ? <Loader /> : null}
    </main>
  );
}

export default Stats;
