import React, { useState } from "react";
import Navbar from "../components/Header/Navbar/Navbar";
import SideDrawer from "../components/Header/SideDrawer/SideDrawer";
import PropTypes from "prop-types";

const Layout = ({ activePage }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(true); // State to manage sidebar collapse

  const toggleDrawer = (open) => {
    setDrawerOpen(open);
  };

  return (
    <div
      style={{
        display: "flex",

        height: "100vh",
        overflow: "hidden",
      }}
    >
      {/* Side Drawer */}
      <aside
        style={{
          width: collapsed ? "68px" : "250px",
          // transition: "width 0.3s",
          transition: "transform 0.3s ",
          position: "relative",
        }}
        className=" d-lg-block bg-sidebar"
      >
        <SideDrawer
          drawerOpen={drawerOpen}
          toggleDrawer={toggleDrawer}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
        />
      </aside>

      {/* Main Content Area */}
      <main
        style={{
          flex: 1,
          padding: "10px",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          marginLeft: collapsed ? "10px" : "10px",
          transition: "margin-left 0.3s",
        }}
      >
        {/* Navbar */}
        <Navbar toggleDrawer={toggleDrawer} drawerOpen={drawerOpen} />

        {/* Page Content */}
        {activePage}
      </main>
    </div>
  );
};

Layout.propTypes = {
  activePage: PropTypes.any.isRequired,
};

export default Layout;
