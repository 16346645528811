import React from "react";
import AppDialog from "../../../common/AppDialog/AppDialog";
import PropTypes from "prop-types";

function UserModal({ open, onClose, state }) {
  return (
    <AppDialog
      open={open}
      onClose={() => {
        onClose(false);
      }}
      title="Users Details"
      minHeight="auto"
      maxWidth="lg"
    >
      <div>
        <div>
          <p>
            <span style={{ fontWeight: "bold" }}>Role Name:</span>{" "}
            {state?.roleName}
          </p>
          <div
            className="table-responsive"
            style={{ maxHeight: "300px", overflowY: "auto" }}
          >
            <table className="table table-striped table-hover">
              <thead style={{ position: "sticky", top: "0", zIndex: 1 }}>
                <tr>
                  <th
                    scope="col"
                    style={{
                      width: "8%",
                      backgroundColor: "#1c4e80",
                      color: "white",
                    }}
                  >
                    Sr.
                  </th>
                  <th
                    scope="col"
                    style={{
                      width: "20%",
                      backgroundColor: "#1c4e80",
                      color: "white",
                    }}
                  >
                    User Name
                  </th>
                  <th
                    scope="col"
                    style={{
                      width: "20%",
                      backgroundColor: "#1c4e80",
                      color: "white",
                    }}
                  >
                    User Code
                  </th>
                  <th
                    scope="col"
                    style={{
                      width: "20%",
                      backgroundColor: "#1c4e80",
                      color: "white",
                    }}
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    style={{
                      width: "20%",
                      backgroundColor: "#1c4e80",
                      color: "white",
                    }}
                  >
                    Packages Count
                  </th>
                  <th
                    scope="col"
                    style={{
                      width: "20%",
                      backgroundColor: "#1c4e80",
                      color: "white",
                    }}
                  >
                    Packages #
                  </th>
                </tr>
              </thead>
              <tbody>
                {state?.users?.map((user, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{user.username}</td>
                    <td>{user.userCode}</td>
                    <td>{user.email}</td>
                    <td>{user.inspectionNumbers.length}</td>
                    <td style={{ fontWeight: "bold" }}>
                      {user.inspectionNumbers.join(", ")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </AppDialog>
  );
}

export default UserModal;

UserModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  state: PropTypes.shape({
    roleName: PropTypes.string,
    users: PropTypes.arrayOf(
      PropTypes.shape({
        username: PropTypes.string,
        userCode: PropTypes.string,
        inspectionNumbers: PropTypes.arrayOf(PropTypes.string),
      })
    ),
  }),
};
