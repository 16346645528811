import React, { useEffect } from "react";
import AppDialog from "../AppDialog/AppDialog";
import { Form as FormikForm, Formik } from "formik";
import * as yup from "yup";
import PropTypes from "prop-types";
import OTPInput from "react-otp-input";
import { Form, Button } from "react-bootstrap";
import { ThreeDots } from "react-loader-spinner";

const validationSchema = yup.object().shape({
  otp: yup
    .string()
    .min(4, "Please add 4-digits OTP!")
    .required("Field Required!"),
});

function VerifyOTPDialog({
  open,
  onClose,
  duration,
  setDuration,
  otpSending,
  resendOTP,
  handleSubmit,
}) {
  useEffect(() => {
    const countdown = setInterval(() => {
      if (duration > 0) {
        setDuration(duration - 1);
      } else {
        clearInterval(countdown);
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, [duration]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return (
    <AppDialog
      open={open}
      onClose={onClose}
      title="Verify OTP"
      maxWidth="md"
      minHeight="auto"
    >
      <Formik
        initialValues={{
          otp: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          setFieldValue,
          isSubmitting,
        }) => (
          <FormikForm>
            <Form.Group className="mb-3">
              <div className="d-flex justify-content-center">
                <div className="d-flex flex-column gap-2">
                  <OTPInput
                    value={values.otp}
                    onChange={(val) => setFieldValue("otp", val)}
                    placeholder={true}
                    numInputs={4}
                    renderInput={(props) => (
                      <Form.Control
                        type="text"
                        name="otp"
                        onBlur={handleBlur}
                        {...props}
                        className="otp-input"
                      />
                    )}
                    inputStyle={{
                      width: "80px",
                      height: "3rem",
                      margin: "0 0.5rem",
                      fontSize: "2rem",
                      borderRadius: "0.25rem",
                      border: "1px solid rgba(0,0,0,0.3)",
                    }}
                  />
                  {touched?.otp && errors?.otp && (
                    <p className="text-danger fs-6">{errors?.otp}</p>
                  )}
                </div>
              </div>
            </Form.Group>

            <div className="d-flex justify-content-between align-items-center mt-3">
              <span>Expires in {formatTime(duration)}</span>

              <div className="d-flex align-items-center gap-2">
                <span>Didn’t receive OTP?</span>
                <Button
                  variant="link"
                  disabled={otpSending}
                  onClick={() => {
                    resendOTP();
                  }}
                >
                  Resend
                </Button>
              </div>
            </div>

            <div className="d-flex justify-content-end mt-3">
              <div className="d-flex align-items-center gap-3">
                <ThreeDots
                  height="40"
                  width="40"
                  radius="9"
                  color="#1c4e80 "
                  visible={isSubmitting || otpSending}
                />

                <Button
                  type="submit"
                  variant="primary"
                  disabled={isSubmitting || !duration}
                >
                  Verify OTP
                </Button>
              </div>
            </div>
          </FormikForm>
        )}
      </Formik>
    </AppDialog>
  );
}

export default VerifyOTPDialog;
VerifyOTPDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  duration: PropTypes.number,
  setDuration: PropTypes.func,
  otpSending: PropTypes.bool,
  resendOTP: PropTypes.func,
  handleSubmit: PropTypes.func,
};
