import { Routes, Route } from "react-router-dom";
import BcrumbsContextProvider from "./context/BcrumbsContextProvider";
import { SnackBarContextProvider } from "./context/SnackBarContext";
import SnackBar from "./components/SnackBar/SnackBar";
import Layout from "./Layouts/Layout";
import NoAccess from "./pages/NotFound/NoAccess";
import AdminRoutes from "./AllRoutes/AllRoutes";
import RequireAuth from "./components/RequireAuth/RequireAuth";
// import SkeletonLayout from "./Layouts/SkeletonLayout";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAuth } from "./context/useAuth";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { fetchUserRolesData } from "./redux/slice/RolesSlice/RolesSlice";

function App() {
  // Loading state to wait for cookie roles

  const { userRoles } = useAuth();
  const dispatch = useDispatch();
  const userId = sessionStorage.getItem("LoginId");
  useEffect(() => {
    if (userId) {
      dispatch(fetchUserRolesData(userId));
    }
  }, [userId]);
  return (
    <div>
      <BcrumbsContextProvider>
        <SnackBarContextProvider>
          <Routes>
            {AdminRoutes().map((route, idx) => {
              if (route?.roleName) {
                return route?.roleName?.find((role) =>
                  userRoles.isAdmin
                    ? role === "Admin"
                    : userRoles.isEmployee
                    ? role === "Employee"
                    : userRoles.isSuperAdmin
                    ? role === "SuperAdmin"
                    : null
                ) ? (
                  <Route
                    key={idx}
                    exact
                    path={route.path}
                    element={route.component}
                  />
                ) : (
                  <Route
                    key={idx}
                    exact
                    path="*"
                    element={
                      <RequireAuth>
                        <Layout activePage={<NoAccess />} />
                      </RequireAuth>
                    }
                  />
                );
              } else {
                return (
                  <Route
                    key={idx}
                    exact
                    path={route.path}
                    element={route.component}
                  />
                );
              }
            })}
          </Routes>
          <SnackBar />
        </SnackBarContextProvider>
      </BcrumbsContextProvider>
    </div>
  );
}

export default App;
